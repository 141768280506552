import React from 'react'
import EnhancedEntete from '../../components/entete/Entete'
import Onglets from '../../components/onglets/Onglets'
import OptionFiscale from './components/OptionFiscale'
import VosMontants from './components/VosMontants'

const titre = 'Fiscalité'

const Fiscalite = () => {
	return (
		<>
			<EnhancedEntete titre={titre} />
			<Onglets />

			{/* Bloc sur l'option fiscale */}
			<OptionFiscale />

			{/* Bloc sur le détail des montants fiscaux */}
			<VosMontants />
		</>
	)
}

export default Fiscalite