import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../../components/button/Button'
import fetchFactory from '../../utils/fetchFactory'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch } from 'react-redux'
import { afficherSnackbar } from '../reducers/snackBar/actions'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { titreOrdreBourse } from './Documents'
import { useHistory } from 'react-router'
import Links from '../../enum/links'

const useStyles = makeStyles(theme => ({
	button: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	icon: {
		width: 32,
		height: 32
	},
	marginButton: {
		margin: '20px 0 0 auto'
	},
	spinnerStyle: {
		textAlign: 'center',

		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	},
	smallSpinnerStyle: {
		textAlign: 'center',

		'& div': {
			width: '54px !important',
			height: '54px !important'
		}
	},
	documentContainer: {
		width: '-webkit-fill-available',
		marginBottom: 20,
		position: 'relative',

		'& > div': {
			backgroundColor: 'white',
			textAlign: 'left',

			'& h2': {
				margin: 10,
				marginLeft: 0,
				color: '#122941'
			},
			'& p': {
				margin: 0,
				color: '#707070',
				font: 'normal normal normal 16px/24px Montserrat'
			}
		}
	},
	documentContainerLargeScreen: {
		'& > img': {
			background: theme.palette.brown.main,
			padding: 35,
			position: 'absolute',
			left: 0,
			top: 25
		},
		'& > div': {
			marginLeft: 60,
			padding: '25px 50px 35px 120px',

			'& h2': {
				font: 'normal normal bold 36px/40px BlackerDisplay'
			}
		}
	},
	documentContainerSmallScreen: {
		'& > img': {
			background: theme.palette.brown.main,
			padding: 15,
			height: 26,
			position: 'absolute',
			left: -8,
			top: 10
		},
		'& > div': {
			padding: '25px 30px 35px',

			'& h2': {
				font: 'normal normal bold 20px/32px BlackerDisplay',
				marginLeft: 30
			},
			'& p': {
				font: 'normal normal normal 14px/24px Montserrat'
			}
		}
	},
	marginIcon: {
		marginRight: 11
	},
	marginBottom: {
		marginBottom: 10
	}
}))

interface ModelBouton {
	id: number,
	titre: string,
	description: string,
	texte: string,
	icon: string
}

interface AcceptingProps {
	tailleEcran: string,
	boutons: ModelBouton[]
}

type BoutonsDocumentsProps = AcceptingProps

const BoutonsDocuments: React.FC<BoutonsDocumentsProps> = (
	{
		tailleEcran,
		boutons
	}
) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()

	const [loadingFile, setLoadingFile] = React.useState<string>('')

	const documentContainerClass: ClassValue = classNames(
		classes.documentContainer,
		tailleEcran === 'grand' ? classes.documentContainerLargeScreen : classes.documentContainerSmallScreen
	)

	const marginButtonClass: ClassValue = classNames(
		'col-xs-7 col-sm-3',
		classes.marginButton
	)


	const onButtonClicked = (texte: string) => {
		setLoadingFile(texte)

		fetchFactory('/api/document?document=' + texte, {
			method: 'POST'
		}, 'blob')
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = texte.toLocaleLowerCase().replace(/\s/g, '_') + '.pdf'
					a.click()
				}
			)
			.catch(
				(error: any) => {
					if (error.status === 404) {
						dispatch(
							afficherSnackbar(
								{
									id: 'documents',
									message: 'Le fichier que vous souhaitez télécharger n\'existe pas',
									open: true,
									hideIcon: true
								}
							)
						)

					} else if (error.status === 403) {
						dispatch(
							afficherSnackbar(
								{
									id: 'documents',
									message: 'Le titulaire est inconnu',
									open: true,
									hideIcon: true
								}
							)
						)
					}
				}
			)
			.finally(
				() => {
					setLoadingFile('')
				}
			)
	}

	return (
		<div className="row center-sm">
			{
				boutons.map(bouton => (
					<div
						className={documentContainerClass}
						key={bouton.id}
					>
						<img src={bouton.icon} alt="Document icon" />
						<div>
							<h2>{bouton.titre}</h2>
							<p>{bouton.description}</p>
							{
								bouton.titre === titreOrdreBourse ? (
									<div
										className={marginButtonClass}
									>
										<Button
											disabled={false}
											onClick={() => history.push(Links.bourseForm)}
										>
											<span>Formulaire en ligne</span>
										</Button>
									</div>
								) : (
									<div
										className={marginButtonClass}
									>
										{
											loadingFile === bouton.texte ? (
												<div className={classes.smallSpinnerStyle}>
													<CircularProgress color="primary" />
												</div>
											) : (
												<Button
													disabled={false}
													onClick={() => onButtonClicked(bouton.texte)}
												>

													<img className={classes.marginIcon} src="/img/picto_download.svg"
													     alt="Picto download" />
													<span>Télécharger</span>
													<span></span>
												</Button>
											)
										}
									</div>
								)
							}
						</div>
					</div>
				))
			}
		</div>
	)
}

export default BoutonsDocuments
