import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../theme/styles'

const useStyle = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            cookiesClass: {
                '& h1': {
                    margin: '40px 0 15px',
                    color: theme.palette.primary.main,
                    ...styles(theme).title,
                    fontSize: 24
                },

                '& p': {
                    marginBottom: 0,
                    color: '#000000',
                    ...styles(theme).text,
                    ...styles(theme).titleSize,

                    '& strong': {
                        marginTop: 20
                    },

                    '& i': {
                        color: theme.palette.primary.main,
                        fontWeight: 600
                    }
                },

                '& .title': {
                    '& h1': {
                        marginBottom: 30,
                        textAlign: 'center'
                    }
                },
                '& a': {
                    color: theme.palette.blue.main
                }
            },
            listStyle: {
                listStyleType: '- '
            }
        }
    )
)

const Cookies = () => {
    const classes = useStyle()

    return (
        <div  className={classes.cookiesClass}>
            <div className="title">
                <h1>Cookies</h1>
            </div>

            <div className="cookies">
                <p>Le site espace-actionnaires.gecina.fr édité par GECINA utilise des cookies afin de vous offrir un
                    accès personnalisé et faciliter votre navigation.</p>
                <br/>
                <p>Cette page permet de comprendre ce qu’est un cookie, à quoi il sert et comment on peut le paramétrer.
                    Nous nous réservons le droit de modifier cette page à tout moment, notamment afin de se conformer à
                    la règlementation applicable.</p>
                <br/>
                <p>En utilisant ce site et en acceptant cette politique, vous nous autorisez à faire usage de cookies
                    conformément aux conditions de celle-ci.</p>
                <br/>
                <p>
                    <i>A propos des cookies</i>
                </p>
                <br/>
                <p>Les cookies sont des fichiers-textes souvent porteurs d’un identifiant unique. Ils sont envoyés par
                    les serveurs web aux navigateurs web, puis retournés aux serveurs web dès que les navigateurs
                    demandent l’accès à une page web.</p>
                <br/>
                <p>Les serveurs web se servent des cookies pour identifier et suivre les utilisateurs lorsque ces
                    derniers consultent différentes pages d’un site Internet, et pour repérer ceux qui accèdent
                    plusieurs fois au même site Internet. Les cookies facilitent votre navigation et garantissent la
                    sécurité des connexions. Les cookies peuvent être persistants ou temporaires. Un cookie persistant
                    est un fichier-texte envoyé par un serveur web à un navigateur web et stocké par ce dernier. Un
                    cookie persistant reste valide jusqu’à la date d’expiration fixée (à moins que l’utilisateur ne le
                    supprime avant la date d’expiration). Un cookie temporaire (ou dit de "session") expire quant à lui
                    à la fin de la session de l’utilisateur, lors de la fermeture du navigateur web.</p>
                <br/>
                <p>
                    <i>Cookies utilisés sur ce site internet</i>
                </p>
                <br/>
                <p>GECINA utilise des cookies temporaires et persistants sur son site Internet. Les Cookies que nous
                    pouvons émettre sont répartis en 2 catégories :
                    <ul>
                        <li>Cookies de fonctionnement : Il s'agit des cookies indispensables à la navigation sur notre
                            site (comme les identifiants de session) qui vous permettent d'utiliser les principales
                            fonctionnalités du site et de sécuriser votre connexion.
                        </li>
                        <li>Cookies de performance et d’analyse : Il s'agit de cookies qui nous permettent de connaître
                            l'utilisation et les performances de notre site, d'établir des statistiques, des volumes de
                            fréquentation et d'utilisation des divers éléments de notre site (contenus visités,
                            parcours) nous permettant d'améliorer l'intérêt et l'ergonomie de nos services (les pages ou
                            les rubriques les plus souvent consultées, les articles les plus lus, ...). Les cookies nous
                            servent également à compter les visiteurs d'une page.
                        </li>
                    </ul>
                </p>
                <br/>
                <p>
                    <i>Mode d'utilisation des cookies</i>
                </p>
                <br/>
                <p>Les cookies ne contiennent aucune information susceptible de vous identifier directement ; nous avons
                    cependant la possibilité d’associer des informations personnelles collectées par d’autres moyens aux
                    données stockées dans les cookies et obtenues par leur intermédiaire.</p>
                <br/>
                <p>Le site espace-actionnaires.gecina.fr peut utiliser les informations sur votre mode d’utilisation de
                    son site Internet collectées via des cookies aux fins suivantes :
                    <ul>
                        <li>reconnaître votre ordinateur lors de la consultation de son site Internet ;</li>
                        <li>vous suivre lorsque vous naviguez sur son site Internet et vous permettre d’utiliser des
                            fonctionnalités spécifiques personnalisées ;
                        </li>
                        <li>simplifier l’utilisation de son site Internet ;</li>
                        <li>analyser le mode d’utilisation de son site Internet ;</li>
                        <li>vous proposer des contenus personnalisés ;</li>
                    </ul>
                </p>
                <br/>
                <p>
                    <i>Cookies tiers</i>
                </p>
                <br/>
                <p>Lorsque vous utilisez le site Internet espace-actionnaires.gecina.fr, vous êtes également susceptible
                    de recevoir des cookies de tiers. Le site Internet peut contenir des cookies envoyés par des tierces
                    parties. Le site espace-actionnaires.gecina.fr décline toute responsabilité à l’égard des cookies
                    provenant de tierces parties.</p>
                <br/>
                <p>En outre, le site espace-actionnaires.gecina.fr fait appel à Google Analytics pour analyser le mode
                    d’utilisation de son site Internet. Google Analytics génère des statistiques et d’autres
                    informations relatives à l’utilisation du site Internet à l’aide de cookies stockés dans
                    l’ordinateur de l’utilisateur. Google enregistre ces données.</p>
                <br/>
                <p>La politique de confidentialité de Google est disponible à l’adresse :
                    <a rel="noopener noreferrer" target="_blank" href="https://www.google.com/privacypolicy.html">www.google.com/privacypolicy.html.</a></p>
                <br/>
                <p>
                    <i>Durée de validité des cookies</i>
                </p>
                <br/>
                <p>Les cookies dits technique que nous émettons sont configurés pour une durée de validité de 13
                    (treize) mois. Les cookies nécessitant le consentement sont configurés pour une durée de validité de
                    6 (six) mois. Toutes les informations recueillis par l’intermédiaire de tous les cookies sont
                    conservées pendant au maximum 25 (vingt-cinq) mois.</p>
                <br/>
                <p>
                    <i>Paramétrage des cookies</i>
                </p>
                <br/>
                <p>La plupart des navigateurs permettent de refuser les cookies :
                    <ul>
                        <li>Internet Explorer™ : <a rel="noopener noreferrer" target="_blank" href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a>
                        </li>
                        <li>Safari™ : <a rel="noopener noreferrer" target="_blank" href="https://support.apple.com/fr-afri/guide/safari/sfri11471/mac">https://support.apple.com/fr-afri/guide/safari/sfri11471/mac</a></li>
                        <li>Chrome™: <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647">https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647</a>
                        </li>
                        <li>Firefox™: <a rel="noopener noreferrer" target="_blank" href="https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies</a>
                        </li>
                    </ul>
                </p>
                <p>Attention, le blocage de l’ensemble des cookies peut toutefois avoir un impact négatif sur la
                    simplicité d’utilisation du site Internet, dans la mesure où vous ne pourrez en utiliser toutes les
                    fonctionnalités.</p>
                <br/>
                <p>
                    <i>Plus d'information sur les cookies</i>
                </p>
                <br/>
                <p>
                    <ul>
                        <li>Pour plus d'information sur les cookies, vous pouvez vous rendre sur le site de la CNIL, à
                            cette adresse : <a rel="noopener noreferrer" target="_blank" href="https://www.cnil.fr/vos-droits/vos-traces/les-cookies/">www.cnil.fr/vos-droits/vos-traces/les-cookies/</a></li>
                    </ul>
                </p>
            </div>
        </div>
    )
}

export default Cookies