import React, {useEffect} from 'react'
import styles from '../../theme/styles'
import {makeStyles} from '@material-ui/core/styles'
import formatDate from '../../utils/formatDate'
import {useMediaQuery} from 'react-responsive'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    textCenter: {
        textAlign: 'center'
    },
    titleSizeGrand: {
        marginTop: 53,
        marginBottom: 35,
        fontSize: 48
    },
    titleSizePetit: {
        marginTop: 10,
        marginBottom: 32,
        fontSize: 28
    },
    headerStyle: {
        background: theme.palette.blue.main
    },
    headerLargeScreenStyle: {
        padding: '40px 77px 40px 56px',
        marginBottom: 53
    },
    headerSmallScreenStyle: {
        padding: '20px 16px 46px',
        marginBottom: 32
    },
    headerWithoutTitleLargeScreen: {
        marginTop: 80
    },
    headerWithoutTitleSmallScreen: {
        marginTop: 32
    },
    accountStyle: {
        fontWeight: 'normal',
        color: 'white',

        '& h2, & p': {
            margin: 0
        },
        '& span': {
            fontWeight: 800,
            whiteSpace: 'nowrap'
        }
    },
    accountLargeScreenStyle: {
        '& h2': {
            fontSize: 24
        },
        '& p': {
            lineHeight: '35px',
            fontSize: 16
        }
    },
    accountSmallScreenStyle: {
        '& h2': {
            fontSize: 18,
            marginBottom: 32
        },
        '& p': {
            lineHeight: '25px',
            fontSize: 13
        }
    },
    textSizePetit: {
        fontSize: 14
    },
    back: {
        width: 170
    },
    backItem: {
        verticalAlign: 'middle',
        fontWeight: 600,

        '&:first-child': {
            marginRight: 10
        },
        '&:last-child': {
            color: theme.palette.primary.main
        }
    }
}))

interface AcceptingProps {
    titre?: string
}

interface ModelUtilisateur {
    numero_compte: string,
    numero_titulaire: string,
    type: string,
    derniere_date_modification: string
}

type EnteteProps = AcceptingProps

const Entete: React.FC<EnteteProps> = (
    {
        titre
    }
) => {
    const classes = useStyles()
    const utilisateur = localStorage.getItem('utilisateur')
    const [numeroCompte, setNumeroCompte] = React.useState<string>('')
    const [numeroTitulaire, setNumeroTitulaire] = React.useState<string>('')
    const [type, setType] = React.useState<string>('')
    const [date, setDate] = React.useState<string>('')

    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )

    const titleClass: ClassValue = classNames(
        classes.colorPrimary,
        classes.title,
        classes.heavy,
        isLargeScreen ? classes.titleSizeGrand : classes.titleSizePetit
    )

    const headerClass: ClassValue = classNames(
        'row',
        classes.headerStyle,
        {
            [classes.headerLargeScreenStyle]: isLargeScreen,
            [classes.headerSmallScreenStyle]: !isLargeScreen,
            [classes.headerWithoutTitleLargeScreen]: isLargeScreen && !titre,
            [classes.headerWithoutTitleSmallScreen]: !isLargeScreen && !titre
        }
    )

    const accountClass: ClassValue = classNames(
        'row',
        classes.accountStyle,
        isLargeScreen ? classes.accountLargeScreenStyle : classes.accountSmallScreenStyle
    )

    useEffect(() => {
        if (localStorage.getItem('utilisateur')) {
            const utilisateur: ModelUtilisateur = JSON.parse(localStorage.getItem('utilisateur') || '')

            setNumeroCompte(utilisateur.numero_compte)
            setNumeroTitulaire(utilisateur.numero_titulaire)
            setType(utilisateur.type)
            setDate(formatDate(new Date(utilisateur.derniere_date_modification)))

        }
    }, [utilisateur])


    return (
        <>
            {
                titre && (
                    <h1 className={titleClass}>
                        {titre}
                    </h1>
                )
            }

            <div className={headerClass}>
                <div className="col-xs-9">
                    <div className={accountClass}>
                        <div className="col-xs-12 col-md-6">
                            <h2>Compte Titres</h2>
                        </div>

                        <div className="col-xs-12 col-md-6">
                            <p>n° <span>{numeroCompte}</span></p>
                            {
                                type.toLowerCase() === 'indivision' || type.toLowerCase() === 'usufruit/nue-propriete' || type.toLowerCase() === 'compte joint de titres' ? (
                                    <>
                                        <p>Titulaire n° {numeroTitulaire}</p>
                                        <p>Type de compte: <span>{type}</span></p>
                                    </>
                                ) : ''
                            }

                            <p>au <span>{date}</span></p>
                        </div>
                    </div>
                </div>

                <div className="col-xs-3 end-xs">
                    <img
                        src="/img/entete.png"
                        alt="Compte titre icon"
                        width={isLargeScreen ? 96 : 55}
                    />
                </div>
            </div>
        </>
    )
}

export default Entete
