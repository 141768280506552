import { MuiThemeProvider } from '@material-ui/core/styles'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-intl-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import Root from './containers/Root'
import './font/blackerdisplay/blackerdisplay.css'
import './font/montserrat/montserrat.css'
import './index.css'
import rootReducer from './modules/reducers'
import theme from './theme/theme'
import { setupOkta } from './utils/authClient'
import localStorageEnum from './enum/localStorageEnum'
import ReactGA from 'react-ga'
import ResponsiveContextProvider from './utils/context/responsiveContext';

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user, { client_id, redirect_uri, issuer }) => {
	// Create a history of your choosing (we're using a browser history in this case)
	const history = createBrowserHistory()

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	ReactGA.initialize('UA-191783827-1')

	history.listen(
		(location) => {
			ReactGA.pageview(`${location.pathname}${location.search}`);
		}
	)

	// En mode dév, on utilise le devtools
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	// Création du store de redux
	let store = createStore(
		rootReducer,
		compose(...enhancers)
	)

	ReactDOM.render(
		<Provider store={store}>
			<ResponsiveContextProvider>
				<ConnectedRouter history={history}>
					<MuiThemeProvider theme={theme}>
						<Root
							client_id={client_id}
							redirect_uri={redirect_uri}
							issuer={issuer}
						/>
					</MuiThemeProvider>
				</ConnectedRouter>
			</ResponsiveContextProvider>
		</Provider>,
		document.getElementById('root')
	)

}


const oktaConfig = () => {
	fetch('/api/conf/okta')
		.then(
			response => {
				if (response.ok) {
					response.json()
						.then(
							(data) => {
								setupOkta(data.client_id, data.redirect_uri, data.issuer)

								if (localStorage.getItem(localStorageEnum.bourseDocument)) {
									localStorage.removeItem(localStorageEnum.bourseDocument)
								}

								window.localStorage.setItem(localStorageEnum.commonUri,
									JSON.stringify(
										{
											login_uri: data.login_uri,
											cookie_domain: data.cookie_domain
										}
									)
								)

								reactInit({}, {
									client_id: data.client_id,
									redirect_uri: data.redirect_uri,
									issuer: data.issuer
								})
							}
						)
				} else {
					setTimeout(
						() => {
							oktaConfig()
						}, 1000
					)
				}
			}
		)
		.catch(
			() => {
				setTimeout(
					() => {
						oktaConfig()
					}, 1000
				)
			}
		)
}

oktaConfig()
