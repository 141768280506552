import React from 'react'
import EnhancedListeBoutonsGrand from './ListeBoutonsGrand'
import ListeTitresGrand from './ListeTitresGrand'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'

const useStyles = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            containerStyle: {
                padding: 0,
                borderTop: '1px solid #D8D8D8'
            },
            backgroundColor: {
                backgroundColor: 'white'
            }
        }
    )
)
const CorpsDetailAvoirsGrand = () => {

    const classes = useStyles()

    const containerDetails: ClassValue = classNames(
        'col-xs-12',
        classes.containerStyle,
        classes.backgroundColor
    )

    return (
        <div className="row">
            <div className={containerDetails}>
                <ListeTitresGrand/>
                <div className="row col-md-12 end-md">
                    <EnhancedListeBoutonsGrand/>
                </div>
            </div>
        </div>
    )
}

export default CorpsDetailAvoirsGrand