import React from 'react'
import ListeDroitsActionsPetit from './ListeDroitsActionsPetit'
import ListeDroitsStockPetit from './ListeDroitsStockPetit'

interface ModelDroitsActions {
	id: number,
	libelle_plan: string,
	date_attribution?: Date,
	date_debut_conservation?: Date,
	quantite: number
}

interface ModelDroitsStock {
	id: number,
	libelle_plan: string,
	quantite: number
	prix_offre?: number,
	date_debut_plan?: Date,
	date_fin_plan?: Date,
	bold?: boolean
}

interface AcceptingProps {
	droitsActions: ModelDroitsActions[],
	droitsStock: ModelDroitsStock[],
	telechargement: (id: number, type: string) => void
}

type CorpsDroitsActionsPetitProps = AcceptingProps

const CorpsDroitsActionsPetit = (
	props: CorpsDroitsActionsPetitProps
) => {
	const {
		droitsActions,
		droitsStock,
		telechargement
	} = props

	return (
		<>
			{
				droitsActions.length > 0 &&
				<ListeDroitsActionsPetit
					droitsActions={droitsActions}
					telechargement={telechargement}
				/>
			}

			{
				droitsStock.length > 0 &&
				<ListeDroitsStockPetit
					droitsStock={droitsStock}
					telechargement={telechargement}
				/>
			}
		</>
	)
}


export default CorpsDroitsActionsPetit
