import React from 'react'
import Dialog from '../../components/dialog/Dialog'
import MaterialDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import { useSelector } from 'react-redux'
import { RootState } from '../reducers'
import CguModal from './components/CguModal'

interface UserModel {
    hasCgu: boolean
}

const Cgu = () => {
    const theme = useTheme()
    const user: UserModel = useSelector((state: RootState) => state.utilisateur)

    const [open, setOpen] = React.useState(!user.hasCgu)
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <MaterialDialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="cgu_modal"
            fullWidth
            maxWidth="md"
        >
            <Dialog
                hasButton={false}
            >
                <CguModal closeDialog={() => setOpen(false)}/>
            </Dialog>
        </MaterialDialog>
    )
}

export default Cgu