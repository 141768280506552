import React, { ReactEventHandler } from 'react'
import { FormControl, FormHelperText, WithStyles, withStyles } from '@material-ui/core'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import classNames from 'classnames'
import { InjectedFormProps } from 'redux-form'
import customStyle, { getStyles } from '../../theme/styles'

const styles = (theme: any) => getStyles({
	...customStyle(theme),
	bootstrapFormLabel: {
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(0.3),
		fontSize: 13,
		transform: 'scale(1)'
	},
	errorLabel: {
		color: theme.palette.error.main
	},
	errorText: {
		marginBottom: theme.spacing(2.7),
		color: theme.palette.error.main,
		fontSize: 10
	},
	editor: {
		'& .ck-editor .ck-editor__main .ck-focused': {
			borderColor: theme.palette.primary.main
		}
	},
	editorError: {
		'& .ck-editor .ck-editor__main .ck-editor__editable': {
			borderColor: theme.palette.error.main
		}
	}
})

interface AcceptingProps {
	label: string,
	configuration: {
		toolbar: string[]
	},
	meta: {
		touched: boolean
	},
	disabled: boolean,
	input: {
		onChange: (text: string) => void,
		value: string
	}
}

type FormRichTextProps =
	InjectedFormProps
	& WithStyles<typeof styles>
	& AcceptingProps

const FormRichText: React.FC<FormRichTextProps> = (
	{
		label,
		input: {
			value,
			onChange
		},
		configuration,
		error,
		meta,
		disabled,
		classes
	}
) => {
	return (
		<FormControl
			className={
				classNames(
					classes.editor,
					classes.fullWidth,
					{
						[classes.editorError]: typeof error !== 'undefined' && (!meta || meta.touched)
					}
				)
			}
		>
			{
				!!label &&
				<span
					className={
						[
							classes.text,
							classes.bold,
							classes.smallSize,
							classes.colorPrimary,
							meta.touched && error && classes.errorColor
						].join(' ')
					}
				>
				{label}
			</span>
			}

			<CKEditor
				editor={ClassicEditor}
				data={value || ''}
				disabled={disabled}
				config={configuration}
				onChange={(event: ReactEventHandler, editor: { getData: () => string }) => onChange(editor.getData())}
			/>

			{
				typeof error !== 'undefined' && (!meta || meta.touched) &&
				<FormHelperText className={classes.errorText}>
					{error}
				</FormHelperText>
			}
		</FormControl>
	)
}

export default withStyles(styles)(FormRichText)
