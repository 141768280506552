import React, {Dispatch, useEffect} from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import CorpsDisponibiliteAvoirsGrand from './grandEcran/CorpsDisponibiliteAvoirsGrand'
import CorpsDisponibiliteAvoirsPetit from './petitEcran/CorpsDisponibiliteAvoirsPetit'
import RecapitulatifAvoirs from './RecapitulatifAvoirs'
import fetchFactory from '../../utils/fetchFactory'
import errorCatcher from '../../utils/errorCatcher'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import Onglets from '../../components/onglets/Onglets'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import styles from '../../theme/styles'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    spinnerStyle: {
        textAlign: 'center',

        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    },
    backgroundColor: {
        backgroundColor: 'white'
    }
}))

interface ModelRecapitulatif {
    total_actions: number,
    actions_cessibles: number,
    valorisation: number,
    montant_cours: number,
    date_cours: Date
}

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date,
    typologie: string,
    actions: string,
    prix_acquisition: number
}

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    message: string
}

interface ModelStoreDisponibilites {
    recapitulatif: ModelRecapitulatif,
    disponibilites: ModelDisponibilites[],
    indisponibilites: ModelIndisponibilites[]
}

interface Store {
    errorCatcher: (error: any, id: string) => void,
    storeDisponibiliteAvoirs: ModelStoreDisponibilites
    location: Location
}

const titre: string = 'Disponibilité de vos avoirs'

type DisponibiliteAvoirsProps = Store

const DisponibiliteAvoirs = (
    props: DisponibiliteAvoirsProps
) => {
    const {
        errorCatcher,
        storeDisponibiliteAvoirs
    } = props

    const classes = useStyles()

    const [recapitulatif, setRecapitulatif] = React.useState<ModelRecapitulatif>(
        {
            total_actions: 0,
            actions_cessibles: 0,
            valorisation: 0,
            montant_cours: 0,
            date_cours: new Date()
        }
    )
    const [disponibilites, setDisponibilites] = React.useState<ModelDisponibilites[]>([])
    const [indisponibilites, setIndisponibilites] = React.useState<ModelIndisponibilites[]>([])

    const [loading, setLoading] = React.useState<boolean>(true)

    useEffect(() => {
        if (storeDisponibiliteAvoirs.disponibilites && storeDisponibiliteAvoirs.disponibilites.length > 0) {
            setRecapitulatif(storeDisponibiliteAvoirs.recapitulatif)

            setDisponibilites(
                storeDisponibiliteAvoirs.disponibilites.sort(
                    (a: ModelDisponibilites, b: ModelDisponibilites) => {
                        if (a.typologie > b.typologie) {
                            return 1
                        }
                        if (a.typologie < b.typologie) {
                            return -1
                        }
                        return 0
                    }
                )
            )

            setIndisponibilites(
                storeDisponibiliteAvoirs.indisponibilites.sort(
                    (a: ModelIndisponibilites, b: ModelIndisponibilites) => {
                        if (a.date_disponibilite > b.date_disponibilite) {
                            return 1
                        }
                        if (a.date_disponibilite < b.date_disponibilite) {
                            return -1
                        }
                        return 0
                    }
                )
            )

            setLoading(false)
        } else {
            fetchFactory('/api/disponibilite/avoirs')
                .then(
                    (disponibilite_avoirs: any) => {
                        setRecapitulatif(disponibilite_avoirs.recapitulatif)

                        setDisponibilites(
                            disponibilite_avoirs.disponibilites.sort(
                                (a: ModelDisponibilites, b: ModelDisponibilites) => {
                                    if (a.typologie > b.typologie) {
                                        return 1
                                    }
                                    if (a.typologie < b.typologie) {
                                        return -1
                                    }
                                    return 0
                                }
                            )
                        )

                        setIndisponibilites(
                            disponibilite_avoirs.indisponibilites.sort(
                                (a: ModelIndisponibilites, b: ModelIndisponibilites) => {
                                    if (a.date_disponibilite > b.date_disponibilite) {
                                        return 1
                                    }
                                    if (a.date_disponibilite < b.date_disponibilite) {
                                        return -1
                                    }
                                    return 0
                                }
                            )
                        )
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'detail_avoirs')
                    }
                )
                .finally(
                    () => {
                        setLoading(false)
                    }
                )
        }
    }, [errorCatcher, storeDisponibiliteAvoirs])

    const containerStyle: ClassValue = classNames(
        'row',
        classes.backgroundColor
    )

    return (
        <>
            <EnhancedEntete titre={titre}/>
            <Onglets/>

            <MediaQuery
                maxWidth={1024}
            >

                {
                    (match) => {
                        return match ? (
                            <>
                                {
                                    loading ? (
                                        <div className={classes.spinnerStyle}>
                                            <CircularProgress color="primary"/>
                                        </div>
                                    ) : (
                                        <div className={containerStyle}>
                                            <RecapitulatifAvoirs
                                                tailleEcran="petit"
                                                recapitulatif={recapitulatif}
                                            />

                                            <CorpsDisponibiliteAvoirsPetit
                                                disponibilites={disponibilites}
                                                indisponibilites={indisponibilites}
                                            />
                                        </div>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                {
                                    loading ? (
                                        <div className={classes.spinnerStyle}>
                                            <CircularProgress color="primary"/>
                                        </div>
                                    ) : (
                                        <div className={containerStyle}>
                                            <div className="col-md-12">
                                                <RecapitulatifAvoirs
                                                    tailleEcran="grand"
                                                    recapitulatif={recapitulatif}
                                                />

                                                <CorpsDisponibiliteAvoirsGrand
                                                    disponibilites={disponibilites}
                                                    indisponibilites={indisponibilites}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                }
            </MediaQuery>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        storeDisponibiliteAvoirs: state.disponibiliteAvoirs
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedDisponibiliteAvoirs = compose<DisponibiliteAvoirsProps, {}>(
    connect(mapStateToProps, mapDispatchToProps)
)(DisponibiliteAvoirs)

export default EnhancedDisponibiliteAvoirs
