import {ENREGISTRER_UTILISATEUR, SUPPRIMER_UTILISATEUR, UPDATE_CGU} from './actionTypes'

export const enregistrerUtilisateur = (utilisateur) => {
	return ({
		type: ENREGISTRER_UTILISATEUR,
		payload: utilisateur
	})
}

export const supprimerUtilisateur = () => {
	return ({
		type: SUPPRIMER_UTILISATEUR
	})
}

export const updateCgu = (hasCgu) => (
	{
		type: UPDATE_CGU,
		payload: hasCgu
	}
)