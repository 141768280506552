import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldRenderProps } from 'react-final-form'
import classNames from 'classnames'
import 'date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Grid } from '@material-ui/core'
import styles from '../../theme/styles'
import 'moment/locale/fr'
import { getNextDate } from '../../utils/formatDate'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			errorStyle: {
				color: '#B92D00',
				fontSize: 18,
				fontWeight: 500,
				opacity: 0.7,
				lineHeight: 1.33
			},
			errorColor: {
				color: '#B92D00 !important'
			},
			inputRoot: {
				borderRadius: 40,
				border: `0.5px solid ${theme.palette.blue['30']}`,
				backgroundColor: theme.palette.blue['5']
			},
			input: {
				padding: '15px 24px'
			},
			focused: {
				border: `0.5px solid ${theme.palette.blue['30']}`
			},
			button: {
				opacity: 0.5
			},
			dropDownPaper: {
				marginTop: 8,
				borderRadius: 8,
				boxShadow: '0 10px 20px 0 rgba(18, 41, 65, 0.2)',

				'& .MuiPickersToolbar-toolbar': {
					backgroundColor: theme.palette.blue.main,

					'& .MuiPickersToolbarText-toolbarTxt, & .MuiPickersToolbarText-toolbarBtnSelected': {
						color: 'white'
					}
				}
			},
			buttonManual: {
				display: 'none'
			},
			materialInput: {
				'&:before': {
					left: 0,
					right: 0,
					bottom: 0,
					content: '""',
					position: 'absolute',
					transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					borderBottom: '2px solid #d0d4d9',
					pointerEvents: 'none'
				},
				'&:after': {
					left: 0,
					right: 0,
					bottom: 0,
					content: '""',
					position: 'absolute',
					transform: 'scaleX(0)',
					transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
					borderBottom: '2px solid #00ACA9',
					pointerEvents: 'none'
				}
			}
		}
	)
)

interface AcceptingProps {
	id: string,
	label?: string,
	inputTextStyle?: string,
	disableToolbar?: boolean,
	isGreyBackground?: boolean,
	hasDefault?: boolean,
	selectFuture?: boolean,
	manual?: boolean
}

type FormDatePickerFieldProps =
	FieldRenderProps<string>
	& AcceptingProps

const FormDatePickerField: React.FC<FormDatePickerFieldProps> = (
	{
		input,
		meta: {
			touched,
			error
		},
		inputTextStyle,
		disableToolbar = true,
		isGreyBackground = true,
		hasDefault = true,
		manual = false,
		selectFuture = false
	}
) => {
	const classes = useStyles()
	const [open, setOpen] = useState<boolean>(false)

	return (
		<Grid container>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
					autoOk
					disableToolbar={disableToolbar}
					onClose={() => setOpen(false)}
					fullWidth
					open={open}
					variant="inline"
					minDate={selectFuture ? getNextDate() : null}
					format={manual ? 'DD/MM/yyyy' : 'D MMMM yyyy'}
					invalidDateMessage="Format invalide"
					value={input.value && input.value.length > 0 ? input.value : (hasDefault ? new Date() : null)}
					onChange={(a: any) => {
						if (a) {
							return input.onChange(a.format('yyyy-MM-DD'))
						}

						return input.onChange('')
					}}
					KeyboardButtonProps={{
						classes: {
							root: manual ? classes.buttonManual : classes.button
						}
					}}
					InputProps={{
						classes: {
							root: isGreyBackground ? classes.inputRoot : classes.materialInput,
							input: classNames(
								inputTextStyle,
								{
									[classes.input]: isGreyBackground
								}
							),
							focused: classes.focused
						},
						disableUnderline: true,
						readOnly: !manual,
						onClick: () => !manual && setOpen(true)
					}}
					PopoverProps={{
						classes: {
							paper: classes.dropDownPaper
						}
					}}
					FormHelperTextProps={{
						className: inputTextStyle
					}}
				/>
			</MuiPickersUtilsProvider>
			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</Grid>
	)
}

export default FormDatePickerField
