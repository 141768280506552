import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import React, { Dispatch, useEffect } from 'react'
import errorCatcher from '../../utils/errorCatcher'
import { compose } from 'recompose'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import fetchFactory from '../../utils/fetchFactory'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Button from '../../components/button/Button'
import { afficherSnackbar } from '../reducers/snackBar/actions'
import parse from 'html-react-parser'
import { RootState } from '../reducers'
import { updateClubActionnaireLastCheck } from './api/updateClubActionnaireLastCheck'
import { enregistrerUtilisateur } from '../reducers/utilisateur/actions'
import localStorageEnum from '../../enum/localStorageEnum'

const useStyle = makeStyles(theme => ({
	...styles(theme),
	spinnerStyle: {
		marginTop: 50,
		textAlign: 'center',

		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	},
	button: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	marginButtonTop: {
		marginTop: 17,
		'& button': {
			width: 'max-content',
			padding: '15px 20px'
		}
	},
	icon: {
		width: 70,
		height: 70
	},
	marginButtonSmallScreen: {
		margin: '20px 0',
		padding: 0,

		'& button': {
			width: 'auto'
		}
	},
	marginButtonFormulaireSmallScreen: {
		margin: '20px 0',
		'& button': {
			width: '100%',
			'& span': {
				width: 'auto'
			}
		}
	},
	marginButtonLargeScreen: {
		margin: '40px 0 0 0',

		'& button': {
			width: 'max-content',
			padding: '15px 20px'
		}
	},
	smallSpinnerStyle: {
		textAlign: 'center',

		'& div': {
			width: '54px !important',
			height: '54px !important'
		}
	},
	container: {
		marginBottom: 40,
		position: 'relative',

		'& > div': {
			backgroundColor: 'white',
			textAlign: 'justify',

			'& h2': {
				margin: 0,
				marginBottom: 10,
				color: theme.palette.blue.main
			},
			'& p': {
				fontSize: 18,
				lineHeight: 1.67,
				margin: 0,
				color: theme.palette.blue[80],
				font: 'normal normal normal 16px/24px Montserrat'
			}
		}
	},
	telecharger: {
		marginLeft: 17
	},
	textButtonSavoirPlus: {
		marginRight: 12
	},
	containerPresentationLargeScreen: {
		'& > img': {
			background: theme.palette.brown.main,
			padding: 40,
			position: 'absolute',
			left: 0,
			top: 32
		},
		'& > div': {
			marginLeft: 100,
			padding: '45px 79px 61px 118px',

			'& h2': {
				font: 'normal normal bold 36px/40px BlackerDisplay'
			}
		}
	},
	containerPresentationSmallScreen: {
		marginTop: 350,

		'& > img': {
			background: theme.palette.brown.main,
			padding: 17,
			height: 30,
			position: 'absolute',
			left: 0,
			top: -30
		},
		'& > div': {
			marginLeft: 20,
			padding: '57px 30px 35px',

			'& h2': {
				font: 'normal normal bold 28px/32px BlackerDisplay'
			},
			'& p': {
				font: 'normal normal normal 14px/24px Montserrat'
			}
		}
	},
	containerSavoirPlusLargeScreen: {
		marginLeft: 100,

		'& > div': {
			padding: '43px 40px 42px 46px',
			'& > img': {
				marginBottom: 25
			},
			'& h2': {
				fontFamily: 'BlackerDisplay',
				fontSize: 24
			}
		}
	},
	containerActualitesLargeScreen: {
		padding: 0,
		position: 'relative',
		marginRight: 130,

		'& > div': {
			padding: '82px 368px 123px 96px',
			minHeight: 430,
			'& h2': {
				fontFamily: theme.typography.fontFamilyBlackerDisplay,
				fontSize: 36,
				color: theme.palette.blue.main,
				maxWidth: 416,
				fontWeight: 900
			},
			'& h3': {
				fontFamily: theme.typography.fontFamilyMonserrat,
				fontWeight: 600,
				fontSize: 20,
				color: theme.palette.blue.main,
				maxWidth: 423
			},
			'& p': {
				fontFamily: theme.typography.fontFamilyMonserrat,
				fontWeight: 300,
				fontSize: 16,
				color: theme.palette.blue.main,
				textAlign: 'left',
				maxWidth: 521
			},
			'& img': {
				position: 'absolute'
			}
		}
	},
	imgHaut: {
		width: 189,
		height: 304,
		top: -41,
		right: -125,
		objectFit: 'cover',
		filter: 'grayscale(100%)'
	},
	imgMileu: {
		width: 189,
		height: 304,
		top: 111,
		right: 100,
		objectFit: 'cover',
		filter: 'grayscale(100%)'
	},
	imgBas: {
		width: 189,
		height: 304,
		bottom: 45,
		right: -125,
		objectFit: 'cover',
		filter: 'grayscale(100%)'
	},
	containerSmallScreen: {
		'& > div': {
			padding: '25px 30px 35px',

			'& h2': {
				marginTop: 20,
				font: 'normal normal 900 20px/32px BlackerDisplay'
			}
		}
	},
	clubActionnaires: {
		fontFamily: theme.typography.fontFamilyBlackerDisplay,
		color: theme.palette.blue.main
	},
	clubActionnairesLargeScreenStyle: {
		marginLeft: 100,
		fontSize: 36
	},
	clubActionnairesSmallScreenStyle: {
		fontSize: 28,
		marginBottom: 30
	},
	actualites: {
		fontFamily: theme.typography.fontFamilyBlackerDisplay,
		color: theme.palette.blue.main
	},
	actualitesLargeScreenStyle: {
		fontSize: 36
	},
	actualitesSmallScreenStyle: {
		fontSize: 20
	},
	imgHeaderContainer: {
		overflow: 'hidden',
		height: 387,

		'& img': {
			filter: 'brightness(0.65)',
			marginTop: '-16%'
		},
		'& span': {
			position: 'absolute',
			backgroundColor: 'black',
			opacity: 0.5,
			width: '100%',
			height: '100%'
		},
		'& div': {
			position: 'absolute',

			'& h1': {
				fontWeight: 800,
				color: 'white',
				fontFamily: theme.typography.fontFamilyBlackerDisplay,
				textAlign: 'left'
			},
			'& h2': {
				maxHeight: 62,
				lineHeight: 1.47,
				fontFamily: theme.typography.fontFamilyMonserrat,
				fontSize: 20,
				color: 'white',
				fontWeight: 'normal',
				textAlign: 'left'
			}
		}
	},
	imgHeaderContainerLargeScreenStyle: {
		position: 'relative',
		marginTop: 17,

		'& img': {
			width: '100%'
		},
		'& div': {
			left: 51,
			top: 57,

			'& h1': {
				maxWidth: 660,
				fontSize: 48
			},
			'& h2': {
				maxWidth: 674,
				marginTop: 100
			}
		}
	},
	imgHeaderContainerSmallScreenStyle: {
		position: 'absolute',
		left: 0,
		margin: 0,
		padding: 0,

		'& img': {
			height: '129%'
		},
		'& div': {
			left: 25,
			top: 15,
			width: '100%',

			'& h1': {
				maxWidth: '70%',
				fontSize: 36
			},
			'& h2': {
				maxWidth: '70%',
				marginTop: 20,
				fontSize: 14
			}
		}
	},
	containerActualitesSmallScreen: {
		position: 'relative',
		marginTop: 0,

		'& > div:first-child': {
			background: 'inherit',
			width: '100%',
			margin: '0 0 -80px 0',

			'& img': {
				width: '100%',
				filter: 'grayscale(100%)',
				height: '30vw',
				minHeight: 140,
				objectFit: 'cover'
			}
		},
		'& > div:nth-child(2)': {
			padding: '100px 15px 25px',

			'& h2': {
				fontSize: 20,
				fontWeight: 900,
				fontFamily: theme.typography.fontFamilyBlackerDisplay,
				marginBottom: 30
			},
			'& h3': {
				fontSize: 14,
				fontWeight: 600,
				fontFamily: theme.typography.fontFamilyMonserrat,
				color: theme.palette.primary.main
			},
			'& p': {
				fontSize: 12,
				fontWeight: 300,
				color: theme.palette.primary.main
			}
		}
	},
	imgNoActu: {
		'& img': {
			margin: '0 20px',
			filter: 'grayscale(100%)'
		},
		'& img:first-child': {
			marginLeft: 100
		}

	},
	imgNoActuSmallScreen: {
		'& img': {
			margin: '0 20px',
			filter: 'grayscale(100%)'
		},
		'& img:first-child': {
			objectFit: 'unset',
			transform: 'scale(0.6)',
			margin: '0 -37px'
		}
	}
}))

interface ModelAdministration {
	titreClub: string,
	accrocheClub: string,
	presentationClub: string,
	titreActualiteClub: string,
	accrocheActualiteClub: string,
	actualiteClub: string,
	libelle_1: string,
	libelle_2: string,
	url_1: string,
	url_2: string
}

const clubActionnaires: string = 'Le club des actionnaires'
const enSavoirPlus: string = 'En savoir plus...'
const actualites: string = 'Actualités'
const UPDATE_LAST_CHECK = 'updateClubActionnaireLastCheck'

interface Store {
	errorCatcher: (error: any, id: string) => void
}

type ClubActionnairesProps = Store

const ClubActionnaire = (props: ClubActionnairesProps) => {
	const {
		errorCatcher
	} = props

	const classes = useStyle()
	const utilisateur = useSelector((state: RootState) => state.utilisateur)
	const dispatch = useDispatch()

	const [bloc, setBloc] = React.useState<ModelAdministration>()
	const [loadingBlocs, setLoadingBlocs] = React.useState<boolean>(false)
	const [loadingFile, setLoadingFile] = React.useState<string>('')

	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	useEffect(
		() => {
			setLoadingBlocs(true)

			fetchFactory('/api/blocs')
				.then(
					(clubActionnaires: any) => {

						setBloc(clubActionnaires)
					}
				)
				.catch(
					(error) => errorCatcher(error, 'club_Actionnaires')
				)
				.finally(
					() => setLoadingBlocs(false)
				)
		}, [errorCatcher]
	)

	useEffect(
		() => {
			if (!utilisateur.aVuNouveauteActionnaire) {
				updateClubActionnaireLastCheck()
					.then(
						() => {
							dispatch(
								enregistrerUtilisateur(
									{
										...utilisateur,
										aVuNouveauteActionnaire: true
									}
								)
							)
							localStorage.setItem(localStorageEnum.user, JSON.stringify({ ...utilisateur, aVuNouveauteActionnaire: true }))
						}
					)
					.catch(
						(error) => errorCatcher(error, UPDATE_LAST_CHECK)
					)
			}
		}, [dispatch, errorCatcher, utilisateur]
	)

	const onButtonClicked = (texte: string) => {
		setLoadingFile(texte)

		fetchFactory(`/api/document?document=${texte}`
			, {
				method: 'POST'
			}, 'blob')
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = `${texte.toLocaleLowerCase().replace(/\\s/g, '_')}.pdf`
					a.click()
				}
			)
			.catch(
				(error: any) => {
					if (error.status === 404) {
						dispatch(
							afficherSnackbar(
								{
									id: 'documents',
									message: 'Le fichier que vous souhaitez télécharger n\'existe pas',
									open: true,
									hideIcon: true
								}
							)
						)

					} else if (error.status === 403) {
						dispatch(
							afficherSnackbar(
								{
									id: 'documents',
									message: 'Le titulaire est inconnu',
									open: true,
									hideIcon: true
								}
							)
						)
					}
				}
			)
			.finally(
				() => {
					setLoadingFile('')
				}
			)
	}

	const containerPresentationClass: ClassValue = classNames(
		'col-xs-12',
		classes.container,
		isLargeScreen ? classes.containerPresentationLargeScreen : classes.containerPresentationSmallScreen
	)

	const containerActualitesClass: ClassValue = classNames(
		classes.container,
		isLargeScreen ? classes.containerActualitesLargeScreen : classes.containerActualitesSmallScreen
	)

	const savoirPlusContainerClass: ClassValue = classNames(
		'col-xs-12 col-md-7 start-md',
		classes.container,
		isLargeScreen ? classes.containerSavoirPlusLargeScreen : classes.containerSmallScreen
	)

	const marginButtonClass: ClassValue = classNames(
		{
			'col-xs-12 col-sm-3': !isLargeScreen
		},
		isLargeScreen ? classes.marginButtonLargeScreen : classes.marginButtonSmallScreen
	)
	const marginButtonFormulaireClass: ClassValue = classNames(
		{
			'col-xs-12 col-sm-3': !isLargeScreen
		},
		isLargeScreen ? classes.marginButtonLargeScreen : classes.marginButtonFormulaireSmallScreen
	)

	const clubActionnairesClass: ClassValue = classNames(
		classes.clubActionnaires,
		isLargeScreen ? classes.clubActionnairesLargeScreenStyle : classes.clubActionnairesSmallScreenStyle
	)

	const actualitesClass: ClassValue = classNames(
		'row col-xs-12',
		classes.actualites,
		isLargeScreen ? classes.actualitesLargeScreenStyle : classes.actualitesSmallScreenStyle
	)

	const buttonFiscaliteClass: ClassValue = classNames(
		{
			'col-xs-12 col-sm-3': !isLargeScreen
		},
		isLargeScreen ? classes.marginButtonTop : classes.marginButtonSmallScreen
	)

	const imageHeaderClass: ClassValue = classNames(
		'col-xs-12 center-xs',
		classes.imgHeaderContainer,
		isLargeScreen ? classes.imgHeaderContainerLargeScreenStyle : classes.imgHeaderContainerSmallScreenStyle
	)

	const imageNoActuClass: ClassValue = classNames(
		'row col-xs-12 start-xs',
		isLargeScreen ? classes.imgNoActu : classes.imgNoActuSmallScreen
	)

	return (
		<div className="row">
			{

				loadingBlocs ? (
					<div className="col-xs-12 center-xs">
						<div className={classes.spinnerStyle}>
							<CircularProgress color="primary" />
						</div>
					</div>
				) : (
					<>
						<div className={imageHeaderClass}>
							<img src="/img/visite_madrid_header.png" alt="Header page" />

							<div>
								<h1>
									{
										bloc && bloc.titreClub.length > 0 && bloc.accrocheClub.length > 0 &&
										parse(bloc.titreClub)
									}
								</h1>

								<h2>
									{
										bloc && bloc.accrocheClub.length > 0
										&& parse(bloc.accrocheClub)
									}
								</h2>
							</div>
						</div>

						<div className="col-xs-12">
							<h2 className={clubActionnairesClass}>{clubActionnaires}</h2>
						</div>

						<div className={containerPresentationClass}>
							<img src="/img/pictos-profil.svg" alt="Profil icon" />
							<div>
								{
									bloc && bloc.presentationClub.length > 0 &&
									<p>
										{
											parse(bloc?.presentationClub || '')
										}
									</p>
								}
								<div
									className={marginButtonFormulaireClass}
								>
									{
										loadingFile === 'Demande d\'adhesion au club' ? (
											<div className={classes.smallSpinnerStyle}>
												<CircularProgress color="primary" />
											</div>
										) : (<Button
											disabled={false}
											onClick={() => onButtonClicked('Demande d\'adhesion au club')}
										>
                                        <span className={classes.button}>
											<img src="/img/picto_download.svg" alt="Picto download" />
											<span className={classes.telecharger}>
                                                Télécharger le formulaire
											</span>
										</span>
										</Button>)
									}
								</div>
							</div>
						</div>

						<div className={savoirPlusContainerClass}>

							<div>
								<img src="/img/pictos-recherche@2x.png" alt="Profil icon" />
								<h2>
									{enSavoirPlus}
								</h2>
								<div
									className={marginButtonClass}
								>

									{
										bloc && bloc.libelle_1.length > 0 && bloc.url_1.length > 0 &&
										<Button
											disabled={false}
											onClick={() => window.open(bloc?.url_1, '_blank')}
										>
                                        <span className={classes.button}>
											<span className={classes.textButtonSavoirPlus}>
                                                {
	                                                parse(bloc.libelle_1)
                                                }
											</span>
                                            <img src="/img/pictos-arrow-d.svg" alt="Picto arrow" />
										</span>
										</Button>
									}
								</div>
								<div
									className={buttonFiscaliteClass}
								>{
									bloc && bloc.libelle_2.length > 0 && bloc.url_2.length > 0 &&
									<Button
										disabled={false}
										onClick={() => window.open(bloc?.url_2, '_blank')}
									>
                                        <span className={classes.button}>
											<span className={classes.textButtonSavoirPlus}>
                                                 {
	                                                 parse(bloc.libelle_2)
                                                 }
											</span>
                                            <img src="/img/pictos-arrow-d.svg" alt="Picto arrow" />
										</span>
									</Button>
								}</div>
							</div>
						</div>
						<div className="col-md-5" />

						{bloc && bloc.accrocheActualiteClub.length > 0 ?
							<>
								<h2 className={actualitesClass}>{actualites}</h2>

								<div className={containerActualitesClass}>
									{
										!isLargeScreen && (
											<div className="row bottom-xs">
												<div className="col-xs-4">
													<img
														src="/img/fresque_du_climat.jpg"
														alt="Fresque du climat"
														title="Atelier La fresque du climat"
													/>
												</div>

												<div className="col-xs-4">
													<img
														src="/img/visite_madrid.jpg"
														alt="Visite Madrid"
														title="Visite de patrimoine"
													/>
												</div>

												<div className="col-xs-4">
													<img
														src="/img/rencontre_à_Lyon2.jpg"
														alt="Rencontre à Lyon"
														title="Rencontre d'actionnaires en province"
													/>
												</div>
											</div>
										)
									}
									<div>
										{
											bloc && bloc.titreActualiteClub.length > 0 &&
											<h2>
												{
													parse(bloc?.titreActualiteClub || '')
												}
											</h2>

										}
										{
											bloc && bloc.accrocheActualiteClub.length > 0 &&
											<h3>
												{
													parse(bloc?.accrocheActualiteClub || '')
												}
											</h3>
										}
										{
											bloc && bloc.actualiteClub.length > 0 &&
											<p>
												{
													parse(bloc?.actualiteClub || '')
												}
											</p>
										}
										{
											isLargeScreen && (
												<>
													<img
														className={classes.imgHaut}
														src="/img/fresque_du_climat.jpg"
														alt="Fresque du climat"
														title="Atelier La fresque du climat"
													/>
													<img
														className={classes.imgMileu}
														src="/img/visite_madrid.jpg"
														alt="Visite Madrid"
														title="Visite de patrimoine"
													/>
													<img
														className={classes.imgBas}
														src="/img/rencontre_à_Lyon2.jpg"
														alt="Rencontre à Lyon"
														title="Rencontre d'actionnaires en province"
													/>
												</>
											)
										}
									</div>
								</div>
							</> :
							<>

								<div className={imageNoActuClass}>
									<div className="col-xs-4">
										<img
											className={classes.imgHaut}
											src="/img/fresque_du_climat.jpg"
											alt="Fresque du climat"
										/>
									</div>
									<div className="col-xs-4">
										<img
											className={classes.imgMileu}
											src="/img/visite_madrid.jpg"
											alt="Visite Madrid"
										/>
									</div>
									<div className="col-xs-4">
										<img
											className={classes.imgBas}
											src="/img/rencontre_à_Lyon2.jpg"
											alt="Rencontre à Lyon"
										/>
									</div>
								</div>

							</>
						}
					</>
				)
			}
		</div>
	)
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		errorCatcher: (error: any, id: string) => {
			dispatch(errorCatcher(error, id))
		}
	}
}

const EnhancedClubActionnaires = compose<ClubActionnairesProps, {}>(
	connect(null, mapDispatchToProps)
)(ClubActionnaire)

export default EnhancedClubActionnaires
