import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../../components/fields/FormTextField'
import Button from '../../../components/button/Button'
import { useDispatch } from 'react-redux'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import useTheme from '@material-ui/core/styles/useTheme'
import styles from '../../../theme/styles'
import { activateAccountModel, registerPostModel } from '../type/logInModel'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import activateStatusEnum from './enum/activateStatusEnum'
import errorCatcher from '../../../utils/errorCatcher'
import { activateAccount, errors, resetPassword } from '../utils/commonConst'
import { isEmailOk } from '../../../utils/formUtils'
import ErrorCard from '../../../components/errorCard/ErrorCard'
import Link from '../../../components/link/Link'

const FR = 'fr-FR'
const EN = 'en-US'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 15
	},
	linkStyle: {
		padding: '25px 0px'
	},
	descriptionStyle: {
		paddingTop: 15
	},
	emailSentContainer: {
		textAlign: 'center'
	},
	formContainer: {
		'& button': {
			width: '100%'
		}
	}
}))

interface AcceptingProps {
	setGoToActivateAccount: (value: boolean) => void
}

type ActivateAccountPageProps = AcceptingProps

const ActivateAccountPage: React.FC<ActivateAccountPageProps> = (
	{
		setGoToActivateAccount
	}
) => {
	const classes = useStyle()
	const theme = useTheme()
	const dispatch = useDispatch()
	const [emailSent, setEmailSent] = React.useState<boolean>(false)
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)
	const [errorMessage, setErrorMessage] = React.useState<string>('')

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		!emailSent && classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorPrimary
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.fieldStyle
	)

	const descriptionClass: ClassValue = classNames(
		'col-xs-12',
		classes.colorPrimary,
		classes.text2,
		classes.descriptionStyle
	)

	const onSubmit = (values: activateAccountModel) => {
		const dataRegister: registerPostModel = {
			email: values.email,
			identifiant: values.identifiant,
			validationCode: values.validationCode,
			password: values.password,
			locale: window.navigator.language === FR ? FR : EN
		}

		fetch('/api/okta/register',
			{
				body: JSON.stringify(dataRegister),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(registerResponse: any) => {
							switch (registerResponse) {
								case activateStatusEnum.OK:
									setEmailSent(true)
									break
								case activateStatusEnum.ACTIVATION_CODE_KO:
									setIncorrectCode(true)
									setErrorMessage(errors.activationCodeKO)
									break
								case activateStatusEnum.EMAIL_ACTIVATION_KO:
									setIncorrectCode(true)
									setErrorMessage(errors.activationCodeKO)
									break
								default:
									setIncorrectCode(true)
									setErrorMessage(errors.activationCodeKO)
							}
						}
					)
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'register')
					)
				}
			)
	}

	const backToLogIn = () => {
		setGoToActivateAccount(false)
	}

	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault()
		return false
	}
	const validate = (
		values: activateAccountModel
	) => {
		const error: any = {}

		//Validate email and confirmEmail
		if (!values.email) {
			error.email = errors.required
		} else if (!isEmailOk(values.email)) {
			error.email = errors.formatEmail
		}

		if (!values.confirmEmail) {
			error.confirmEmail = errors.required
		}

		if (values.email && values.confirmEmail && values.confirmEmail !== values.email) {
			error.confirmEmail = errors.notSameEmail
		}

		//Validate Identifiant
		if (!values.identifiant) {
			error.identifiant = errors.required
		}

		//Validate Validation code
		if (!values.validationCode) {
			error.validationCode = errors.required
		}

		return error
	}

	return (
		<>
			{
				emailSent ?
					<div className={classes.emailSentContainer}>
						<CheckCircleIcon style={{ color: theme.palette.blue.main }} />

						<div className={titleClass}>
							{activateAccount.emailSent.title}
						</div>

						<div className={descriptionClass}>
							{activateAccount.emailSent.description}
						</div>
					</div>
					:
					<>
						<div className={titleClass}>
							{activateAccount.title}
						</div>

						{
							incorrectCode &&
							<div className={containerErrorClass}>
								<ErrorCard message={errorMessage} />
							</div>
						}

						<Form
							onSubmit={onSubmit}
							validate={validate}
							render={
								(
									{
										handleSubmit
									}
								) => (

									<form
										onSubmit={handleSubmit}
									>
										<div className={classes.formContainer}>
											<div className="col-xs-12">
												<div className={classes.fieldStyle}>
													<Field
														name="email"
														component={FormTextField}
														placeholder={activateAccount.email}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="confirmEmail"
														component={FormTextField}
														placeholder={activateAccount.confirmEmail}
														inputOnPaste={onPaste}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="identifiant"
														component={FormTextField}
														placeholder={activateAccount.identifiant}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="validationCode"
														component={FormTextField}
														placeholder={activateAccount.validationCode}
													/>
												</div>
												<Button
													background="backgroundPrimary"
													color="colorTertiary"
													type="submit"
													disabled={false}
												>
													{activateAccount.activate}
												</Button>
											</div>
										</div>
									</form>

								)}
						/>
					</>
			}

			<div className="col-xs-12">
				<div className={classes.linkStyle}>
					<Link
						textStyle="text8"
						color="colorPrimary"
						onClick={backToLogIn}
					>
						{resetPassword.backSignIn}
					</Link>
				</div>
			</div>

		</>
	)
}

export default ActivateAccountPage