enum Links {
    login = '/login',
    checkOkta = '/implicit/callback',
    activateAccount = '/activate',
    resetPassword = '/reset/password',
    accueil = '/accueil',
    compteTitres = '/compte/titres',
    detailAvoirs = '/detail/avoirs',
    dispoAvoirs = '/disponibilite/avoirs',
    droitsActions = '/droits/actions',
    donneesPerso = '/donnees/personnelles',
    coordonneesBancaires = '/coordonnees/bancaires',
    contacts = '/contacts',
    documentsUtiles = '/documents',
    documentsPerso = '/documents/personnels',
    mentionsLegales = '/mentions/legales',
    protectionDonnees = '/protection/donnees',
    conditionsGenerales = '/conditions/generales',
    glossaire = '/glossaire',
    protected = '/protected',
    oauth = '/oauth2',
    clubActionnaires = '/club/actionnaires',
    historiqueOperations = '/historique/operations',
    administrationAccueil = '/administration/accueil',
    administrationClub = '/administration/club',
    cookies = '/cookies',
    eServices = '/e-services',
    bourseForm = '/formulaire/bourse',
    fiscalite = '/fiscalite'
}

export default Links
