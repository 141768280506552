import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../theme/styles'

const useStyle = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            conditionsContainerClass: {
                '& h1': {
                    margin: '40px 0 15px',
                    color: theme.palette.primary.main,
                    ...styles(theme).title,
                    fontSize: 24
                },

                '& p': {
                    marginBottom: 0,
                    color: '#000000',
                    ...styles(theme).text,
                    ...styles(theme).titleSize,

                    '& strong': {
                        marginTop: 20
                    },

                    '& i': {
                        color: theme.palette.primary.main,
                        fontWeight: 600
                    }
                },

                '& .title': {
                    '& h1': {
                        marginBottom: 30,
                        textAlign: 'center'
                    }
                }
            },
            listStyle: {
                listStyleType: '- '
            }
        }
    )
)

const ConditionsGenerales = () => {
    const classes = useStyle()

    return (
        <div className={classes.conditionsContainerClass}>
            <div className="title">
                <h1>Conditions Générales d’Utilisation</h1>
            </div>

            <div className="condition">
                <p>Dans cet espace actionnaire, les actionnaires de GECINA dont les comptes-titres sont gérés en direct
                    par le Service Titres & Bourse de la Société peuvent accéder à un espace sécurisé leur permettant de
                    consulter leurs compte-titres, retrouver rapidement des informations et des documents et contacter
                    les équipes en charge de leur compte-titres.</p>
                <br/>
                <p>Par les présentes conditions générales d’utilisation (ci-après « CGU »), GECINA (ci-après « GECINA »
                    ou « l’Editeur ») rappelle à l’utilisateur (ci-après « l’Utilisateur ») les droit et obligations
                    lors de la navigation sur le site du site <a
                        href="www.espace-actionnaires.gecina.fr">www.espace-actionnaires.gecina.fr</a>. (ci-après le «
                    Site »).
                    Elles sont accessibles sur le Site à la rubrique prévue à cet effet au pied de chacune des pages du
                    Site.</p>
                <p>L’Utilisateur dispose de la faculté de sauvegarder, d’éditer et d’imprimer les présentes CGU. </p>
                <br/>
                <p>Leur acceptation est préalable et obligatoire. L’activation du compte par l’utilisateur implique
                    l’adhésion aux CGU accessibles via le mail de confirmation reçu par l’utilisateur en cliquant sur le
                    lien prévu à cet effet.</p>
                <br/>
                <p>L’Editeur se réserve le droit de modifier les présentes CGU à tout moment afin, notamment, de les
                    adapter aux évolutions du Site. L’Utilisateur s’engage donc à les consulter régulièrement.</p>
                <br/>
                <p>Les nouvelles CGU entreront en vigueur à compter de leur date de mise en ligne sur le Site. Elles
                    s’imposent à l’Utilisateur qui a la possibilité de les consulter au moment de sa connexion et à tout
                    moment en se référant à la rubrique <u>« conditions générales d’utilisation »</u>. Les CGU figurant
                    en ligne prévalent sur toute version imprimée de date antérieure.</p>
                <br/>
                <p>L’Utilisateur déclare avoir obtenu de la part de GECINA toutes les informations nécessaires quant au
                    fonctionnement, aux modalités d’accès et à l’utilisation du Site.</p>
                <br/>
                <p><i>Objet de l’espace actionnaire</i></p>
                <br/>
                <p>Cet environnement est mis à disposition des actionnaires dont les comptes-titres sont gérés en direct
                    par le service Titres & Bourse de Gecina pour leur permettre d’accéder aux informations ou services
                    suivants :
                    <ul>
                        <li>Situation de leurs compte-titres,</li>
                        <li>Détail des avoirs,</li>
                        <li>Suivi des opérations réalisées (achat, vente, souscription aux opérations réservées pour les
                            salariés),
                        </li>
                        <li>Editions de relevé de compte-titres,</li>
                        <li>Consultation de toutes informations et documents liés au compte,</li>
                        <li>Consultation des coordonnées personnelles de l’actionnaire et possibilité de les mettre à
                            jour,
                        </li>
                    </ul>
                </p>

                <p><i>Accessibilité du site</i></p>
                <br/>
                <p>L’espace mis à disposition des actionnaires est accessible à l’adresse suivante :
                    <a href="www.espace-actionnaires.gecina.fr">www.espace-actionnaires.gecina.fr</a>.</p>
                <br/>
                <p>L’accès au Site est possible en tout lieu à tout Utilisateur ayant un accès à Internet. Il est
                    gratuit (hors coût de connexion à Internet). Tous les frais supportés par l'Utilisateur pour accéder
                    au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>
                <br/>
                <p>Dans le cadre d’une obligation de moyens, le présent Site est accessible 24 heures sur 24, 7 jours
                    sur 7, sauf cas de force majeure, pannes informatiques ou problèmes liés aux réseaux de
                    télécommunications, fait(s) de tiers et/ou travaux de maintenance et interventions nécessaires au
                    bon fonctionnement du Site. Dans ce cas, cette interruption sera notifiée au moyen d’un
                    avertissement figurant sur la page d’accueil ou par tout autre procédé. Cette interruption ne peut
                    en aucun cas engager la responsabilité de l’Editeur et n’ouvre droit à aucune indemnité.</p>
                <br/>
                <p>Création du compte de l’Utilisateur : l’accès à l’espace actionnaires nécessite un enregistrement
                    préalable en se connectant au Site et en complétant le formulaire « Créer un compte ». Cette demande
                    nécessite l’utilisation d’un identifiant, d’une adresse mail, d’un code de validation et d’un mot de
                    passe. L’Utilisateur s’engage à fournir des informations sincères et exactes.</p>
                <p>Pour tout accès ultérieur au Site, l’Utilisateur pourra s'identifier à l'aide de son identifiant ou
                    login et de son mot de passe, mot de passe que l’Utilisateur aura créé lors de sa première
                    connexion, sur invitation reçue de l’Editeur à se connecter.</p>
                <br/>
                <p>À tout moment et en cas de perte ou d’oubli, les mots de passe peuvent être réinitialisés depuis le
                    formulaire « Se connecter – Mot de passe oublié ».</p>
                <br/>
                <p>À tout moment l’Utilisateur connecté au Site peut modifier certaines de ses informations en se
                    rendant dans la rubrique <u>« Mes données personnelles » ou « Mes coordonnées bancaires »</u>.</p>
                <p>À tout moment l’Utilisateur peut se désinscrire en adressant sa demande par courrier postal à Gecina,
                    DPO, 16 rue des Capucines, 75084 Paris cedex 02 ou par courrier électronique à
                    <a href="mailto:protectiondesdonneesfinances@gecina.fr">protectiondesdonneesfinances@gecina.fr</a>,
                    une copie d’un titre d’identité pouvant lui être demandé en
                    cas de doute sur son identité.</p>
                <p>L’identifiant Utilisateur et le mot de passe sont strictement personnels et confidentiels.
                    L’Utilisateur est seul responsable de la préservation de la confidentialité de son mot de passe et
                    de tous actes effectués en l’utilisant. Il se reconnaît en outre seul responsable des conséquences
                    de leur divulgation ou de leur utilisation par un tiers.</p>
                <br/>
                <p>La connexion à partir du mot de passe est présumée émaner exclusivement de l’Utilisateur à qui il
                    appartient.</p>
                <br/>
                <p>Suppression de compte : Gecina se réserve le droit de désinscrire un Utilisateur sans délai ni
                    formalité, dans les cas suivants :
                    <ul className={classes.listStyle}>
                        <li> utilisation non-conforme aux présentes CGU ;</li>
                        <li> utilisation de termes ou propos excessifs, violents, injurieux ou contraires aux bonnes
                            mœurs par l’Utilisateur lors des démarches ou demandes qu’il est amené à effectuer par
                            l’intermédiaire du Site ;
                        </li>
                        <li> tentative de connexion non autorisée, par utilisation frauduleuse du système ou par
                            usurpation de codes d’accès.
                        </li>
                    </ul>
                </p>
                <br/>
                <br/>
                <p><i>Responsabilité et force majeure</i></p>
                <br/>
                <p>Gecina est soumise à une obligation de moyen et n’est responsable que des dommages directs. Ni
                    l’Editeur, ni ses dirigeants, employés ou autres représentants ne pourront être tenus pour
                    responsables d’une éventuelle impossibilité d’accès au Site du fait de la configuration particulière
                    du poste informatique de l’Utilisateur ou de son accès au réseau internet.</p>
                <br/>
                <p>D’une manière générale, l’Editeur ne saurait être responsable de la qualité du service, de la
                    perturbation de l’utilisation du Site, de l’impossibilité de l’utiliser, des atteintes à la sécurité
                    informatique pouvant causer des dommages aux matériels informatiques de l’Utilisateur et à ses
                    données.</p>
                <br/>
                <p>L’Editeur ne saurait être tenu responsable de la violation des présentes conditions générales par un
                    Utilisateur.</p>
                <br/>
                <p>L’Utilisateur reconnaît que, nonobstant tous les moyens que l’Editeur met en œuvre, l’Internet et les
                    réseaux télécoms présentent des spécificités techniques qui impliquent l’impossibilité de garantir
                    la continuité ininterrompue de l’accès au Site et le temps de réponse du Site.</p>
                <p>L’Editeur décline toute responsabilité quant aux dommages pouvant survenir, quelles qu’en soient les
                    causes, origines, natures ou conséquences susceptibles de priver partiellement ou totalement
                    l’Utilisateur de l’accès et/ou du fonctionnement du Site.
                </p>
                <p>L’Editeur ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur
                    ou tout matériel informatique de l’Internaute, à la suite d’une utilisation, d’un accès, ou d’un
                    téléchargement provenant du Site.</p>
                <p>La responsabilité de l’Editeur ne peut être engagée en cas de force majeure ou du fait imprévisible
                    et insurmontable d'un tiers.</p>
                <p>Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du Site ou
                    serveur, et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas
                    la responsabilité de l’Editeur. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à
                    l’Editeur de toute interruption ou suspension de service, même sans préavis.</p>
                <p>L'Utilisateur a la possibilité de contacter l’Editeur par messagerie électronique à l’adresse email
                    de l’Editeur communiquée dans les mentions légales accessibles via le lien suivant : mentions
                    légales.</p>
                <p>Malgré des mises à jour régulières, l’Editeur ne peut être tenu responsable de la modification des
                    dispositions administratives et juridiques survenant après la publication. L'ensemble des
                    informations accessibles via ce Site est fourni en l'état. Gecina ne donne aucune garantie,
                    explicite ou implicite, et n'assume aucune responsabilité relative à l'utilisation de ces
                    informations. L'Utilisateur est seul responsable de l'utilisation de telles informations. </p>
                <p>L'Utilisateur s'engage à ne transmettre sur ce Site aucune information pouvant entraîner une
                    responsabilité civile ou pénale et s'engage à ce titre à ne pas divulguer via ce Site des
                    informations illégales, contraires à l'ordre public ou diffamatoires.
                </p>
                <br/>
                <br/>
                <p><i>Propriété intellectuelle</i></p>
                <br/>
                <p>Le présent site Internet doit être considéré comme un tout indissociable. L’ensemble des éléments y
                    figurant (informations, données, texte, sons, images, dessins, graphismes, signes distinctifs,
                    logos, marques, etc.), et, d’une manière générale, toutes les informations et/ou documents contenus
                    dans et sur le Site, ainsi que tous les éléments créés pour le Site sont soit la propriété exclusive
                    de l’Editeur, soit font l’objet de droits d’exploitation, d’utilisation, de reproduction et/ou de
                    représentation consentis au profit de ce dernier.</p>
                <br/>
                <p>Ces informations, documents et éléments sont soumis à la réglementation protégeant les droits
                    d’auteur, et notamment aux dispositions du Code de la propriété intellectuelle. Ils sont, à ce
                    titre, protégés contre toute utilisation non autorisée par la loi ou les présentes conditions
                    d’utilisation.</p>
                <p>Les présentes CGU n’emportent aucune cession de droits de propriété intellectuelle attachés au Site
                    ou ses éléments au bénéfice des Utilisateurs.</p>
                <br/>
                <p>La reproduction de ces éléments n’est autorisée qu’à des fins d’information et/ou de copies ou
                    reproductions destinées à un usage strictement privé et personnel. Toute autre reproduction,
                    représentation ou diffusion, en tout ou partie du contenu de ce Site, sur quelque support ou par
                    tout procédé que ce soit, est interdite. Le non-respect de cette interdiction constitue une
                    contrefaçon susceptible d’engager la responsabilité civile et pénale du contrefacteur.</p>
                <br/>
                <p>Il est notamment strictement interdit d’utiliser ou de reproduire le nom « Gecina » et/ou son logo,
                    seuls ou associés, à quelque titre que ce soit, et notamment à des fins publicitaires, sans l’accord
                    préalable écrit de l’Editeur. De même, le téléchargement ou toute autre forme de copie d’un logiciel
                    ou d’informations présentes sur le Site ne confèrent aucun droit sur ceux-ci. Il est strictement
                    interdit de les reproduire (en tout ou partie), de les transmettre (électroniquement ou de quelque
                    autre manière) et de les modifier. Il est également interdit d’utiliser le présent Site à des fins
                    publiques ou commerciales sans l’autorisation écrite préalable de l’Editeur.</p>
                <br/>
                <p>L’Utilisateur s’interdit tout agissement susceptible de porter atteinte, directement ou
                    indirectement, aux droits de propriété de l’Editeur ou, le cas échéant, aux droits de propriété de
                    tiers sur les éléments du Site. Ainsi, il s’interdit toute modification de fichiers, de documents,
                    de données ou d’informations, ainsi que de tout élément appartenant à l’Editeur ou à des tiers.</p>
                <br/>
                <p>L’Utilisateur s’engage à prendre toutes les mesures nécessaires à la protection des droits de
                    propriété intellectuelle attachés au Site.</p>
                <br/>
                <p>L’Utilisateur s’engage à ne pas reproduire, transmettre, vendre ou distribuer le contenu du Site.</p>
                <br/>
                <br/>
                <p><i>Mise en garde sur les informations diffusées</i></p>
                <br/>
                <p>L’Editeur du présent Site s’efforce d’assurer l’exactitude et la mise à jour des informations
                    diffusées sur ce Site, dont il se réserve le droit de corriger, à tout moment et sans préavis, le
                    contenu. L’Editeur ne peut cependant en garantir l’exhaustivité ou l’absence de modification par un
                    tiers (intrusion, virus, etc.).</p>
                <br/>
                <p>L’Utilisateur est informé que le contenu du présent site Internet est à usage purement informatif.
                    Les informations qui y sont contenues ne peuvent être assimilées à une offre au public, une
                    sollicitation ou un démarchage de la part de l’Editeur à l’égard de l’Utilisateur.</p>
                <br/>
                <br/>
                <p><i>Recueil et traitement des données à caractère personnel</i></p>
                <br/>
                <p>Le bon fonctionnement du Site nécessite que l’Utilisateur fournisse des données personnelles exactes
                    et à jour.</p>
                <br/>
                <p>Conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD »)
                    et à la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés
                    modifiée (Loi « Informatique et Libertés »), ils bénéficient d’un droit d’accès, de rectification et
                    de suppression des données à caractère personnel les concernant, ainsi que d’un droit d’opposition
                    pour des motifs légitimes au traitement de ces données et du droit de définir des directives sur le
                    sort de ces données après leur mort.</p>
                <br/>
                <p>Ils peuvent exercer ces droits auprès du DPO de l’éditeur, par mail à :
                    <a href="mailto:protectiondesdonneesfinances@gecina.fr">protectiondesdonneesfinances@gecina.fr</a>
                </p>
                <br/>
                <p>La politique de protection des données personnelles applicable dans le cadre du fonctionnement du
                    Site peut être consultée en cliquant sur le lien suivant : politique de protection des données
                    personnelles </p>
                <br/>
                <p><i>Sécurité</i></p>
                <br/>
                <p>L’éditeur fait ses meilleurs efforts pour sécuriser le Site mais ne peut lui assurer une sécurité
                    absolue eu égard à la complexité de l’internet. Les connexions ainsi que les échanges de données
                    avec le Site sont sécurisées. Le Site utilise la sécurisation des échanges par SSL.</p>
                <br/>
                <p>L’Utilisateur s’engage à ne pas endommager, accéder illégalement ou modifier les données stockées sur
                    le serveur de l’Editeur.</p>
                <br/>
                <p><i>Droit applicable et attribution de juridiction</i></p>
                <br/>
                <p>Le présent site Internet est soumis au droit français. Nonobstant leur traduction en une ou plusieurs
                    langues étrangères, la langue officielle des CGU est la langue française. La totalité des contenus
                    et services est proposée en français.</p>
                <br/>
                <p>En cas de litige ou de réclamation émanant de l’Utilisateur, de l’Editeur ou d’un tiers, seule la
                    version actuelle des présentes CGU et accessible sur le Site aura force entre les parties, quelle
                    que soit la date des faits incriminés.</p>
                <br/>
                <p>Le fait de ne pas appliquer une stipulation des présentes CGU ne saurait être considéré comme
                    l’acceptation de la violation, de l’inexécution ou d’un manquement subséquent à ladite
                    stipulation.</p>
                <p>TOUT DIFFEREND, CONTESTATION, LITIGE PORTANT SUR LA CONCLUSION, L'EXECUTION OU LA RESILIATION DES
                    PRESENTES CGU SERA SOUMIS AU DROIT FRANÇAIS. IL EN EST AINSI POUR LES REGLES DE FOND COMME POUR LES
                    REGLES DE FORME, QUELS QUE SOIENT LE LIEU DU DOMMAGE, LE NOMBRE DE DEFENDEURS, MEME POUR LES
                    PROCEDURES D’URGENCE OU LES PROCEDURES CONSERVATOIRES EN REFERE OU PAR REQUETE, SAUF DISPOSITION
                    D’ORDRE PUBLIC CONTRAIRE. LES TRIBUNAUX FRANÇAIS SERONT COMPETENTS POUR LES INTERNAUTES SITUES SUR
                    LE TERRITOIRE FRANÇAIS.</p>
                <p>Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’Editeur aux
                    coordonnées inscrites aux mentions légales accessibles via le lien suivant : mentions légales.</p>
            </div>
        </div>

    )
}

export default ConditionsGenerales