import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
	marginTitleGrand: {
		marginBottom: '20px',

		'& p': {
			margin: '0px'
		}
	},
	marginTitlePetit: {
		marginBottom: '10px',

		'& p': {
			margin: '0px'
		}
	},
	marginBottomGrand: {
		marginBottom: '20px'
	},
	marginBottomPetit: {
		marginBottom: '50px'
	}
}))

interface ModelAdressePostale {
	adresse_postale_rue: string,
	adresse_postale_batiment: string,
	adresse_postale_complement: string,
	adresse_postale_code_postal: number | string,
	adresse_postale_commune: string,
	adresse_postale_pays: string
}

interface AcceptingProps {
	tailleEcran: string,
	adressePostale: ModelAdressePostale
}

type AdressePostaleProps = WithStyles<typeof styles> & AcceptingProps

const AdressePostale = (
	props: AdressePostaleProps
) => {
	const {
		classes,
		tailleEcran,
		adressePostale
	} = props

	const customClasses = useStyles()

	return (
		<>
			<div
				className={
					[
						classes.titleSize,
						classes.colorPrimary,
						classes.text,
						classes.bold,
						tailleEcran === 'grand' ? customClasses.marginTitleGrand : customClasses.marginTitlePetit
					].join(' ')
				}
			>
				<p>Adresse postale</p>
			</div>

			<div
				className={
					[
						'row',
						tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
					].join(' ')
				}
			>
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Rue
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{adressePostale.adresse_postale_rue}
					</span>
				</div>

				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Bâtiment
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{adressePostale.adresse_postale_batiment}
					</span>
				</div>
			</div>

			<div
				className={
					[
						'row',
						tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
					].join(' ')
				}
			>
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Complément
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{adressePostale.adresse_postale_complement}
					</span>
				</div>

				<div className="col-xs-6"></div>
			</div>

			<div
				className={
					[
						'row',
						tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
					].join(' ')
				}
			>
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Code postal
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{adressePostale.adresse_postale_code_postal}
					</span>
				</div>

				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Ville
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{adressePostale.adresse_postale_commune}
					</span>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Pays
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{adressePostale.adresse_postale_pays}
					</span>
				</div>

				<div className="col-xs-6"></div>
			</div>
		</>
	)
}

const EnhancedAdressePostale = compose<AdressePostaleProps, AcceptingProps>(
	withStyles(styles)
)(AdressePostale)

export default EnhancedAdressePostale
