import React from 'react'
import Header from '../../../components/dialog/Header'
import Button from '../../../components/button/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import { Grid } from '@material-ui/core'
import EnhancedFormTextField from '../../../components/fields/FormTextField'
import Loader from '../../../components/loader/Loader'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'
import { isEmailOk } from '../../../utils/formUtils'
import { FORM_ERROR } from 'final-form'
import { updateRecoveryEmail } from './api/updateRecoveryEmail'
import Links from '../../../enum/links'
import localStorageEnum from '../../../enum/localStorageEnum'
import { useCookies } from 'react-cookie'
import { withOktaAuth } from '@okta/okta-react'

const useStyles = makeStyles(
	() => (
		{
			dialogContainer: {
				'& > div:first-child': {
					marginTop: 35
				},
				'& p:first-child': {
					marginTop: 40
				}

			},
			buttonContainer: {
				display: 'flex',
				justifyContent: 'flex-end',

				'& button': {
					marginRight: 20
				}
			},
			formStyle: {
				marginTop: 40,
				marginBottom: 20,
				width: '100%'
			},
			fieldStyle: {
				marginBottom: 25
			},
			marginButton: {
				marginBottom: 20,

				'& button': {
					width: '100%',
					minHeight: 54
				}
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: 10
			}
		}
	)
)

interface ModelAuthService {
	logout: (path: string) => void
}

interface ModelOkta {
	authService: ModelAuthService
}

interface AcceptingProp {
	onClose: () => void
}

interface FormModel {
	email: string,
	confirmEmail: string
}

type PopinEditRecoverEmailProps =
	AcceptingProp
	& ModelOkta

const PopinEditRecoveryEmail: React.FC<PopinEditRecoverEmailProps> = (
	{
		onClose,
		authService
	}
) => {
	const classes = useStyles()
	const removeCookies = useCookies()[2]

	const validate = (values: FormModel) => {
		const errors: any = {}

		if (!values.email) {
			errors.email = 'Requis*'
		} else {
			if (!isEmailOk(values.email)) {
				errors.email = 'Format du mail incorrect'
			}
			if (!values.confirmEmail) {
				errors.confirmEmail = 'Requis*'
			} else if (values.confirmEmail !== values.email) {
				errors.confirmEmail = 'Le mail saisi et sa confirmation sont différents'
			}
		}
		return errors
	}

	const onSubmit = (values: FormModel) => {
		return updateRecoveryEmail(values.email)
			.then(
				() => {
					localStorage.removeItem('utilisateur')

					removeCookies(localStorageEnum.idToken, {
						path: '/',
						domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
					})
					removeCookies(localStorageEnum.accessToken, {
						path: '/',
						domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
					})

					authService.logout(Links.login)
				}
			)
			.catch(e => {
				return {
					[FORM_ERROR]: e
				}
			})
	}

	return (
		<div className={classes.dialogContainer}>
			<Header title="Modification du mail de récupération d'identifiant" />

			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={
					(
						{
							handleSubmit,
							submitting,
							submitError
						}
					) => (
						<form
							onSubmit={handleSubmit}
							className={classes.formStyle}
						>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} className={classes.fieldStyle}>
									<Field
										name="email"
										label="Nouveau mail de récupération"
										component={EnhancedFormTextField}
									/>
								</Grid>
								<Grid item xs={12} md={6} className={classes.fieldStyle}>
									<Field
										name="confirmEmail"
										label="Confirmation du mail de récupération"
										component={EnhancedFormTextField}
									/>
								</Grid>
							</Grid>

							{submitError && <Grid item container justify="center" className={classes.errorStyle}>{submitError}</Grid>}

							<div className="row middle-xs end-xs reverse-xs">
								<div
									className={
										classNames(
											'col-xs-12 col-sm-3 last-sm',
											classes.marginButton
										)
									}
								>
									{
										submitting ? (
											<Loader />
										) : (
											<Button
												disabled={false}
												type="submit"
											>
												Valider
											</Button>
										)
									}
								</div>

								<div
									className={
										classNames(
											'col-xs-12 col-sm-3',
											classes.marginButton
										)
									}
								>
									<Button
										type="button"
										disabled={false}
										onClick={onClose}
										background="backgroundInherit"
										color="colorPrimary"
									>
										<ChevronLeftIcon />
										Annuler
									</Button>
								</div>
							</div>
						</form>
					)
				}
			/>
		</div>
	)
}

export default withOktaAuth(PopinEditRecoveryEmail)
