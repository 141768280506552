import React, {Dispatch, useEffect} from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import EnhancedTableauSimple from '../../../components/table/TableauSimple'
import fetchFactory from '../../../utils/fetchFactory'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import errorCatcher from '../../../utils/errorCatcher'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import Chart from 'react-apexcharts'
import styles from '../../../theme/styles'
import theme from '../../../theme/theme'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    spinnerStyle: {
        textAlign: 'center',

        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    }
}))

interface ModelColonnes {
    value: string,
    label: string,
    type: string,
    render?: (row: ModelTitres) => React.ReactChild
}

interface ModeLResponse {
    id: number,
    code_isin: string,
    nature_valeur: string,
    libelle_valeur: string,
    typologie: string,
    quantite: number,
}

interface ModelPrixGecina {
    update_date: Date,
    price: string
}

interface ModelTitres {
    id: number,
    valeur: string,
    typologie: string,
    quantite: number,
    bold: boolean
}

interface Store {
    errorCatcher: (error: any, id: string) => void
}

type ListeTitresGrandProps = Store

const ListeTitresGrand = (
    props: ListeTitresGrandProps
) => {
    const {
        errorCatcher
    } = props

    const customClasses = useStyles()

    const colonnes: ModelColonnes[] = [
        {
            value: 'valeur',
            label: 'Valeur',
            type: 'string'
        },
        {
            value: 'typologie',
            label: 'Typologie de titres',
            type: 'string'
        },
        {
            value: 'quantite',
            label: 'Quantité',
            type: 'number',
            render: (row: ModelTitres) => (
                <div>
                    {thousandsSeparator(row.quantite, true)}
                </div>
            )
        }
    ]

    const [titres, setTitres] = React.useState<ModelTitres[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)
    const [partCountTypo, setPartCountTypo] = React.useState([{}])
    const [partCountValorisation, setPartCountValorisation] = React.useState([{}])
    const [prixGecina, setPrixGecina] = React.useState<ModelPrixGecina>(
        {
            update_date: new Date('now'),
            price: ''
        }
    )

    useEffect(() => {
        if (prixGecina.price === '') {
            fetchFactory('/api/gecina/prix_part')
                .then(
                    (prix_gecina: any) => {
                        setPrixGecina(prix_gecina)
                    },
                    (error: any) => {
                        errorCatcher(error, 'prix_gecina')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'prix_gecina')
                    }
                )
        }

        fetchFactory('/api/detail/avoirs')
            .then(
                (detail_avoirs: any) => {
                    const tmpDetailAvoir: ModelTitres[] = []
                    let oldCodeIsin: string = ''
                    let oldQuantite: number = 0
                    let sousTotal: number = 0
                    let total: number = 0
                    let moreThanOneIsin: boolean = false

                    detail_avoirs.sort(
                        (a: ModeLResponse, b: ModeLResponse) => {
                            if (a.code_isin.toLowerCase() < b.code_isin.toLowerCase()) {
                                return -1
                            }
                            if (a.code_isin.toLowerCase() > b.code_isin.toLowerCase()) {
                                return 1
                            }
                            if (a.typologie.toLowerCase() < b.typologie.toLowerCase()) {
                                return -1
                            }
                            if (a.typologie.toLowerCase() > b.typologie.toLowerCase()) {
                                return 1
                            }

                            return 0
                        }
                    ).forEach(
                        (detail_avoir: ModeLResponse, index: number) => {
                            if (oldCodeIsin.length > 0 && oldCodeIsin !== detail_avoir.code_isin) {
                                if (sousTotal !== oldQuantite) {
                                    tmpDetailAvoir.push(
                                        {
                                            id: (detail_avoir.id - detail_avoir.id) - 1,
                                            valeur: 'Sous-total',
                                            typologie: '',
                                            quantite: sousTotal,
                                            bold: false
                                        }
                                    )
                                }
                                oldCodeIsin = ''
                                sousTotal = 0
                                oldQuantite = 0
                                moreThanOneIsin = true
                            }

                            tmpDetailAvoir.push(
                                {
                                    id: detail_avoir.id,
                                    valeur: oldCodeIsin !== detail_avoir.code_isin ? `${detail_avoir.libelle_valeur} - ${detail_avoir.code_isin}` : '',
                                    typologie: detail_avoir.typologie,
                                    quantite: detail_avoir.quantite,
                                    bold: false
                                }
                            )

                            oldCodeIsin = detail_avoir.code_isin
                            sousTotal += detail_avoir.quantite
                            oldQuantite = detail_avoir.quantite
                            total += detail_avoir.quantite

                            if (index === detail_avoirs.length - 1) {
                                if (sousTotal !== detail_avoir.quantite && moreThanOneIsin) {
                                    tmpDetailAvoir.push(
                                        {
                                            id: (detail_avoir.id - detail_avoir.id) - 2,
                                            valeur: 'Sous-total',
                                            typologie: '',
                                            quantite: sousTotal,
                                            bold: false
                                        },
                                        {
                                            id: (detail_avoir.id - detail_avoir.id) - 3,
                                            valeur: 'Total',
                                            typologie: '',
                                            quantite: total,
                                            bold: false
                                        }
                                    )
                                } else {
                                    tmpDetailAvoir.push(
                                        {
                                            id: (detail_avoir.id - detail_avoir.id) - 3,
                                            valeur: 'Total',
                                            typologie: '',
                                            quantite: total,
                                            bold: false
                                        }
                                    )
                                }
                            }
                        }
                    )
                    let groups = detail_avoirs.map(function (value: any, index: any) {
                        return [value['typologie'], value['quantite']]
                    })
                    let clonePartCount: any = []

                    groups.forEach(function (value: any) {
                        if (clonePartCount.find((group: any) => group.typologie === value[0]) === undefined) {
                            clonePartCount.push({
                                'typologie': value[0],
                                'valorisation': (value[1] * Number(prixGecina.price))
                            })
                        } else {
                            clonePartCount.forEach((group: any) => {
                                if (group.typologie === value[0]) {
                                    group.valorisation += (value[1] * Number(prixGecina.price))
                                }

                                return 0
                            })
                        }

                    })
                    clonePartCount.sort(
                        (a: any, b: any) => {

                            if (a.valorisation < b.valorisation) {
                                return 1
                            }
                            if (a.valorisation > b.valorisation) {
                                return -1
                            }
                            return 0
                        }
                    )

                    let typo: any = []
                    let valorisation: any = []
                    clonePartCount.map((part: any) => {
                        typo.push(part.typologie)
                        valorisation.push(part.valorisation)
                        return 0
                    })

                    setPartCountTypo(typo)
                    setPartCountValorisation(valorisation)
                    setTitres(tmpDetailAvoir)
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, 'detail_avoirs')
                }
            )
            .finally(
                () => {
                    setLoading(false)
                }
            )
    }, [errorCatcher, prixGecina.price])

    const chartLabels = partCountTypo
    const chartValues = partCountValorisation

    const options: any = {
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return `${thousandsSeparator(val)} €`
                }
            }
        },
        textanchor: 'middle',
        colors: [
            '#717f8c', '#4e75ba', '#90acdc', '#162840', '#b7c0c9', theme.palette.blue[50],
            theme.palette.blue[70], theme.palette.blue[30], theme.palette.blue[60], theme.palette.blue[80], theme.palette.blue[40]
        ],
        subtitle: {
            text: 'En valeur en €',
            align: 'center',
            margin: 40,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: 12

            }
        },
        title: {
            text: 'Répartition des titres détenus',
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: 14
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            align: 'center',
            style: {
                fontSize: 14,
                fontWeight: 'bold'
            }
        },
        labels: chartLabels
    }

    return (
        <>
            {
                loading ? (
                    <div className={customClasses.spinnerStyle}>
                        <CircularProgress color="primary"/>
                    </div>
                ) : (
                    <>
                        <EnhancedTableauSimple
                            colonnes={colonnes}
                            lignes={titres}
                        />
                        {
                            chartValues.length <= 1 && chartValues[1] !== 0 ? '' :
                                <div className="row center-md">
                                    <Chart options={options}
                                           series={chartValues}
                                           type="pie"
                                           height={500}
                                           width={500}/>
                                </div>
                        }
                    </>
                )
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedListeTitresGrand = compose<ListeTitresGrandProps, {}>(
    connect(null, mapDispatchToProps)
)(ListeTitresGrand)

export default EnhancedListeTitresGrand
