import React, { Dispatch } from 'react'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import styles from '../../../theme/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Button from '../../../components/button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import errorCatcher from '../../../utils/errorCatcher'
import { afficherSnackbar } from '../../reducers/snackBar/actions'
import EnhancedFormTextField from '../../../components/fields/FormTextField'
import FormNumberMaskField from '../../../components/fields/FormNumberMaskField'
import FormFichier from '../../../components/fields/FormFichier'
import fetchFactory from '../../../utils/fetchFactory'
import formatDate, { formatInputDate } from '../../../utils/formatDate'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Header from '../../../components/dialog/Header'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	containerLargeScreen: {
		width: '80%',
		margin: 'auto',
		marginTop: 50,
		marginBottom: 60
	},
	containerSmallScreen: {
		marginTop: 30
	},
	texteInformatifStyle: {
		fontSize: 14,
		margin: '30px 0'
	},
	marginBottom: {
		marginBottom: 20
	},
	textSize: {
		fontSize: 14
	},
	marginButton: {
		marginBottom: 20,

		'& button': {
			width: '100%',
			minHeight: 54
		}
	},
	paddingBottom: {
		paddingBottom: 30
	},
	border: {
		marginBottom: 10,
		borderBottom: '1px solid #707070'
	}
}))

interface ModelFichiers {
	fichier: string,
	fichierEncode: string,
	file: File
}

interface FormData {
	email: string,
	telephone_mobile: string,
	telephone_fixe: string,
	adresse_fiscale_rue: string,
	adresse_fiscale_batiment: string,
	adresse_fiscale_complement: string,
	adresse_fiscale_code_postal: number,
	adresse_fiscale_commune: string,
	adresse_fiscale_pays: string,
	adresse_postale_rue: string,
	adresse_postale_batiment: string,
	adresse_postale_complement: string,
	adresse_postale_code_postal: number,
	adresse_postale_commune: string,
	adresse_postale_pays: string
	date_naissance: string | Date,
	lieu_naissance: string,
	departement_naissance: number,
	pays_naissance: string,
	commentaire: string,
	fichiers: ModelFichiers[]
}

interface ModelSnackBarData {
	id: string
	message: string,
	open: boolean
}

interface Store {
	afficherSnackBar: (snackBarData: ModelSnackBarData) => void,
	errorCatcher: (error: any, id: string) => void,
	initialValues: FormData
}

interface AcceptingProps {
	tailleEcran: 'petit' | 'grand'
	closeDialog: () => void
}

const titreDialog: string = 'Communiquer vos nouvelles données personnelles'
const texteInformatif: string = 'Les données du présent formulaire sont pré-alimentées avec les données actuelles dont nous disposons vous concernant.'
const messageEmailEnvoye: string = 'Vos nouvelles coordonnées sont transmises à nos équipes et seront prises en compte très prochainement'

type FormulaireDonneesPersonnellesProps =
	Store
	& AcceptingProps

const FormulaireDonneesPersonnelles: React.FC<FormulaireDonneesPersonnellesProps> = (
	{
		initialValues,
		afficherSnackBar,
		errorCatcher,
		closeDialog,
		tailleEcran
	}
) => {
	const classes = useStyles()
	const [loading, setLoading] = React.useState<boolean>(false)
	const [afficherAdresseFiscale, setAfficherAdresseFiscale] = React.useState<boolean>(false)

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAfficherAdresseFiscale(event.target.checked)
	}

	const cancelForm = () => {
		closeDialog()
	}

	const onSubmit = (values: FormData) => {
		values.date_naissance = formatDate(new Date(values.date_naissance))

		if (afficherAdresseFiscale) {
			values.adresse_fiscale_rue = ''
			values.adresse_fiscale_batiment = ''
			values.adresse_fiscale_complement = ''
			values.adresse_fiscale_code_postal = 0
			values.adresse_fiscale_commune = ''
			values.adresse_fiscale_pays = ''
		}

		setLoading(true)

		const fd = new FormData()
		values.fichiers?.forEach((value) => {
				fd.append('fichiers[]', value.file, value.fichier)
			}
		)
		fd.append('values', JSON.stringify(values))

		fetchFactory('/api/donnees/personnelles',
			{
				method: 'POST',
				body: fd
			}
		)
			.then(
				() => afficherSnackBar(
					{
						id: 'formulaire_donnees_personnelles',
						message: messageEmailEnvoye,
						open: true
					}
				)
			)
			.catch(
				(error: any) => {
					errorCatcher(error, 'formulaire_donnees_personnelles')
				}
			)
			.finally(
				() => {
					setLoading(false)
					closeDialog()
				}
			)
	}

	return (
		<Form
			mutators={{
				...arrayMutators
			}}
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
			render={
				(
					{
						handleSubmit,
						values,
						error
					}
				) => (
					<form
						onSubmit={handleSubmit}
						className={
							tailleEcran === 'grand' ? classes.containerLargeScreen : classes.containerSmallScreen
						}
					>
						<Header title={titreDialog} />
						<div className="row">
							<div
								className={
									[
										'col-xs-12',
										classes.colorPrimary,
										classes.medium,
										classes.text,
										classes.texteInformatifStyle
									].join(' ')
								}
							>
								{texteInformatif}
							</div>
						</div>

						<div
							className={
								[
									'row',
									classes.marginBottom
								].join(' ')
							}
						>
							{/* Input de l'adresse email */}
							<div className="col-xs-12 col-sm-6">
								<Field
									name="email"
									component={EnhancedFormTextField}
									label="Adresse mail de contact"
									id="email"
									maxLength={64}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									classes.border
								].join(' ')
							}
						>
							{/* Input du téléphone mobile */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.paddingBottom
									].join(' ')
								}
							>
								<Field
									name="telephone_mobile"
									component={FormNumberMaskField}
									formatMask={
										values ?
											values.adresse_postale_commune && values.adresse_postale_commune !== '-' ?
												values.adresse_postale_pays ? values.adresse_postale_pays.toLowerCase().includes('fr') && '## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ##' : undefined
												:
												values.adresse_fiscale_pays ? values.adresse_fiscale_pays.toLowerCase().includes('fr') && '## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ##' : undefined
											:
											undefined
									}
									label="N° de téléphone mobile"
									id="telephone_mobile"
								/>
							</div>

							{/* Input du téléphone fixe */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.paddingBottom
									].join(' ')
								}
							>
								<Field
									name="telephone_fixe"
									component={FormNumberMaskField}
									formatMask={
										values ?
											values.adresse_postale_commune && values.adresse_postale_commune !== '-' ?
												values.adresse_postale_pays ? values.adresse_postale_pays.toLowerCase().includes('fr') && '## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ##' : undefined
												:
												values.adresse_fiscale_pays ? values.adresse_fiscale_pays.toLowerCase().includes('fr') && '## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ## ##' : undefined
											:
											undefined
									}
									label="N° de téléphone fixe"
									id="telephone_fixe"
								/>
							</div>
						</div>

						<div
							className={
								[
									classes.titleSize,
									classes.colorPrimary,
									classes.text,
									classes.bold,
									classes.marginBottom
								].join(' ')
							}
						>
							<span>Adresse postale</span>
						</div>

						<div className="row">
							{/* Input de l'adresse 1 */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="adresse_postale_rue"
									component={EnhancedFormTextField}
									label="Rue"
									id="adresse_postale_rue"
									maxLength={32}
								/>
							</div>

							{/* Input de l'adresse 2 */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="adresse_postale_batiment"
									component={EnhancedFormTextField}
									label="Bâtiment"
									id="adresse_postale_batiment"
									maxLength={32}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									classes.marginBottom
								].join(' ')
							}
						>
							{/* Input de l'adresse 3 */}
							<div className="col-xs-12 col-sm-6">
								<Field
									name="adresse_postale_complement"
									component={EnhancedFormTextField}
									label="Complément"
									id="adresse_postale_complement"
									maxLength={32}
								/>
							</div>
						</div>

						<div className="row">
							{/* Input du code postal */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="adresse_postale_code_postal"
									component={EnhancedFormTextField}
									label="Code postal"
									id="adresse_postale_code_postal"
									maxLength={5}
								/>
							</div>

							{/* Input de la ville */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="adresse_postale_commune"
									component={EnhancedFormTextField}
									label="Ville"
									id="adresse_postale_commune"
									maxLength={27}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									classes.marginBottom
								].join(' ')
							}
						>
							{/* Input du pays */}
							<div className="col-xs-12 col-sm-6">
								<Field
									name="adresse_postale_pays"
									component={EnhancedFormTextField}
									label="Pays"
									id="adresse_postale_pays"
									maxLength={40}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									classes.border
								].join(' ')
							}
						>
							<FormControl
								component="fieldset"
								className="col-xs-12"
							>
								<FormControlLabel
									control={
										<Checkbox
											checked={afficherAdresseFiscale}
											onChange={handleChange}
											name="afficher_adresse_fiscale"
										/>
									}
									label="Adresse fiscale identique à l'adresse postale"
								/>
							</FormControl>
						</div>

						{
							!afficherAdresseFiscale &&
							<>
								<div
									className={
										[
											classes.titleSize,
											classes.colorPrimary,
											classes.text,
											classes.bold,
											classes.marginBottom
										].join(' ')
									}
								>
									<span>Adresse fiscale</span>
								</div>

								<div className="row">
									{/* Input de l'adresse 1 */}
									<div
										className={
											[
												'col-xs-12 col-sm-6',
												classes.marginBottom
											].join(' ')
										}
									>
										<Field
											name="adresse_fiscale_rue"
											component={EnhancedFormTextField}
											label="Rue"
											id="adresse_fiscale_rue"
											maxLength={32}
										/>
									</div>

									{/* Input de l'adresse 2 */}
									<div
										className={
											[
												'col-xs-12 col-sm-6',
												classes.marginBottom
											].join(' ')
										}
									>
										<Field
											name="adresse_fiscale_batiment"
											component={EnhancedFormTextField}
											label="Bâtiment"
											id="adresse_fiscale_batiment"
											maxLength={32}
										/>
									</div>
								</div>

								<div
									className={
										[
											'row',
											classes.marginBottom
										].join(' ')
									}
								>
									{/* Input de l'adresse 3 */}
									<div className="col-xs-12 col-sm-6">
										<Field
											name="adresse_fiscale_complement"
											component={EnhancedFormTextField}
											label="Complément"
											id="adresse_fiscale_complement"
											maxLength={32}
										/>
									</div>
								</div>

								<div className="row">
									{/* Input du code postal */}
									<div
										className={
											[
												'col-xs-12 col-sm-6',
												classes.marginBottom
											].join(' ')
										}
									>
										<Field
											name="adresse_fiscale_code_postal"
											component={EnhancedFormTextField}
											label="Code postal"
											id="adresse_fiscale_code_postal"
											maxLength={5}
										/>
									</div>

									{/* Input de la ville */}
									<div
										className={
											[
												'col-xs-12 col-sm-6',
												classes.marginBottom
											].join(' ')
										}
									>
										<Field
											name="adresse_fiscale_commune"
											component={EnhancedFormTextField}
											label="Ville"
											id="adresse_fiscale_commune"
											maxLength={27}
										/>
									</div>
								</div>

								<div
									className={
										[
											'row',
											classes.paddingBottom,
											classes.border
										].join(' ')
									}
								>
									{/* Input du pays */}
									<div className="col-xs-12 col-sm-6">
										<Field
											name="adresse_fiscale_pays"
											component={EnhancedFormTextField}
											label="Pays"
											id="adresse_fiscale_pays"
											maxLength={40}
										/>
									</div>
								</div>
							</>
						}

						<div className="row">
							{/* Input du date de naissance */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="date_naissance"
									component={EnhancedFormTextField}
									label="Date de naissance"
									id="date_naissance"
									type="date"
									format={
										(value: Date) => {
											return value === null ? '' : formatInputDate(new Date(value))
										}
									}
								/>
							</div>

							{/* Input du lieu de naissance */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="lieu_naissance"
									component={EnhancedFormTextField}
									label="Lieu de naissance"
									id="lieu_naissance"
									maxLength={27}
								/>
							</div>
						</div>

						<div className="row">
							{/* Input du département de naissance */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="departement_naissance"
									component={EnhancedFormTextField}
									label="Département de naissance"
									id="departement_naissance"
									maxLength={2}
								/>
							</div>

							{/* Input du pays de naissance */}
							<div
								className={
									[
										'col-xs-12 col-sm-6',
										classes.marginBottom
									].join(' ')
								}
							>
								<Field
									name="pays_naissance"
									component={EnhancedFormTextField}
									label="Pays de naissance"
									id="pays_naissance"
									maxLength={40}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									classes.marginBottom
								].join(' ')
							}
						>
							{/* Input du commentaire */}
							<div className="col-xs-12">
								<Field
									name="commentaire"
									component={EnhancedFormTextField}
									label="Commentaire"
									id="commentaire"
									multiline
									maxLength={500}
								/>
							</div>
						</div>

						<>
							<div className="row">
								<div className="col-xs-12">
									<p
										className={
											[
												classes.text,
												classes.textSize,
												classes.medium,
												classes.colorPrimary
											].join(' ')
										}
									>
										Pour toute modification de votre état civil ou de vos coordonnées, veuillez joindre les justificatifs nécessaires (fiche d'état civil ou justificatif de domicile)
									</p>
								</div>
							</div>

							<FieldArray
								name="fichiers"
								render={
									(
										{
											fields,
											meta
										}
									) => (
										<FormFichier
											fields={fields}
											meta={meta}
											label="Ajouter un fichier"
											id="fichiers"
										/>
									)
								}
							/>
						</>

						<div className="row middle-xs end-xs reverse-xs">
							<div
								className={
									classNames(
										'col-xs-12 col-sm-3 last-sm',
										classes.marginButton
									)
								}
							>
								{
									loading ? (
										<CircularProgress color="primary" />
									) : (
										<Button
											disabled={false}
											type="submit"
										>
											Transmettre
										</Button>
									)
								}
							</div>

							<div
								className={
									classNames(
										'col-xs-12 col-sm-3',
										classes.marginButton
									)
								}
							>
								<Button
									type="button"
									disabled={false}
									onClick={cancelForm}
									background="backgroundInherit"
									color="colorPrimary"
								>
									<ChevronLeftIcon />
									Annuler
								</Button>
							</div>
						</div>
						{
							error && <strong>{error}</strong>
						}
					</form>
				)
			}
		/>
	)
}

const checkEmail = (email: string) => {
	return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

const validate = (values: FormData) => {
	const errors: any = {}

	if (values.email && values.email.length > 64) {
		errors.email = 'L\'adresse email ne peut pas dépasser 64 caractères'
	} else if (values.email && !checkEmail(values.email)) {
		errors.email = 'L\'adresse email n\'est pas valide'
	}
	if (values.telephone_mobile && isNaN(Number(values.telephone_mobile))) {
		errors.telephone_mobile = 'Le téléphone mobile ne doit contenir que des chiffres'
	} else if (values.telephone_mobile && values.telephone_mobile.length > 32) {
		errors.telephone_mobile = 'Le téléphone mobile ne peut pas dépasser 32 caractères'
	}
	if (values.telephone_fixe && isNaN(Number(values.telephone_fixe))) {
		errors.telephone_fixe = 'Le téléphone fixe ne doit contenir que des chiffres'
	} else if (values.telephone_fixe && values.telephone_fixe.length > 32) {
		errors.telephone_fixe = 'Le téléphone portable ne peut pas dépasser 32 caractères'
	}
	if (values.adresse_fiscale_rue && values.adresse_fiscale_rue.length > 32) {
		errors.adresse_fiscale_rue = 'L\'adresse ne peut pas dépasser 32 caractères'
	}
	if (values.adresse_fiscale_batiment && values.adresse_fiscale_batiment.length > 32) {
		errors.adresse_fiscale_batiment = 'L\'adresse ne peut pas dépasser 32 caractères'
	}
	if (values.adresse_fiscale_complement && values.adresse_fiscale_complement.length > 32) {
		errors.adresse_fiscale_complement = 'L\'adresse ne peut pas dépasser 32 caractères'
	}
	if (!values.adresse_fiscale_code_postal) {
		errors.adresse_fiscale_code_postal = 'Requis*'
	} else if (isNaN(values.adresse_fiscale_code_postal)) {
		errors.adresse_fiscale_code_postal = 'Le code postal ne doit contenir que des chiffres'
	} else if (values.adresse_fiscale_code_postal.toString().length > 5) {
		errors.adresse_fiscale_code_postal = 'Le code postal ne peut pas dépasser 5 caractères'
	}
	if (!values.adresse_fiscale_commune) {
		errors.adresse_fiscale_commune = 'Requis*'
	} else if (values.adresse_fiscale_commune.length > 27) {
		errors.adresse_fiscale_commune = 'La ville ne peut pas dépasser 27 caractères'
	}
	if (!values.adresse_fiscale_pays) {
		errors.adresse_fiscale_pays = 'Requis*'
	} else if (values.adresse_fiscale_pays.length > 40) {
		errors.adresse_fiscale_pays = 'Le pays ne peut pas dépasser 40 caractères'
	}
	if (values.lieu_naissance && values.lieu_naissance.length > 27) {
		errors.lieu_naissance = 'Le lieu de naissance ne peut pas dépasser 27 caractères'
	}
	if (values.departement_naissance && isNaN(values.departement_naissance)) {
		errors.departement_naissance = 'Le département de naissance ne peut contenir que des chiffres'
	} else if (values.departement_naissance && values.departement_naissance.toString().length > 2) {
		errors.departement_naissance = 'Le département de naissance ne peut pas dépasser 2 caractères'
	}
	if (values.pays_naissance && values.pays_naissance.length > 40) {
		errors.pays_naissance = 'Le pays de naissance ne peut pas dépasser 40 caractères'
	}
	if (values.commentaire && values.commentaire.length > 500) {
		errors.commentaire = 'Le commentaire ne peut pas dépasser 500 caractères'
	}
	return errors
}

const mapStateToProps = (state: any) => {
	return {
		initialValues: state.formulaireDonneesPersonnelles
	}
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		afficherSnackBar: (snackBarData: ModelSnackBarData) => {
			dispatch(afficherSnackbar(snackBarData))
		},
		errorCatcher: (error: any, id: string) => {
			dispatch(errorCatcher(error, id))
		}
	}
}

const EnhancedFormulaireDonneesPersonnelles = compose<FormulaireDonneesPersonnellesProps, AcceptingProps>(
	connect(mapStateToProps, mapDispatchToProps)
)(FormulaireDonneesPersonnelles)

export default EnhancedFormulaireDonneesPersonnelles
