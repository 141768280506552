import React, { useEffect, useState } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { BOURSE_FORM_INITIAL, BourseFormModel } from './types/bourseFormModel'
import { getBourseForm } from './api/getBourseForm'
import { useDispatch } from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import Loader from '../../components/loader/Loader'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import Button from '../../components/button/Button'
import { makeStyles } from '@material-ui/core/styles'
import { formFieldsEnum } from './enums/formFieldsEnum'
import EnhancedFormTextField from '../../components/fields/FormTextField'
import FormSelect from '../../components/fields/FormSelect'
import MenuItem from '@material-ui/core/MenuItem'
import { CONDITION_LABEL, conditionEnum } from './enums/conditionEnum'
import FormRadioGroup from '../../components/fields/FormRadioGroup'
import { ORDER_DIRECTION_LABEL, orderDirectionEnum } from './enums/orderDirectionEnum'
import { useMediaQuery as ResponsiveMediaQuery } from 'react-responsive'
import FormNumberFormatField from '../../components/fields/FormNumberFormatField'
import { postBourseForm } from './api/postBourseForm'
import { afficherSnackbar } from '../reducers/snackBar/actions'
import FormFichier from '../../components/fields/FormFichier'
import { FieldArray } from 'react-final-form-arrays'
import styles from '../../theme/styles'
import { VALIDITY_LABEL, validityDateEnum } from './enums/validityDateEnum'
import FormDatePickerField from '../../components/fields/FormDatePickerField'
import classNames from 'classnames'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Links from '../../enum/links'
import { useHistory } from 'react-router'
import Dialog from '../../components/dialog/Dialog'
import MaterialDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PopinFormRecap from './components/PopinFormRecap'
import formatDate from '../../utils/formatDate'
import localStorageEnum from '../../enum/localStorageEnum'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			titleStyle: {
				color: theme.palette.blue.main,
				fontSize: 20,
				fontWeight: 600
			},
			texteInformatifStyle: {
				fontSize: 14,
				margin: '10px 0 20px'
			}
		}
	)
)

const GET_BOURSE = 'getBourseForm'
const POST_BOURSE_SUCCESS = 'postBourseSuccess'
const POST_BOURSE_ERROR = 'postBourseSuccessError'
const FORM_ID = 'bourseForm'
const texteInformatif: string = 'Les données du présent formulaire sont modifiables et pré-alimentées avec les données actuelles dont nous disposons vous concernant.'

const BourseForm = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const theme = useTheme()
	const isLargeScreen = ResponsiveMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)
	const [data, setData] = useState<BourseFormModel>(BOURSE_FORM_INITIAL)
	const [loading, setLoading] = useState<boolean>(true)
	const [postingData, setPostingData] = useState<boolean>(false)
	const nonRequiredFields: string[] = [formFieldsEnum.comment, formFieldsEnum.files]
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [open, setOpen] = React.useState(false)
	const [formValues, setFormValues] = React.useState<BourseFormModel>(BOURSE_FORM_INITIAL)

	const dateText = classNames(
		classes.text8,
		classes.colorPrimary,
		classes.semiBold
	)

	useEffect(
		() => {
			getBourseForm()
				.then(
					(response: any) => setData({
						...BOURSE_FORM_INITIAL,
						...response
					})
				)
				.catch(
					(error) => dispatch(
						errorCatcher(error, GET_BOURSE)
					)
				)
				.finally(() => setLoading(false))
		}, [dispatch]
	)

	const validate = (values: BourseFormModel) => {
		const error: any = {}

		Object.keys(formFieldsEnum).forEach(
			(formField) => {
				if ((!values[formField as keyof typeof values] || values[formField as keyof typeof values].toString().length === 0) && !nonRequiredFields.includes(formField)) {
					if (formField === formFieldsEnum.ongoing && values[formFieldsEnum.condition] === conditionEnum.onGoingLimited) {
						error[formField] = 'Requis*'
					} else if (formField !== formFieldsEnum.ongoing) {
						error[formField] = 'Requis*'
					}
				}
			}
		)

		return error
	}

	const onSubmit = (values: BourseFormModel) => {
		setFormValues(values)
		setOpen(true)
	}

	const downloadCopy = () => {
		console.log('pdf', localStorage.getItem(localStorageEnum.bourseDocument))
		const a = document.createElement('a')
		a.href = localStorage.getItem(localStorageEnum.bourseDocument) ?? ''
		a.download = `ordre_bourse_${formatDate(new Date())}`
		a.click()
		localStorage.removeItem(localStorageEnum.bourseDocument)
	}

	const submitData = (values: BourseFormModel) => {
		setPostingData(true)

		const fd = new FormData()
		values.files.forEach((value) => {
				fd.append('files[]', value.file, value.fichier)
			}
		)
		fd.append('values', JSON.stringify(values))

		postBourseForm(fd)
			.then(
				(response: any) => {
					dispatch(
						afficherSnackbar(
							{
								id: POST_BOURSE_SUCCESS,
								message: 'Ordre de bourse envoyé avec succès',
								open: true,
								additionalButtons: [
									{
										content: 'Télécharger une copie',
										id: 'download_button',
										flag: 'download',
										onClick: downloadCopy
									}
								]
							}
						)
					)
					const url = window.URL.createObjectURL(response)

					localStorage.setItem(localStorageEnum.bourseDocument, url)
					history.push(Links.documentsUtiles)
				}
			)
			.catch(
				(error) => dispatch(
					errorCatcher(error, POST_BOURSE_ERROR)
				)
			)
			.finally(() => setPostingData(false))
	}

	if (loading) {
		return (
			<Loader />
		)
	}

	return (
		<Grid container item xs={12}>
			<Grid item xs={12}>
				<h1 className={classes.titleStyle}>Détail de vos instructions</h1>
			</Grid>

			<Grid container item xs={12}>
				<Grid
					item
					xs={12}
					className={
						[
							classes.colorPrimary,
							classes.medium,
							classes.text,
							classes.texteInformatifStyle
						].join(' ')
					}
				>
					{texteInformatif}
				</Grid>
			</Grid>

			<Form
				mutators={{
					...arrayMutators
				}}
				initialValues={data}
				onSubmit={onSubmit}
				validate={validate}
				render={
					(
						{
							handleSubmit,
							values
						}
					) => (
						<form
							id={FORM_ID}
							onSubmit={handleSubmit}
						>
							<Grid container item xs={12} spacing={3}>
								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.lastname}
										component={EnhancedFormTextField}
										label="Nom"
										id={formFieldsEnum.lastname}
										maxLength={32}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.firstname}
										component={EnhancedFormTextField}
										label="Prénom"
										id={formFieldsEnum.firstname}
										maxLength={32}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.account}
										component={EnhancedFormTextField}
										label="N° de compte"
										id={formFieldsEnum.account}
										maxLength={9}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.email}
										component={EnhancedFormTextField}
										label="Email"
										id={formFieldsEnum.email}
										maxLength={64}
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										name={formFieldsEnum.address}
										component={EnhancedFormTextField}
										label="Adresse"
										id={formFieldsEnum.address}
										maxLength={168}
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										name={formFieldsEnum.orderDirection}
										component={FormRadioGroup}
										items={Object.keys(orderDirectionEnum).map(
											(direction) => (
												{
													value: direction,
													label: ORDER_DIRECTION_LABEL[direction as keyof typeof ORDER_DIRECTION_LABEL]
												}
											)
										)}
										textStyle={isLargeScreen ? 'text1' : 'text2'}
										textColor="colorPrimary"
										textWeight="medium"
										margin={false}
										type="radio"
										rowDirection
										spaceBetween={false}
										label="Sens"
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.nbOrders}
										component={FormNumberFormatField}
										allowNegative={false}
										decimalScale={0}
										label="Nombre de titres"
										maxLength={15}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.condition}
										component={FormSelect}
										label="Conditions de prix"
										id={formFieldsEnum.condition}
									>
										{
											Object.keys(conditionEnum).map(
												(condition) => (
													<MenuItem value={condition} key={condition}>
														{CONDITION_LABEL[condition as keyof typeof CONDITION_LABEL]}
													</MenuItem>
												)
											)
										}
									</Field>
								</Grid>

								{
									values[formFieldsEnum.condition] === conditionEnum.onGoingLimited && (
										<Grid item xs={12} md={6}>
											<Field
												name={formFieldsEnum.ongoing}
												component={FormNumberFormatField}
												allowNegative={false}
												decimalScale={2}
												label="Cours souhaité"
											/>
										</Grid>
									)
								}

								<Grid item xs={12} md={6}>
									<Field
										name={formFieldsEnum.validityDate}
										component={FormSelect}
										label="Date de validité de l'ordre"
										id={formFieldsEnum.validityDate}
									>
										{
											Object.keys(validityDateEnum).map(
												(validityDate) => (
													<MenuItem value={validityDate} key={validityDate}>
														{VALIDITY_LABEL[validityDate as keyof typeof VALIDITY_LABEL]}
													</MenuItem>
												)
											)
										}
									</Field>
								</Grid>


								{
									values[formFieldsEnum.validityDate] === validityDateEnum.date && (
										<Grid item xs={12} md={6}>
											<Field
												name={formFieldsEnum.date}
												component={FormDatePickerField}
												type="date"
												inputTextStyle={dateText}
												isGreyBackground={false}
												selectFuture
											/>
										</Grid>
									)
								}

								<Grid item xs={12}>
									<Field
										name={formFieldsEnum.comment}
										component={EnhancedFormTextField}
										label="Commentaire"
										id={formFieldsEnum.comment}
										multiline
										maxLength={500}
									/>
								</Grid>

								<Grid item xs={12}>
									<FieldArray
										name={formFieldsEnum.files}
										render={
											(
												{
													fields,
													meta
												}
											) => (
												<FormFichier
													fields={fields}
													meta={meta}
													label="Ajouter un fichier"
													id={formFieldsEnum.files}
												/>
											)
										}
									/>
								</Grid>

								<Grid
									container
									justify="flex-end"
									alignItems="center"
								>
									<Grid
										container
										item
										xs={6}
										md={3}
										justify="flex-end"
									>
										<Button
											type="button"
											disabled={false}
											onClick={() => history.push(Links.documentsUtiles)}
											background="backgroundInherit"
											color="colorPrimary"
										>
											<ChevronLeftIcon />
											Annuler
										</Button>
									</Grid>

									<Grid
										container
										item
										xs={6}
										md={3}
										justify="flex-end"
									>
										{
											postingData ? (
												<Loader />
											) : (
												<Button
													disabled={false}
													type="submit"
												>
													Envoyer
												</Button>
											)
										}
									</Grid>
								</Grid>
							</Grid>
						</form>
					)
				}
			/>

			<MaterialDialog
				fullScreen={fullScreen}
				open={open}
				aria-labelledby="responsive-dialog-editRecoveryEmail"
				fullWidth
				maxWidth="md"
			>
				<Dialog
					hasButton={false}
					onCloseDialog={() => setOpen(false)}
				>
					<PopinFormRecap
						onClose={() => setOpen(false)}
						onConfirm={(values: BourseFormModel) => submitData(values)}
						values={formValues}
					/>
				</Dialog>
			</MaterialDialog>
		</Grid>
	)
}

export default BourseForm
