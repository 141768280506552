import React from 'react'
import {useDispatch} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../theme/styles'
import {Field, Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import Header from '../../components/dialog/Header'
import classNames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '../../components/button/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import fetchFactory from '../../utils/fetchFactory'
import FormSelect from '../../components/fields/FormSelect'
import {afficherSnackbar} from '../reducers/snackBar/actions'
import errorCatcher from '../../utils/errorCatcher'
import {configEnum} from '../../enum/fetchFactoryEnum'
import {MenuItem} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    containerLargeScreen: {
        width: '80%',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 60
    },
    containerSmallScreen: {
        marginTop: 30
    },
    texteInformatifStyle: {
        fontSize: 14,
        margin: '30px 0'
    },
    marginBottom: {
        marginBottom: 20
    },
    textSize: {
        fontSize: 14
    },
    marginButton: {
        marginBottom: 20,

        '& button': {
            width: '100%',
            minHeight: 54
        }
    },
    paddingBottom: {
        paddingBottom: 30
    },
    border: {
        marginBottom: 10,
        borderBottom: '1px solid #707070'
    }
}))

interface FormData {
    modeReception: 'Courriel' | 'Courrier postal'
}

interface AcceptingProps {
    tailleEcran: 'petit' | 'grand'
    closeDialog: () => void,
    souscription: 'O' | 'N' | ''
}

type FormulaireEServicesProps = AcceptingProps

const TITRE: string = 'Modifier le mode de réception des convocations'
const TEXTEINFORMATIF: string = 'Les données du présent formulaire sont pré-alimentées avec les données actuelles dont nous disposons vous concernant.'
const TEXTEINFORMATIF2: string = 'L\'adresse mail utilisée pour l\'envoi des convocations sera l\'adresse connue par nos services.'
const MODECOURRIEL: string = 'Courriel'
const MODECOURRIER: string = 'Courrier postal'
const MESSAGEMAILENVOYE: string = 'Votre demande a été transmise et sera prise en compte très prochainement'

const modeCourriel: string = 'COURRIEL'
const modeCourrier: string = 'COURRIER'

const FormulaireEServices: React.FC<FormulaireEServicesProps> = (
    {
        closeDialog,
        tailleEcran,
        souscription
    }: FormulaireEServicesProps
) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState<boolean>(false)
    const cancelForm = () => {
        closeDialog()
    }

    const onSubmit = (values: FormData) => {
        setLoading(true)

        const demande_convocation = JSON.stringify({demande_convocation: {...values}})

        fetchFactory('/api/demande/convocation',
            {
                method: configEnum.post,
                body: demande_convocation
            }
        )
            .then(
                () => dispatch(
                    afficherSnackbar(
                        {
                            id: 'formulaire_demande_convocation',
                            message: MESSAGEMAILENVOYE,
                            open: true
                        }
                    )
                )
            )
            .catch(
                (error: any) => dispatch(
                    errorCatcher(error, 'formulaire_demande_convocation')
                )
            )
            .finally(
                () => {
                    setLoading(false)
                    closeDialog()
                }
            )
    }

    return (
        <Form
            mutators={{
                ...arrayMutators
            }}
            onSubmit={onSubmit}
            render={
                (
                    {
                        handleSubmit,
                        error
                    }
                ) => (
                    <form
                        onSubmit={handleSubmit}
                        className={
                            tailleEcran === 'grand' ? classes.containerLargeScreen : classes.containerSmallScreen
                        }
                    >
                        <Header title={TITRE}/>
                        <div className="row">
                            <div
                                className={
                                    classNames(
                                        'col-xs-12',
                                        classes.colorPrimary,
                                        classes.medium,
                                        classes.text,
                                        classes.texteInformatifStyle
                                    )
                                }
                            >
                                {souscription === 'O' ? TEXTEINFORMATIF : TEXTEINFORMATIF2}
                            </div>
                        </div>

                        <div
                            className={classNames(
                                'row',
                                classes.marginBottom
                            )

                            }
                        >
                            <div className="col-xs-12 col-sm-6">
                                <Field
                                    name="modeReception"
                                    component={FormSelect}
                                    label="MODE DE RÉCEPTION SOUHAITÉ DES CONVOCATIONS"
                                    defaultValue={souscription === '' || souscription === 'N' ? modeCourriel : modeCourrier}
                                    id="modeReception"
                                >
                                    {
                                        souscription === '' || souscription === 'N' ?
                                        <MenuItem value={modeCourriel}>
                                            {MODECOURRIEL}
                                        </MenuItem>
                                        :
                                        <MenuItem value={modeCourrier}>
                                            {MODECOURRIER}
                                        </MenuItem>
                                    }
                                </Field>
                            </div>
                        </div>

                        <div className="row middle-xs end-xs reverse-xs">
                            <div
                                className={
                                    classNames(
                                        'col-xs-12 col-sm-3 last-sm',
                                        classes.marginButton
                                    )
                                }
                            >
                                {
                                    loading ? (
                                        <CircularProgress color="primary"/>
                                    ) : (
                                        <Button
                                            disabled={false}
                                            type="submit"
                                        >
                                            Transmettre
                                        </Button>
                                    )
                                }
                            </div>

                            <div
                                className={
                                    classNames(
                                        'col-xs-12 col-sm-3',
                                        classes.marginButton
                                    )
                                }
                            >
                                <Button
                                    type="button"
                                    disabled={false}
                                    onClick={cancelForm}
                                    background="backgroundInherit"
                                    color="colorPrimary"
                                >
                                    <ChevronLeftIcon/>
                                    Annuler
                                </Button>
                            </div>
                        </div>
                        {
                            error && <strong>{error}</strong>
                        }
                    </form>
                )
            }
        />
    )
}

export default FormulaireEServices