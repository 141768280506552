export const recuperationAnneesPrecedentes = () => {
	const anneesActuelle = new Date().getFullYear()

	const annees = [anneesActuelle]

	// On fait le calcul des années précédentes
	for (let i = 1; i <= 3; i++) {
		annees.push(anneesActuelle - i)
	}

	return annees
}