import { AJOUTER_DROITS_ACTIONS, AJOUTER_DROITS_STOCK } from './actionTypes'

export const ajouterDroitsActions = (droitsActions) => {
	return ({
		type: AJOUTER_DROITS_ACTIONS,
		payload: droitsActions
	})
}

export const ajouterDroitsStock = (droitsStock) => {
	return ({
		type: AJOUTER_DROITS_STOCK,
		payload: droitsStock
	})
}
