import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import Links from '../../enum/links'

const useStyle = makeStyles((theme) => (
	{
		footerStyle: {
			background: theme.palette.primary.main,
			position: 'absolute',
			bottom: 0,
			left: 0,
			margin: 0,
			width: '100%',
			display: 'flex',

			'& div': {
				display: 'flex',
				alignItems: 'center',

				'& img': {
					width: 100
				},
				'& a, & span': {
					color: 'white',
					opacity: 0.5,
					textDecoration: 'none',
					cursor: 'pointer'
				}
			}
		},
		footerLargeScreenStyle: {
			height: 90,
			paddingRight: 30,
			boxSizing: 'border-box',

			'& div': {
				marginLeft: 50
			},
			'& div:nth-child(2)': {
				marginLeft: '5%'
			}
		},
		footerSmallScreenStyle: {
			height: 150,
			padding: '5px 30px',
			flexDirection: 'column',
			justifyContent: 'space-around',
			boxSizing: 'border-box'
		}
	}
))
const Footer = () => {
	const customClasses = useStyle()
	const history = useHistory()

	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const footerStyle: ClassValue = classNames(
		customClasses.footerStyle,
		isLargeScreen ? customClasses.footerLargeScreenStyle : customClasses.footerSmallScreenStyle
	)

	const redirect = (lien: string) => {
		history.push(lien)
		window.scrollTo(0, 0)
	}
	return (
		<div
			className={footerStyle}
		>
			<div>
				<img
					src="/img/Gecina_logo2.png"
					alt="Logo Gecina"
				/>
			</div>

			<div onClick={() => redirect(Links.conditionsGenerales)}>
				<span>
					Conditions Générales d'Utilisation
				</span>
			</div>

			<div onClick={() => redirect(Links.mentionsLegales)}>
				<span>
					Mentions légales
				</span>
			</div>

			<div onClick={() => redirect(Links.protectionDonnees)}>
				<span>
					Politique de protection des données personnelles
				</span>
			</div>

			<div onClick={() => redirect(Links.cookies)}>
				<span>
					Cookies
				</span>
			</div>

			<div onClick={() => window.open(Links.glossaire, '_blank')}>
				<span>
					Glossaire
				</span>
			</div>
		</div>
	)
}

export default Footer
