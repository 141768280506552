import {AJOUTER_FORMULAIRE_COORDONNEES_BANCAIRES} from './actionTypes'

const formulaireCoordonneesBancaires = (state = {}, action) => {

    switch (action.type) {
        case AJOUTER_FORMULAIRE_COORDONNEES_BANCAIRES: {
            return {
                ...action.payload,
                mode_reglement: action.payload.mode_reglement.toUpperCase()
            }
        }
        default:
            return state
    }
}

export default formulaireCoordonneesBancaires