import React from 'react'
import arrayMutators from 'final-form-arrays'
import Header from '../../../components/dialog/Header'
import { Field, Form } from 'react-final-form'
import EnhancedFormTextField from '../../../components/fields/FormTextField'
import { FieldArray } from 'react-final-form-arrays'
import FormFichier from '../../../components/fields/FormFichier'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '../../../components/button/Button'
import fetchFactory from '../../../utils/fetchFactory'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { afficherSnackbar } from '../../reducers/snackBar/actions'
import errorCatcher from '../../../utils/errorCatcher'
import { ContactFormModel } from '../  types/contactModel'
import classNames from 'classnames'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyles = makeStyles(
	() => (
		{
			containerLargeScreen: {
				width: '80%',
				margin: 'auto',
				marginTop: 50,
				marginBottom: 60
			},
			containerSmallScreen: {
				marginTop: 30,
			},
			marginTop: {
				marginTop: 30
			},
			marginBottom: {
				marginBottom: 30
			},
			marginButton: {
				marginBottom: 20,

				'& button': {
					width: '100%',
					minHeight: 54
				}
			}
		}
	)
)

interface AcceptingProps {
	closeDialog: () => void,
	tailleEcran: 'petit' | 'grand'
}

const SNACKBAR_ID = 'contact_form'
const messageEmailEnvoye: string = 'Votre demande de contact a bien été envoyée'
const titreDialog: string = 'Envoyer un message à votre contact'

type contactFormProps = AcceptingProps

const ContactForm: React.FC<contactFormProps> = (
	{
		closeDialog,
		tailleEcran
	}
) => {
	const classes = useStyles()
	const [loading, setLoading] = React.useState<boolean>(false)
	const dispatch = useDispatch()

	const cancelForm = () => {
		closeDialog()
	}

	const validate = (values: ContactFormModel) => {
		const errors: any = {}

		if (values.subject && values.subject.length === 0) {
			errors.subject = 'Requis*'
		}
		if (values.body && values.body.length === 0) {
			errors.body = 'Requis*'
		}

		return errors
	}

	const onSubmit = (values: ContactFormModel) => {
		setLoading(true)

		fetchFactory('/api/contact',
			{
				method: 'POST',
				body: JSON.stringify({ contact: values })
			}
		)
			.then(
				() => dispatch(
					afficherSnackbar(
						{
							id: SNACKBAR_ID,
							message: messageEmailEnvoye,
							open: true
						}
					)
				)
			)
			.catch(
				(error: any) => dispatch(
					errorCatcher(error, SNACKBAR_ID)
				)
			)
			.finally(
				() => {
					setLoading(false)
					closeDialog()
				}
			)
	}

	return (
		<Form
			mutators={{
				...arrayMutators
			}}
			onSubmit={onSubmit}
			validate={validate}
			render={
				(
					{
						handleSubmit,
						error
					}
				) => (
					<form
						onSubmit={handleSubmit}
						className={
							tailleEcran === 'grand' ? classes.containerLargeScreen : classes.containerSmallScreen
						}
					>
						<Header title={titreDialog} />

						<div
							className={
								classNames(
									'row',
									classes.marginBottom,
									classes.marginTop
								)
							}
						>
							{/* Subject input */}
							<div className="col-xs-12">
								<Field
									name="subject"
									component={EnhancedFormTextField}
									label="Objet"
									id="subject"
								/>
							</div>
						</div>

						<div
							className={
								classNames(
									'row',
									classes.marginBottom
								)
							}
						>
							{/* Subject input */}
							<div className="col-xs-12">
								<Field
									name="body"
									component={EnhancedFormTextField}
									label="Corps"
									id="body"
									multiline
								/>
							</div>
						</div>

						<FieldArray
							name="attachments"
							render={
								(
									{
										fields,
										meta
									}
								) => (
									<FormFichier
										fields={fields}
										meta={meta}
										label="Ajouter une pièce jointe"
										id="attachments"
									/>
								)
							}
						/>

						<div className="row middle-xs end-xs reverse-xs">
							<div
								className={
									classNames(
										'col-xs-12 col-sm-3 last-sm',
										classes.marginButton
									)
								}
							>
								{
									loading ? (
										<CircularProgress color="primary" />
									) : (
										<Button
											disabled={false}
											type="submit"
										>
											Envoyer
										</Button>
									)
								}
							</div>

							<div
								className={
									classNames(
										'col-xs-12 col-sm-3',
										classes.marginButton
									)
								}
							>
								<Button
									type="button"
									disabled={false}
									onClick={cancelForm}
									background="backgroundInherit"
									color="colorPrimary"
								>
									<ChevronLeftIcon />
									Annuler
								</Button>
							</div>
						</div>
						{
							error && <strong>{error}</strong>
						}
					</form>
				)
			}
		/>
	)
}

export default ContactForm
