import React, { useEffect } from 'react'
import styles from '../../theme/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import { useHistory, useLocation } from 'react-router-dom'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Links from '../../enum/links'
import fetchFactory from '../../utils/fetchFactory'
import errorCatcher from '../../utils/errorCatcher'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	rowStyleGrand: {
		height: 92,
		width: '100%',
		margin: 0
	},
	rowStylePetit: {
		height: 114,
		width: '100%',
		marginLeft: 0
	},
	itemStyleGrand: {
		height: 92,
		width: '50%',
		borderBottom: '1px solid #D8D8D8'
	},
	itemSelectedGrand: {
		height: 92,
		width: '50%',
		borderBottom: '2px solid #c2ad8a'
	},
	itemStylePetit: {
		height: 114,
		width: '50%',
		borderBottom: '1px solid #D8D8D8'
	},
	itemSelectedPetit: {
		height: 114,
		width: '50%',
		borderBottom: '2px solid #c2ad8a'
	},
	containerStyleGrand: {
		marginTop: -53,
		backgroundColor: 'white'
	},
	containerStylePetit: {
		marginTop: -32,
		backgroundColor: 'white'

	},
	contenuStyleGrand: {
		height: '100%',
		paddingLeft: 20,
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600,
			opacity: 0.5
		},
		'& img': {
			marginRight: 26,
			marginTop: 32,
			marginBottom: -8,
			marginLeft: 26
		}
	},
	contenuSelectedGrand: {
		height: '100%',
		paddingLeft: 20,
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600
		},
		'& img': {
			marginRight: 26,
			marginTop: 32,
			marginBottom: -8,
			marginLeft: 26
		}
	},
	contenuStylePetit: {
		height: '100%',
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600,
			opacity: 0.5,
			textAlign: 'center',
			fontSize: 13,
			padding: '0px 30% 0px 30%',
			placeContent: 'center'
		},
		'& img': {
			marginTop: 24,
			marginBottom: 9,
			marginLeft: '46%'
		}
	},
	contenuSelectedPetit: {
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600,
			textAlign: 'center',
			fontSize: 13,
			padding: '0px 30% 0px 30%',
			placeContent: 'center'
		},
		'& img': {
			marginTop: 24,
			marginBottom: 9,
			marginLeft: '46%'
		}
	}
}))

interface ModelDroitsActions {
	id: number,
	libelle_plan: string,
	date_attribution?: Date,
	date_debut_conservation?: Date,
	date_cessibilite?: Date,
	quantite: number
	bold?: boolean
}

interface ModelDroitsStock {
	id: number,
	libelle_plan: string,
	quantite: number
	prix_offre?: number,
	date_debut_plan?: Date,
	date_fin_plan?: Date,
	bold?: boolean
}

interface Store {
	errorCatcher: (error: any, id: string) => void
}

const DROITACTION = 'droits_actions'
const DROITSTOCK = 'droits_stock'
const Onglets = () => {
	const classes = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()

	const location = useLocation()

	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const [droitsActions, setDroitsActions] = React.useState<ModelDroitsActions[]>([])
	const [droitsStock, setDroitsStock] = React.useState<ModelDroitsStock[]>([])
	const [loading, setLoading] = React.useState<boolean>(true)

	useEffect(() => {
			fetchFactory('/api/droits/actionsPerformances')
				.then(
					(droits_actions: any) => {
						setDroitsActions(
							[
								...droits_actions
							]
						)
					},
					(error: any) => {
						dispatch(errorCatcher(error, DROITACTION))
					}
				)
				.catch(
					(error: any) => {
						dispatch(errorCatcher(error, DROITACTION))
					}
				)

			fetchFactory('/api/droits/stockOptions')
				.then(
					(droits_stock: any) => {
						setDroitsStock(
							[
								...droits_stock
							]
						)
					},
					(error: any) => {
						dispatch(errorCatcher(error, DROITSTOCK))
					}
				)
				.catch(
					(error: any) => {
						dispatch(errorCatcher(error, DROITSTOCK))
					}
				)
				.finally(
					() => {
						setLoading(false)
					}
				)
		}, [dispatch]
	)

	const lien = location.pathname

	const rowsContainer: ClassValue = classNames(
		'row',
		isLargeScreen ? classes.containerStyleGrand : classes.containerStylePetit
	)

	const rowStyle: ClassValue = classNames(
		'row',
		isLargeScreen ? classes.rowStyleGrand : classes.rowStylePetit
	)

	const itemStyle: ClassValue = classNames(
		isLargeScreen ? classes.itemStyleGrand : classes.itemStylePetit,
		classes.cursorPointer
	)

	const itemSelectedStyle: ClassValue = classNames(
		isLargeScreen ? classes.itemSelectedGrand : classes.itemSelectedPetit
	)

	const contenuStyle: ClassValue = classNames(
		isLargeScreen ? classes.contenuStyleGrand : classes.contenuStylePetit
	)

	const contenuSelectedStyle: ClassValue = classNames(
		isLargeScreen ? classes.contenuSelectedGrand : classes.contenuSelectedPetit
	)

	if (loading) {
		return <></>
	}

	return (
		<div className={rowsContainer}>
			<div className={rowStyle}>
				<div className={Links.compteTitres.includes(lien) ? itemSelectedStyle : itemStyle}
				     onClick={() => history.push(Links.compteTitres)}
				>
					<div className={Links.compteTitres.includes(lien) ? contenuSelectedStyle : contenuStyle}>
						<img
							src="/img/pictos_synthese.svg"
							alt="Synthèse icon"
							width={isLargeScreen ? 32 : 24}
							height={isLargeScreen ? 32 : 24}
							className={isLargeScreen ? '' : 'row'}
						/>
						<span className={isLargeScreen ? '' : 'row'}>Synthèse</span>
					</div>
				</div>
				<div className={Links.detailAvoirs.includes(lien) ? itemSelectedStyle : itemStyle}
				     onClick={() => history.push(Links.detailAvoirs)}
				>
					<div className={Links.detailAvoirs.includes(lien) ? contenuSelectedStyle : contenuStyle}>
						<img
							src="/img/pictos_recherche.svg"
							alt="Recherche icon"
							width={isLargeScreen ? 32 : 24}
							height={isLargeScreen ? 32 : 24}
						/>
						<span className={isLargeScreen ? '' : 'row'}>Détails des avoirs</span>
					</div>
				</div>
			</div>

			<div className={rowStyle}>
				<div className={Links.dispoAvoirs.includes(lien) ? itemSelectedStyle : itemStyle}
				     onClick={() => history.push(Links.dispoAvoirs)}
				>
					<div
						className={Links.dispoAvoirs.includes(lien) ? contenuSelectedStyle : contenuStyle}>
						<img
							src="/img/pictos_dispo_avoirs.svg"
							alt="Compte titre icon"
							width={isLargeScreen ? 32 : 24}
							height={isLargeScreen ? 32 : 24}
						/>
						<span className={isLargeScreen ? '' : 'row'}>Disponibilité des avoirs</span>
					</div>
				</div>

				<div
					className={Links.historiqueOperations.includes(lien) ? itemSelectedStyle : itemStyle}
					onClick={() => history.push(Links.historiqueOperations)}
				>
					<div
						className={Links.historiqueOperations.includes(lien) ? contenuSelectedStyle : contenuStyle}>
						<img
							src="/img/pictos-historique.svg"
							alt="Compte titre icon"
							width={isLargeScreen ? 32 : 24}
							height={isLargeScreen ? 32 : 24}
						/>
						<span className={isLargeScreen ? '' : 'row'}>Historique des opérations</span>
					</div>
				</div>
			</div>

			<div className={rowStyle}>
				{
					(droitsActions.length > 0 || droitsStock.length > 0) && (
						<div className={Links.droitsActions.includes(lien) ? itemSelectedStyle : itemStyle}
						     onClick={() => history.push(Links.droitsActions)}
						>
							<div
								className={Links.droitsActions.includes(lien) ? contenuSelectedStyle : contenuStyle}>
								<img
									src="/img/pictos_diagram.svg"
									alt="Diagramme icon"
									width={isLargeScreen ? 32 : 24}
									height={isLargeScreen ? 32 : 24}
								/>
								<span
									className={isLargeScreen ? '' : 'row'}>Droits à actions de performance</span>
							</div>
						</div>
					)
				}

				<div className={Links.fiscalite.includes(lien) ? itemSelectedStyle : itemStyle}
				     onClick={() => history.push(Links.fiscalite)}
				>
					<div
						className={Links.fiscalite.includes(lien) ? contenuSelectedStyle : contenuStyle}>
						<img
							src="/img/fiscalite.png"
							alt="Fiscalité"
							width={isLargeScreen ? 32 : 24}
							height={isLargeScreen ? 32 : 24}
						/>
						<span
							className={isLargeScreen ? '' : 'row'}>Fiscalité</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Onglets