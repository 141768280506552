import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import LogInPage from './LogInPage'
import { useCookies } from 'react-cookie'
import localStorageEnum from '../../../enum/localStorageEnum'
import Loader from '../../../components/loader/Loader'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import { useHistory } from 'react-router'
import Links from '../../../enum/links'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	loginBackground: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		height: 'fit-content',
		minHeight: '100vh',
		width: 'fit-content',
		minWidth: '100vw',
		backgroundImage: 'url(\'/img/visuel_connexion.jpg\')',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	}
}))

interface ModelAccessToken {
	accessToken: string
	authorizeUrl: string
	expiresAt: number
	scopes: string[]
	tokenType: string
	userinfoUrl: string
	value: string
}

interface AcceptingProps {
	goToNewPwd?: boolean
}

type AuthentificationPageProps = AcceptingProps

const AuthentificationPage: React.FC<AuthentificationPageProps> = (
	{
		goToNewPwd = false
	}
) => {
	const classes = useStyle()
	const history = useHistory()
	const removeCookies = useCookies()[2]
	const cookies = useCookies()[0]
	const [loading, setLoading] = useState<boolean>(true)
	const accessTokenFromCookies: ModelAccessToken = cookies[localStorageEnum.accessToken]
	const fetchRef = useRef<Promise<any>>()

	const clearCaches = useCallback(
		() => {
			localStorage.removeItem(localStorageEnum.accessToken)
			localStorage.removeItem(localStorageEnum.codeVerifier)

			removeCookies(localStorageEnum.idToken, {
				path: '/',
				domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
			})
			removeCookies(localStorageEnum.accessToken, {
				path: '/',
				domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
			})
			setLoading(false)
		}, [removeCookies]
	)

	const checkToken = useCallback(
		() => {
			fetchRef.current = fetch('/api/okta/checkTokenValidity',
				{
					method: configEnum.post,
					body: JSON.stringify({ token: accessTokenFromCookies.value })
				}
			)
				.then(
					(response: Response) => {
						if (response.ok) {
							response.json()
								.then(
									() => history.push(Links.accueil)
								)
								.catch(
									() => clearCaches()
								)
						} else {
							clearCaches()
						}
					}
				)
		}, [accessTokenFromCookies, history, clearCaches]
	)

	useEffect(() => {
		if (loading) {
			if (accessTokenFromCookies && 'value' in accessTokenFromCookies && !fetchRef.current) {
				checkToken()
			} else {
				clearCaches()
			}
		}
	}, [accessTokenFromCookies, checkToken, clearCaches, loading])


	if (loading) {
		return (
			<Loader styles={{ height: '99vh' }} />
		)
	}

	return (
		<div className={classes.loginBackground}>
			<LogInPage toNewPwd={goToNewPwd} />
		</div>
	)
}

export default AuthentificationPage
