import React from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import EnhancedCorpsDonneesPersonnelles from './CorpsDonneesPersonnelles'

const titre: string = 'Vos données personnelles'

const DonneesPersonnelles = () => {
	return (
		<>
			<EnhancedEntete titre={titre} />

			<MediaQuery
				maxWidth={1024}
			>

				{
					(match) => {
						return match ? (
							<>
								<EnhancedCorpsDonneesPersonnelles tailleEcran="petit" />
							</>
						) : (
							<>
								<EnhancedCorpsDonneesPersonnelles tailleEcran="grand" />
							</>
						)
					}
				}
			</MediaQuery>
		</>
	)
}

export default DonneesPersonnelles
