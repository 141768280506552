import { createMuiTheme } from '@material-ui/core/styles'

const gecinaBlueMain = '#122941'
const gecinaBlue90 = '#293e54'
const gecinaBlue80 = '#415367'
const gecinaBlue70 = '#586979'
const gecinaBlue60 = '#717f8d'
const gecinaBlue50 = '#8894a0'
const gecinaBlue40 = '#a0a9b3'
const gecinaBlue30 = '#b7bec6'
const gecinaBlue20 = '#d0d4d9'
const gecinaBlue10 = '#e7e9ec'
const gecinaBlue5 = '#f3f4f5'

const gecinaBrownMain = '#B3A688'
const gecinaBrown90 = '#baae93'
const gecinaBrown80 = '#c2b8a0'
const gecinaBrown70 = '#c9c0ab'
const gecinaBrown60 = '#d1cab8'
const gecinaBrown50 = '#d9d2c3'
const gecinaBrown40 = '#e1dbcf'
const gecinaBrown30 = '#e8e4db'
const gecinaBrown20 = '#f0ede7'
const gecinaBrown10 = '#f7f6f3'
const gecinaBrown5 = '#fbfaf9'

const gecinaGrey = '#F3F4F5'

const fontFamilyMonserrat = '\'Montserrat\', sans-serif'
const fontFamilyBlackerDisplay = '\'BlackerDisplay\', sans-serif'

const theme = createMuiTheme({
	overrides: {
		MuiSnackbarContent: {
			root: {
				backgroundColor: gecinaBlueMain
			}
		},
		MuiInputBase: {
			root: {
				fontFamily: fontFamilyMonserrat
			}

		},
		MuiDialog: {
			paper: {
				borderRadius: 32
			}
		}
	},
	typography: {
		fontFamilyMonserrat,
		fontFamilyBlackerDisplay,
		headline: {
			fontSize: 20,
			fontWeight: 700,
			marginBottom: 20
		},
		subheading: {
			fontSize: 15
		},
		body1: {
			fontSize: 14
		},
		title0: {
			fontSize: 72,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '0.96'
		},
		title1: {
			fontSize: 48,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.33'
		},
		title2: {
			fontSize: 32,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.13'
		},
		title3: {
			fontSize: 24,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.17'
		},
		title4: {
			fontSize: 16,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5'
		},
		title5: {
			fontSize: 14,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.43'
		},
		title6: {
			fontSize: 28,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.21'
		},
		title7: {
			fontSize: 16,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.38'
		},
		title8: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1
		},
		text0: {
			fontSize: 20,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4'
		},
		text0_opacity: {
			fontSize: 20,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4',
			opacity: 0.6
		},
		text1_opacity: {
			fontSize: 16,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5',
			opacity: 0.6
		},
		text1: {
			fontSize: 16,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.5'
		},
		text2_opacity: {
			fontSize: 14,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.57',
			opacity: 0.6
		},
		text2: {
			fontSize: 14,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.57'
		},
		text3_opacity: {
			fontSize: 12,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67',
			opacity: 0.6
		},
		text3: {
			fontSize: 12,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67'
		},
		text4_opacity: {
			fontSize: 12,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67',
			opacity: 0.6
		},
		text4: {
			fontSize: 12,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.67'
		},
		text5: {
			fontSize: 12,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1
		},
		text6: {
			fontSize: 11,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1
		},
		text6_opacity: {
			fontSize: 11,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 1,
			opacity: 0.7
		},
		text7: {
			fontSize: 16,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.25'
		},
		text8: {
			fontSize: 14,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.43'
		},
		text9: {
			fontSize: 10,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.6'
		},
		text9_opacity: {
			fontSize: 10,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.6',
			opacity: 0.6
		},
		text10: {
			fontSize: 24,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1'
		},
		text11: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.2'
		},
		text11_opacity: {
			fontSize: 20,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.2',
			opacity: 0.6
		},
		text12: {
			fontSize: 20,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1'
		},
		text13: {
			fontSize: 20,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4'
		},
		text13_opacity: {
			fontSize: 20,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.4',
			opacity: 0.6
		},
		text14: {
			fontSize: 10,
			fontWeight: 600,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal'
		},
		text15: {
			fontSize: 14,
			fontWeight: 'normal' as 'normal',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal'
		},
		text16_opacity: {
			fontSize: 8,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal',
			opacity: 0.6
		},
		text17: {
			fontSize: 13,
			fontWeight: 500,
			fontFamily: fontFamilyMonserrat,
			lineHeight: '1.54'
		},
		text18: {
			fontSize: 18,
			fontWeight: 'bold' as 'bold',
			fontFamily: fontFamilyMonserrat,
			lineHeight: 'normal'
		},
		text19: {
			fontSize: 20,
			fontWeight: 900,
			fontFamily: fontFamilyBlackerDisplay,
			lineHeight: '1.2'
		}
	},
	palette: {
		primary: {
			main: gecinaBlueMain
		},
		secondary: {
			main: gecinaBrownMain
		},
		blue: {
			main: gecinaBlueMain,
			90: gecinaBlue90,
			80: gecinaBlue80,
			70: gecinaBlue70,
			60: gecinaBlue60,
			50: gecinaBlue50,
			40: gecinaBlue40,
			30: gecinaBlue30,
			20: gecinaBlue20,
			10: gecinaBlue10,
			5: gecinaBlue5
		},
		brown: {
			main: gecinaBrownMain,
			90: gecinaBrown90,
			80: gecinaBrown80,
			70: gecinaBrown70,
			60: gecinaBrown60,
			50: gecinaBrown50,
			40: gecinaBrown40,
			30: gecinaBrown30,
			20: gecinaBrown20,
			10: gecinaBrown10,
			5: gecinaBrown5
		}
	},
	gecina: {
		blue: gecinaBlueMain,
		brown: gecinaBrownMain,
		grey: gecinaGrey
	}
})

export default theme
