import React from 'react'
import { BourseFormModel } from '../types/bourseFormModel'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../../components/dialog/Header'
import { Form } from 'react-final-form'
import { Grid } from '@material-ui/core'
import classNames from 'classnames'
import Button from '../../../components/button/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { formFieldsEnum } from '../enums/formFieldsEnum'
import { ORDER_DIRECTION_LABEL } from '../enums/orderDirectionEnum'
import { CONDITION_LABEL, conditionEnum } from '../enums/conditionEnum'
import { VALIDITY_LABEL, validityDateEnum } from '../enums/validityDateEnum'
import styles from '../../../theme/styles'
import arrayMutators from 'final-form-arrays'
import ReadOnlyValue from './ReadOnlyValue'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			texteInformatifStyle: {
				fontSize: 14,
				margin: '30px 0'
			},
			dialogContainer: {
				'& > div:first-child': {
					marginTop: 35
				},
				'& p:first-child': {
					marginTop: 40
				}
			},
			buttonContainer: {
				marginBottom: 20
			}
		}
	)
)

interface AcceptingProps {
	onClose: () => void,
	onConfirm: (values: BourseFormModel) => void,
	values: BourseFormModel
}

type PopinFormRecapProps = AcceptingProps

const PopinFormRecap: React.FC<PopinFormRecapProps> = (
	{
		onClose,
		onConfirm,
		values
	}
) => {
	const classes = useStyles()

	const informationText = classNames(
		classes.colorPrimary,
		classes.medium,
		classes.text,
		classes.texteInformatifStyle
	)

	return (
		<div className={classes.dialogContainer}>
			<Header title="Confirmer l'envoi de l'ordre de bourse" />

			<p className={informationText}>Vérifiez que les données suivantes sont exactes.</p>

			<Form
				mutators={{
					...arrayMutators
				}}
				onSubmit={onConfirm}
				initialValues={values}
				render={
					(
						{
							handleSubmit
						}
					) => (
						<form
							onSubmit={handleSubmit}
						>
							<Grid container item xs={12} spacing={3}>
								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="Nom" value={values.lastname} />
								</Grid>

								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="Prénom" value={values.firstname} />
								</Grid>

								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="N° de compte" value={values.account} />
								</Grid>

								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="Email" value={values.email} />
								</Grid>

								<Grid item xs={12}>
									<ReadOnlyValue label="Adresse" value={values.address} />
								</Grid>

								<Grid item xs={12}>
									<ReadOnlyValue label="Sens" value={ORDER_DIRECTION_LABEL[values.orderDirection]} />
								</Grid>

								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="Nombre de titres" value={values.nbOrders} />
								</Grid>

								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="Conditions de prix" value={CONDITION_LABEL[values.condition]} />
								</Grid>

								{
									values[formFieldsEnum.condition] === conditionEnum.onGoingLimited && (
										<Grid item xs={12} md={6}>
											<ReadOnlyValue label="Cours souhaité" value={values.ongoing} />
										</Grid>
									)
								}

								<Grid item xs={12} md={6}>
									<ReadOnlyValue label="Date de validité de l'ordre" value={values[formFieldsEnum.validityDate] === validityDateEnum.date ? values.date : VALIDITY_LABEL[values.validityDate as keyof typeof VALIDITY_LABEL]} />
								</Grid>

								{
									values.comment && (
										<Grid item xs={12}>
											<ReadOnlyValue label="Commentaire" value={values.comment} />
										</Grid>
									)
								}

								{
									values.files && values.files.length > 0 && (
										<Grid item xs={12}>
											<ReadOnlyValue label="Fichiers" value={
												values.files.map(
													(mappedFile) => (
														<Grid container item xs={12}>
															<span>{ mappedFile.fichier }</span>
														</Grid>
													)
												)
											} />
										</Grid>
									)
								}

								<Grid
									container
									justify="flex-end"
									alignItems="center"
									className={classes.buttonContainer}
								>
									<Grid
										container
										item
										xs={6}
										md={3}
										justify="flex-end"
									>
										<Button
											type="button"
											disabled={false}
											onClick={() => onClose()}
											background="backgroundInherit"
											color="colorPrimary"
										>
											<ChevronLeftIcon />
											Annuler
										</Button>
									</Grid>

									<Grid
										container
										item
										xs={6}
										md={3}
										justify="flex-end"
									>
										<Button
											disabled={false}
											type="submit"
										>
											Envoyer
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)
				}
			/>
		</div>
	)
}

export default PopinFormRecap
