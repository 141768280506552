import React, { useEffect, useState } from 'react'
import styles from '../../theme/styles'
import { makeStyles } from '@material-ui/core/styles'
import EnhancedProfileMenu from '../../modules/profileMenu/ProfileMenu'
import Links from '../../enum/links'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import thousandsSeparator from '../../utils/thousandsSeparator'
import { useMediaQuery } from 'react-responsive'
import { Badge, withStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from '../../modules/reducers'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	profileMenuStyle: {
		paddingRight: 30,
		display: 'flex',
		textAlign: 'center'
	},
	menuItem: {
		marginLeft: 40,

		'&:hover': {
			color: theme.palette.secondary.main
		},

		'&:first-child': {
			marginLeft: 25
		}
	},
	marginTop: {
		marginTop: 15
	},
	hauteurLigne: {
		height: '100%',
		flexFlow: 'nowrap',

		'& p': {
			display: 'inline-block',
			position: 'relative',
			height: '100%',
			margin: 0,
			paddingTop: 18,
			boxSizing: 'border-box'
		}
	},
	selected: {
		color: theme.palette.secondary.main,

		'&::after': {
			bottom: '0',
			width: '100%',
			height: 8,
			content: '\' \'',
			display: 'inline-block',
			background: theme.palette.secondary.main,
			position: 'absolute',
			right: 0
		}
	},
	onGoingGecina: {
		marginLeft: 40,

		'& span': {
			display: 'flex',
			alignItems: 'center',

			'& svg': {
				marginRight: 5
			}
		}
	},
	clubActionnaireStyle: {
		width: 'min-content'
	}
}))

const CustomBadge = withStyles(
	() => (
		{
			root: {
				height: '100%'
			},
			badge: {
				top: -5,
				right: -10
			}
		}
	)
)(Badge)

interface AcceptingProps {
	location: Location,
	onClick: (lien: string) => void
}

const lienAccueil: string = Links.accueil
const lienCompteTiers: string = Links.compteTitres
const lienDetailAvoirs: string = Links.detailAvoirs
const lienDisponibiliteAvoirs: string = Links.dispoAvoirs
const lienDroitsActions: string = Links.droitsActions
const lienContacts: string = Links.contacts
const lienClubActionnaires: string = Links.clubActionnaires
const lienDocuments: string = Links.documentsUtiles
const lienDocumentsPerso: string = Links.documentsPerso
const lienOperations: string = Links.historiqueOperations
const lienEServices: string = Links.eServices

type MenuGrandEcranProps = AcceptingProps

const MenuGrandEcran: React.FC<MenuGrandEcranProps> = (
	{
		location,
		onClick
	}
) => {
	const classes = useStyles()

	const dislayOnGoing = useMediaQuery(
		{
			query: '(min-width: 1100px)'
		}
	)

	const [coursGecina, setCoursGecina] = useState<number>(0)
	const lien = location.pathname
	const utilisateur = useSelector((state: RootState) => state.utilisateur)

	const liensAccueil: string[] = [lienAccueil]
	const liensCompteTitres: string[] = [lienCompteTiers, lienDetailAvoirs, lienDisponibiliteAvoirs, lienDroitsActions, lienOperations]
	const liensContacts: string[] = [lienContacts]
	const liensDocuments: string[] = [lienDocuments, lienDocumentsPerso]
	const liensClubActionnaires: string[] = [lienClubActionnaires]
	const liensEServices: string[] = [lienEServices]

	const itemStyle: ClassValue = classNames(
		classes.cursorPointer,
		classes.menuItem
	)

	const textStyle: ClassValue = classNames(
		classes.text,
		classes.semiBold,
		classes.textSize
	)

	useEffect(
		() => {
			fetch('https://www.gecina.fr/fr/gecina-finance/stock-prices')
				.then(
					(response: any) => response.json().then(
						(response: any) => setCoursGecina(Number(response[0].fr.last_quotation_rate.split(' €')[0].replace(',', '.')))
					)
				)
		}, []
	)

	return (
		<>
			<div
				className={classNames(
					'col-sm-8',
					classes.marginTop
				)
				}
			>
				<div
					className={classNames(
						'row',
						classes.hauteurLigne
					)
					}
				>
					<div
						className={itemStyle}
						onClick={() => onClick(lienAccueil)}
					>
						<p
							className={textStyle}
						>
							<span
								className={liensAccueil.includes(lien) ? classes.selected : ''}
							>
								Accueil
							</span>
						</p>
					</div>

					<div
						className={itemStyle}
						onClick={() => onClick(lienCompteTiers)}
					>
						<p
							className={textStyle}
						>
							<span
								className={liensCompteTitres.includes(lien) ? classes.selected : ''}
							>
								Votre compte-titres
							</span>
						</p>
					</div>

					<div
						className={itemStyle}
						onClick={() => onClick(lienDocuments)}
					>
						<p
							className={textStyle}
						>
							<CustomBadge
								badgeContent={utilisateur.nbDocuments}
								color="primary"
								className={liensDocuments.includes(lien) ? classes.selected : ''}
								invisible={utilisateur.nbDocuments === 0}
							>
								Documents
							</CustomBadge>
						</p>
					</div>

					<div
						className={itemStyle}
						onClick={() => onClick(lienEServices)}
					>
						<p
							className={textStyle}
						>
							<span
								className={liensEServices.includes(lien) ? classes.selected : ''}
							>
								E-services
							</span>
						</p>
					</div>

					<div
						className={itemStyle}
						onClick={() => onClick(lienContacts)}
					>
						<p
							className={textStyle}
						>
							<span
								className={liensContacts.includes(lien) ? classes.selected : ''}
							>
								Contacts
							</span>
						</p>
					</div>

					<div
						className={itemStyle}
						onClick={() => onClick(lienClubActionnaires)}
					>
						<p
							className={
								classNames(
									textStyle,
									classes.clubActionnaireStyle
								)
							}
						>
							<CustomBadge
								badgeContent={1}
								color="primary"
								className={liensClubActionnaires.includes(lien) ? classes.selected : ''}
								invisible={utilisateur.aVuNouveauteActionnaire}
							>
								Club des Actionnaires
							</CustomBadge>
						</p>
					</div>

					{
						dislayOnGoing && (
							<div className={classes.onGoingGecina}>
								<p
									className={textStyle}
								>
									<span>
										<ShowChartIcon color="secondary" />
										{thousandsSeparator(coursGecina)}€
									</span>
								</p>
							</div>
						)
					}
				</div>
			</div>

			<div
				className={
					[
						'col-sm-2 end-sm',
						classes.profileMenuStyle,
						classes.marginTop
					].join(' ')
				}
			>
				<EnhancedProfileMenu onClick={onClick} />
			</div>
		</>
	)
}

export default MenuGrandEcran
