import * as React from 'react'
import { IconsModel } from './iconsModel'

function SvgAttachment(
	{
		color,
		width = 25,
		height = 25
	}: IconsModel
) {
	return (
		<svg width={width} height={height}>
			<path
				fill={color}
				fillRule="evenodd"
				d="M19.564 3.01a2.481 2.481 0 012.47 2.395l.002.106-.201 4.228c0 1.092-.35 1.663-1.826 3.16l-.256.258-8.158 8.157-.849-.848 8.392-8.393c1.188-1.202 1.466-1.612 1.495-2.241l.002-.121.202-4.228c0-.672-.53-1.229-1.167-1.271l-.077-.003-4.286.203c-.682 0-1.098.254-2.355 1.495L3.48 15.378a1.654 1.654 0 00-.066 2.26l.066.07 1.766 1.766c.618.617 1.616.64 2.26.067l.07-.067 8.485-8.484a.151.151 0 00.021-.185l-.02-.026-1.795-1.795a.152.152 0 00-.185-.021l-.026.02-7.405 7.405-.849-.848 7.405-7.405a1.35 1.35 0 011.84-.063l.068.063 1.796 1.796c.502.504.523 1.311.062 1.84l-.063.067-8.485 8.485a2.853 2.853 0 01-3.938.085l-.09-.085-1.766-1.766a2.856 2.856 0 01-.085-3.939l.085-.088 9.502-9.5c1.427-1.405 2.044-1.796 3.056-1.816l.09-.001 4.285-.203z"
			/>
		</svg>
	)
}

export default SvgAttachment
