import React from 'react'
import styles from '../../../theme/styles'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import EnhancedTableauSimple from '../../../components/table/TableauSimple'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import {makeStyles} from '@material-ui/core/styles'
import formatDate from '../../../utils/formatDate'
import classNames from 'classnames'
import {ClassValue} from 'classnames/types'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    margin: {
        marginBottom: 20,
        marginLeft: 40
    }
}))

interface ModelDroitsActions {
    id: number,
    libelle_plan: string,
    date_attribution?: Date,
    date_debut_conservation?: Date,
    date_cessibilite?: Date,
    quantite: number
}

interface ModelColonnes {
    value: string,
    label: string,
    type: string,
    width: string,
    render?: (row: ModelDroitsActions) => React.ReactChild
}

interface AcceptingProps {
    droitsActions: ModelDroitsActions[],
    telechargement: (id: number, type: string) => void
}

type ListeDroitsActionsGrandProps = AcceptingProps

const ListeDroitsActionsGrand = (
    props: ListeDroitsActionsGrandProps
) => {
    const {
        droitsActions,
        telechargement
    } = props
    const classes = useStyles()

    const colonnes: ModelColonnes[] = [
        {
            value: 'libelle_plan',
            label: 'Plan',
            type: 'string',
            width: '22%'
        },
        {
            value: 'date_attribution',
            label: 'Date d\'attribution',
            type: 'date',
            width: '17%',
            render: (row: ModelDroitsActions) => (
                <div>
                    {row.date_attribution !== undefined ? formatDate(row.date_attribution) : ''}
                </div>
            )
        },
        {
            value: 'date_debut_conservation',
            label: 'Date de début de conservation',
            type: 'date',
            width: '17%',
            render: (row: ModelDroitsActions) => (
                <div>
                    {row.date_debut_conservation !== undefined ? formatDate(row.date_debut_conservation) : ''}
                </div>
            )
        },
        {
            value: 'date_cessibilite',
            label: 'Date de cessibilité',
            type: 'date',
            width: '17%',
            render: (row: ModelDroitsActions) => (
                <div>
                    {row.date_cessibilite !== undefined ? formatDate(row.date_cessibilite) : ''}
                </div>
            )
        },
        {
            value: 'quantite',
            label: 'Quantité à attribuer',
            type: 'number',
            width: '17%',
            render: (row: ModelDroitsActions) => (
                <div>
                    {thousandsSeparator(row.quantite, true)}
                </div>
            )
        },
        {
            value: 'reglement_plan',
            label: 'Règlement du plan',
            type: 'icon',
            width: '15%',
            render: (row: ModelDroitsActions) => (
                <div>
                    {row.libelle_plan !== 'Total' ? <PictureAsPdfOutlinedIcon
                            onClick={() => telechargerDocument(row.id)}
                            className={classNames(
                                [
                                    classes.colorPrimary,
                                    classes.cursorPointer
                                ])
                            }
                        />
                        : ''}
                </div>
            )
        }
    ]

    const telechargerDocument = (id: number) => {
        telechargement(id, 'actions')
    }

    const titreStyle: ClassValue = classNames(
        classes.titleSize,
        classes.colorPrimary,
        classes.title,
        classes.bold,
        classes.margin
    )

    return (
        <>
            {droitsActions.reduce((a: number, b: ModelDroitsActions) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0) > 0 ?
                <>
                    <div
                        className={titreStyle}
                    >
                        <p>Droits à actions de performance</p>
                    </div>

                    <EnhancedTableauSimple
                        colonnes={colonnes}
                        lignes={droitsActions}
                    />
                </>
                :
                <></>
            }
        </>
    )
}

export default ListeDroitsActionsGrand
