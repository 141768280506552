import React from 'react'
import ListeHistoriqueOperationsPetit from './ListeHistoriqueOperationsPetit'

const CorpsHistoriqueOperationsPetit = () => {
    return (
        <>
            <ListeHistoriqueOperationsPetit/>
        </>
    )
}

export default CorpsHistoriqueOperationsPetit