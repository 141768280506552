import React from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import EnhancedCorpsCoordonneesBancaires from './CorpsCoordonneesBancaires'

const titre: string = 'Vos coordonnées bancaires'

const CoordonneesBancaires = () => {
	return (
		<>
			<EnhancedEntete titre={titre} />

			<MediaQuery
				maxWidth={1024}
			>

				{
					(match) => {
						return match ? (
							<>
								<EnhancedCorpsCoordonneesBancaires tailleEcran="petit" />
							</>
						) : (
							<>
								<EnhancedCorpsCoordonneesBancaires tailleEcran="grand" />
							</>
						)
					}
				}
			</MediaQuery>
		</>
	)
}

export default CoordonneesBancaires
