import React, { Dispatch, useEffect } from 'react'
import Button from '../../../components/button/Button'
import { compose } from 'recompose'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../../theme/styles'
import { makeStyles } from '@material-ui/core/styles'
import ListAltIcon from '@material-ui/icons/ListAlt'
import fetchFactory from '../../../utils/fetchFactory'
import { connect } from 'react-redux'
import errorCatcher from '../../../utils/errorCatcher'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
	buttonContainer: {
		height: '54px',
		paddingBottom: '20px'
	},
	button: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	spinnerStyle: {
		textAlign: 'center',

		'& div': {
			width: '100px !important',
			height: '100px !important'
		}
	}
}))

interface ModelBouton {
	id: number,
	texte: string
}

interface Store {
	errorCatcher: (error: any, id: string) => void
}

const texteTelecharger: string = 'Télécharger'

type ListeBoutonsPetitProps = WithStyles<typeof styles> & Store

const ListeBoutonsPetit = (
	props: ListeBoutonsPetitProps
) => {
	const {
		classes,
		errorCatcher
	} = props

	const customClasses = useStyles()

	const [boutons, setBoutons] = React.useState<ModelBouton[]>([])
	const [loading, setLoading] = React.useState<boolean>(false)

	useEffect(() => {
		setBoutons([
			{
				id: 4,
				texte: texteTelecharger
			}
		])
	}, [])

	const onButtonClicked = () => {
		setLoading(true)

		fetchFactory('/api/telecharger/detail/avoirs', {
			method: 'POST'
		}, 'blob')
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = 'detail_avoirs.csv'
					a.click()
				}
			)
			.catch(
				(error: any) => {
					errorCatcher(error, 'detail_avoirs')
				}
			)
			.finally(
				() => {
					setLoading(false)
				}
			)
	}

	return (
		<div>
			{
				!loading && boutons.map(bouton => (
					<div
						className={customClasses.buttonContainer}
						key={bouton.id}
					>
						<Button
							disabled={false}
							onClick={onButtonClicked}
						>
							<span className={customClasses.button}>
								<ListAltIcon
									className={classes.colorSecondary}
								/>
								<span className={classes.textSize}>{bouton.texte}</span>
								<span></span>
							</span>
						</Button>
					</div>
				))
			}

			{
				loading &&
				<div className={customClasses.spinnerStyle}>
					<CircularProgress color="primary" />
				</div>
			}
		</div>
	)
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		errorCatcher: (error: any, id: string) => {
			dispatch(errorCatcher(error, id))
		}
	}
}

const EnhancedListeBoutonsPetit = compose<ListeBoutonsPetitProps, {}>(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)(ListeBoutonsPetit)

export default EnhancedListeBoutonsPetit
