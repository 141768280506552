import React from 'react'
import EnhancedListeActionsGrand from './ListeActionsGrand'
import {compose} from 'recompose'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    containerStyle: {
        backgroundColor: 'white'
    },
    width: {
        width: '100%',
        margin: '30px auto'
    },
    marginStyle: {
        margin: 0
    }
}))

interface ModelCompteTitres {
    id: number,
    code_isin: string,
    nature_valeur: string,
    solde_global: number,
    valorisation: number,
    libelle_valeur: string
}

interface ModelPrixGecina {
    update_date: Date,
    price: string
}

interface ModelDroitsActions {
    id: number,
    libelle_plan: string,
    date_attribution: Date,
    date_debut_conservation: Date,
    quantite: number
}

interface ModelDroitsStock {
    id: number,
    libelle_plan: string,
    quantite: number
    prix_offre: number,
    date_debut_plan: Date,
    date_fin_plan: Date
}

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date,
    typologie: string,
    actions: string,
    prix_acquisition: number
}

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    message: string
}

interface ModelAvoirs {
    disponibilites: ModelDisponibilites[],
    indisponibilites: ModelIndisponibilites[]
}

interface AcceptingProps {
    compteTitres: ModelCompteTitres[],
    prixGecina: ModelPrixGecina,
    droitsActions: ModelDroitsActions[],
    droitsStock: ModelDroitsStock[],
    avoirs: ModelAvoirs
}

type CorpsCompteTitresGrandProps = AcceptingProps

const CorpsCompteTitresGrand = (
    props: CorpsCompteTitresGrandProps
) => {
    const {
        compteTitres,
        prixGecina
    } = props

    const classes = useStyles()

    const containerStyle: ClassValue = classNames(
        'col-md-11',
        classes.containerStyle,
        classes.width
    )

    const blocStyle: ClassValue =classNames(
        'row',
        classes.containerStyle
    )
    return (
        <div className={blocStyle}>
            <div className={containerStyle}>
                <EnhancedListeActionsGrand
                    compteTitres={compteTitres}
                    prixGecina={prixGecina}
                />
            </div>
        </div>
    )
}

const EnhancedCorpsCompteTitresGrand = compose<CorpsCompteTitresGrandProps, AcceptingProps>()(CorpsCompteTitresGrand)

export default EnhancedCorpsCompteTitresGrand
