import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery } from 'react-responsive'
import SvgAttachment from '../../icons/Attachment'
import { FileModel } from './types/fieldsModel'
import { useDispatch } from 'react-redux'
import { afficherSnackbar } from '../../modules/reducers/snackBar/actions'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			errorStyle: {
				color: '#B92D00',
				fontSize: 10
			},
			textSize: {
				fontSize: 14
			},
			alignItems: {
				verticalAlign: 'middle'
			},
			alignItemsLargeScreen: {
				'&:first-child': {
					marginRight: 20
				}
			},
			alignItemsSmallScreen: {
				'&:first-child': {
					marginRight: 10
				}
			},
			customFontWeight: {
				fontWeight: 600
			},
			fileListStyle: {
				padding: '12px 12px 12px 16px',
				background: theme.palette.blue['5'],
				marginBottom: 20,

				'& svg': {
					marginLeft: 'auto'
				}
			},
			fileNameLargeScreenStyle: {
				fontSize: 14,
				fontWeight: 500,
				fontFamily: theme.typography.fontFamilyMonserrat,
				lineHeight: '1.57'
			},
			fileNameSmallScreenStyle: {
				fontSize: 12,
				fontWeight: 500,
				fontFamily: theme.typography.fontFamilyMonserrat,
				lineHeight: '1.67',
				opacity: 0.6
			},
			labelLargeScreenStyle: {
				color: theme.palette.blue['30'],
				fontSize: 20,
				fontWeight: 'normal' as 'normal',
				fontFamily: theme.typography.fontFamilyMonserrat,
				lineHeight: '1'
			},
			labelSmallScreenStyle: {
				fontSize: 12,
				fontWeight: 600,
				fontFamily: theme.typography.fontFamilyMonserrat,
				lineHeight: '1.67'
			},
			addFileContainerStyle: {
				marginBottom: 30
			}
		}
	)
)

interface AcceptingProps {
	label: React.ReactChild,
	id: string,
}

type FormFileProps =
	FieldArrayRenderProps<FileModel, HTMLElement>
	& AcceptingProps

// Limite taille fichier à 10Mo
const MAX_SIZE = 10000000
const AcceptedExtensions: string[] = ['png', 'jpg', 'jpeg', 'gif', 'pdf']

const FormFile: React.FC<FormFileProps> = (
	{
		fields,
		label,
		id,
		meta: {
			error,
			submitFailed
		}
	}
) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)
	const attachmentImg = isLargeScreen ? <SvgAttachment color="#122941" /> : <SvgAttachment color="#00ACA9" />
	const inputRef = useRef<HTMLInputElement>(document.createElement('input'))

	const fileNameClass: ClassValue = classNames(
		classes.colorPrimary,
		classes.alignItems,
		{
			[classes.alignItemsLargeScreen]: isLargeScreen,
			[classes.fileNameLargeScreenStyle]: isLargeScreen,
			[classes.alignItemsSmallScreen]: !isLargeScreen,
			[classes.fileNameSmallScreenStyle]: !isLargeScreen
		}
	)

	const fileIconClass: ClassValue = classNames(
		classes.colorPrimary,
		classes.alignItems,
		classes.cursorPointer,
		isLargeScreen ? classes.alignItemsLargeScreen : classes.alignItemsSmallScreen
	)

	const addFileContainerClass: ClassValue = classNames(
		'col-xs-12',
		classes.cursorPointer,
		classes.flex,
		classes.alignCenter,
		classes.addFileContainerStyle
	)

	const addFileIconClass: ClassValue = classNames(
		classes.colorPrimary,
		classes.alignItems,
		isLargeScreen ? classes.alignItemsLargeScreen : classes.alignItemsSmallScreen
	)

	const labelClass: ClassValue = classNames(
		classes.alignItems,
		{
			[classes.alignItemsLargeScreen]: isLargeScreen,
			[classes.labelLargeScreenStyle]: isLargeScreen,
			[classes.alignItemsSmallScreen]: !isLargeScreen,
			[classes.colorPrimary]: !isLargeScreen,
			[classes.labelSmallScreenStyle]: !isLargeScreen
		}
	)

	const fileListClass: ClassValue = classNames(
		'col-xs-12',
		classes.fileListStyle,
		classes.flex,
		classes.alignCenter
	)

	const openFileSelectionner = () => {
		const input = inputRef.current
		input.click()
	}

	const toBase64 = (file: File) => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(
			{
				name: file.name,
				encodedFile: reader.result,
				file: file
			}
		)
		reader.onerror = error => reject(error)
	})

	const onFileSelected = (event: any) => {
		const { push } = fields
		for (let i = 0; i < event.target.files.length; i++) {
			if (event.target.files[i].size <= MAX_SIZE) {
				if (isCorrectExtension(event.target.files[i].name)) {
					toBase64(event.target.files[i])
						.then(
							(response: any) => {
								push(
									{
										fichier: response.name,
										fichierEncode: response.encodedFile,
										file: response.file
									}
								)
							}
						)
				} else {
					dispatch(
						afficherSnackbar(
							{
								id: 'inputFile',
								message: 'Fichiers autorisés : .png, .jpg, .jpeg, .gif, .pdf',
								open: true,
								hideIcon: true
							}
						)
					)
				}
			} else {
				dispatch(
					afficherSnackbar(
						{
							id: 'inputFile',
							message: 'Le fichier ne peut pas faire plus de 10Mo',
							open: true,
							hideIcon: true
						}
					)
				)
			}
		}
	}

	const isCorrectExtension = (name: string) => {
		const lastDot = name.lastIndexOf('.')
		const ext = name.substring(lastDot + 1)
		return AcceptedExtensions.includes(ext.toLowerCase())
	}

	return (
		<div>
			{
				fields.map((field: any, index: number) => {
						return (
							<div
								className="row"
								key={fields.value[index].fichier}
							>
								<div
									key={index}
									className={fileListClass}
								>
									<div className={fileIconClass}>
										<SvgAttachment color="#122941" />
									</div>

									<span className={fileNameClass}>
										{fields.value[index].fichier}
									</span>

									<CloseIcon
										onClick={() => fields.remove(index)}
										className={classes.cursorPointer}
									/>
								</div>
							</div>
						)
					}
				)
			}

			<div
				className={addFileContainerClass}
				onClick={openFileSelectionner}
			>
				<div className={addFileIconClass}>
					{attachmentImg}
				</div>

				<span className={labelClass}>
					{label}
				</span>

				<input
					id={id}
					type="file"
					hidden
					multiple
					onChange={onFileSelected}
					ref={inputRef}
				/>

				<br />

				{
					(submitFailed && error &&
						<span className={classes.errorStyle}>{error}</span>)
				}
			</div>
		</div>
	)
}

export default FormFile
