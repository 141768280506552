import React, { useEffect, useState } from 'react'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import { useHistory, useLocation } from 'react-router'
import styles from '../../../theme/styles'
import { mfaResponseModel, newPasswordModel, pwdRecoveryPostModel, recoveryTokenPostModel } from '../type/logInModel'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import pwdRecoveryStatusEnum from './enum/pwdRecoveryStatusEnum'
import Links from '../../../enum/links'
import { errors, resetPassword } from '../utils/commonConst'
import { isPasswordOk } from '../../../utils/formUtils'
import Loader from '../../../components/loader/Loader'
import ErrorCard from '../../../components/errorCard/ErrorCard'
import FormTextField from '../../../components/fields/FormTextField'
import Button from '../../../components/button/Button'
import Link from '../../../components/link/Link'

const TOKEN = 'recoveryToken'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 30
	},
	linkStyle: {
		padding: '25px 0px'
	},
	betweenField: {
		paddingBottom: 15
	}
}))

interface AcceptingProps {
	setGoToMFA: (value: boolean) => void,
	setGoToNewPwd: (value: boolean) => void,
	setMfaInfo: (value: mfaResponseModel) => void,
}

type NewPasswordProps = AcceptingProps

const NewPassword: React.FC<NewPasswordProps> = (
	{
		setGoToMFA,
		setGoToNewPwd,
		setMfaInfo
	}
) => {
	const classes = useStyle()
	const history = useHistory()
	const location = useLocation()
	const [stateToken, setStateToken] = React.useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [incorrectPwd, setIncorrectPwd] = useState<boolean>(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorPrimary
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.betweenField
	)

	useEffect(() => {
			const token = location.search.substr(1).split('=')
			if (token.length > 1) {
				if (token[1] && token[0] === TOKEN) {
					setLoading(true)
					const dataTokenRecovery: recoveryTokenPostModel = {
						token: token[1]
					}
					fetch('/api/okta/recovery/token',
						{
							body: JSON.stringify(dataTokenRecovery),
							method: configEnum.post
						})
						.then(
							(response: Response) => response.json()
								.then(
									(tokenRecoveryResponse: any) => {
										if (tokenRecoveryResponse.status === pwdRecoveryStatusEnum.PASSWORD_RESET) {
											setStateToken(tokenRecoveryResponse.stateToken)
										}
									}
								)
						)
						.finally(
							() => {
								setLoading(false)
							}
						)
				}
			}
		}, [location.search]
	)

	const backToLogIn = () => {
		history.push(Links.login)
	}

	const onSubmit = (values: newPasswordModel) => {

		const dataPwdRecovery: pwdRecoveryPostModel = {
			newPassword: values.newPassword,
			stateToken: stateToken
		}

		fetch('/api/okta/recovery/password',
			{
				body: JSON.stringify(dataPwdRecovery),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(pwdRecoveryResponse: any) => {
							if (pwdRecoveryResponse.status === pwdRecoveryStatusEnum.SUCCESS) {
								history.push(Links.login)
							} else {
								switch (pwdRecoveryResponse.status) {
									case pwdRecoveryStatusEnum.MFA_CHALLENGE:
										//go to enter verification code
										setMfaInfo({
											email: pwdRecoveryResponse.email || '',
											verify_url: pwdRecoveryResponse.verify_url || '',
											state_token: pwdRecoveryResponse.state_token || '',
											factor_id: pwdRecoveryResponse.factor_id || '',
											status: pwdRecoveryResponse.status || ''
										})
										setGoToMFA(true)
										setGoToNewPwd(false)
										break
									case pwdRecoveryStatusEnum.ERROR:
										setIncorrectPwd(true)
										break
								}
							}
						}
					)
			)

	}

	const validate = (
		values: newPasswordModel
	) => {
		const error: any = {}

		if (!values.newPassword) {
			error.newPassword = errors.required
		}
		if (!values.repeatPassword) {
			error.repeatPassword = errors.required
		}

		if (values.newPassword) {
			if (!isPasswordOk(values.newPassword)) {
				error.newPassword = errors.formatPassword
			}
			if (values.repeatPassword && values.repeatPassword !== values.newPassword) {
				error.repeatPassword = errors.samePassword
			}
		}

		return error
	}

	return (
		<>
			{
				loading ?
					<Loader />
					:
					<div>
						<div className={titleClass}>
							{resetPassword.title}
						</div>

						{
							incorrectPwd &&
							<div className={containerErrorClass}>
								<ErrorCard message={errors.newPwdKO} />
							</div>
						}

						<Form
							onSubmit={onSubmit}
							validate={validate}
							render={
								(
									{
										handleSubmit
									}
								) => (

									<form
										onSubmit={handleSubmit}
									>
										<div>
											<div className="col-xs-12">
												<div className={classes.betweenField}>
													<Field
														name="newPassword"
														type="password"
														component={FormTextField}
														placeholder={resetPassword.newPassword}
													/>
												</div>
												<div className={classes.fieldStyle}>
													<Field
														name="repeatPassword"
														type="password"
														component={FormTextField}
														placeholder={resetPassword.repeatNewPassword}
													/>
												</div>
												<Button
													background="backgroundPrimary"
													color="colorTertiary"
													type="submit"
													disabled={false}
												>
													{resetPassword.reset}
												</Button>
											</div>
										</div>
									</form>
								)}
						/>

						<div className="col-xs-12">
							<div className={classes.linkStyle}>
								<Link
									textStyle="text8"
									color="colorPrimary"
									onClick={backToLogIn}
								>
									{resetPassword.backSignIn}
								</Link>
							</div>
						</div>
					</div>
			}
		</>
	)
}

export default NewPassword
