import React, {Dispatch, useEffect} from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import {makeStyles} from '@material-ui/core/styles'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import styles from '../../../theme/styles'
import {compose} from 'recompose'
import fetchFactory from '../../../utils/fetchFactory'
import errorCatcher from '../../../utils/errorCatcher'
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect} from 'react-redux'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import Chart from 'react-apexcharts'
import theme from '../../../theme/theme'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    borderBottom: {
        borderBottom: '1px solid #0000001E',
        paddingBottom: 10,
        paddingTop: 10
    },
    margin: {
        margin: '10px 16px',
        '& .apexcharts-legend-series': {
            display: 'block !important'
        }
    },
    paddingTop: {
        paddingTop: 20
    },
    libellePadding: {
        paddingBottom: 4
    },
    valeurPaddingBorder: {
        paddingBottom: 10,
        '&::after': {
            content: '""',
            margin: '20px 0px 0px 7px',
            height: 1,
            width: '19%',
            borderBottom: '1px solid #0000001E'
        }
    },
    spinnerStyle: {
        textAlign: 'center',

        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    },
    opacity: {
        opacity: 0.5
    },
    paddingSousTotal: {
        padding: '4px 0'
    },
    noMargin: {
        margin: 0
    },
    paddingIsin: {
        paddingBottom: 20
    },
    valueSize: {
        fontSize: 15
    }
}))

interface ModelTitres {
    id: number,
    typologie: string,
    quantite: number,
}

interface ModelResponse {
    id: number,
    code_isin: string,
    nature_valeur: string,
    typologie: string,
    libelle_valeur: string,
    quantite: number,
}

interface ModelPrixGecina {
    update_date: Date,
    price: string
}

interface ModelAvoirs {
    valeur: string,
    titres: ModelTitres[]
}

interface Store {
    errorCatcher: (error: any, id: string) => void
}

const valeur: string = 'Valeur'
const typologie: string = 'Typologie de titres'
const quantite: string = 'Quantité'

type ListeTitresPetitProps = WithStyles<typeof styles> & Store

const ListeTitresPetit = (
    props: ListeTitresPetitProps
) => {
    const {
        errorCatcher
    } = props

    const classes = useStyles()

    const [avoirs, setAvoirs] = React.useState<ModelAvoirs[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)
    const [partCountTypo, setPartCountTypo] = React.useState([{}])
    const [partCountValorisation, setPartCountValorisation] = React.useState([{}])
    const [prixGecina, setPrixGecina] = React.useState<ModelPrixGecina>(
        {
            update_date: new Date('now'),
            price: ''
        }
    )

    useEffect(() => {
        if (prixGecina.price === '') {
            fetchFactory('/api/gecina/prix_part')
                .then(
                    (prix_gecina: any) => {
                        setPrixGecina(prix_gecina)
                    },
                    (error: any) => {
                        errorCatcher(error, 'prix_gecina')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'prix_gecina')
                    }
                )
        }
        fetchFactory('/api/detail/avoirs')
            .then(
                (detail_avoirs: any) => {
                    const tmpDetailAvoir: ModelAvoirs[] = []
                    let sousTotal: number = 0
                    let total: number = 0
                    let moreThanOneIsin: boolean = false

                    detail_avoirs.sort(
                        (a: ModelResponse, b: ModelResponse) => {
                            if (a.code_isin.toLowerCase() < b.code_isin.toLowerCase()) {
                                return -1
                            }
                            if (a.code_isin.toLowerCase() > b.code_isin.toLowerCase()) {
                                return 1
                            }
                            if (a.typologie.toLowerCase() < b.typologie.toLowerCase()) {
                                return -1
                            }
                            if (a.typologie.toLowerCase() > b.typologie.toLowerCase()) {
                                return 1
                            }

                            return 0
                        }
                    ).forEach(
                        (detail_avoir: ModelResponse, index: number) => {
                            const indexTmpDetailAvoir: number = tmpDetailAvoir.findIndex(
                                (avoir) => {
                                    return avoir.valeur.split(' - ')[1] === detail_avoir.code_isin
                                }
                            )

                            if (indexTmpDetailAvoir >= 0) {
                                tmpDetailAvoir[indexTmpDetailAvoir].titres.push(
                                    {
                                        id: detail_avoir.id,
                                        typologie: detail_avoir.typologie,
                                        quantite: detail_avoir.quantite
                                    }
                                )

                                sousTotal += detail_avoir.quantite

                                if ((detail_avoirs[index + 1] && detail_avoirs[index].code_isin !== detail_avoirs[index + 1].code_isin) || index === detail_avoirs.length - 1) {
                                    if (detail_avoirs[index + 1] && !moreThanOneIsin) {
                                        moreThanOneIsin = true
                                    }

                                    if (moreThanOneIsin) {
                                        tmpDetailAvoir[indexTmpDetailAvoir].titres.push(
                                            {
                                                id: sousTotal,
                                                typologie: 'Sous-total',
                                                quantite: sousTotal
                                            }
                                        )
                                    }
                                }
                            } else {
                                sousTotal = 0

                                tmpDetailAvoir.push(
                                    {
                                        valeur: `${detail_avoir.libelle_valeur} - ${detail_avoir.code_isin}`,
                                        titres: [
                                            {
                                                id: detail_avoir.id,
                                                typologie: detail_avoir.typologie,
                                                quantite: detail_avoir.quantite
                                            }
                                        ]
                                    }
                                )
                                sousTotal += detail_avoir.quantite
                            }

                            total += detail_avoir.quantite
                        }
                    )

                    tmpDetailAvoir.push(
                        {
                            valeur: 'Total',
                            titres: [
                                {
                                    id: total,
                                    typologie: '',
                                    quantite: total
                                }
                            ]
                        }
                    )
                    let groups = detail_avoirs.map(function (value: any, index: any) {
                        return [value['typologie'], value['quantite']]
                    })
                    let clonePartCount: any = []

                    groups.forEach(function (value: any) {
                        if (clonePartCount.find((group: any) => group.typologie === value[0]) === undefined) {
                            clonePartCount.push({
                                'typologie': value[0],
                                'valorisation': (value[1] * Number(prixGecina.price))
                            })
                        } else {
                            clonePartCount.forEach((group: any) => {
                                if (group.typologie === value[0]) {
                                    group.valorisation += (value[1] * Number(prixGecina.price))
                                }

                                return 0
                            })
                        }

                    })
                    clonePartCount.sort(
                        (a: any, b: any) => {

                            if (a.valorisation < b.valorisation) {
                                return 1
                            }
                            if (a.valorisation > b.valorisation) {
                                return -1
                            }
                            return 0
                        }
                    )

                    let typo: any = []
                    let valorisation: any = []
                    clonePartCount.map((part: any) => {
                        typo.push(part.typologie)
                        valorisation.push(part.valorisation)
                        return 0
                    })

                    setPartCountTypo(typo)
                    setPartCountValorisation(valorisation)
                    setAvoirs(tmpDetailAvoir)
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, 'detail_avoirs')
                }
            )
            .finally(
                () => {
                    setLoading(false)
                }
            )
    }, [errorCatcher, prixGecina.price])

    const libelleStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.text,
        classes.semiBold,
        classes.smallSize,
        classes.opacity
    )

    const valeurStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.text,
        classes.valueSize,
        classes.semiBold
    )

    const rowBorderStyle: ClassValue = classNames(
        'row',
        classes.paddingTop,
        classes.borderBottom,
        classes.noMargin
    )

    const rowNoBorderStyle: ClassValue = classNames(
        'row',
        classes.paddingTop,
        classes.noMargin
    )

    const libelleRowStyle: ClassValue = classNames(
        'row',
        classes.libellePadding
    )

    const valeurRowBorderStyle: ClassValue = classNames(
        'row',
        classes.valeurPaddingBorder
    )

    const valeurRowNoBorderStyle: ClassValue = classNames(
        'row',
        classes.paddingIsin
    )

    const totalStyle: ClassValue = classNames(
        'row col-xs-12 end-xs',
        classes.paddingSousTotal
    )

    const chartLabels = partCountTypo
    const chartValues = partCountValorisation

    const options: any = {
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft',
                offsetX: -20
            },
            y: {
                formatter: function (val: any) {
                    return `${thousandsSeparator(val)} €`
                },
                title: {
                    formatter: function (val: string) {
                        if (val.length < 47) {
                            return `${val} : <br/>`
                        } else {
                            return `${val.substr(0, 48)} <br/> ${val.substr(48, val.length)}`
                        }
                    }
                }
            }
        },
        textanchor: 'middle',
        colors: [
            '#717f8c', '#4e75ba', '#90acdc', '#162840', '#b7c0c9', theme.palette.blue[50],
            theme.palette.blue[70], theme.palette.blue[30], theme.palette.blue[60], theme.palette.blue[80], theme.palette.blue[40]
        ],
        subtitle: {
            text: 'En valeur en €',
            align: 'center',
            margin: 40,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: 12

            }
        },
        title: {
            text: 'Répartition des titres détenus',
            align: 'center',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: 14
            }
        },
        legend: {
            show: true,
            position: 'bottom',
            align: 'center',
            style: {
                fontSize: 14,
                fontWeight: 'bold'
            }
        },
        labels: chartLabels
    }

    return (
        <div className={classes.margin}>
            {
                loading ? (
                    <div className={classes.spinnerStyle}>
                        <CircularProgress color="primary"/>
                    </div>
                ) : (
                    avoirs.map((avoir, indexAvoir) => (
                            avoir.valeur === 'Total' ? (
                                <>
                                    <div
                                        className={rowNoBorderStyle}
                                        key={avoir.valeur}
                                    >
                                        <div className={totalStyle}>
										<span
                                            className={libelleStyle}
                                        >
											{avoir.valeur}
										</span>
                                        </div>

                                        <div className={totalStyle}>
										<span
                                            className={valeurStyle}
                                        >
											{thousandsSeparator(avoir.titres[0].quantite, true)}
										</span>
                                        </div>
                                    </div>
                                    {chartValues.length <= 1 ? '' :
                                        <div className="row center-xs">
                                            <Chart options={options}
                                                   series={chartValues}
                                                   type="pie"
                                                   height={500}
                                                   width={350}/>
                                        </div>
                                    }
                                </>
                            ) : (
                                <div
                                    className={indexAvoir !== avoirs.length - 2 ? rowBorderStyle : rowNoBorderStyle}
                                    key={avoir.valeur}
                                >
                                    <div className="col-xs-12">
                                        <div className={libelleRowStyle}>
                                            <div className="col-xs-12">
												<span
                                                    className={libelleStyle}
                                                >
													{valeur}
												</span>
                                            </div>
                                        </div>

                                        <div className={valeurRowNoBorderStyle}>
                                            <div className="col-xs-12">
												<span
                                                    className={valeurStyle}
                                                >
													{avoir.valeur}
												</span>
                                            </div>
                                        </div>

                                        {
                                            avoir.titres.map(
                                                (titre, index) => {
                                                    return (
                                                        <React.Fragment key={titre.id}>
                                                            {
                                                                titre.typologie !== 'Sous-total' &&
                                                                <div className={libelleRowStyle}>
                                                                    <div className="col-xs-9">
																		<span
                                                                            className={libelleStyle}
                                                                        >
																			{typologie}
																		</span>
                                                                    </div>

                                                                    <div className="col-xs-3 end-xs">
																		<span
                                                                            className={libelleStyle}
                                                                        >
																			{quantite}
																		</span>
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div
                                                                className={titre.typologie !== 'Sous-total' ? valeurRowBorderStyle : valeurRowNoBorderStyle}>
                                                                <div
                                                                    className={titre.typologie !== 'Sous-total' ? 'col-xs-9' : totalStyle}>
																	<span
                                                                        className={titre.typologie !== 'Sous-total' ? valeurStyle : libelleStyle}
                                                                    >
																		{titre.typologie}
																	</span>
                                                                </div>

                                                                <div
                                                                    className={titre.typologie !== 'Sous-total' ? 'col-xs-3 end-xs' : totalStyle}>
																	<span
                                                                        className={valeurStyle}
                                                                    >
																		{thousandsSeparator(titre.quantite, true)}
																	</span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        )
                    )
                )
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedListeTitresPetit = compose<ListeTitresPetitProps, {}>(
    connect(null, mapDispatchToProps),
    withStyles(styles)
)(ListeTitresPetit)

export default EnhancedListeTitresPetit
