import { getAuthClient } from './authClient'

const fetchFactory = (
	lien: string,
	config: any = {},
	type: string = 'json',
	donnees?: any
) => {
	const {
		headers,
		...others
	} = config
	const AuthClient = getAuthClient()

	return new Promise(
		(resolve, reject) => {
			// Vérification de la validité du token
			AuthClient.tokenManager.get('accessToken')
				.then(
					(token: any) => {
						// Si le token est valide, on initialise les headers et on fetch
						if (token) {
							const defaultHeaders: HeadersInit = {
								Accept: 'application/json'
							}

							defaultHeaders.Authorization = 'Bearer ' + token.value

							const newConfig = {
								headers: {
									...defaultHeaders,
									...headers
								},
								...others
							}

							return fetch(lien, newConfig)
								.then(
									response => {
										if (response.ok) {
											if (type === 'json') {
												response.json()
													.then(
														(data: any) => {
															resolve(data)
														}
													)
											}
											if (type === 'blob') {
												response.blob()
													.then(blob => {
														resolve(blob)
													})
											}
											if (type === 'response') {
												resolve(response)
											}
										} else {
											if (response.statusText === 'Unauthorized' && response.status === 401) {
												AuthClient.signOut(
													{
														postLogoutRedirectUri: window.location.origin + '/login'
													}
												)
											} else {
												response.json()
													.then(
														(data: any) => {
															reject(data)
														}
													)
											}
										}
									}
								)
								.catch(
									(error: any) => {
										reject(error)
									}
								)
						} else {
							AuthClient.signOut(
								{
									postLogoutRedirectUri: window.location.origin + '/login'
								}
							)
						}
					}
				)
				.catch(
					(err: any) => {
						console.error('Une erreur est survenue lors de la récupération du token : ', err)
					}
				)
		}
	)
}

export default fetchFactory
