import React, { ReactChild } from 'react'
import { useMediaQuery } from 'react-responsive'

const isLargeScreen: boolean = true
const isTabletteScreen: boolean = false

export const ResponsiveContext = React.createContext(
	{
		isLargeScreen,
		isTabletteScreen
	}
)

const ResponsiveContextProvider = (props: { children: ReactChild }) => {
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const isTabletteScreen = useMediaQuery(
		{
			query: '(min-width: 600px)'
		}
	)

	return (
		<ResponsiveContext.Provider
			value={{
				isLargeScreen,
				isTabletteScreen
			}}
		>
			{props.children}
		</ResponsiveContext.Provider>
	)
}

export default ResponsiveContextProvider
