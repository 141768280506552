enum localStorageEnum {
    user = 'utilisateur',
    accessToken = 'accessToken',
    idToken = 'id_token',
    commonUri = 'commonUri',
    language = 'language',
    oktaToken = 'okta-token-storage',
    codeVerifier = 'code_verifier',
    oktaCache = 'okta-cache-storage',
    bourseDocument = 'bourseDocument'
}

export default localStorageEnum
