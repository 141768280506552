import React from 'react'
import MuiButton from '@material-ui/core/Button'
import styles from '../../theme/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from 'react-responsive'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    textTransform: {
        textTransform: 'inherit'
    },
    button: {
        fontWeight: 600,
        borderRadius: 0,
        lineHeight: 1,

        '&:hover': {
            color: theme.palette.primary.main,

            '& svg': {
                color: theme.palette.primary.main
            }
        }
    },
    buttonLargeScreenStyle: {
        fontSize: 15,
        height: 48,
        width: 157
    },
    buttonSmallScreenStyle: {
        fontSize: 12,
        height: 40,
        width: 151
    }
}))

const Button: React.FC<ButtonProps> = (
    {
        children,
        onClick,
        disabled = false,
        type = 'submit',
        padding = '',
        background = 'backgroundPrimary',
        color = 'colorSecondary',
        border
    }
) => {
    const classes = useStyles()
    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )

    const buttonClass: ClassValue = classNames(
        classes.text,
        classes[padding as keyof typeof classes],
        classes.textTransform,
        classes.button,
        isLargeScreen ? classes.buttonLargeScreenStyle : classes.buttonSmallScreenStyle,
        {
            [classes[background as keyof typeof classes]]: background,
            [classes[color as keyof typeof classes]]: color,
            [classes[border as keyof typeof classes]]: border
        }
    )

    return (
        <MuiButton
            onClick={onClick}
            className={buttonClass}
            disabled={disabled}
            type={type}
        >
            {children}
        </MuiButton>
    )
}

interface ButtonProps {
    children: React.ReactNode,
    onClick?: () => void,
    disabled?: boolean,
    type?: 'submit' | 'reset' | 'button',
    padding?: string,
    background?: string,
    color?: string,
    border?: string
}

export default Button