import { AFFICHER_SNACKBAR, CACHER_SNACKBAR } from './actionTypes'

export const afficherSnackbar = (snackBar) => {
	return ({
		type: AFFICHER_SNACKBAR,
		payload: snackBar
	})
}

export const cacherSnackbar = () => {
	return ({
		type: CACHER_SNACKBAR
	})
}
