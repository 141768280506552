import React from 'react'
import EnhancedListeActionsPetit from './ListeActionsPetit'
import {compose} from 'recompose'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    containerStyle: {
        backgroundColor: 'white'
    },
    marginStyle: {
        margin: 0
    }
}))

interface ModelCompteTitres {
    id: number,
    code_isin: string,
    nature_valeur: string,
    solde_global: number,
    valorisation: number,
    libelle_valeur: string
}

interface ModelPrixGecina {
    update_date: Date,
    price: string
}

interface ModelDroitsActions {
    id: number,
    libelle_plan: string,
    date_attribution: Date,
    date_debut_conservation: Date,
    quantite: number
}

interface ModelDroitsStock {
    id: number,
    libelle_plan: string,
    quantite: number
    prix_offre: number,
    date_debut_plan: Date,
    date_fin_plan: Date
}

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date,
    typologie: string,
    actions: string,
    prix_acquisition: number
}

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    message: string
}

interface ModelAvoirs {
    disponibilites: ModelDisponibilites[],
    indisponibilites: ModelIndisponibilites[]
}

interface AcceptingProps {
    compteTitres: ModelCompteTitres[],
    prixGecina: ModelPrixGecina
    droitsActions: ModelDroitsActions[],
    droitsStock: ModelDroitsStock[]
    avoirs: ModelAvoirs
}

type CorpsCompteTitresPetitProps = AcceptingProps

const CorpsCompteTitresPetit = (
    props: CorpsCompteTitresPetitProps
) => {
    const {
        compteTitres,
        prixGecina
    } = props

    const classes = useStyles()

    const containerStyle: ClassValue = classNames(
        'row',
        classes.containerStyle
    )
    return (
        <div className={containerStyle}>
            <EnhancedListeActionsPetit
                compteTitres={compteTitres}
                prixGecina={prixGecina}
            />
        </div>
    )
}

const EnhancedCorpsCompteTitresPetit = compose<CorpsCompteTitresPetitProps, AcceptingProps>()(CorpsCompteTitresPetit)

export default EnhancedCorpsCompteTitresPetit
