import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'

const useStyle = makeStyles(
	(theme) => (
		{
			...styles(theme),
			mentionsContainerClass: {
				'& h1': {
					margin: '40px 0 15px',
					color: theme.palette.primary.main,
					...styles(theme).title,
					fontSize: 24
				},

				'& p': {
					margin: 0,
					color: '#6A6A6A',
					...styles(theme).text,
					...styles(theme).titleSize,

					'& strong': {
						marginTop: 20
					}
				},

				'& .intro': {
					margin: 0,
					color: '#B3A688',
					...styles(theme).text,
					...styles(theme).titleSize
				},

				'& .title': {
					'& h1': {
						marginBottom: 30,
						textAlign: 'center'
					}
				},
				'& .date': {
					'& p' : {
						textAlign: 'right'
					}
				}
			}
		}
	)
)

const ProtectionDonnees = () => {
	const classes = useStyle()

	return (
		<div className={classes.mentionsContainerClass}>
			<div className="title">
				<h1>Politique de protection des données à caractère personnel</h1>
			</div>

			<div className="date">
				<p>Version du 12/01/2021</p>
			</div>

			<br/>

			<div className="intro">
				<strong>Information sur le traitement par Gecina des données à caractère personnel de ses actionnaires dans le cadre de l’utilisation du Site</strong>
			</div>

			<div className="informations">
				<p>Gecina collecte et traite les données à caractère personnel de ses actionnaires dans le respect du Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD ») et de la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés modifiée (loi « Informatique et Libertés »).</p>
				<br />
				<p>Elle édite le site www.espace-actionnaires.gecina.fr.(le « Site »).</p>
				<br />
				<p>La présente Politique s’inscrit dans une démarche de transparence et décrit pourquoi et comment Gecina collecte, traite et protège les données à caractère personnel (les « Données ») des utilisateurs du Site (« Utilisateurs » ou « vous ») conformément à la réglementation sur la protection des données (la « Réglementation »).</p>
				<h1>I) Quelles données sont collectées ?</h1>

				<p>Dans ce cadre, sont notamment collectées les données personnelles suivantes : nom, prénom, civilité, coordonnées de contact (notamment n° de téléphone, adresse postale et fiscale, adresse de courriel), date et lieu de naissance, informations sur les actions, titres, droits et obligations de l’Utilisateur, nombre d’actions détenues, coordonnées bancaires, informations fiscales.</p>
				<br />
				<p>Ces données personnelles sont directement collectées auprès de l’Utilisateur mais Gecina est également susceptible d’être destinataire de données collectées auprès de l’actionnaire par un tiers (banque qui transmet à Gecina les données des particuliers actionnaires, notaires dans le cas de succession exemple).</p>

				<h1>II) Quelles sont les finalités de ces traitements de données personnelles ?</h1>
				<p>Gecina collecte vos Données lors de votre inscription sur le Site et lors de son utilisation pour :
					<ul>
						<li>assurer le suivi de la relation d’investissement avec Gecina et la gestion administratives des comptes-titres ouvert dans les registres de la Société ;</li>
						<li>connaître ses actionnaires inscrits en comptes-titres nominatifs purs ;</li>
						<li>transmettre toute documentation légale, notamment les informations liées à la tenue de l’assemblée générale ;</li>
						<li>permettre de gérer les relations avec ses actionnaires par l’envoi de relevés de comptes, de lettres d’information ou d’invitations à des évènements ;</li>
						<li>vous permettre de vous inscrire ;</li>
						<li>vous permettre d’utiliser le Site et d’accéder aux services qu’il propose ;</li>
						<li>assurer le bon fonctionnement et la sécurité du Site et des informations qui y sont stockées.</li>
					</ul>
				</p>

				<h1>III) Quelles sont les bases légales de ces traitements ?</h1>
				<p>Le traitement des données personnelles de ses actionnaires par Gecina, dans le cadre du fonctionnement du Site repose sur :
					<ul>
						<li>Le respect des obligations légales ou réglementaires de Gecina, en sa qualité d’émetteur de titres d’une part, et de titres cotés en bourse d’autre part ;</li>
						<li>L’intérêt légitime de Gecina, notamment pour informer ses actionnaires ou communiquer avec eux.</li>
					</ul>
				</p>

				<h1>IV) Quelle est la durée de conservation des données des actionnaires ?</h1>
				<p>Les données des actionnaires de Gecina sont conservées pendant une durée limitée correspondant aux finalités pour lesquelles elles ont été collectées, en conformité avec la réglementation en vigueur, et le respect des obligations légales, contractuelles, fiscales, boursière, sociales et pour la défense des intérêts légitimes du Groupe Gecina.</p>
				<br />
				<p>Après l’expiration de ces délais, les données correspondantes sont effacées ou anonymisées, à condition qu’elles ne soient plus nécessaires au respect d’une obligation légale ou à la preuve d’un droit et/ou qu’il n’y ait plus d’intérêt légitime à leur conservation.</p>

				<h1>V) Quels sont les droits des actionnaires sur leurs données ?</h1>
				<p>Conformément à la Réglementation en matière de protection des données et dans les limites posées par la loi, chaque actionnaire dispose notamment :
					<ul>
						<li>d’un droit d’accès à ses données, notamment pour vérifier leur exactitude et leur exhaustivité ;</li>
						<li>du droit d’obtenir une rectification de ses données ;</li>
						<li>du droit d’obtenir l’effacement de ses données ;</li>
						<li>du droit de s’opposer ou de demander une limitation du traitement de ses données ;</li>
						<li>d’un droit à la portabilité des données qu’il a fournies à Gecina ;</li>
						<li>du droit d'édicter des directives spécifiques ou générales sur le traitement de ses données après son décès.</li>
					</ul>
				</p>
				<br />
				<p>Un actionnaire peut exercer ses droits auprès du DPO de Gecina par mail à :
					<a href="mailto:protectiondesdonneesfinances@gecina.fr">protectiondesdonneesfinances@gecina.fr</a>, ou par courrier postal à l’attention de : DPO Gecina, 16 rue des Capucines 75084 PARIS cedex 02.
				</p>

				<h1>VI) Avec qui peuvent être partagées les données personnelles des actionnaires ?</h1>
				<p>Les données des actionnaires sont strictement confidentielles et ne peuvent être librement transférées à un tiers.</p>
				<br />
				<p>Gecina traite vos Données de manière confidentielle pour l’utilisation des services du Site.</p>
				<br />
				<p>Certaines données peuvent toutefois être communiquées à des prestataires/sous-traitants de Gecina dans le cadre de la stricte mise en œuvre de ses traitements, et notamment :
					<ul>
						<li>à des fins de fonctionnement, d’hébergement et de maintenance du Site, certaines Données peuvent être accessibles à ses prestataires techniques qui lui garantissent contractuellement le même niveau de protection adéquat de vos Données ;</li>
						<li>la gestion des documents requis par la réglementation ;</li>
						<li>pour des opérations d’administration du Site, les données collectées, via les formulaires en ligne, pouvant être transférées au prestataire intervenant dans ces opérations.</li>
					</ul>
				</p>

				<h1>VII) Où se trouvent les données personnelles des actionnaires ?</h1>
				<p>Les données des actionnaires sont traitées, la plupart du temps, sur le territoire de l’Union européenne et ne sont, dans la mesure du possible, pas transférées vers des pays tiers.</p>
				<br />
				<p>Cependant, dans l’hypothèse où, dans le cadre des traitements et des finalités mis en œuvre par Gecina, ces données seraient transférées vers des pays tiers, Gecina s’engage, en pareil cas, à prendre toutes les mesures adéquates et appropriées, conformément à la règlementation en matière de protection des données à caractère personnel, de manière que le niveau de protection garanti par cette réglementation ne soit pas compromis.</p>

				<h1>VIII) Modification de la politique de protection des données personnelles</h1>
				<p>La politique de protection des données personnelles figurant actuellement sur le Site reflète les standards de confidentialité actuels de Gecina, qui peuvent faire l’objet de modifications.</p>
				<br />
				<p>Dans ce cas, Gecina publiera ces changements sur le Site.</p>

				<h1>IX) Qui contacter pour toute question sur vos données ?</h1>
				<p>Pour toute question ou réclamation relative à vos Données, vous pouvez contacter notre DPO à l’adresse suivante : <a
					href="mailto:protectiondesdonneesfinances@gecina.fr">protectiondesdonneesfinances@gecina.fr</a></p>
				<br />
				<p>Vous disposez, par ailleurs, du droit d’introduire une réclamation auprès de la « Commission Nationale d’Informatique et des Libertés », autorité de régulation chargée de faire respecter la réglementation sur la protection des données à caractère personnel en France.</p>
			</div>

		</div>
	)
}

export default ProtectionDonnees
