import React from 'react'
import EnhancedListeAvoirsPetit from './ListeAvoirsPetit'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    margin: {
        margin: 0
    }
}))

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    typologie: string,
}

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    message: string
}

interface AcceptingProps {
    disponibilites: ModelDisponibilites[],
    indisponibilites: ModelIndisponibilites[]
}

type ListeActionsPetitProps = AcceptingProps

const ListeActionsPetit = (
    props: ListeActionsPetitProps
) => {
    const {
        disponibilites,
        indisponibilites
    } = props

    const classes = useStyles()

    const marginStyle: ClassValue = classNames(
        'row col-xs-12',
        classes.margin
    )
    return (
        <>
            {
                disponibilites.length > 0 &&
                <div className={marginStyle}>
                    <EnhancedListeAvoirsPetit
                        avoirs={disponibilites}
                        type="disponibles"
                    />
                </div>
            }

            {
                indisponibilites.length > 0 &&
                <div className={marginStyle}>
                    <EnhancedListeAvoirsPetit
                        avoirs={indisponibilites}
                        type="indisponibles"
                    />
                </div>
            }
        </>
    )
}

export default ListeActionsPetit