import React from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import formatDate from '../../../utils/formatDate'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import MaterialTooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    borderBottom: {
        borderBottom: '1px solid #0000001E',
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10
    },
    marginBottom: {
        marginBottom: 20
    },
    textDecoration: {
        textDecoration: 'underline'
    },
    marginLeft: {
        marginLeft: 10
    },
    opacity: {
        opacity: 0.5
    },
    libelleStyle: {
        fontSize: 12,
        fontWeight: 300
    },
    valeurPlanStyle: {
        fontSize: 15,
        fontWeight: 600
    },
    valeurStyle: {
        fontSize: 15,
        fontWeight: 600
    },
    smallTextStyle: {
        fontSize: 10,
        fontWeight: 600
    },
    tailleTooltip: {
        maxWidth: 200,
        fontSize: 12,
        marginTop: 0
    }
}))

interface ModelDroitsActions {
    id: number,
    libelle_plan: string,
    date_attribution?: Date,
    date_debut_conservation?: Date,
    date_cessibilite?: Date,
    quantite: number,
    bold?: boolean
}

interface AcceptingProps {
    droitsActions: ModelDroitsActions[],
    telechargement: (id: number, type: string) => void
}

const libelle_plan: string = 'Plan'
const date_attribution: string = 'Date d\'attribution'
const quantite_attribuee: string = 'Quantité à attribuer'
const date_conservation: string = 'Date de début de conservation'
const date_cessibilite: string = 'Date de cessibilité'
const reglement_plan: string = 'Télécharger le règlement du plan'

type ListeDroitsActionsPetitProps = AcceptingProps

const ListeDroitsActionsPetit = (
    props: ListeDroitsActionsPetitProps
) => {
    const {
        droitsActions,
        telechargement
    } = props

    const classes = useStyles()

    const telechargerDocument = (id: number) => {
        telechargement(id, 'actions')
    }

    const containerStyle: ClassValue = classNames(
        'col-xs-12',
        classes.marginBottom
    )
    const rowStyle: ClassValue = classNames(
        'row middle-xs',
        classes.marginBottom
    )
    const titreStyle: ClassValue = classNames(
        classes.titleSize,
        classes.colorPrimary,
        classes.text,
        classes.bold,
        classes.marginBottom
    )
    const libelleStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.opacity,
        classes.libelleStyle
    )
    const valeurPlanStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.valeurPlanStyle
    )
    const valeurStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.valeurStyle
    )
    const smallTextStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.smallTextStyle
    )

    return (
        <div className={containerStyle}>
            {
                droitsActions.reduce((a: number, b: ModelDroitsActions) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0) > 0 ?
                    <>
                        <div
                            className={titreStyle}
                        >
                            <p>Droits à actions de performance</p>
                        </div>

                        {
                            droitsActions.map((droitsAction, index) => (
                                    <div key={droitsAction.id}>
                                        {droitsAction.libelle_plan !== 'Total' ?
                                            <>
                                                <div className={'row'}>
                                                    <div className="col-xs-12">
                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">
                                                    <span className={libelleStyle}>
                                                        {libelle_plan}
                                                    </span>

                                                                <br/>

                                                                <span className={valeurPlanStyle}>
                                                        {droitsAction.libelle_plan}
                                                    </span>
                                                            </div>

                                                            <div className="col-xs-6 end-xs">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        classNames(
                                                            'row',
                                                            index === droitsActions.length - 1 ? '' : classes.borderBottom
                                                        )
                                                    }
                                                >
                                                    <div className="col-xs-12">
                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">
                                                    <span className={libelleStyle}>
                                                        {droitsAction.date_attribution && date_attribution}
                                                    </span>

                                                                <br/>

                                                                <span className={valeurStyle}>
                                                        {droitsAction.date_attribution && formatDate(droitsAction.date_attribution)}
                                                    </span>
                                                            </div>

                                                            <div className="col-xs-6 end-xs">
                                                    <span className={libelleStyle}>
                                                        {droitsAction.date_debut_conservation && date_conservation}
                                                        <MaterialTooltip
                                                            title="Date à partir de laquelle les Actions de performance effectivement reçues ne peuvent être cédées ou converties au porteur"
                                                            placement="right"
                                                            classes={{tooltip: classes.tailleTooltip}}
                                                        >
                                                    <InfoOutlinedIcon/>
                                                </MaterialTooltip>
                                                    </span>

                                                                <br/>

                                                                <span className={valeurStyle}>
                                                        {droitsAction.date_debut_conservation && formatDate(droitsAction.date_debut_conservation)}
                                                    </span>
                                                            </div>
                                                        </div>

                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">
                                                    <span className={libelleStyle}>
                                                        {droitsAction.date_cessibilite && date_cessibilite}
                                                    </span>

                                                                <br/>

                                                                <span className={valeurStyle}>
                                                        {droitsAction.date_cessibilite && formatDate(droitsAction.date_cessibilite)}
                                                    </span>
                                                            </div>

                                                            <div className="col-xs-6 end-xs">
                                                    <span className={libelleStyle}>
                                                        {quantite_attribuee}
                                                    </span>

                                                                <br/>

                                                                <span className={valeurStyle}>
                                                        {thousandsSeparator(droitsAction.quantite, true)}
                                                    </span>
                                                            </div>
                                                        </div>

                                                        <div className={rowStyle}>
                                                            <div className="col-xs-12 end-xs">

                                                                {droitsAction.libelle_plan !== 'Total' ? (
                                                                        <>
                                                        <span
                                                            onClick={() => telechargerDocument(droitsAction.id)}
                                                            className={smallTextStyle}
                                                        >
                                                            {reglement_plan}
                                                            <span className={classes.marginLeft}>
                                                                <img
                                                                    src="/img/pictos_telechargement.svg"
                                                                    alt="telechargement icon"
                                                                    width="20"
                                                                    height="20"
                                                                />
                                                            </span>
                                                        </span>
                                                                        </>
                                                                    )
                                                                    : ('')
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className={'row'}>
                                                    <div className="col-xs-12">
                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">

                                                    <span
                                                        className={valeurStyle}
                                                    >
                                                        {droitsAction.libelle_plan}
                                                    </span>

                                                            </div>

                                                            <div className="col-xs-6 end-xs">

                                                    <span className={libelleStyle}>
                                                        {quantite_attribuee}
                                                    </span>

                                                                <br/>

                                                                <span className={valeurStyle}>
                                                        {thousandsSeparator(droitsAction.quantite, true)}
                                                    </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>

                                )
                            )
                        }
                    </>
                    : <></>
            }
        </div>
    )
}
export default ListeDroitsActionsPetit
