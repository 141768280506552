import React, {useEffect} from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import EnhancedTableauSimple from '../../../components/table/TableauSimple'
import styles from '../../../theme/styles'
import {makeStyles} from '@material-ui/core/styles'
import EnhancedListeBoutons from '../ListeBoutons'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyle = makeStyles(theme => ({
    ...styles(theme),
    titleStyle: {
        margin: '40px 30px 20px'
    },
    noMargin: {
        margin: 0
    },
}))

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date | string,
    typologie: string,
    actions: string,
    prix_acquisition: number | null,
    couleur?: string,
    bold?: boolean
}

interface ModelColonnes {
    value: string,
    label: string,
    type: string,
    render?: (row: ModelDisponibilites) => false | React.ReactChild
}

interface AcceptingProps {
    disponibilites: ModelDisponibilites[]
}

type ListeDisponibilitesGrandProps =
    AcceptingProps

const ListeDisponibilitesGrand = (
    props: ListeDisponibilitesGrandProps
) => {
    const {
        disponibilites
    } = props
    const classes = useStyle()

    const colonnes: ModelColonnes[] = [
        {
            value: 'typologie',
            label: 'Typologie',
            type: 'string'
        },
        {
            value: 'actions',
            label: 'Nombre d\'actions',
            type: 'number',
            render: (row: ModelDisponibilites) => (
                <div>
                    {thousandsSeparator(Number(row.actions), true)}
                </div>
            )
        },
        {
            value: 'prix_acquisition',
            label: 'Prix d\'acquisition',
            type: 'number',
            render: (row: ModelDisponibilites) => (
                <div>
                    {row.prix_acquisition !== null ? `${thousandsSeparator(Number(row.prix_acquisition))}  €` : ''}
                </div>
            )
        }
    ]

    const [actions, setActions] = React.useState<ModelDisponibilites[]>([])

    useEffect(() => {
            setActions(
                [
                    ...disponibilites,
                    {
                        id: disponibilites.reduce((a, b) => a + (Number(b.actions.toString().replace(/\s/g, '')) || 0), 0),
                        date_disponibilite: 'Total',
                        typologie: 'Total',
                        actions: disponibilites.reduce((a, b) => a + (Number(b.actions.toString().replace(/\s/g, '')) || 0), 0).toString(),
                        prix_acquisition: null,
                        couleur: 'colorPrimary',
                        bold: true
                    }
                ]
            )
        }, [disponibilites]
    )

    const buttonRowStyle: ClassValue = classNames(
        'row end-md',
        classes.noMargin
    )

    const titleStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.titleSize,
        classes.title,
        classes.bold,
        classes.titleStyle
    )

    return (
        <>
            <div className="col-xs-12">
                <h1
                    className={titleStyle}
                >
                    Avoirs disponibles
                </h1>
            </div>

            <div className="col-xs-12">
                <div className="row">
                    <EnhancedTableauSimple
                        colonnes={colonnes}
                        lignes={actions}
                    />
                </div>
                <div className={buttonRowStyle}>
                    <EnhancedListeBoutons type="disponibles"/>
                </div>
            </div>
        </>
    )
}

export default ListeDisponibilitesGrand

