import {AJOUTER_FORMULAIRE_DONNEES_PERSONNELLES} from './actionTypes'

const formulaireDonneesPersonnelles = (state = {}, action) => {


    switch (action.type) {
        case AJOUTER_FORMULAIRE_DONNEES_PERSONNELLES: {
            return {
                email: action.payload.email ? action.payload.email : '',
                qualite: action.payload.qualite ?? '-',
                telephone_mobile: action.payload.telephone_mobile ? action.payload.telephone_mobile : undefined,
                telephone_fixe: action.payload.telephone_fixe ? action.payload.telephone_fixe : undefined,
                adresse_fiscale_rue: action.payload.adresse_fiscale_rue ? action.payload.adresse_fiscale_rue : '',
                adresse_fiscale_batiment: action.payload.adresse_fiscale_batiment ? action.payload.adresse_fiscale_batiment : '',
                adresse_fiscale_complement: action.payload.adresse_fiscale_complement ? action.payload.adresse_fiscale_complement : '',
                adresse_fiscale_code_postal: action.payload.adresse_fiscale_code_postal ? action.payload.adresse_fiscale_code_postal : '',
                adresse_fiscale_commune: action.payload.adresse_fiscale_commune ? action.payload.adresse_fiscale_commune : '',
                adresse_fiscale_pays: action.payload.adresse_fiscale_pays ? action.payload.adresse_fiscale_pays : '',
                adresse_postale_rue: action.payload.adresse_postale_rue ? action.payload.adresse_postale_rue : '',
                adresse_postale_batiment: action.payload.adresse_postale_batiment ? action.payload.adresse_postale_batiment : '',
                adresse_postale_complement: action.payload.adresse_postale_complement ? action.payload.adresse_postale_complement : '',
                adresse_postale_code_postal: action.payload.adresse_postale_code_postal ? action.payload.adresse_postale_code_postal : '',
                adresse_postale_commune: action.payload.adresse_postale_commune ? action.payload.adresse_postale_commune : '',
                adresse_postale_pays: action.payload.adresse_postale_pays ? action.payload.adresse_postale_pays : '',
                date_naissance: action.payload.date_naissance ? action.payload.date_naissance : '',
                lieu_naissance: action.payload.lieu_naissance ? action.payload.lieu_naissance : '',
                departement_naissance: action.payload.departement_naissance ? action.payload.departement_naissance : '',
                pays_naissance: action.payload.pays_naissance ? action.payload.pays_naissance : '',
                indicateur_dispense: action.payload.indicateur_dispense === 'O'
            }
        }
        default:
            return state
    }
}

export default formulaireDonneesPersonnelles
