import React from 'react'
import {WithStyles} from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import {makeStyles} from '@material-ui/core/styles'
import './styles.css'
import {withOktaAuth} from '@okta/okta-react'
import classNames from 'classnames'
import Links from '../../enum/links'
import {useHistory} from 'react-router-dom'
import { useCookies } from 'react-cookie'
import localStorageEnum from '../../enum/localStorageEnum'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    height: {
        height: 80,
        display: 'flex',
        padding: '0 20px'
    },
    menuTitleStyle: {
        textAlign: 'center',
        fontSize: 24
    },
    textAlign: {
        textAlign: 'center'
    },
    cursorPointer: {
        cursor: 'pointer'
    }
}))

interface AcceptingProps {
    location: Location
}

interface ModelAuthService {
    logout: (path: string) => void
}

interface ModelOkta {
    authService: ModelAuthService
}

const lienLogin: string = '/login'

type MenuAdminProps =
    WithStyles<typeof styles>
    & AcceptingProps
    & ModelOkta

const MenuAdmin: React.FC<MenuAdminProps> = (
    {
        authService
    }
) => {

    const classes = useStyles()
    const history = useHistory()
    const removeCookies = useCookies()[2]

    const logout = () => {
        localStorage.removeItem('utilisateur')

        removeCookies(localStorageEnum.idToken, {
            path: '/',
            domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
        })
        removeCookies(localStorageEnum.accessToken, {
            path: '/',
            domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
        })

        authService.logout(lienLogin)
    }

    return (
        <div
            className={classNames(classes.backgroundPrimary,
                classes.colorTertiary,
                classes.height)}
        >
            <div
                className={classNames('col-xs-4',
                    classes.title,
                    classes.heavy,
                    classes.colorTertiary,
                    classes.flex,
                    classes.alignCenter,
                    classes.menuTitleStyle)}
            >
                Espace actionnaire
            </div>

            <div
                onClick={() => history.push(Links.administrationAccueil)}
                className={classNames(
                    'col-xs-2',
                    classes.title,
                    classes.bold,
                    classes.colorTertiary,
                    classes.flex,
                    classes.alignCenter,
                    classes.textAlign,
                    classes.cursorPointer)}
            >
                Administration de l'accueil
            </div>

            <div
                onClick={() => history.push(Links.administrationClub)}
                className={classNames(
                    'col-xs-2',
                    classes.title,
                    classes.bold,
                    classes.colorTertiary,
                    classes.flex,
                    classes.alignCenter,
                    classes.textAlign,
                    classes.cursorPointer)}
            >
                Administration du club des actionnaires
            </div>

            <div
                className={classNames('col-xs-4 end-xs',
                    classes.text,
                    classes.colorTertiary,
                    classes.textSize,
                    classes.flex,
                    classes.alignCenter)}
            >
				<span
                    onClick={logout}
                    className={classes.cursorPointer}
                >
					Déconnexion
				</span>
            </div>
        </div>
    )
}

export default withOktaAuth(MenuAdmin)
