import { AJOUTER_COMPTE_TITRES } from './actionTypes'

const compteTitres = (state = [], action) => {

	switch (action.type) {
		case AJOUTER_COMPTE_TITRES: {
			return action.payload
		}
		default:
			return state
	}
}

export default compteTitres
