import React from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import EnhancedCorpsContacts from './components/CorpsContacts'

const titre: string = 'Vos contacts'

const Contacts = () => {
	return (
		<>
			<EnhancedEntete titre={titre} />

			<MediaQuery
				maxWidth={1024}
			>

				{
					(match) => {
						return match ? (
							<>
								<EnhancedCorpsContacts tailleEcran="petit" />
							</>
						) : (
							<>
								<EnhancedCorpsContacts tailleEcran="grand" />
							</>
						)
					}
				}
			</MediaQuery>
		</>
	)
}

export default Contacts
