import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import formatDate from '../../utils/formatDate'

const useStyles = makeStyles(theme => ({
	marginTitleGrand: {
		marginBottom: '20px',

		'& p': {
			margin: '0px'
		}
	},
	marginTitlePetit: {
		marginBottom: '10px',

		'& p': {
			margin: '0px'
		}
	},
	marginBottomGrand: {
		marginBottom: '20px'
	},
	marginBottomPetit: {
		marginBottom: '50px'
	}
}))

interface ModelDonneesNaissance {
	date_naissance: Date | string,
	lieu_naissance: string,
	departement_naissance: number | string,
	pays_naissance: string
}

interface AcceptingProps {
	tailleEcran: string,
	donneesNaissance: ModelDonneesNaissance
}

type DonneesNaissanceProps = WithStyles<typeof styles> & AcceptingProps

const DonneesNaissance = (
	props: DonneesNaissanceProps
) => {
	const {
		classes,
		tailleEcran,
		donneesNaissance
	} = props

	const customClasses = useStyles()

	return (
		<>
			<div
				className={
					[
						classes.titleSize,
						classes.colorPrimary,
						classes.text,
						classes.bold,
						tailleEcran === 'grand' ? customClasses.marginTitleGrand : customClasses.marginTitlePetit
					].join(' ')
				}
			>
				<p>Etat civil</p>
			</div>

			<div
				className={
					[
						'row',
						tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
					].join(' ')
				}
			>
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Date de naissance
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{formatDate(new Date(donneesNaissance.date_naissance))}
					</span>
				</div>

				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Lieu de naissance
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{donneesNaissance.lieu_naissance}
					</span>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Département de naissance
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{donneesNaissance.departement_naissance}
					</span>
				</div>

				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Pays de naissance
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{donneesNaissance.pays_naissance}
					</span>
				</div>
			</div>
		</>
	)
}

const EnhancedDonneesNaissance = compose<DonneesNaissanceProps, AcceptingProps>(
	withStyles(styles)
)(DonneesNaissance)

export default EnhancedDonneesNaissance
