import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListeDroitsActionsGrand from './ListeDroitsActionsGrand'
import ListeDroitsStockGrand from './ListeDroitsStockGrand'
import styles from '../../../theme/styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	margin: {
		margin: '60px -.5rem 40px'
	}
}))

interface ModelDroitsActions {
	id: number,
	libelle_plan: string,
	date_attribution?: Date,
	date_debut_conservation?: Date,
	date_cessibilite?: Date,
	quantite: number
}

interface ModelDroitsStock {
	id: number,
	libelle_plan: string,
	quantite: number
	prix_offre?: number,
	date_debut_plan?: Date,
	date_fin_plan?: Date,
	bold?: boolean
}

interface AcceptingProps {
	droitsActions: ModelDroitsActions[],
	droitsStock: ModelDroitsStock[],
	telechargement: (id: number, type: string) => void
}

type CorpsDroitsActionsGrandProps = AcceptingProps

const CorpsDroitsActionsGrand = (
	props: CorpsDroitsActionsGrandProps
) => {
	const {
		droitsActions,
		droitsStock,
		telechargement
	} = props

	const classes = useStyles()

	const containerStyle: ClassValue = classNames(
		'row col-md-12',
		classes.margin
	)

	return (
		<>
			{
				droitsActions.length > 0 &&
				<div
					className={containerStyle}
				>
					<div className="col-xs-12">
						<ListeDroitsActionsGrand
							droitsActions={droitsActions}
							telechargement={telechargement}
						/>
					</div>
				</div>
			}

			{
				droitsStock.length > 0 &&
				<div className="row col-md-12">
					<div className="col-xs-12">
						<ListeDroitsStockGrand
							droitsStock={droitsStock}
							telechargement={telechargement}
						/>
					</div>
				</div>
			}
		</>
	)
}

export default CorpsDroitsActionsGrand
