import React, { Dispatch, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import EnhancedCoordonneesPersonnelles from './CoordonneesPersonnelles'
import EnhancedAdresseFiscale from './AdresseFiscale'
import EnhancedDonneesNaissance from './DonneesNaissance'
import EnhancedBoutonDonneesPersonnelles from './BoutonDonneesPersonnelles'
import { compose } from 'recompose'
import Dialog from '../../components/dialog/Dialog'
import MaterialDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import EnhancedFormulaireDonneesPersonnelles from './formulaireDonneesPersonnelles/FormulaireDonneesPersonnelles'
import fetchFactory from '../../utils/fetchFactory'
import errorCatcher from '../../utils/errorCatcher'
import { connect } from 'react-redux'
import { ajouterFormulaireDonneesPersonnelles } from '../reducers/formulaireDonneesPersonnelles/actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import EnhancedAdressePostale from './AdressePostale'

const useStyles = makeStyles(theme => ({
	marginTopGrand: {
		marginTop: '20px'
	},
	marginTopPetit: {
		marginTop: '30px'
	},
	blockContainer: {
		marginBottom: '10px',
		paddingBottom: '20px',
		borderBottom: '1px solid #707070'
	},
	marginButton: {
		marginTop: '30px'
	},
	spinnerStyle: {
		textAlign: 'center',

		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	}
}))

interface AcceptingProps {
	tailleEcran: 'petit' | 'grand'
}

interface ModelDonneesPersonnelles {
	email_recuperation: string,
	email: string,
	telephone_mobile: string,
	telephone_fixe: string
}

interface ModelAdresseFiscale {
	adresse_fiscale_rue: string,
	adresse_fiscale_batiment: string,
	adresse_fiscale_complement: string,
	adresse_fiscale_code_postal: number | string,
	adresse_fiscale_commune: string,
	adresse_fiscale_pays: string
}

interface ModelAdressePostale {
	adresse_postale_rue: string,
	adresse_postale_batiment: string,
	adresse_postale_complement: string,
	adresse_postale_code_postal: number | string,
	adresse_postale_commune: string,
	adresse_postale_pays: string
}

interface ModelDonneesNaissance {
	date_naissance: Date | string,
	lieu_naissance: string,
	departement_naissance: number | string,
	pays_naissance: string
}

interface ModelOptionFiscale {
	qualite: string,
	indicateur_dispense: boolean,
	pays_fiscale: string
}

interface Store {
	ajouterFormulaireDonneesPersonnelles: (donneesPersonnelles: ModelDonneesPersonnelles & ModelAdresseFiscale & ModelDonneesNaissance) => void,
	errorCatcher: (error: any, id: string) => void
}

type CorpsDonneesPersonnellesProps = AcceptingProps & Store

const CorpsDonneesPersonnelles = (
	props: CorpsDonneesPersonnellesProps
) => {
	const {
		tailleEcran,
		ajouterFormulaireDonneesPersonnelles,
		errorCatcher
	} = props

	const customClasses = useStyles()

	const [donneesPersonnelles, setDonneesPersonnelles] = React.useState<ModelDonneesPersonnelles>(
		{
			email_recuperation: '',
			email: '',
			telephone_mobile: '',
			telephone_fixe: ''
		}
	)
	const [adresseFiscale, setAdresseFiscale] = React.useState<ModelAdresseFiscale>(
		{
			adresse_fiscale_rue: '',
			adresse_fiscale_batiment: '',
			adresse_fiscale_complement: '',
			adresse_fiscale_code_postal: 0,
			adresse_fiscale_commune: '',
			adresse_fiscale_pays: ''
		}
	)
	const [adressePostale, setAdressePostale] = React.useState<ModelAdressePostale>(
		{
			adresse_postale_rue: '',
			adresse_postale_batiment: '',
			adresse_postale_complement: '',
			adresse_postale_code_postal: 0,
			adresse_postale_commune: '',
			adresse_postale_pays: ''
		}
	)

	const [donneesNaissance, setDonneesNaissance] = React.useState<ModelDonneesNaissance>(
		{
			date_naissance: new Date('now'),
			lieu_naissance: '',
			departement_naissance: 0,
			pays_naissance: ''
		}
	)

	const [loading, setLoading] = React.useState<boolean>(true)

	// Dialog's props
	const [open, setOpen] = React.useState(false)
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	useEffect(() => {
		fetchFactory('/api/donnees/personnelles')
			.then(
				(donnees_personnelles: any) => {
					setDonneesPersonnelles(
						{
							email_recuperation: donnees_personnelles.email_recuperation ?? '-',
							email: donnees_personnelles.email ? donnees_personnelles.email : '-',
							telephone_mobile: donnees_personnelles.telephone_mobile ? donnees_personnelles.telephone_mobile : '-',
							telephone_fixe: donnees_personnelles.telephone_fixe ? donnees_personnelles.telephone_fixe : '-'
						}
					)

					setAdresseFiscale(
						{
							adresse_fiscale_rue: donnees_personnelles.adresse_fiscale_rue ? donnees_personnelles.adresse_fiscale_rue : '-',
							adresse_fiscale_batiment: donnees_personnelles.adresse_fiscale_batiment ? donnees_personnelles.adresse_fiscale_batiment : '-',
							adresse_fiscale_complement: donnees_personnelles.adresse_fiscale_complement ? donnees_personnelles.adresse_fiscale_complement : '-',
							adresse_fiscale_code_postal: donnees_personnelles.adresse_fiscale_code_postal ? donnees_personnelles.adresse_fiscale_code_postal : '-',
							adresse_fiscale_commune: donnees_personnelles.adresse_fiscale_commune ? donnees_personnelles.adresse_fiscale_commune : '-',
							adresse_fiscale_pays: donnees_personnelles.adresse_fiscale_pays ? donnees_personnelles.adresse_fiscale_pays : '-'
						}
					)

					setAdressePostale(
						{
							adresse_postale_rue: donnees_personnelles.adresse_postale_rue ? donnees_personnelles.adresse_postale_rue : '-',
							adresse_postale_batiment: donnees_personnelles.adresse_postale_batiment ? donnees_personnelles.adresse_postale_batiment : '-',
							adresse_postale_complement: donnees_personnelles.adresse_postale_complement ? donnees_personnelles.adresse_postale_complement : '-',
							adresse_postale_code_postal: donnees_personnelles.adresse_postale_code_postal ? donnees_personnelles.adresse_postale_code_postal : '-',
							adresse_postale_commune: donnees_personnelles.adresse_postale_commune ? donnees_personnelles.adresse_postale_commune : '-',
							adresse_postale_pays: donnees_personnelles.adresse_postale_pays ? donnees_personnelles.adresse_postale_pays : '-'
						}
					)

					setDonneesNaissance(
						{
							date_naissance: donnees_personnelles.date_naissance ? donnees_personnelles.date_naissance : '-',
							lieu_naissance: donnees_personnelles.lieu_naissance ? donnees_personnelles.lieu_naissance : '-',
							departement_naissance: donnees_personnelles.departement_naissance ? donnees_personnelles.departement_naissance : '-',
							pays_naissance: donnees_personnelles.pays_naissance ? donnees_personnelles.pays_naissance : '-'

						}
					)

					ajouterFormulaireDonneesPersonnelles(donnees_personnelles)
				},
				(error: any) => {
					errorCatcher(error, 'donnees_personnelles')
				}
			)
			.catch(
				(error: any) => {
					errorCatcher(error, 'donnees_personnelles')
				}
			)
			.finally(
				() => {
					setLoading(false)
				}
			)
	}, [ajouterFormulaireDonneesPersonnelles, errorCatcher])

	return (
		<>
			{
				loading ? (
					<div className={customClasses.spinnerStyle}>
						<CircularProgress color="primary" />
					</div>
				) : (
					<div>
						<div
							className={
								[
									'row',
									tailleEcran === 'grand' ? customClasses.marginTopGrand : customClasses.marginTopPetit,
									customClasses.blockContainer
								].join(' ')
							}
						>
							<div className="col-xs-12">
								<EnhancedCoordonneesPersonnelles
									tailleEcran={tailleEcran}
									donneesPersonnelles={donneesPersonnelles}
									pays={adressePostale.adresse_postale_commune !== '-' ? adressePostale.adresse_postale_pays : adresseFiscale.adresse_fiscale_pays}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									customClasses.blockContainer
								].join(' ')
							}
						>
							<div className="col-xs-12">
								{
									adressePostale.adresse_postale_commune !== '-' ? (
										<EnhancedAdressePostale
											tailleEcran={tailleEcran}
											adressePostale={adressePostale}
										/>
									) : (
										<EnhancedAdresseFiscale
											tailleEcran={tailleEcran}
											adresseFiscale={adresseFiscale}
											type="postale"
										/>
									)
								}
							</div>
						</div>

						<div
							className={
								[
									'row',
									customClasses.blockContainer
								].join(' ')
							}
						>
							<div className="col-xs-12">
								<EnhancedAdresseFiscale
									tailleEcran={tailleEcran}
									adresseFiscale={adresseFiscale}
									type="fiscale"
								/>
							</div>
						</div>

						<div
							className={
								[
									'row',
									customClasses.blockContainer
								].join(' ')
							}
						>
							<div className="col-xs-12">
								<EnhancedDonneesNaissance
									tailleEcran={tailleEcran}
									donneesNaissance={donneesNaissance}
								/>
							</div>
						</div>

						<div
							className={
								[
									'row center-xs',
									customClasses.marginButton
								].join(' ')
							}
						>
							<div className="col-xs-12 col-sm-5">
								<EnhancedBoutonDonneesPersonnelles openDialog={() => setOpen(true)} />
							</div>
						</div>

						<MaterialDialog
							fullScreen={fullScreen}
							open={open}
							aria-labelledby="responsive-dialog-title"
							fullWidth
							maxWidth="md"
						>
							<Dialog
								hasButton={false}
								onCloseDialog={() => setOpen(false)}
							>
								<EnhancedFormulaireDonneesPersonnelles
									closeDialog={() => setOpen(false)}
									tailleEcran={tailleEcran}
								/>
							</Dialog>
						</MaterialDialog>
					</div>
				)
			}
		</>
	)
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		ajouterFormulaireDonneesPersonnelles: (donneesPersonnelles: ModelDonneesPersonnelles & ModelAdresseFiscale & ModelDonneesNaissance) => {
			dispatch(ajouterFormulaireDonneesPersonnelles(donneesPersonnelles))
		},
		errorCatcher: (error: any, id: string) => {
			dispatch(errorCatcher(error, id))
		}
	}
}

const EnhancedCorpsDonneesPersonnelles = compose<CorpsDonneesPersonnellesProps, AcceptingProps>(
	connect(null, mapDispatchToProps)
)(CorpsDonneesPersonnelles)

export default EnhancedCorpsDonneesPersonnelles
