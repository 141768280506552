export enum formFieldsEnum {
	account = 'account',
	lastname = 'lastname',
	firstname = 'firstname',
	address = 'address',
	email = 'email',
	orderDirection = 'orderDirection',
	nbOrders = 'nbOrders',
	condition = 'condition',
	ongoing = 'ongoing',
	comment = 'comment',
	files = 'files',
	validityDate = 'validityDate',
	date = 'date'
}
