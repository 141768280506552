import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import masqueIban from '../../utils/masqueIban'

const useStyles = makeStyles(theme => ({
	marginBottomGrand: {
		marginBottom: '30px'
	},
	spinnerStyle: {
		textAlign: 'center',

		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	}
}))

interface ModelCoordonnees {
	mode_reglement: string,
	code_bic: string,
	code_iban: string,
	domiciliation_bancaire: string
}

interface AcceptingProps {
	tailleEcran: string,
	coordonnees: ModelCoordonnees
}

type BodyCoordonneesBancairesProps = WithStyles<typeof styles> & AcceptingProps

const BodyCoordonneesBancaires = (
	props: BodyCoordonneesBancairesProps
) => {
	const {
		classes,
		tailleEcran,
		coordonnees
	} = props

	const customClasses = useStyles()

	return (
		<>
			<div
				className={
					[
						'row',
						tailleEcran === 'grand' && customClasses.marginBottomGrand
					].join(' ')
				}
			>
				<div className="col-xs-12">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Mode de réglement
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{coordonnees.mode_reglement}
					</span>
				</div>
			</div>

			{
				coordonnees.mode_reglement.toLowerCase() === 'virement' &&
				<div
					className={
						[
							'row',
							tailleEcran === 'grand' && customClasses.marginBottomGrand
						].join(' ')
					}
				>
					<div className="col-xs-12">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Code BIC
					</span>

						<br />

						<span
							className={
								[
									classes.colorPrimary,
									classes.textSize,
									classes.text,
									classes.regular
								].join(' ')
							}
						>
						{coordonnees.code_bic}
					</span>
					</div>
				</div>
			}

			{
				coordonnees.mode_reglement.toLowerCase() === 'virement' &&
				<div
					className={
						[
							'row',
							tailleEcran === 'grand' && customClasses.marginBottomGrand
						].join(' ')
					}
				>
					<div className="col-xs-12">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Code IBAN
					</span>

						<br />

						<span
							className={
								[
									classes.colorPrimary,
									classes.textSize,
									classes.text,
									classes.regular
								].join(' ')
							}
						>
						{coordonnees.code_iban && masqueIban(coordonnees.code_iban)}
					</span>
					</div>
				</div>
			}

			{
				coordonnees.mode_reglement.toLowerCase() === 'virement' &&
				<div
					className={
						[
							'row',
							tailleEcran === 'grand' && customClasses.marginBottomGrand
						].join(' ')
					}
				>
					<div className="col-xs-12">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Domiciliation bancaire
					</span>

						<br />

						<span
							className={
								[
									classes.colorPrimary,
									classes.textSize,
									classes.text,
									classes.regular
								].join(' ')
							}
						>
						{coordonnees.domiciliation_bancaire}
					</span>
					</div>
				</div>
			}
		</>
	)
}

const EnhancedBodyCoordonneesBancaires = compose<BodyCoordonneesBancairesProps, AcceptingProps>(
	withStyles(styles)
)(BodyCoordonneesBancaires)

export default EnhancedBodyCoordonneesBancaires
