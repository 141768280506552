import { AFFICHER_SNACKBAR, CACHER_SNACKBAR } from './actionTypes'

const snackBar = (state = {}, action) => {

	switch (action.type) {
		case AFFICHER_SNACKBAR: {
			return action.payload
		}
		case CACHER_SNACKBAR: {
			return {
				id: '',
				message: '',
				open: false
			}
		}
		default:
			return state
	}
}

export default snackBar
