import ListItem from '@material-ui/core/ListItem'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import React from 'react'
import {compose} from 'recompose'
import styles from '../../theme/styles'
import {makeStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classNames from 'classnames'
import localStorageEnum from '../../enum/localStorageEnum'
import Links from '../../enum/links'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    fab: {
        width: 40,
        height: 40
    },
    majuscule: {
        textTransform: 'uppercase'
    },
    noMargin: {
        margin: 0
    },
    margin: {
        marginRight: 10
    },
    listItem: {
        padding: 0,
        height: 40,
        width: 280
    },
    button: {
        height: '100%',
        textTransform: 'inherit',
        paddingLeft: 20,
        width: '100%',
        justifyContent: 'flex-start'
    },
    nomStyle: {
        fontSize: 14
    },
    ArrowDownStyle: {
        fill: '#aaa'
    },
    profileContainerStyle: {
        paddingBottom: 20
    }
}))

interface UserProps {
    lastname: string,
    firstname: string,
    email: string
}

interface Store {
    utilisateur: ModelUtilisateur
}

interface AcceptingProps {
    onClick: (lien: string) => void
}

interface ModelUtilisateur {
    utilisateur: string
}

type ProfileMenuProps = UserProps & WithStyles<typeof styles> & Store & AcceptingProps

const ProfileMenu = (
    {
        onClick,
        utilisateur
    }: ProfileMenuProps
) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState<boolean>(false)
    const removeCookies = useCookies()[2]

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const logout = () => {
        const dataLogout = {
            id_token: JSON.parse(localStorage.getItem(localStorageEnum.oktaToken) || '').accessToken.id_token || '',
            redirect: JSON.parse(localStorage.getItem(localStorageEnum.commonUri) || '').login_uri || ''
        }
        fetch('/api/okta/logout',
            {
                body: JSON.stringify(dataLogout),
                method: 'POST'
            })
            .then((response: Response) => response.json()
                .then(
                    (logoutResponse: any) => {
                        localStorage.removeItem(localStorageEnum.user)
                        localStorage.removeItem(localStorageEnum.oktaToken)

                        removeCookies(localStorageEnum.idToken, {
                            path: '/',
                            domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
                        })
                        removeCookies(localStorageEnum.accessToken, {
                            path: '/',
                            domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
                        })

                        window.location.href = logoutResponse.url

                    }
                )
            )
    }

    const profileContainerStyle = classNames(
        classes.flex,
        classes.flexEnd,
        classes.alignCenter,
        classes.cursorPointer,
        classes.profileContainerStyle
    )

    const pictoStyle = classNames(
        classes.margin,
        classes.backgroundWhite,
        classes.colorPrimary
    )

    const nomStyle = classNames(
        classes.noMargin,
        classes.nomStyle,
        classes.text,
        classes.semiBold
    )

    const profileItemStyle = classNames(
        classes.button,
        classes.text,
        classes.regular,
        classes.colorPrimary
    )

    return (
        <>
            <div
                className={profileContainerStyle}
                onClick={(event) => handleClick(event)}
            >
                <div
                    className={pictoStyle}
                >
                    <img src="/img/pictos-profil.png" alt="Picto profil"/>
                </div>

                <div>
                    <p
                        className={nomStyle}

                    >
                        {utilisateur.utilisateur}
                    </p>
                </div>

                <KeyboardArrowDownIcon
                    className={classes.ArrowDownStyle}/>
            </div>

            <Popover
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 45, horizontal: 'right'}}
            >
                <ListItem
                    className={classes.listItem}
                    onClick={() => onClick(Links.donneesPerso)}
                >
                    <Button
                        onClick={() => {
                            setOpen(false)
                        }}
                        className={profileItemStyle}
                    >
						<span>
							Vos données personnelles
						</span>
                    </Button>
                </ListItem>
                <ListItem
                    className={classes.listItem}
                    onClick={() => onClick(Links.coordonneesBancaires)}
                >
                    <Button
                        onClick={() => {
                            setOpen(false)
                        }}
                        className={profileItemStyle}
                    >
						<span>
							Vos coordonnées bancaires
						</span>
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <Button
                        onClick={() => {
                            setOpen(false)
                            logout()
                        }}
                        className={profileItemStyle}
                    >
						<span>
							Déconnexion
						</span>
                    </Button>
                </ListItem>
            </Popover>
        </>
    )

}

const mapStateToProps = (state: any) => {
    return {
        utilisateur: state.utilisateur
    }
}

const EnhancedProfileMenu = compose<ProfileMenuProps, AcceptingProps>(
    connect(mapStateToProps),
    withStyles(styles)
)(ProfileMenu)

export default EnhancedProfileMenu
