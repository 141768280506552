import { makeStyles } from '@material-ui/core/styles'
import { Security } from '@okta/okta-react'
import React, { useCallback, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { Redirect, Route, Switch } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import EnhancedMenu from '../components/menu/Menu'
import EnhancedMenuAdmin from '../components/menu/MenuAdmin'
import SnackBar from '../components/snackBar/SnackBar'
import Links from '../enum/links'
import EnhancedAccueil from '../modules/accueil/Accueil'
import EnhancedAdministration from '../modules/administration/Administration'
import EnhancedClubActionnaires from '../modules/clubActionnaires/ClubActionnaires'
import EnhancedCompteTitres from '../modules/compteTitres/CompteTitres'
import ConditionsGenerales from '../modules/conditionsGenerales/ConditionsGenerales'
import Contacts from '../modules/contacts/Contacts'
import CoordonneesBancaires from '../modules/coordonneesBancaires/CoordonneesBancaires'
import DetailAvoirs from '../modules/detailAvoirs/DetailAvoirs'
import DisponibiliteAvoirs from '../modules/disponibiliteAvoirs/DisponibiliteAvoirs'
import Documents from '../modules/documents/Documents'
import DonneesPersonnelles from '../modules/donneesPersonnelles/DonneesPersonnelles'
import DroitsActions from '../modules/droitsActions/DroitsActions'
import Glossaire from '../modules/glossaire/Glossaire'
import HistoriqueOperations from '../modules/historiqueOperations/HistoriqueOperations'
import FinishActivationPage from '../modules/login/activate/FinishActivationPage'
import AuthentificationPage from '../modules/login/login/AuthentificationPage'
import CallbackLogin from '../modules/login/login/CallbackLogin'
import MentionsLegales from '../modules/mentionsLegales/MentionsLegales'
import ProtectionDonnees from '../modules/protectionDonnees/ProtectionDonnees'
import { enregistrerUtilisateur, supprimerUtilisateur } from '../modules/reducers/utilisateur/actions'
import styles from '../theme/styles'
import { getAuthClient } from '../utils/authClient'
import fetchFactory from '../utils/fetchFactory'
import Cookies from '../modules/cookies/Cookies'
import ResetPasswordPage from '../modules/login/resetPwd/ResetPasswordPage'
import Cgu from '../modules/cgu/Cgu'
import EServices from '../modules/eServices/EServices'
import BourseForm from '../modules/bourseForm/BourseForm';
import Fiscalite from '../modules/fiscalite/Fiscalite';

const useStyles = makeStyles(theme => ({
	...styles(theme),
	positionSticky: {
		position: 'sticky',
		top: 0,
		zIndex: 2
	},
	appContainer: {
		minHeight: '100vh',
		position: 'relative',
		backgroundColor: theme.gecina.grey
	}
}))

const UNLOGGED_PATHNAME = [Links.login, Links.checkOkta, Links.conditionsGenerales, Links.activateAccount, Links.resetPassword]

const App = (props) => {
	const {
		location,
		history,
		supprimerUtilisateur,
		enregistrerUtilisateur,
		issuer,
		client_id,
		redirect_uri
	} = props
	const classes = useStyles()
	const [utilisateur, setUtilisateur] = React.useState(localStorage.getItem('utilisateur'))
	const [token, setToken] = React.useState(null)


	const onAuthRequired = () => {
		localStorage.removeItem('utilisateur')
		supprimerUtilisateur()
		history.push(Links.login)
	}


	const logoutForInactivity = useCallback(
		() => {
			localStorage.removeItem('okta-token-storage')
			localStorage.removeItem('utilisateur')
			supprimerUtilisateur()
			history.push(Links.login)
		}, [history, supprimerUtilisateur]
	)

	useIdleTimer(
		{
			timeout: 1000 * 60 * 15,
			onIdle: logoutForInactivity,
			debounce: 500
		}
	)

	useEffect(() => {
		if (!utilisateur && (UNLOGGED_PATHNAME.findIndex((pathname) => location.pathname.includes(pathname)) < 0 && location.pathname !== '/')) {
			fetchFactory('/api/compte/identite')
				.then(
					utilisateurReponse => {
						enregistrerUtilisateur(utilisateurReponse)
						localStorage.setItem('utilisateur', JSON.stringify(utilisateurReponse))
						setUtilisateur(JSON.stringify(utilisateurReponse))
					}
				)
				.catch(
					error => {
						console.error('Erreur lors de la récupération de l\'utilisateur : ', error)
						history.push(Links.login)
					}
				)
		} else if (utilisateur) {
			enregistrerUtilisateur(JSON.parse(localStorage.getItem('utilisateur')))
		}

		const AuthClient = getAuthClient()
		AuthClient.tokenManager.get('accessToken')
			.then(
				(token) => {
					setToken(token)
				}
			)
			.catch(
				(error) => (
					console.error('Une erreur est survenue lors de la récupération du token : ', error)
				)
			)

	}, [utilisateur, enregistrerUtilisateur, history, location.pathname])

	if (!token && location.pathname === Links.conditionsGenerales) {
		return <ConditionsGenerales />
	}

	if (!token && location.pathname === '/') {
		history.push(Links.login)
	}


	return (
		<div className={classes.appContainer}>
			<Security
				issuer={issuer}
				clientId={client_id}
				redirectUri={redirect_uri}
				onAuthRequired={onAuthRequired}
				pkce
			>
				{
					location.pathname !== Links.login && token &&
					<div className={classes.positionSticky}>
						{
							utilisateur && JSON.parse(utilisateur).admin ? (
								<EnhancedMenuAdmin />
							) : (
								<EnhancedMenu location={location} />
							)
						}
					</div>
				}

				{
					token && utilisateur && !JSON.parse(utilisateur).admin &&
					<MediaQuery
						maxWidth={1024}
					>
						{
							(match) => {
								return (
									<div
										className={location.pathname !== Links.login ? match ? classes.sizeContainerPetit : classes.sizeContainerGrand : ''}>
										<Switch>
											<Route path={Links.accueil} component={EnhancedAccueil} />
											<Route path={Links.compteTitres} component={EnhancedCompteTitres} />
											<Route path={Links.detailAvoirs} component={DetailAvoirs} />
											<Route path={Links.dispoAvoirs} component={DisponibiliteAvoirs} />
											<Route path={Links.droitsActions} component={DroitsActions} />
											<Route path={Links.donneesPerso} component={DonneesPersonnelles} />
											<Route path={Links.coordonneesBancaires} component={CoordonneesBancaires} />
											<Route path={Links.contacts} component={Contacts} />
											<Route path={Links.documentsUtiles} component={Documents} />
											<Route path={Links.documentsPerso} component={Documents} />
											<Route path={Links.mentionsLegales} component={MentionsLegales} />
											<Route path={Links.protectionDonnees} component={ProtectionDonnees} />
											<Route path={Links.eServices} component={EServices} />
											<Route path={Links.bourseForm} component={BourseForm} />
											<Route path={Links.conditionsGenerales} component={ConditionsGenerales} />
											<Route path={Links.glossaire} component={Glossaire} />
											<Route path={Links.clubActionnaires} component={EnhancedClubActionnaires} />
											<Route path={Links.historiqueOperations} component={HistoriqueOperations} />
											<Route path={Links.cookies} component={Cookies} />
											<Route path={Links.login} component={AuthentificationPage} />
											<Route path={Links.checkOkta} component={CallbackLogin} />
											<Route path={Links.fiscalite} component={Fiscalite} />
											<Route path="/" exact>
												<Redirect to={Links.accueil} />
											</Route>
										</Switch>

									</div>
								)
							}
						}
					</MediaQuery>
				}
				{
					token && utilisateur && JSON.parse(utilisateur).admin &&
					<div className={classes.sizeContainerGrand}>
						<Switch>
							<Route path={Links.administrationAccueil} component={EnhancedAdministration} />
							<Route path={Links.administrationClub} component={EnhancedAdministration} />
							<Route path="/" exact>
								<Redirect to={Links.administrationAccueil} />
							</Route>
						</Switch>
					</div>
				}
				{
					!token && location.pathname === Links.login && <AuthentificationPage />
				}
				{
					!token && location.pathname === Links.checkOkta && <CallbackLogin />
				}
				{
					!token && location.pathname.includes(Links.activateAccount) && (
						<Route path={`${Links.activateAccount}/:token`} component={FinishActivationPage} />
					)
				}
				{
					!token && location.pathname.includes(Links.resetPassword) && (
						<Route path={`${Links.resetPassword}/:token`} component={ResetPasswordPage} />
					)
				}

				{
					location.pathname !== Links.login && token && utilisateur && (
						<>
							<Footer />
							<Cgu />
						</>
					)
				}

				<SnackBar />
			</Security>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		enregistrerUtilisateur: (utilisateur) => {
			dispatch(enregistrerUtilisateur(utilisateur))
		},
		supprimerUtilisateur: () => {
			dispatch(supprimerUtilisateur())
		}
	}
}

export default connect(null, mapDispatchToProps)(App)
