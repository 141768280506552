import React, { useEffect, useState } from 'react'
import Loader from '../../../components/loader/Loader'
import fetchFactory from '../../../utils/fetchFactory'
import { useDispatch, useSelector } from 'react-redux'
import errorCatcher from '../../../utils/errorCatcher'
import parse from 'html-react-parser'
import Button from '../../../components/button/Button'
import localStorageEnum from '../../../enum/localStorageEnum'
import { supprimerUtilisateur, updateCgu } from '../../reducers/utilisateur/actions'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { RootState } from '../../reducers'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles(
    (theme) => (
        {
            cguContainer: {
                padding: 40,
                maxWidth: 900,
                margin: 'auto',

                '& h1, & p': {
                    color: theme.palette.blue.main
                },
                '& .buttonsContainer': {
                    textAlign: 'right',

                    '& button:first-child': {
                        marginRight: 30
                    }
                }
            },
            cguContainerLargeScreen: {
                '& h1': {
                    ...styles(theme).title2
                },
                '& h2': {
                    ...styles(theme).title3
                },
                '& p': {
                    ...styles(theme).text8
                }
            },
            cguContainerSmallScreen: {
                '& h1': {
                    ...styles(theme).title3
                },
                '& h2': {
                    ...styles(theme).title7
                },
                '& p': {
                    ...styles(theme).text4
                }
            }
        }
    )
)

interface AcceptingProps {
    closeDialog: () => void
}

interface CguModel {
    id: number,
    texte_cgu: string
}

const GET_CGU = 'get_cgu'
const ACCEPT_CGU = 'accept_cgu'

type cguModalProps = AcceptingProps

const CguModal: React.FC<cguModalProps> = (
    {
        closeDialog
    }
) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.utilisateur)
    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )

    const [cgu, setCgu] = useState<CguModel>(
        {
            id: 0,
            texte_cgu: ''
        }
    )
    const [loading, setLoading] = useState<boolean>(true)
    const removeCookies = useCookies()[2]

    useEffect(
        () => {
            fetchFactory('/api/cgu')
                .then(
                    (cgu: any) => setCgu(cgu)
                )
                .catch(
                    (error) => dispatch(
                        errorCatcher(error, GET_CGU)
                    )
                )
                .then(
                    () => setLoading(false)
                )
        }, [setCgu, dispatch]
    )

    const refuseCgu = () => {
        const dataLogout = {
            id_token: JSON.parse(localStorage.getItem(localStorageEnum.oktaToken) || '').accessToken.id_token || '',
            redirect: JSON.parse(localStorage.getItem(localStorageEnum.commonUri) || '').login_uri || ''
        }

        fetch('/api/okta/logout',
            {
                body: JSON.stringify(dataLogout),
                method: 'POST'
            })
            .then((response: Response) => response.json()
                .then(
                    (logoutResponse: any) => {
                        localStorage.removeItem(localStorageEnum.user)
                        localStorage.removeItem(localStorageEnum.oktaToken)
                        dispatch(
                            supprimerUtilisateur()
                        )

                        removeCookies(localStorageEnum.idToken, {
                            path: '/',
                            domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
                        })
                        removeCookies(localStorageEnum.accessToken, {
                            path: '/',
                            domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
                        })

                        window.location.href = logoutResponse.url
                    }
                )
            )
    }

    const acceptCgu = () => {
        fetchFactory(`/api/accept/cgu/${cgu.id}`)
            .then(
                () => {
                    dispatch(
                        updateCgu(true)
                    )
                    localStorage.setItem('utilisateur', JSON.stringify(
                        {
                            ...user,
                            hasCgu: true
                        }
                    ))
                    closeDialog()
                }
            )
            .catch(
                (error) => dispatch(
                    errorCatcher(error, ACCEPT_CGU)
                )
            )
    }

    if (loading) {
        return (
            <Loader/>
        )
    }

    return (
        <div
            className={
                classNames(
                    classes.cguContainer,
                    isLargeScreen ? classes.cguContainerLargeScreen : classes.cguContainerSmallScreen
                )
            }
        >
            <h1>Mise à jour des Conditions Générales d'Utilisation</h1>

            {
                parse(cgu.texte_cgu)
            }

            <div className="buttonsContainer">
                <Button
                    background="backgroundWhite"
                    color="colorPrimary"
                    border="borderPrimary"
                    onClick={refuseCgu}
                >
                    Refuser
                </Button>

                <Button
                    background="backgroundPrimary"
                    color="colorSecondary"
                    onClick={acceptCgu}
                >
                    Accepter
                </Button>
            </div>
        </div>
    )
}

export default CguModal
