import React from 'react'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import { FieldRenderProps } from 'react-final-form'
import classNames from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	labelStyle: {
		fontSize: 14,
		fontWeight: 600,
		textTransform: 'uppercase'
	},
	selectStyle: {
		width: '100%',
		paddingTop: 8,
		color: theme.palette.blue['70'],

		'& .MuiSelect-root': {
			fontSize: 18,
			fontWeight: 600,
		},
		'& .MuiSelect-icon': {
			height: '100%',
			width: '8%',
			marginTop: -6,
			color: theme.palette.secondary.main
		}
	},
	errorStyle: {
		color: '#B92D00',
		fontSize: 10
	},
	errorColor: {
		color: '#B92D00 !important'
	},
	errorBorder: {
		border: '1px solid #B92D00'
	}
}))

interface AcceptingProps {
	label: React.ReactChild,
	id: string,
}

type FormSelectProps =
	FieldRenderProps<string, HTMLElement>
	& AcceptingProps

const FormSelect: React.FC<FormSelectProps> = (
	{
		input,
		label,
		id,
		meta: { touched, error },
		children
	}
) => {
	const classes = useStyles()

	return (
		<>
			<span
				className={
					classNames(
						classes.labelStyle,
						classes.text,
						classes.colorPrimary,
						touched && error && classes.errorColor
					)
				}
			>
				{label}
			</span>

			<Select
				id={id}
				label={label}
				value={input.value}
				onChange={input.onChange}
				IconComponent={ExpandMoreIcon}
				className={
					classNames(
						classes.selectStyle,
						touched && error && classes.errorBorder,
						classes.text,
						classes.regular,
						classes.textSize
					)
				}
			>
				{children}
			</Select>
			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</>
	)
}

export default FormSelect
