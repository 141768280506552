import React from 'react'
import styles from '../../theme/styles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { cacherSnackbar } from '../../modules/reducers/snackBar/actions'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Dialog from '../dialog/Dialog'
import MaterialDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	textContainer: {
		textAlign: 'center',
		marginTop: 20,
		marginBottom: 0
	},
	verticalBottom: {
		color: theme.palette.blue.main,
		verticalAlign: 'bottom',
		marginRight: 20
	},
	textSize: {
		color: theme.palette.blue.main,
		fontSize: 13
	}
}))

export interface AdditionalButtonsModel {
	content: string,
	id: string
	action: () => void
}

interface ModelSnackBarData {
	id: string,
	message: string,
	open: boolean,
	hideIcon?: boolean,
	additionalButtons?: AdditionalButtonsModel[]
}

interface StoreProps {
	snackBarData: ModelSnackBarData,
	cacherSnackBar: () => void
}

type SnackBarProps = StoreProps
const SnackBar = (
	{
		snackBarData,
		cacherSnackBar
	}: SnackBarProps) => {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const textClass: ClassValue = classNames(
		classes.text,
		classes.bold,
		classes.textSize
	)

	const handleClose = () => {
		cacherSnackBar()
	}

	return (
		<>
			<MaterialDialog
				fullScreen={fullScreen}
				open={snackBarData.open ?? false}
				aria-labelledby="snackbar-dialog"
				fullWidth
				maxWidth="md"
			>
				<Dialog
					hasButton={true}
					buttons={
						[
							...snackBarData.additionalButtons || [],
							{
								content: 'Fermer',
								id: 'close-dialog',
								flag: true
							}
						]
					}
					onCloseDialog={handleClose}
					hasCloseIcon={false}
				>
					<p id={snackBarData.id} className={classes.textContainer}>
						{
							!snackBarData.hideIcon &&
							<DoneOutlineIcon className={classes.verticalBottom} />
						}
						<span
							className={textClass}
						>
							{snackBarData.message}
						</span>
					</p>
				</Dialog>
			</MaterialDialog>
		</>
	)
}

const mapStateToProps = (state: any) => {
	return {
		snackBarData: state.snackBar
	}
}

const mapDispatchToProps = (dispatch: any) => {
	return {
		cacherSnackBar: () => {
			dispatch(cacherSnackbar())
		}
	}
}

export default compose<SnackBarProps, {}>(
	connect(mapStateToProps, mapDispatchToProps)
)(SnackBar)
