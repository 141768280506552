import React, { CSSProperties } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../theme/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => ({
			...styles(theme),
			circularProgressStyle: {
				width: '100%',
				height: '100%',
				marginBottom: 20,

				'& div': {
					width: '100px !important',
					height: '100px !important'
				}
			}
		}
	)
)

interface AcceptingProps {
	styles?: CSSProperties
}

const Loader: React.FC<AcceptingProps> = (
	{
		styles
	}
) => {
	const classes = useStyles()

	const circularProgressClass: ClassValue = classNames(
		classes.circularProgressStyle,
		classes.flex,
		classes.alignCenter,
		classes.flexCenter
	)

	return (
		<div style={styles} className={circularProgressClass}>
			<CircularProgress />
		</div>
	)
}

export default Loader