import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListeActionsGrand from './ListeActionsGrand'
import styles from '../../../theme/styles'

interface ModelDisponibilites {
	id: number,
	date_disponibilite: Date,
	typologie: string,
	actions: string,
	prix_acquisition: number
}

interface ModelIndisponibilites {
	id: number,
	date_disponibilite: Date,
	actions: string,
	prix_acquisition: number,
	message: string
}

interface AcceptingProps {
	disponibilites: ModelDisponibilites[],
	indisponibilites: ModelIndisponibilites[]
}

const useStyles = makeStyles(theme => ({
	...styles(theme),
	margin: {
		marginTop: '20px'
	}
}))

type CorpsDisponibiliteAvoirsGrandProps = AcceptingProps

const CorpsDisponibiliteAvoirsGrand = (
	props: CorpsDisponibiliteAvoirsGrandProps
) => {
	const {
		disponibilites,
		indisponibilites
	} = props

	const classes = useStyles()

	return (
		<div
			className={classes.margin}
		>
			<ListeActionsGrand
				disponibilites={disponibilites}
				indisponibilites={indisponibilites}
			/>
		</div>
	)
}

export default CorpsDisponibiliteAvoirsGrand
