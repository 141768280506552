import React from 'react'
import Header from '../../../components/dialog/Header'
import Button from '../../../components/button/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
	() => (
		{
			dialogContainer: {
				'& > div:first-child': {
					marginTop: 35
				},
				'& p:first-child': {
					marginTop: 40
				}

			},
			buttonContainer: {
				display: 'flex',
				justifyContent: 'flex-end',

				'& button': {
					marginRight: 20
				}
			}
		}
	)
)

const TITRE = 'Avertissement'

interface AcceptingProp {
	onDlFile: (id: number, libelle: string) => void,
	onOpenRefused: () => void,
	id: number,
	libelle: string
}

type PopinMultiTitulaireProps = AcceptingProp

const PopinMultiTitulaire: React.FC<PopinMultiTitulaireProps> = (
	{
		onDlFile,
		onOpenRefused,
		id,
		libelle
	}
) => {
	const classes = useStyles()

	return (
		<div className={classes.dialogContainer}>
			<Header title={TITRE}/>

			<div>
				<p>Le document mis à disposition est établi au nom du représentant du compte ou du bénéficiaire des revenus.</p>

				<p>Voulez-vous continuez ?</p>
			</div>

			<div className={classes.buttonContainer}>
				<Button
					background="backgroundInherit"
					color="colorSecondary"
					border="borderSecondaryLight"
					onClick={onOpenRefused}
				>
					Non
				</Button>

				<Button
					background="backgroundPrimary"
					color="colorTertiary"
					onClick={() => onDlFile(id, libelle)}
				>
					Oui
				</Button>
			</div>
		</div>
	)
}

export default PopinMultiTitulaire