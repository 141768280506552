import React from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import { compose } from 'recompose'
import formatDate from '../../../utils/formatDate'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Button from '../../../components/button/Button'
import fetchFactory from '../../../utils/fetchFactory'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	borderBottom: {
		borderBottom: '1px solid #0000001E'
	},
	borderLeft: {
		borderLeft: '5px solid #122941'
	},
	contentStyle: {
		margin: 0,
		padding: 0
	},
	libelleStyle: {
		opacity: 0.5,
		height: 60
	},
	textSize: {
		fontSize: 14
	},
	centerContent: {
		placeContent: 'center',
		padding: 0,

		'& > div': {
			padding: 0
		}

	},
	heightRowStyle: {
		height: 55,
		boxSizing: 'content-box'
	},
	heightPdfRowStyle: {
		height: 35
	},
	coursActionStyle: {
		width: 'fit-content',
		margin: '15px 10px 18px 0'
	},
	buttonContainer: {
		height: 48,

		'& button': {
			'& #icon-left': {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%'
			}
		}
	},
	marginPdf: {
		margin: '0 20px'
	},
	marginIcon: {
		marginRight: 11
	}
}))

interface ModelCompteTitres {
	id: number,
	code_isin: string,
	nature_valeur: string,
	solde_global: number,
	valorisation: number
}

interface ModelPrixGecina {
	update_date: Date,
	price: string
}

interface AcceptingProps {
	compteTitres: ModelCompteTitres[],
	prixGecina: ModelPrixGecina
}

const VALORISATION = 'valorisation'

type ListeActionsGrandProps = AcceptingProps

const ListeActionsGrand = (
	props: ListeActionsGrandProps
) => {
	const {
		compteTitres,
		prixGecina
	} = props

	const classes = useStyles()

	const rowTitreStyle: ClassValue = classNames(
		'row col-md-12',
		classes.borderBottom,
		classes.libelleStyle,
		classes.centerContent
	)
	const rowBorderStyle: ClassValue = classNames(
		'row col-md-12',
		classes.borderBottom,
		classes.centerContent,
		classes.heightRowStyle
	)

	const rowNoBorderStyle: ClassValue = classNames(
		'row col-md-12',
		classes.centerContent,
		classes.heightRowStyle
	)

	const codeSoldeValoStyle: ClassValue = classNames(
		'col-md-2',
		classes.textSize,
		classes.contentStyle,
		classes.bold
	)

	const natureValeurStyle: ClassValue = classNames(
		'col-md-6',
		classes.textSize,
		classes.contentStyle,
		classes.bold
	)

	const buttonPdfStyle: ClassValue = classNames(
		'col-md-2 end-md',
		classes.marginPdf
	)

	const rowPdfStyle: ClassValue = classNames(
		'row col-md-12 end-md',
		classes.buttonContainer
	)

	const firstColumnStyle: ClassValue = classNames(
		'col-md-2',
		classes.text,
		classes.regular,
		classes.colorPrimary,
		classes.contentStyle
	)

	const secondColumnStyle: ClassValue = classNames(
		'col-md-6',
		classes.text,
		classes.textSize,
		classes.colorPrimary,
		classes.contentStyle
	)

	const thirdColumnStyle: ClassValue = classNames(
		'col-md-2',
		classes.text,
		classes.textSize,
		classes.colorPrimary,
		classes.contentStyle
	)

	const fourthColumnStyle: ClassValue = classNames(
		'col-md-2',
		classes.text,
		classes.regular,
		classes.textSize,
		classes.colorPrimary,
		classes.contentStyle
	)

	const coursActionStyle: ClassValue = classNames(
		classes.text,
		classes.bold,
		classes.textSize,
		classes.colorPrimary,
		classes.contentStyle,
		classes.coursActionStyle
	)

	const containerCoursStyle: ClassValue = classNames(
		'row col-md-12'
	)

	const prixActionsGecinaStyle: ClassValue = classNames(
		classes.text,
		classes.regular,
		classes.textSize,
		classes.colorPrimary,
		classes.coursActionStyle
	)

	const iconClass: ClassValue = classNames(
		classes.colorSecondary,
		classes.marginIcon
	)
	const onButtonClicked = () => {
		fetchFactory('/api/attestation', {
			method: 'POST'
		}, 'blob')
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = 'attestation.pdf'
					a.click()
				}
			)
	}

	return (
		<>
			<div className={rowTitreStyle}>
				<div className="col-md-3">
					<span className={codeSoldeValoStyle}>Code ISIN</span>
				</div>

				<div className="col-md-3">
					<span className={natureValeurStyle}>Nature de la valeur</span>
				</div>

				<div className="col-md-3 end-md">
					<span className={codeSoldeValoStyle}>Solde Global</span>
				</div>

				<div className="col-md-3 end-md">
					<span className={codeSoldeValoStyle}>Valorisation*</span>
				</div>
			</div>

			{

				compteTitres.map((action, index) => (
						<div
							key={action.id}
							className={index === compteTitres.length - 1 ? rowNoBorderStyle : rowBorderStyle}
						>
							<div className="col-md-3">
                                <span className={firstColumnStyle}>
                                    {action.code_isin}
                                </span>
							</div>

							<div className="col-md-3">
                                <span className={secondColumnStyle}>
                                    {action.nature_valeur}
                                </span>
							</div>

							<div className="col-md-3 end-md">
                                <span className={thirdColumnStyle}>
                                    {thousandsSeparator(action.solde_global, true)} actions
                                </span>
							</div>

							<div className="col-md-3 end-md">
								{
									VALORISATION in action ? (
										<span className={fourthColumnStyle}>
                                            {thousandsSeparator(Number(action.valorisation))} €
                                        </span>
									) : (
										<span></span>
									)
								}
							</div>
						</div>
					)
				)
			}
			<div className={rowPdfStyle}>
				<div className={buttonPdfStyle}>
					<Button
						disabled={false}
						onClick={onButtonClicked}
					><PictureAsPdfOutlinedIcon className={iconClass} />
						Télécharger
					</Button>
				</div>
			</div>
			{
				compteTitres.length > 0 &&
				<div className={containerCoursStyle}>
					<small
						className={coursActionStyle}
					>
						*Cours de l'action
					</small>

					<small
						className={prixActionsGecinaStyle}
					>{thousandsSeparator(Number(prixGecina.price))} €
						au {formatDate(prixGecina.update_date)}</small>
				</div>
			}
		</>
	)
}

const EnhancedListeActionsGrand = compose<ListeActionsGrandProps, AcceptingProps>()(ListeActionsGrand)

export default EnhancedListeActionsGrand
