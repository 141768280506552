import { ContactModel } from '../  types/contactModel'

export const CONTACT_MOCK: ContactModel[] = [
	{
		picture: '/img/franck_brunet.png',
		name: 'Franck Brunet',
		position: 'Responsable Titres et Bourse',
		phone: '01 40 40 65 47'
	},
	{
		picture: '/img/daniela_conde.png',
		name: 'Daniela Conde',
		position: 'Attachée de Relations Actionnaires',
		phone: '01 40 40 50 79'
	},
	{
		picture: '/img/Legoff.jpg',
		name: 'Laurent Le Goff',
		position: 'Responsable Actionnariat et Communication Financière',
		phone: '01 40 40 62 69'
	}
]
