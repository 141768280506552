import React, {useEffect} from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import EnhancedTableauSimple from '../../../components/table/TableauSimple'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import styles from '../../../theme/styles'
import formatDate from '../../../utils/formatDate'
import MaterialTooltip from '@material-ui/core/Tooltip'
import {makeStyles} from '@material-ui/core/styles'
import EnhancedListeBoutons from '../ListeBoutons'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	...styles(theme),
    tailleTooltip: {
        maxWidth: '200px'
    },
    titleStyle: {
        margin: '40px 30px 20px'
    },
	noMargin: {
		margin: 0
	}
}))

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date | string,
    actions: string,
    prix_acquisition: number | null,
    message: string,
    couleur?: string,
    bold?: boolean
}

interface ModelColonnes {
    value: string,
    label: string,
    type: string,
    render?: (row: ModelIndisponibilites) => false | React.ReactChild
}

interface AcceptingProps {
    indisponibilites: ModelIndisponibilites[]
}

type ListeIndisponibilitesGrandProps =
    & AcceptingProps

const ListeIndisponibilitesGrand = (
    props: ListeIndisponibilitesGrandProps
) => {
    const {
        indisponibilites
    } = props
    const classes = useStyles()

    const colonnes: ModelColonnes[] = [
        {
            value: 'date_disponibilite',
            label: 'Date de disponibilité',
            type: 'date',
            render: (row: ModelIndisponibilites) => (
                <div>
                    {row.date_disponibilite !== 'Total' ? formatDate(new Date(row.date_disponibilite)) : row.date_disponibilite}
                </div>
            )
        },
        {
            value: 'actions',
            label: 'Nombre d\'actions',
            type: 'number',
            render: (row: ModelIndisponibilites) => (
                <div>
                    {thousandsSeparator(Number(row.actions), true)}
                </div>
            )
        },
        {
            value: 'prix_acquisition',
            label: 'Prix d\'acquisition',
            type: 'number',
            render: (row: ModelIndisponibilites) => (
                <div>
                    {row.prix_acquisition !== null ? thousandsSeparator(Number(row.prix_acquisition)) + ' €' : ''}
                </div>
            )
        },
        {
            value: 'message',
            label: '',
            type: 'icon',
            render: (row: ModelIndisponibilites) => (
                row.date_disponibilite !== 'Total' &&
                <MaterialTooltip
                    title={row.message}
                    placement="right"
                    classes={{tooltip: classes.tailleTooltip}}
                >
                    <InfoOutlinedIcon/>
                </MaterialTooltip>
            )
        }
    ]

    const [actions, setActions] = React.useState<ModelIndisponibilites[]>([])


    useEffect(() => {
            setActions(
                [
                    ...indisponibilites,
                    {
                        id: indisponibilites.reduce((a, b) => a + (Number(b.actions.toString().replace(/\s/g, '')) || 0), 0),
                        date_disponibilite: 'Total',
                        actions: indisponibilites.reduce((a, b) => a + (Number(b.actions.toString().replace(/\s/g, '')) || 0), 0).toString(),
                        prix_acquisition: null,
                        message: '',
                        couleur: 'colorPrimary',
                        bold: true
                    }
                ]
            )
        }, [indisponibilites]
    )

    const buttonRowStyle: ClassValue = classNames(
        'row end-md',
        classes.noMargin
    )

    const titleStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.titleSize,
        classes.title,
        classes.bold,
        classes.titleStyle
    )

    return (
        <>
            <div className="col-xs-12">
                <h1
                    className={titleStyle}
                >
                    Avoirs indisponibles
                </h1>
            </div>

            <div className="col-xs-12">
                <div className="row">
                    <EnhancedTableauSimple
                        colonnes={colonnes}
                        lignes={actions}
                    />
                </div>

                <div className={buttonRowStyle}>
                    <EnhancedListeBoutons type="indisponibles"/>
                </div>
            </div>
        </>
    )
}

export default ListeIndisponibilitesGrand

