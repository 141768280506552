import React from 'react'
import styles from '../../theme/styles'
import thousandsSeparator from '../../utils/thousandsSeparator'
import {makeStyles} from '@material-ui/core/styles'
import formatDate from '../../utils/formatDate'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    background: {
        background: theme.palette.blue.main
    },
    titleSizePetit: {
        fontSize: 15,

        '& strong': {
            whiteSpace: 'pre'
        }
    },
    marginPetit: {
        margin: '25px 0'
    },
    marginTopGrand: {
        marginTop: 35
    },
    padding: {
        padding: '40px 20px 40px 20px'
    },
    paddingBorder: {
        '&::after': {
            content: '""',
            margin: '20px 0px 0px 7px',
            height: 1,
            width: '19%',
            borderBottom: '1px solid #979797'
        }
    },
    marginLeft: {
        marginLeft: 5
    },
    paddingBottom: {
        padding: '0 0 20px'
    },
    colorGrey: {
        color: theme.gecina.grey
    },
    margin: {
        margin: '5px 25px'
    },
    itemCenter: {
        alignItems: 'center'
    }
}))

interface ModelRecapitulatif {
    total_actions: number,
    actions_cessibles: number,
    valorisation: number,
    montant_cours: number,
    date_cours: Date
}

interface AcceptingProps {
    tailleEcran: string,
    recapitulatif: ModelRecapitulatif
}

type RecapitulatifAvoirsProps = AcceptingProps

const RecapitulatifAvoirs = (
    props: RecapitulatifAvoirsProps
) => {
    const {
        tailleEcran,
        recapitulatif
    } = props

    const classes = useStyles()

    const rowStyle: ClassValue = classNames(
        'row',
        classes.margin
    )

    const containerCenter: ClassValue = classNames(
        'row',
        classes.itemCenter
    )

    const valeurStyle: ClassValue = classNames(
        classes.text,
        tailleEcran === 'grand' ? classes.titleSize : classes.titleSizePetit,
        classes.colorTertiary,
        classes.semiBold,
        classes.marginLeft
    )

    const textStyle: ClassValue = classNames(
        classes.text,
        tailleEcran === 'grand' ? classes.titleSize : classes.titleSizePetit,
        classes.colorTertiary,
        classes.semiBold
    )

    const containerStyle: ClassValue = classNames(
        'row',
        classes.background,
        classes.padding,
        tailleEcran === 'grand' ? classes.marginTopGrand : classes.marginPetit
    )

    const contenuStyle: ClassValue = classNames(
        'col-xs-12 col-sm-6',
        tailleEcran === 'grand' ? '' : classes.paddingBorder
    )

    const coursActionStyle: ClassValue = classNames(
        classes.text,
        tailleEcran === 'grand' ? classes.textSize : classes.smallSize,
        classes.colorGrey
    )

    return (
        <div
            className={containerStyle}
        >
            <div className="col-xs-12">
                <div className={containerCenter}>
                    <div
                        className={contenuStyle}
                    >
                        <div className={rowStyle}>
						    <span
                                className={textStyle}
                            >
							Total action(s) GECINA :
						    </span>
                            <span
                                className={valeurStyle}
                            >
							    {thousandsSeparator(recapitulatif.total_actions, true)}
						    </span>
                        </div>

                        <div className={rowStyle}>

                        <span
                            className={textStyle}
                        >
							<em>dont action(s) cessible(s) : <strong>{thousandsSeparator(recapitulatif.actions_cessibles, true)}</strong></em>
						</span>
                        </div>
                    </div>

                    <div
                        className={contenuStyle}
                    >
                        <div className={rowStyle}>
						<span
                            className={textStyle}
                        >
							Valorisation* :
						</span>
                            <span
                                className={valeurStyle}
                            >
							{thousandsSeparator(recapitulatif.valorisation)} €
						</span>
                        </div>

                        <div className={rowStyle}>
                        <span
                            className={coursActionStyle}
                        >
							<em>* cours de l’action Gecina : {`${thousandsSeparator(Number(recapitulatif.montant_cours))} € au ${formatDate(recapitulatif.date_cours)}`}</em>
						</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecapitulatifAvoirs
