import React from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import masqueTelephone from '../../utils/masqueTelephone'
import Dialog from '../../components/dialog/Dialog'
import MaterialDialog from '@material-ui/core/Dialog'
import PopinEditRecoveryEmail from './components/PopinEditRecoveryEmail'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	blockContainer: {
		marginBottom: '10px',
		paddingBottom: '20px',
		borderBottom: '1px solid #707070'
	},
	marginTitleGrand: {
		marginBottom: '20px',

		'& p': {
			margin: '0px'
		}
	},
	marginTitlePetit: {
		marginBottom: '10px',

		'& p': {
			margin: '0px'
		}
	},
	marginBottomGrand: {
		marginBottom: '20px'
	}
}))

interface ModelDonneesPersonnelles {
	email_recuperation: string,
	email: string,
	telephone_mobile: string,
	telephone_fixe: string
}

interface AcceptingProps {
	tailleEcran: string,
	donneesPersonnelles: ModelDonneesPersonnelles,
	pays: string
}

type CoordonneesPersonnellesProps = WithStyles<typeof styles> & AcceptingProps

const CoordonneesPersonnelles: React.FC<CoordonneesPersonnellesProps> = (
	{
		classes,
		tailleEcran,
		donneesPersonnelles,
		pays
	}
) => {
	const customClasses = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [open, setOpen] = React.useState(false)

	return (
		<>
			<div
				className={
					[
						'row',
						customClasses.blockContainer
					].join(' ')
				}
			>
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Adresse mail de récupération d'identifiant
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{donneesPersonnelles.email_recuperation}
					</span>
					 
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text,
								classes.cursorPointer
							].join(' ')
						}
						onClick={() => setOpen(true)}
					>
                        (Modifier cette adresse)
                    </span>
				</div>

				<div className="col-xs-6"></div>
			</div>

			<div
				className={
					[
						classes.titleSize,
						classes.colorPrimary,
						classes.text,
						classes.bold,
						tailleEcran === 'grand' ? customClasses.marginTitleGrand : customClasses.marginTitlePetit
					].join(' ')
				}
			>
				<p>Coordonnées</p>
			</div>

			<div
				className={
					[
						'row',
						tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
					].join(' ')
				}
			>
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						Adresse mail de contact
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{donneesPersonnelles.email}
					</span>
				</div>

				<div className="col-xs-6"></div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						N° de téléphone mobile
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{pays.toLowerCase().includes('fr') ? masqueTelephone(donneesPersonnelles.telephone_mobile) : donneesPersonnelles.telephone_mobile}
					</span>
				</div>


				<div className="col-xs-12 col-sm-6">
					<span
						className={
							[
								classes.smallSize,
								classes.colorPrimary,
								classes.bold,
								classes.text
							].join(' ')
						}
					>
						N° de téléphone fixe
					</span>

					<br />

					<span
						className={
							[
								classes.colorPrimary,
								classes.textSize,
								classes.text,
								classes.regular
							].join(' ')
						}
					>
						{pays.toLowerCase().includes('fr') ? masqueTelephone(donneesPersonnelles.telephone_fixe) : donneesPersonnelles.telephone_fixe}
					</span>
				</div>
			</div>

			<MaterialDialog
				fullScreen={fullScreen}
				open={open}
				aria-labelledby="responsive-dialog-editRecoveryEmail"
				fullWidth
				maxWidth="md"
			>
				<Dialog
					hasButton={false}
					onCloseDialog={() => setOpen(false)}
				>
					<PopinEditRecoveryEmail onClose={() => setOpen(false)} />
				</Dialog>
			</MaterialDialog>
		</>
	)
}

const EnhancedCoordonneesPersonnelles = compose<CoordonneesPersonnellesProps, AcceptingProps>(
	withStyles(styles)
)(CoordonneesPersonnelles)

export default EnhancedCoordonneesPersonnelles
