import React from 'react'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import {compose} from 'recompose'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    marginTitleGrand: {
        marginBottom: 20,

        '& p': {
            margin: 0
        }
    },
    marginTitlePetit: {
        marginBottom: 10,

        '& p': {
            margin: 0
        }
    },
    marginBottomGrand: {
        marginBottom: 20
    },
    marginBottomPetit: {
        marginBottom: 50
    }
}))

interface ModelAdresseFiscale {
    adresse_fiscale_rue: string,
    adresse_fiscale_batiment: string,
    adresse_fiscale_complement: string,
    adresse_fiscale_code_postal: number | string,
    adresse_fiscale_commune: string,
    adresse_fiscale_pays: string
}

interface AcceptingProps {
    tailleEcran: string,
    adresseFiscale: ModelAdresseFiscale,
    type: string
}

type AdresseFiscaleProps = WithStyles<typeof styles> & AcceptingProps

const AdresseFiscale = (
    props: AdresseFiscaleProps
) => {
    const {
        classes,
        tailleEcran,
        adresseFiscale,
        type
    } = props

    const customClasses = useStyles()

    return (
        <>
            <div
                className={
                    [
                        classes.titleSize,
                        classes.colorPrimary,
                        classes.text,
                        classes.bold,
                        tailleEcran === 'grand' ? customClasses.marginTitleGrand : customClasses.marginTitlePetit
                    ].join(' ')
                }
            >
                {
                    type === 'postale' ? (
                        <p>Adresse postale</p>
                    ) : (
                        <p>Adresse fiscale</p>
                    )
                }
            </div>

            <div
                className={
                    [
                        'row',
                        tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
                    ].join(' ')
                }
            >
                <div className="col-xs-12 col-sm-6">
					<span
                        className={
                            [
                                classes.smallSize,
                                classes.colorPrimary,
                                classes.bold,
                                classes.text
                            ].join(' ')
                        }
                    >
						Rue
					</span>

                    <br/>

                    <span
                        className={
                            [
                                classes.colorPrimary,
                                classes.textSize,
                                classes.text,
                                classes.regular
                            ].join(' ')
                        }
                    >
						{adresseFiscale.adresse_fiscale_rue}
					</span>
                </div>

                <div className="col-xs-12 col-sm-6">
					<span
                        className={
                            [
                                classes.smallSize,
                                classes.colorPrimary,
                                classes.bold,
                                classes.text
                            ].join(' ')
                        }
                    >
						Bâtiment
					</span>

                    <br/>

                    <span
                        className={
                            [
                                classes.colorPrimary,
                                classes.textSize,
                                classes.text,
                                classes.regular
                            ].join(' ')
                        }
                    >
						{adresseFiscale.adresse_fiscale_batiment}
					</span>
                </div>
            </div>

            <div
                className={
                    [
                        'row',
                        tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
                    ].join(' ')
                }
            >
                <div className="col-xs-12 col-sm-6">
					<span
                        className={
                            [
                                classes.smallSize,
                                classes.colorPrimary,
                                classes.bold,
                                classes.text
                            ].join(' ')
                        }
                    >
						Complément
					</span>

                    <br/>

                    <span
                        className={
                            [
                                classes.colorPrimary,
                                classes.textSize,
                                classes.text,
                                classes.regular
                            ].join(' ')
                        }
                    >
						{adresseFiscale.adresse_fiscale_complement}
					</span>
                </div>

                <div className="col-xs-6"></div>
            </div>

            <div
                className={
                    [
                        'row',
                        tailleEcran === 'grand' ? customClasses.marginBottomGrand : ''
                    ].join(' ')
                }
            >
                <div className="col-xs-12 col-sm-6">
					<span
                        className={
                            [
                                classes.smallSize,
                                classes.colorPrimary,
                                classes.bold,
                                classes.text
                            ].join(' ')
                        }
                    >
						Code postal
					</span>

                    <br/>

                    <span
                        className={
                            [
                                classes.colorPrimary,
                                classes.textSize,
                                classes.text,
                                classes.regular
                            ].join(' ')
                        }
                    >
						{adresseFiscale.adresse_fiscale_code_postal}
					</span>
                </div>

                <div className="col-xs-12 col-sm-6">
					<span
                        className={
                            [
                                classes.smallSize,
                                classes.colorPrimary,
                                classes.bold,
                                classes.text
                            ].join(' ')
                        }
                    >
						Ville
					</span>

                    <br/>

                    <span
                        className={
                            [
                                classes.colorPrimary,
                                classes.textSize,
                                classes.text,
                                classes.regular
                            ].join(' ')
                        }
                    >
						{adresseFiscale.adresse_fiscale_commune}
					</span>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-sm-6">
					<span
                        className={
                            [
                                classes.smallSize,
                                classes.colorPrimary,
                                classes.bold,
                                classes.text
                            ].join(' ')
                        }
                    >
						Pays
					</span>

                    <br/>

                    <span
                        className={
                            [
                                classes.colorPrimary,
                                classes.textSize,
                                classes.text,
                                classes.regular
                            ].join(' ')
                        }
                    >
						{adresseFiscale.adresse_fiscale_pays}
					</span>
                </div>

                <div className="col-xs-6"></div>
            </div>
        </>
    )
}

const EnhancedAdresseFiscale = compose<AdresseFiscaleProps, AcceptingProps>(
    withStyles(styles)
)(AdresseFiscale)

export default EnhancedAdresseFiscale
