import React, {ChangeEvent, Dispatch} from 'react'
import {compose} from 'recompose'
import styles from '../../theme/styles'
import {Field, InjectedFormProps, reduxForm} from 'redux-form'
import EnhancedFormTextField from '../../components/fields/FormTextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '../../components/button/Button'
import {connect} from 'react-redux'
import {afficherSnackbar} from '../reducers/snackBar/actions'
import errorCatcher from '../../utils/errorCatcher'
import {makeStyles} from '@material-ui/core/styles'
import fetchFactory from '../../utils/fetchFactory'
import FormRichText from '../../components/fields/FormRichText'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyle = makeStyles((theme) => (
    {
        ...styles(theme),
        marginBottom: {
            marginBottom: 20,

            '& h1': {
                margin: 0
            }
        },
        marginButton: {
            marginBottom: 20,

            '& button': {
                minHeight: 54
            }
        },
        button: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 40
        },
        upload: {
            marginLeft: 11
        },
        formulaireText: {
            fontWeight: 800,
            color: theme.palette.blue.main,
            fontFamily: theme.typography.fontFamilyMonserrat,
            fontSize: 12
        }
    }
))

interface FormData {
    titre: string,
    accroche: string,
    presentation: string,
    actualite: string
    libelle_1: string,
    libelle_2: string,
    url_1: string,
    url_2: string,
}

interface ModelSnackBarData {
    id: string
    message: string,
    open: boolean
}

interface Store {
    afficherSnackBar: (snackBarData: ModelSnackBarData) => void,
    errorCatcher: (error: any, id: string) => void,
}

const NOM_FORMULAIRE: string = 'FormulaireAdministrationClubForm'
const MESSAGE_CONFIRMATION: string = 'Le contenu de la page du club des actionnaires a été modifié avec succès'
const TITRE_CLUB: string = 'Titre du Club des Actionnaires'
const ACCROCHE_CLUB: string = 'Accroche du Club des Actionnaires'
const PRESENTATION_CLUB: string = 'Bloc de présentation du Club des Actionnaires'
const TITRE_ACTUALITE_CLUB: string = 'Titre du bloc actualité du Club des Actionnaires'
const ACCROCHE_ACTUALITE_CLUB: string = 'Accroche du bloc actualité du Club des Actionnaires'
const ACTUALITE_CLUB: string = 'Bloc actualité du Club des Actionnaires'
const LIEN_EXTERNES: string = 'Liens externes'
const LIBELLES: string = 'Libellé lien '
const URLS: string = 'URL lien '
const ENREGISTRER: string = 'Enregistrer'
const ANNULER: string = 'Annuler'
const FORMULAIRE_ID: string = 'formulaire_administration'

type FormulaireAdministrationProps =
    InjectedFormProps<FormData>
    & Store

const FormulaireAdministrationClub: React.FC<FormulaireAdministrationProps> = (
    {
        afficherSnackBar,
        errorCatcher,
        handleSubmit,
        error,
        reset
    }
) => {

    const classes = useStyle()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [file, setFile] = React.useState<any>({encodedFile: ''})

    const inputRef = React.useRef(document.createElement('input'))

    const toBase64 = (fileData: File) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(fileData)
        reader.onload = () => resolve(
            {
                encodedFile: reader.result
            }
        )
        reader.onerror = error => reject(error)
    })
    const onFileSelect = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement
        const fileData: File = (target.files as FileList)[0]

        toBase64(fileData)
            .then(file => {
                setFile(file)
            })
    }
    const rowStyle: ClassValue = classNames(
        'row',
        classes.marginBottom
    )

    const links: React.ReactChild[] = []

    for (let i = 1; i <= 2; i++) {
        links.push(
            <div
                className={rowStyle}
                key={i}
            >
                <div className="col-xs-12 col-sm-6">
                    <Field
                        name={`libelle_${i}`}
                        component={EnhancedFormTextField}
                        label={LIBELLES + i}
                        id={`libelle_${i}`}
                    />
                </div>

                <div className="col-xs-12 col-sm-6">
                    <Field
                        name={`url_${i}`}
                        component={EnhancedFormTextField}
                        label={URLS + i}
                        id={`url_${i}`}
                    />
                </div>
            </div>
        )
    }

    const onSubmit = (values: FormData) => {
        setLoading(true)

        const data = JSON.stringify({...values})
        fetchFactory('/api/bloc',
            {
                method: 'PUT',
                body: data
            }
        )
            .then(
                () => {
                    if (file.encodedFile !== '') {
                        fetchFactory('/api/admin/document/ajout/formulaire',
                            {
                                method: 'POST',
                                contentType: 'multipart/form-data',
                                body: JSON.stringify(file)
                            })
                            .then(
                                () => {
                                    afficherSnackBar(
                                        {
                                            id: FORMULAIRE_ID,
                                            message: MESSAGE_CONFIRMATION,
                                            open: true
                                        }
                                    )
                                }
                            )
                            .catch(
                                (error: any) => {
                                    errorCatcher(error, FORMULAIRE_ID)
                                }
                            )
                    }
                }
            )
            .then(
                () => {
                    afficherSnackBar(
                        {
                            id: FORMULAIRE_ID,
                            message: MESSAGE_CONFIRMATION,
                            open: true
                        }
                    )
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, FORMULAIRE_ID)
                }
            )
            .finally(
                () => {
                    setLoading(false)

                }
            )

    }

    const cancelForm = () => {
        reset()
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
        >
            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="titreClub"
                        component={EnhancedFormTextField}
                        label={TITRE_CLUB}
                        id="titreClub"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="accrocheClub"
                        component={EnhancedFormTextField}
                        label={ACCROCHE_CLUB}
                        id="accrocheClub"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="presentationClub"
                        component={FormRichText}
                        label={PRESENTATION_CLUB}
                        id="presentationClub"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <span className={classes.formulaireText}>
                        Formulaire d'adhésion au club des actionnaires
                    </span>
                    <div className="row col-xs-4">
                        <input type="file" hidden onChange={onFileSelect} ref={inputRef}/>
                        <Button
                            disabled={false}
                            type="button"
                            onClick={() => inputRef.current.click()}>
                            <span className={classes.button}>
                                <img src="/img/picto_download.svg" alt="Picto download"/>
                                <span className={classes.upload}>
                                    {
                                        file.encodedFile.length > 0 ? 'Formulaire chargé' : 'Charger le formulaire d\'adhésion'
                                    }

                                </span>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="titreActualiteClub"
                        component={EnhancedFormTextField}
                        label={TITRE_ACTUALITE_CLUB}
                        id="titreActualiteClub"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="accrocheActualiteClub"
                        component={EnhancedFormTextField}
                        label={ACCROCHE_ACTUALITE_CLUB}
                        id="accrocheActualiteClub"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="actualiteClub"
                        component={FormRichText}
                        label={ACTUALITE_CLUB}
                        id="actualiteClub"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <h1
                        className={classNames(
                            classes.text,
                            classes.titleSize,
                            classes.colorPrimary,
                            classes.bold
                        )
                        }
                    >
                        {LIEN_EXTERNES}
                    </h1>
                </div>
            </div>

            {links}


            <div className="row middle-xs center-xs reverse-xs">
                <div
                    className={classNames(
                        'col-xs-12 col-sm-4 last-sm',
                        classes.marginButton
                    )
                    }
                >
                    {
                        loading ? (
                            <CircularProgress color="primary"/>
                        ) : (
                            <Button
                                disabled={false}
                                type="submit"
                            >
                                {ENREGISTRER}
                            </Button>
                        )
                    }
                </div>

                <div
                    className={classNames(
                        'col-xs-12 col-sm-4',
                        classes.marginButton
                    )
                    }
                >
                    <Button
                        type="button"
                        disabled={false}
                        onClick={cancelForm}
                    >
                        {ANNULER}
                    </Button>
                </div>
            </div>
            {
                error && <strong>{error}</strong>
            }
        </form>
    )
}

const validate = (values: FormData) => {
    const errors: FormData = {
        titre: '',
        accroche: '',
        presentation: '',
        actualite: '',
        libelle_1: '',
        url_1: '',
        libelle_2: '',
        url_2: ''
    }

    if (!values.titre) {
        errors.titre = 'Requis*'
    }
    if (!values.presentation) {
        errors.presentation = 'Requis*'
    }

    for (let i = 1; i <= 2; i++) {
        const libelle: string = `libelle_${i}`
        const url: string = `url_${i}`

        if (!values[libelle as keyof FormData] && values[url as keyof FormData]) {
            errors[libelle as keyof FormData] = 'Requis*'
        }
        if (!values[url as keyof FormData] && values[libelle as keyof FormData]) {
            errors[url as keyof FormData] = 'Requis*'
        }
    }

    return errors
}

const mapStateToProps = (state: any) => {
    return {
        initialValues: state.formulaireAdministration
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        afficherSnackBar: (snackBarData: ModelSnackBarData) => {
            dispatch(afficherSnackbar(snackBarData))
        },
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedFormulaireAdministrationClub = compose<FormulaireAdministrationProps, {}>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: NOM_FORMULAIRE,
        validate,
        enableReinitialize: true
    })
)(FormulaireAdministrationClub)

export default EnhancedFormulaireAdministrationClub
