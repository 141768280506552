import React from 'react'
import styles from '../../../theme/styles'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import EnhancedTableauSimple from '../../../components/table/TableauSimple'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import {makeStyles} from '@material-ui/core/styles'
import formatDate from '../../../utils/formatDate'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    margin: {
        marginBottom: 20,
        marginLeft: 40
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}))

interface ModelDroitsStock {
    id: number,
    libelle_plan: string,
    quantite: number
    prix_offre?: number,
    date_debut_plan?: Date,
    date_fin_plan?: Date
}

interface ModelColonnes {
    value: string,
    label: string,
    type: string,
    width: string,
    render?: (row: ModelDroitsStock) => React.ReactChild
}

interface AcceptingProps {
    droitsStock: ModelDroitsStock[],
    telechargement: (id: number, type: string) => void
}

type ListeDroitsStockGrandProps = AcceptingProps

const ListeDroitsStockGrand = (
    props: ListeDroitsStockGrandProps
) => {
    const {
        droitsStock,
        telechargement
    } = props
    const classes = useStyles()

    const colonnes: ModelColonnes[] = [
        {
            value: 'libelle_plan',
            label: 'Plan',
            type: 'string',
            width: '22%'
        },
        {
            value: 'date_debut_plan',
            label: 'Date de début de plan',
            type: 'date',
            width: '13%',
            render: (row: ModelDroitsStock) => (
                <div>
                    {row.date_debut_plan !== undefined ? formatDate(row.date_debut_plan) : ''}
                </div>
            )
        },
        {
            value: 'date_fin_plan',
            label: 'Date de fin de plan',
            type: 'date',
            width: '13%',
            render: (row: ModelDroitsStock) => (
                <div>
                    {row.date_fin_plan !== undefined ? formatDate(row.date_fin_plan) : ''}
                </div>
            )
        },
        {
            value: 'prix_offre',
            label: 'Prix d\'offre',
            type: 'number',
            width: '13%',
            render: (row: ModelDroitsStock) => (
                <div className={classes.noWrap}>
                    {row.prix_offre !== undefined ? `${thousandsSeparator(row.prix_offre)} €` : ''}
                </div>
            )
        },
        {
            value: 'quantite',
            label: 'Quantité à exercer',
            type: 'number',
            width: '13%',
            render: (row: ModelDroitsStock) => (
                <div>
                    {thousandsSeparator(row.quantite, true)}
                </div>
            )
        },
        {
            value: 'icon',
            label: 'Règlement du plan',
            type: 'icon',
            width: '8%',
            render: (row: ModelDroitsStock) => (
                <div>
                    {row.libelle_plan !== 'Total' ? <PictureAsPdfOutlinedIcon
                            onClick={() => telechargerDocument(row.id)}
                            className={
                                classNames(
                                    [
                                    classes.colorPrimary,
                                    classes.cursorPointer
                                ])
                            }
                        />
                        : ''}
                </div>
            )
        }
    ]

    const telechargerDocument = (id: number) => {
        telechargement(id, 'stock')
    }

    const titreStyle: ClassValue = classNames(
        classes.titleSize,
        classes.colorPrimary,
        classes.title,
        classes.bold,
        classes.margin
    )

    return (
        <>
            <div
                className={titreStyle}
            >
                <p>Droits à stock-options</p>
            </div>

            <EnhancedTableauSimple
                colonnes={colonnes}
                lignes={droitsStock}
            />
        </>
    )
}

export default ListeDroitsStockGrand
