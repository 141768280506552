import snackBarReducer from '../modules/reducers/snackBar/reducers'
import formulaireCoordonneesBancairesReducer from '../modules/reducers/formulaireCoordonneesBancaires/reducers'
import formulaireDonneesPersonnellesReducer from '../modules/reducers/formulaireDonneesPersonnelles/reducers'
import droitsActionsReducer from '../modules/reducers/droitsActions/reducers'
import disponibiliteAvoirsReducer from '../modules/reducers/disponibiliteAvoirs/reducers'
import utilisateurReducer from '../modules/reducers/utilisateur/reducers'
import formulaireAdministrationReducer from '../modules/reducers/formulaireAdministration/reducers'
import compteTitresReducer from '../modules/reducers/compteTitres/reducers'

export default {
	snackBar: snackBarReducer,
	formulaireCoordonneesBancaires: formulaireCoordonneesBancairesReducer,
	formulaireDonneesPersonnelles: formulaireDonneesPersonnellesReducer,
	droitsActions: droitsActionsReducer,
	disponibiliteAvoirs: disponibiliteAvoirsReducer,
	utilisateur: utilisateurReducer,
	formulaireAdministration: formulaireAdministrationReducer,
	compteTitres: compteTitresReducer
}
