import React, {Dispatch, useEffect} from 'react'
import fetchFactory from '../../utils/fetchFactory'
import {compose} from 'recompose'
import styles from '../../theme/styles'
import {connect} from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import {makeStyles} from '@material-ui/core/styles'
import {useMediaQuery} from 'react-responsive'
import parse from 'html-react-parser'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import {ClassValue} from 'classnames/types'

const useStyle = makeStyles((theme) => ({
        ...styles(theme),
        chersActionnairesGrandStyle: {
            marginLeft: 30,
            marginTop: 40,
            fontSize: 36,
            fontFamily: theme.typography.fontFamilyBlackerDisplay,
            color: theme.palette.blue.main
        },
        chersActionnairesPetitStyle: {
            height: 49,
            marginBottom: 55,
            marginTop: 30,
            fontFamily: theme.typography.fontFamilyBlackerDisplay,
            fontSize: 28,
            fontWeight: 800,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: theme.palette.blue.main
        },
        rectangleHautGrandStyle: {
            zIndex: 1,
            height: 'fit-content',
            position: 'relative',
            padding: '49px 102px 49px 140px',
            margin: '27px 50px',
            backgroundColor: '#ffffff',

            '& h2': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 18,
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.67,
                letterSpacing: 'normal',
                textAlign: 'justify',
                color: theme.palette.blue[80]
            },
            '& a': {
                color: `${theme.palette.blue[80]} !important`
            }
        },
        rectangleHautPetitStyle: {
            zIndex: 1,
            height: 'fit-content',
            position: 'relative',
            padding: '50px 20px 50px 20px',
            marginTop: 27,
            backgroundColor: '#ffffff',

            '& h2': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 18,
                fontWeight: 300,
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 1.67,
                letterSpacing: 'normal',
                textAlign: 'justify',
                color: theme.palette.blue[80]
            },
            '& a': {
                color: `${theme.palette.blue[80]} !important`
            }
        },
        rectangleBleuGrandStyle: {
            position: 'relative',
            zIndex: 0,
            margin: '-150px 0 0 885px',
            width: 140,
            height: 250,
            backgroundColor: theme.palette.blue.main
        },
        rectangleBleuPetitStyle: {
            position: 'relative',
            zIndex: 0,
            margin: '-64% 0 0 87%',
            width: 50,
            height: 250,
            backgroundColor: theme.palette.blue.main
        },
        rectangleIconGrandStyle: {
            transform: 'scale(0.7)',
            position: 'absolute',
            left: -75,
            padding: '39px 38px 38px 39px',
            backgroundColor: '#c2ad8a',

            '& img': {
                objectFit: 'contain'
            }
        },
        rectangleIconPetitStyle: {
            position: 'absolute',
            transform: 'scale(0.5)',
            top: -70,
            left: -60,
            padding: '39px 38px 38px 39px',
            backgroundColor: '#c2ad8a',

            '& img': {
                objectFit: 'contain'
            }
        },
        leMotBrunelGrandStyle: {
            position: 'relative',
            height: 'fit-content',
            width: '100%',
            marginTop: 100,
            backgroundColor: '#ffffff',

            '& h1:first-of-type': {
                fontFamily: theme.typography.fontFamilyBlackerDisplay,
                fontSize: 24,
                fontWeight: 900,
                lineHeight: 1.17,
                color: theme.palette.blue.main,
                position: 'relative',
                left: 100,
                top: 135
            },
            '& h1': {
                fontFamily: theme.typography.fontFamilyBlackerDisplay,
                fontSize: 24,
                fontWeight: 900,
                lineHeight: 1.17,
                color: theme.palette.blue.main,
                position: 'relative',
                left: 217,
                top: 135
            },
            '& h2:first-of-type': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 20,
                color: '#c2ad8a',
                position: 'relative',
                left: 420,
                top: 45,
                fontWeight: 'normal'

            },
            '& h2': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 20,
                color: '#c2ad8a',
                position: 'relative',
                left: 420,
                top: 49,
                fontWeight: 'normal',
                width: 'fit-content'

            },
            '& > p': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 20,
                fontWeight: 500,
                fontStyle: 'italic',
                lineHeight: 1.8,
                color: theme.palette.blue.main,
                margin: '110px 100px',
                paddingBottom: 20,

                '& > p': {
                    margin: 0,

                    '& .lastQuote': {
                        fontSize: 32,
                        fontFamily: theme.typography.fontFamilyMonserrat,
                        fontWeight: 500
                    }
                }
            },
            '& img:first-child': {
                height: 199,
                position: 'absolute',
                margin: '-100px 0 0 102px'
            },
            '& img': {
                height: 199,
                position: 'absolute',
                margin: '-100px 0 0 341px'
            },
            '& a': {
                color: `${theme.palette.blue[80]} !important`
            }
        },
        leMotBrunelPetitStyle: {
            position: 'relative',
            height: 'fit-content',
            width: '100%',
            marginTop: 100,
            marginBottom: 150,
            backgroundColor: '#ffffff',

            '& h1:first-of-type': {
                fontFamily: 'erProDis-Hv',
                fontSize: 21,
                fontWeight: 900,
                lineHeight: 1.17,
                color: theme.palette.blue.main,
                position: 'relative',
                left: 10,
                top: 84
            },
            '& h1': {
                fontFamily: 'erProDis-Hv',
                fontSize: 21,
                fontWeight: 900,
                lineHeight: 1.17,
                color: theme.palette.blue.main,
                position: 'relative',
                left: 10,
                top: 115
            },
            '& h2:first-of-type': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 14,
                color: '#c2ad8a',
                position: 'relative',
                left: 10,
                top: 35

            },
            '& h2': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 14,
                color: '#c2ad8a',
                position: 'relative',
                left: 10,
                top: 76

            },
            '& > p': {
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 19,
                fontWeight: 500,
                fontStyle: 'italic',
                lineHeight: 1.8,
                color: theme.palette.blue.main,
                margin: '130px 20px',
                paddingBottom: 20,
            },
            '& img:first-of-type': {
                transform: 'scale(0.9)',
                height: 160,
                position: 'absolute',
                margin: '-80px 0 0 0',
                top: -20
            },
            '& img': {
                transform: 'scale(0.9)',
                height: 160,
                position: 'absolute',
                margin: '-80px 0 0 164px',
                top: -20
            },
            '& a': {
                color: `${theme.palette.blue[80]} !important`
            }
        },
        backgroundFilter: {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: 1,
            minHeight: 110,
            backgroundColor: theme.palette.primary.main,
            opacity: 0.6
        },
        spinnerStyle: {
            marginTop: 50,
            textAlign: 'center',

            '& div': {
                width: '150px !important',
                height: '150px !important'
            }
        },
        guillemetsFondGrandStyle: {
            width: 115,
            height: 288,
            margin: '42px 0 0 102px',
            opacity: '0.2',
            fontFamily: 'BlackerProDis-Hv',
            fontSize: 200,
            fontWeight: 900,
            color: '#c2ad8a',
            position: 'absolute'
        },
        guillemetsFondPetitStyle: {
            width: 115,
            height: 288,
            margin: '75px 0 0 50px',
            opacity: 0.2,
            fontFamily: 'BlackerProDis-Hv',
            fontSize: 200,
            fontWeight: 900,
            color: '#c2ad8a',
            position: 'absolute'
        },
        imageContainer: {
            height: 374,
            overflow: 'hidden',
            position: 'relative',
            top: 15,
            marginBottom: 70,

            '& h1': {
                zIndex: 1,
                fontFamily: theme.typography.fontFamilyBlackerDisplay,
                fontSize: 48,
                fontWeight: 800,
                lineHeight: 1.25,
                color: '#FFFFFF',
                textAlign: 'left',
                padding: '60px 0 0 40px',
                margin: '0 180px 0 0',
                width: 690,
                height: 'fit-content'

            },
            '& p': {
                zIndex: 1,
                margin: '0 0 0 40px',
                width: 600,
                height: 'fit-content',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                textAlign: 'left',
                fontFamily: theme.typography.fontFamilyMonserrat,
                color: '#FFFFFF'
            }
        },
        imageCenterer: {
            position: 'absolute',
            height: '-webkit-fill-available',
            padding: 0,

            '& img': {
                display: 'block',
                margin: '0 auto',
                padding: 0,
                height: '100%'
            }
        },
        imgHeaderContainer: {
            overflow: 'hidden',
            position: 'relative',
            marginTop: 17,
            height: 387,

            '& h1': {
                position: 'absolute',
                maxWidth: 660,
                fontSize: 48,
                fontWeight: 800,
                color: 'white',
                fontFamily: theme.typography.fontFamilyBlackerDisplay,
                left: 51,
                top: 57,
                textAlign: 'left'
            },

            ' & h2': {
                position: 'absolute',
                maxWidth: 674,
                maxHeight: 62,
                lineHeight: 1.47,
                overflow: 'hidden',
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 20,
                color: 'white',
                left: 51,
                bottom: 54,
                fontWeight: 'normal',
                textAlign: 'left'
            },
            '& img': {
                height: '-webkit-fill-available',
                objectFit: 'cover'
            },
            '& span': {
                position: 'absolute',
                backgroundColor: 'black',
                opacity: 0.5,
                width: '100%',
                height: '100%'
            }
        },
        imgHeaderContainerSmallScreen: {
            overflow: 'hidden',
            position: 'relative',
            marginTop: 17,
            height: 387,

            '& h1': {
                position: 'absolute',
                maxWidth: 660,
                fontSize: 36,
                fontWeight: 800,
                color: 'white',
                fontFamily: theme.typography.fontFamilyBlackerDisplay,
                left: 28,
                top: -23,
                textAlign: 'left'
            },

            ' & h2': {
                position: 'absolute',
                maxWidth: 285,
                maxHeight: 112,
                lineHeight: 1.47,
                overflow: 'hidden',
                fontFamily: theme.typography.fontFamilyMonserrat,
                fontSize: 14,
                color: 'white',
                left: 28,
                bottom: 14,
                fontWeight: 'normal',
                textAlign: 'left'
            },
            '& img': {
                height: '-webkit-fill-available',
                objectFit: 'cover'
            },
            '& span': {
                position: 'absolute',
                backgroundColor: 'black',
                opacity: 0.5,
                width: '100%',
                height: '100%'
            }
        }
    }
))

interface ModelAdministration {
    titre: string,
    accroche: string,
    presentation: string,
    leMotBrunel: string
}

interface Store {
    errorCatcher: (error: any, id: string) => void
}

const chersActionnaires: string = 'Chers actionnaires,'

type AccueilProps = Store

const Accueil = (
    props: AccueilProps
) => {
    const {
        errorCatcher
    } = props
    const classes = useStyle()

    const [bloc, setBloc] = React.useState<ModelAdministration>()
    const [loadingBlocs, setLoadingBlocs] = React.useState<boolean>(false)

    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )

    useEffect(
        () => {
            setLoadingBlocs(true)

            fetchFactory('/api/blocs')
                .then(
                    (accueil: any) => {

                        setBloc(
                            accueil
                        )
                    }
                )
                .catch(
                    (error) => {
                        errorCatcher(error, 'accueil')
                    }
                )
                .finally(
                    () => setLoadingBlocs(false)
                )
        }, [errorCatcher]
    )

    const chersActionnairesStyle: ClassValue = classNames(
        'row col-xs-12',
        isLargeScreen ? classes.chersActionnairesGrandStyle : classes.chersActionnairesPetitStyle
    )

    const rectangleHaut: ClassValue = classNames(
        isLargeScreen ? classes.rectangleHautGrandStyle : classes.rectangleHautPetitStyle
    )

    const rectangleBleu: ClassValue = classNames(
        isLargeScreen ? classes.rectangleBleuGrandStyle : classes.rectangleBleuPetitStyle
    )

    const rectangleIconStyle: ClassValue = classNames(
        isLargeScreen ? classes.rectangleIconGrandStyle : classes.rectangleIconPetitStyle
    )

    const motBrunelStyle: ClassValue = classNames(
        isLargeScreen ? classes.leMotBrunelGrandStyle : classes.leMotBrunelPetitStyle
    )

    const guillemetsStyle: ClassValue = classNames(
        isLargeScreen ? classes.guillemetsFondGrandStyle : classes.guillemetsFondPetitStyle
    )

    const imageHeaderClass: ClassValue = classNames(
        'row col-xs-12 center-xs',
        isLargeScreen ? classes.imgHeaderContainer : classes.imgHeaderContainerSmallScreen
    )

    return (
        <>
            {
                loadingBlocs ? (
                    <div className={classes.spinnerStyle}>
                        <CircularProgress color="primary"/>
                    </div>
                ) : (
                    <>
                        <div className={imageHeaderClass}>
                            <img src="/img/accueil-header.png" alt="Header page"/>
                            <span></span>
                            <h1>
                                {
                                    bloc && bloc.titre.length > 0 && bloc.accroche.length > 0 &&
                                    parse(bloc.titre)
                                }
                            </h1>
                            <h2>
                                {
                                    bloc && bloc.accroche.length > 0
                                    && parse(bloc.accroche)
                                }
                            </h2>
                        </div>

                        <div className={chersActionnairesStyle}>
                            {chersActionnaires}
                        </div>

                        {bloc && bloc.presentation.length > 0 &&
                        <>
                            <div className={rectangleHaut}>
                                <div className={rectangleIconStyle}>
                                    <img src="/img/pictos-profil@3x.png" alt="Picto profil"/>
                                </div>
                                <h2>
                                    {
                                        parse(bloc?.presentation.replace(/href/g, 'target="_blank" href') || '')
                                    }
                                </h2>
                            </div>
                            <div className={rectangleBleu}/>
                        </>
                        }
                        {
                            bloc && bloc.leMotBrunel.length > 0 &&
                            <div className={motBrunelStyle}>
                                <img src="/img/JB.jpg" alt="Jérôme Brunel"/>
                                <img src="/img/Benat_Ortega.jpg" alt="Beñat Ortega"/>
                                <h1>Le mot de Jérôme Brunel</h1>
                                <h1>et Beñat Ortega</h1>
                                <h2>Président du Conseil d'Administration</h2>
                                <h2>Directeur Général</h2>
                                <div className={guillemetsStyle}>
                                    ‘‘
                                </div>
                                <p>
                                    {
                                        parse(bloc?.leMotBrunel.replace(/href/g, 'target="_blank" href') || '')
                                    }
                                </p>
                            </div>

                        }
                    </>
                )
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedAccueil = compose<AccueilProps, {}>(
    connect(null, mapDispatchToProps)
)(Accueil)

export default EnhancedAccueil