import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'

const useStyles = makeStyles(
	(theme) => (
		{
			emptyStateContainer: {
				'& h1': {
					color: theme.palette.primary.main,
					...styles(theme).titleSize,
					...styles(theme).semiBold
				}
			}
		}
	)
)

const EmptyState = () => {
	const classes = useStyles()

	return (
		<div className={classes.emptyStateContainer}>
			<h1>Votre historique ne comporte pas d'opération</h1>
		</div>
	)
}

export default EmptyState
