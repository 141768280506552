import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'

const useStyles = makeStyles(
	(theme) => ({
		...styles(theme)
	})
)

interface AcceptingProps {
	label: string,
	value: any
}

type ReadOnlyValueProps = AcceptingProps

const ReadOnlyValue: React.FC<ReadOnlyValueProps> = (
	{
		label,
		value
	}
) => {
	const classes = useStyles()
	return (
		<>
			<span
				className={
					[
						classes.smallSize,
						classes.colorPrimary,
						classes.bold,
						classes.text
					].join(' ')
				}
			>
				{label}
			</span>

			<br />

			<span
				className={
					[
						classes.colorPrimary,
						classes.textSize,
						classes.text,
						classes.regular
					].join(' ')
				}
			>
				{value}
			</span>
		</>
	)
}

export default ReadOnlyValue
