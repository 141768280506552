import { formulaireVosMontantsEnum } from '../enums/formulaireVosMontantsEnum'

export interface FormulaireVosMontantsModel {
	[formulaireVosMontantsEnum.exercice]: number
}

export interface DetailsFiscaliteModel {
	M1: number | null,
	M2: number | null,
	M3: number | null,
	M4: number | null,
	M5: number | null,
	M6: number | null,
	M7: number | null
}

export const INITIAL_DETAILS_FISCALITE: DetailsFiscaliteModel = {
	M1: null,
	M2: null,
	M3: null,
	M4: null,
	M5: null,
	M6: null,
	M7: null
}