import React, {Dispatch, useEffect} from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {compose} from 'recompose'
import EnhancedBodyCoordonneesBancaires from './BodyCoordonneesBancaires'
import EnhancedBoutonCoordonneesBancaires from './BoutonCoordonneesBancaires'
import MaterialDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '../../components/dialog/Dialog'
import EnhancedFormulaireCoordonneesBancaires from './formulaireCoordonneesBancaires/FormulaireCoordonneesBancaires'
import fetchFactory from '../../utils/fetchFactory'
import {connect} from 'react-redux'
import {ajouterFormulaireCoordonneesBancaires} from '../reducers/formulaireCoordonneesBancaires/actions'
import errorCatcher from '../../utils/errorCatcher'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    marginTopGrand: {
        marginTop: 20
    },
    marginTopPetit: {
        marginTop: 30
    },
    marginButtonGrand: {
        marginTop: 30
    },
    marginButtonPetit: {
        marginTop: 20
    },
    spinnerStyle: {
        textAlign: 'center',

        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    }
}))

interface ModelCoordonnees {
    mode_reglement: string,
    code_bic: string,
    code_iban: string,
    domiciliation_bancaire: string
}

interface AcceptingProps {
    tailleEcran: string
}

interface Store {
    ajouterFormulaireCoordonneesBancaires: (coordonneesBancaires: ModelCoordonnees) => void,
    errorCatcher: (error: any, id: string) => void
}

type CorpsCoordonneesBancairesProps = AcceptingProps & Store

const CorpsCoordonneesBancaires: React.FC<CorpsCoordonneesBancairesProps> = (
    {
        tailleEcran,
        ajouterFormulaireCoordonneesBancaires,
        errorCatcher
    }
) => {

    const customClasses = useStyles()

    const [coordonnees, setCoordonnees] = React.useState<ModelCoordonnees>(
        {
            mode_reglement: '',
            code_bic: '',
            code_iban: '',
            domiciliation_bancaire: ''
        }
    )
    const [loading, setLoading] = React.useState<boolean>(true)

    const ibanFormat = (iban: string) => {
        let ibanSpace: string = ''
        const ibanBase: string = iban.replace(/\s/g, '')
        for (let i = 0; i < ibanBase.length; i += 4) {
            i + 4 >= ibanBase.length ? ibanSpace += ibanBase.substr(i, 4) :
                ibanSpace += ibanBase.substr(i, 4) + ' '
        }
        return ibanSpace
    }

    useEffect(() => {
        fetchFactory('/api/coordonnees/bancaires')
            .then(
                (coordonnees_bancaires: any) => {
                    setCoordonnees(
                        {
                            mode_reglement: coordonnees_bancaires.mode_reglement,
                            code_bic: coordonnees_bancaires.bic ? coordonnees_bancaires.bic : '-',
                            code_iban: coordonnees_bancaires.iban ? coordonnees_bancaires.iban : '-',
                            domiciliation_bancaire: coordonnees_bancaires.domiciliation_bancaire ? coordonnees_bancaires.domiciliation_bancaire : '-'
                        }
                    )
                    ajouterFormulaireCoordonneesBancaires(
                        {
                            mode_reglement: coordonnees_bancaires.mode_reglement,
                            code_bic: coordonnees_bancaires.bic ? coordonnees_bancaires.bic : '',
                            code_iban: coordonnees_bancaires.iban ? ibanFormat(coordonnees_bancaires.iban) : '',
                            domiciliation_bancaire: coordonnees_bancaires.domiciliation_bancaire ? coordonnees_bancaires.domiciliation_bancaire : ''
                        }
                    )
                },
                (error: any) => {
                    errorCatcher(error, 'coordonnees_bancaires')
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, 'coordonnees_bancaires')
                }
            )
            .finally(
                () => {
                    setLoading(false)
                }
            )
    }, [ajouterFormulaireCoordonneesBancaires, errorCatcher])

    // Dialog's props
    const [open, setOpen] = React.useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <>
            {
                loading ? (
                    <div className={customClasses.spinnerStyle}>
                        <CircularProgress color="primary"/>
                    </div>
                ) : (
                    <div>
                        <div
                            className={classNames(
                                    'row',
                                    tailleEcran === 'grand' ? customClasses.marginTopGrand : customClasses.marginTopPetit
                               )}
                        >
                            <div className="col-xs-12">
                                <EnhancedBodyCoordonneesBancaires
                                    tailleEcran={tailleEcran}
                                    coordonnees={coordonnees}
                                />
                            </div>
                        </div>

                        <div
                            className={classNames(
                                'row center-xs',
                                tailleEcran === 'grand' ? customClasses.marginButtonGrand : customClasses.marginButtonPetit
                            )}
                        >
                            <div className="col-xs-12 col-sm-5">
                                <EnhancedBoutonCoordonneesBancaires openDialog={() => setOpen(true)}/>
                            </div>
                        </div>
                    </div>
                )
            }

            <MaterialDialog
                fullScreen={fullScreen}
                open={open}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <Dialog
                    hasButton={false}
                    onCloseDialog={() => setOpen(false)}
                >
                    <EnhancedFormulaireCoordonneesBancaires
                        closeDialog={() => setOpen(false)}
                        tailleEcran={tailleEcran}
                    />
                </Dialog>
            </MaterialDialog>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        ajouterFormulaireCoordonneesBancaires: (coordonneesBancaires: ModelCoordonnees) => {
            dispatch(ajouterFormulaireCoordonneesBancaires(coordonneesBancaires))
        },
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedCorpsCoordonneesBancaires = compose<CorpsCoordonneesBancairesProps, AcceptingProps>(
    connect(null, mapDispatchToProps)
)(CorpsCoordonneesBancaires)

export default EnhancedCorpsCoordonneesBancaires
