import React from 'react'
import EnhancedListeActionsPetit from './ListeActionsPetit'
import { compose } from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../../theme/styles'

interface ModelDisponibilites {
	id: number,
	date_disponibilite: Date,
	typologie: string,
	actions: string,
	prix_acquisition: number
}

interface ModelIndisponibilites {
	id: number,
	date_disponibilite: Date,
	actions: string,
	prix_acquisition: number,
	message: string
}

interface AcceptingProps {
	disponibilites: ModelDisponibilites[],
	indisponibilites: ModelIndisponibilites[]
}

type CorpsDisponibiliteAvoirsPetitProps = AcceptingProps

const CorpsDisponibiliteAvoirsPetit = (
	props: CorpsDisponibiliteAvoirsPetitProps
) => {
	const {
		disponibilites,
		indisponibilites
	} = props

	return (
		<>
			<EnhancedListeActionsPetit
				disponibilites={disponibilites}
				indisponibilites={indisponibilites}
			/>
		</>
	)
}

const EnhancedCorpsDisponibiliteAvoirsPetit = compose<CorpsDisponibiliteAvoirsPetitProps, AcceptingProps>(
	withStyles(styles)
)(CorpsDisponibiliteAvoirsPetit)

export default EnhancedCorpsDisponibiliteAvoirsPetit
