export enum conditionEnum {
	onMarket = 'onMarket',
	bestLimit = 'bestLimit',
	onGoingLimited = 'onGoingLimited'
}

export const CONDITION_LABEL = {
	onMarket: 'Au marché',
	bestLimit: 'À la meilleure limite',
	onGoingLimited: 'Au cours limité de'
}
