import React from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import CorpsDetailAvoirsGrand from './grandEcran/CorpsDetailAvoirsGrand'
import CorpsDetailAvoirsPetit from './petitEcran/CorpsDetailAvoirsPetit'
import Onglets from '../../components/onglets/Onglets'

const titre: string = 'Vos avoirs'

const DetailAvoirs = () => {
    return (
        <>
            <EnhancedEntete titre={titre}/>
            <Onglets/>

            <MediaQuery
                maxWidth={1024}
            >

                {
                    (match) => {
                        return match ? (
                            <>
                                <CorpsDetailAvoirsPetit/>
                            </>
                        ) : (
                            <>
                                <CorpsDetailAvoirsGrand/>
                            </>
                        )
                    }
                }
            </MediaQuery>
        </>
    )
}

export default DetailAvoirs
