import React from 'react'
import EnhancedListeTitresPetit from './ListeTitresPetit'
import EnhancedListeBoutonsPetit from './ListeBoutonsPetit'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'

const useStyles = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            containerStyle: {
                padding: 0,
                borderTop: '1px solid #D8D8D8'
            },
            backgroundColor: {
                backgroundColor: 'white'
            },
            paddingTop: {
                paddingTop: 30
            }
        }
    )
)

const CorpsDetailAvoirsPetit = () => {

    const classes = useStyles()

    const containerDetails: ClassValue = classNames(
        'col-xs-12',
        classes.containerStyle,
        classes.backgroundColor
    )

    const boutonStyle: ClassValue = classNames(
        'row col-xs-12 end-xs',
        classes.paddingTop
    )
    return (
        <>
            <div className="row">
                <div className={containerDetails}>
                    <EnhancedListeTitresPetit/>
                    <div className={boutonStyle}>
                        <EnhancedListeBoutonsPetit/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorpsDetailAvoirsPetit