import React, {Dispatch} from 'react'
import arrayMutators from 'final-form-arrays'
import {Field, Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import styles from '../../../theme/styles'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import Button from '../../../components/button/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import errorCatcher from '../../../utils/errorCatcher'
import {afficherSnackbar} from '../../reducers/snackBar/actions'
import EnhancedFormTextField from '../../../components/fields/FormTextField'
import FormSelect from '../../../components/fields/FormSelect'
import MenuItem from '@material-ui/core/MenuItem'
import FormFichier from '../../../components/fields/FormFichier'
import fetchFactory from '../../../utils/fetchFactory'
import * as iban from '../../../utils/iban'
import masqueIban from '../../../utils/masqueIban'
import Header from '../../../components/dialog/Header'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    containerLargeScreen: {
        width: '80%',
        margin: 'auto',
        marginTop: 50,
        marginBottom: 60
    },
    containerSmallScreen: {
        marginTop: 30
    },
    texteInformatifStyle: {
        fontSize: 14,
        margin: '30px 0'
    },
    marginBottom: {
        marginBottom: 30
    },
    textSize: {
        fontSize: 14
    },
    alignItems: {
        verticalAlign: 'middle',

        '&:first-child': {
            marginRight: 20
        }
    },
    marginButton: {
        marginBottom: 20,

        '& button': {
            width: '100%',
            minHeight: 54
        }
    },
    texteImportantContainer: {
        backgroundColor: '#f7f6f3',
        padding: 10,
        marginBottom: 20,

        '& h1': {
            fontSize: 12
        },
        '& p': {
            margin: 0,
            fontSize: 14
        }
    }
}))

export interface ModelFichiers {
    fichier: string,
    fichierEncode: string,
    file: File
}

interface FormData {
    mode_reglement: string,
    code_bic: string,
    code_iban: string,
    domiciliation_bancaire: string
    commentaire: string,
    fichiers: ModelFichiers[]
}

interface ModelSnackBarData {
    id: string
    message: string,
    open: boolean
}

interface Store {
    initialValues: FormData,
    afficherSnackBar: (snackBarData: ModelSnackBarData) => void,
    errorCatcher: (error: any, id: string) => void
}

interface AcceptingProps {
    closeDialog: () => void,
    tailleEcran: string
}

const titreDialog: string = 'Communiquer vos nouvelles coordonnées bancaires'
const texteInformatif: string = 'Les données du présent formulaire sont pré-alimentées avec les données actuelles dont nous disposons vous concernant.'
const titreImportant: string = 'Important'
const texteImportant: string = 'Pour une facilité de règlement accrue, nous vous recommandons de passer au règlement par virement en choisissant l\'option correspondante dans la liste des modes de règlement ci-dessus.'
const modeVirement: string = 'Virement'
const modeCheque: string = 'Chèque'
const messageEmailEnvoye: string = 'Vos nouvelles coordonnées sont transmises à nos équipes et seront prises en compte très prochainement'

type FormulaireCoordonneesBancairesProps =
    Store
    & AcceptingProps

const FormulaireCoordonneesBancaires: React.FC<FormulaireCoordonneesBancairesProps> = (
    {
        afficherSnackBar,
        errorCatcher,
        initialValues,
        closeDialog,
        tailleEcran
    }
) => {

    const classes = useStyles()
    const [loading, setLoading] = React.useState<boolean>(false)

    const cancelForm = () => {
        closeDialog()
    }

    const onSubmit = (values: FormData) => {
        setLoading(true)

        if (values.code_iban.charAt(4) !== ' ') {
            values.code_iban = masqueIban(values.code_iban)
        }

        const fd = new FormData()
        values.fichiers.forEach((value) => {
                fd.append('fichiers[]', value.file, value.fichier)
            }
        )
        fd.append('values', JSON.stringify(values))

        fetchFactory('/api/coordonnees/bancaires',
            {
                method: 'POST',
                body: fd
            }
        )
            .then(
                () => {
                    afficherSnackBar(
                        {
                            id: 'formulaire_coordonnees_bancaires',
                            message: messageEmailEnvoye,
                            open: true
                        }
                    )
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, 'formulaire_coordonnees_bancaires')
                }
            )
            .finally(
                () => {
                    setLoading(false)
                    closeDialog()
                }
            )
    }

    return (
        <Form
            mutators={{
                ...arrayMutators
            }}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={
                (
                    {
                        handleSubmit,
                        values,
                        error
                    }
                ) => (
                    <form
                        onSubmit={handleSubmit}
                        className={
                            tailleEcran === 'grand' ? classes.containerLargeScreen : classes.containerSmallScreen
                        }
                    >
                        <Header title={titreDialog}/>

                        <div className="row">
                            <div
                                className={
                                    [
                                        'col-xs-12',
                                        classes.colorPrimary,
                                        classes.medium,
                                        classes.text,
                                        classes.texteInformatifStyle
                                    ].join(' ')
                                }
                            >
                                {texteInformatif}
                            </div>
                        </div>

                        <div
                            className={classNames(
                                'row',
                                classes.marginBottom
                            )}
                        >
                            {/* Input du mode de règlement */}
                            <div className="col-xs-12 col-sm-6">
                                <Field
                                    name="mode_reglement"
                                    component={FormSelect}
                                    label="Mode de règlement"
                                    id="mode_reglement"
                                >
                                    <MenuItem value={modeCheque}>
                                        {modeCheque}
                                    </MenuItem>

                                    <MenuItem value={modeVirement}>
                                        {modeVirement}
                                    </MenuItem>
                                </Field>
                            </div>
                        </div>

                        {
                            values && values.mode_reglement === modeVirement &&
                            <div
                                className={classNames(
                                    'row',
                                    classes.marginBottom
                                )}
                            >
                                {/* Input du code BIC */}
                                <div className="col-xs-12 col-sm-6">
                                    <Field
                                        name="code_bic"
                                        component={EnhancedFormTextField}
                                        label="Code BIC"
                                        id="code_bic"
                                        maxLength={11}
                                    />
                                </div>

                                {/* Input du code IBAN */}
                                <div className="col-xs-12 col-sm-6">
                                    <Field
                                        name="code_iban"
                                        component={EnhancedFormTextField}
                                        label="Code IBAN"
                                        id="code_iban"
                                        ibanFormatter={true}
                                        maxLength={49}
                                    />
                                </div>
                            </div>
                        }

                        {
                            values && values.mode_reglement === modeVirement &&
                            <div
                                className={classNames(
                                    'row',
                                    classes.marginBottom
                                )}
                            >
                                {/* Input de la domiciliation bancaire */}
                                <div className="col-xs-12 col-sm-6">
                                    <Field
                                        name="domiciliation_bancaire"
                                        component={EnhancedFormTextField}
                                        label="Domiciliation bancaire"
                                        id="domiciliation_bancaire"
                                        maxLength={15}
                                    />
                                </div>
                            </div>
                        }

                        {
                            values && values.mode_reglement === modeVirement &&

                            <div
                                className={classNames(
                                    'row',
                                    classes.marginBottom
                                )}
                            >
                                {/* Input du commentaire */}
                                <div className="col-xs-12">
                                    <Field
                                        name="commentaire"
                                        component={EnhancedFormTextField}
                                        label="Commentaire"
                                        id="commentaire"
                                        multiline
                                        maxLength={500}
                                    />
                                </div>
                            </div>
                        }

                        {
                            values && values.mode_reglement === modeVirement &&
                            <>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <p
                                            className={classNames(
                                                    classes.text,
                                                    classes.textSize,
                                                    classes.medium,
                                                    classes.colorPrimary
                                                )}
                                        >
                                            Pour toute mise en place ou modification du règlement par virement, veuillez
                                            joindre un Relevé d'Identité Bancaire
                                        </p>
                                    </div>
                                </div>

                                <FieldArray
                                    name="fichiers"
                                    render={
                                        (
                                            {
                                                fields,
                                                meta
                                            }
                                        ) => (
                                            <FormFichier
                                                fields={fields}
                                                meta={meta}
                                                label="Ajouter un fichier"
                                                id="fichiers"
                                            />
                                        )
                                    }
                                />
                            </>
                        }

                        {
                            values && values.mode_reglement === modeCheque &&
                            <div className="row">
                                <div
                                    className={classNames(
                                            'col-xs-12',
                                            classes.colorPrimary,
                                            classes.text,
                                            classes.texteImportantContainer
                                        )}
                                >
                                    <h1>{titreImportant}</h1>

                                    <p>{texteImportant}</p>
                                </div>
                            </div>
                        }

                        <div className="row middle-xs end-xs reverse-xs">
                            <div
                                className={
                                    classNames(
                                        'col-xs-12 col-sm-3 last-sm',
                                        classes.marginButton
                                    )
                                }
                            >
                                {
                                    loading ? (
                                        <CircularProgress color="primary"/>
                                    ) : (
                                        <Button
                                            disabled={false}
                                            type="submit"
                                        >
                                            Transmettre
                                        </Button>
                                    )
                                }
                            </div>

                            <div
                                className={
                                    classNames(
                                        'col-xs-12 col-sm-3',
                                        classes.marginButton
                                    )
                                }
                            >
                                <Button
                                    type="button"
                                    disabled={false}
                                    onClick={cancelForm}
                                    background="backgroundInherit"
                                    color="colorPrimary"
                                >
                                    <ChevronLeftIcon/>
                                    Annuler
                                </Button>
                            </div>
                        </div>
                        {
                            error && <strong>{error}</strong>
                        }
                    </form>
                )
            }
        />
    )
}

const validate = (values: FormData) => {
    const errors: any = {}

    if (!values.mode_reglement) {
        errors.mode_reglement = 'Requis*'
    } else if (values.mode_reglement !== modeVirement && values.mode_reglement !== modeCheque) {
        errors.mode_reglement = 'Veuille choisir un mode de règlement correct'
    } else {
        if (values.mode_reglement === modeVirement) {
            if (!values.code_bic) {
                errors.code_bic = 'Requis*'
            } else if (values.code_bic.length > 11) {
                errors.code_bic = 'Le code BIC ne peut pas dépasser 11 caractères'
            }
            if (!values.code_iban) {
                errors.code_iban = 'Requis*'
            } else if (!iban.isValid(values.code_iban)) {
                errors.code_iban = 'Le code IBAN n\'est pas correct'
            }
            if (values.commentaire && values.commentaire.length > 500) {
                errors.commentaire = 'Le commentaire ne peut pas dépasser 500 caractères'
            }
            if (!values.domiciliation_bancaire) {
                errors.domiciliation_bancaire = 'Requis*'
            } else if (values.domiciliation_bancaire.length > 15) {
                errors.domiciliation_bancaire = 'La domiciliation bancaire ne peut pas dépasser 15 caractères'
            }
            if (!values.fichiers) {
                errors.fichiers =  'Fourniture d\'un RIB obligatoire'
            } else if (values.fichiers.length === 0) {
                errors.fichiers = 'Fourniture d\'un RIB obligatoire'
            }
        }

    }

    return errors
}

const mapStateToProps = (state: any) => {
    return {
        initialValues: state.formulaireCoordonneesBancaires
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        afficherSnackBar: (snackBarData: ModelSnackBarData) => {
            dispatch(afficherSnackbar(snackBarData))
        },
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedFormulaireCoordonneesBancaires = compose<FormulaireCoordonneesBancairesProps, AcceptingProps>(
    connect(mapStateToProps, mapDispatchToProps)
)(FormulaireCoordonneesBancaires)

export default EnhancedFormulaireCoordonneesBancaires
