import React, {Dispatch, useEffect} from 'react'
import EnhancedCorpsCompteTitresGrand from './grandEcran/CorpsCompteTitresGrand'
import MediaQuery from 'react-responsive'
import EnhancedCorpsCompteTitresPetit from './petitEcran/CorpsCompteTitresPetit'
import EnhancedEntete from '../../components/entete/Entete'
import fetchFactory from '../../utils/fetchFactory'
import errorCatcher from '../../utils/errorCatcher'
import {compose} from 'recompose'
import {connect, useDispatch, useSelector} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import {ajouterDroitsActions, ajouterDroitsStock} from '../reducers/droitsActions/actions'
import {ajouterDisponibiliteAvoirs} from '../reducers/disponibiliteAvoirs/actions'
import {ajouterCompteTitres} from '../reducers/compteTitres/actions'
import Onglets from '../../components/onglets/Onglets'
import styles from '../../theme/styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    spinnerStyle: {
        marginTop: 50,
        textAlign: 'center',

        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    },
    iframeContainerStyle: {
        overflow: 'hidden'
    },
    iframeLargeScreenStyle: {
        margin: '-1px 0 0 -2px',
        minHeight: 1250
    },
    iframeSmallScreenStyle: {
        margin: '-1px 0 0 -2px',
        minHeight: 2050
    }
}))

interface ModelCompteTitres {
    id: number,
    code_isin: string,
    nature_valeur: string,
    solde_global: number,
    valorisation: number,
    libelle_valeur: string
}

interface ModelPrixGecina {
    update_date: Date,
    price: string
}

interface ModelDroitsActions {
    id: number,
    libelle_plan: string,
    date_attribution: Date,
    date_debut_conservation: Date,
    quantite: number
}

interface ModelDroitsStock {
    id: number,
    libelle_plan: string,
    quantite: number
    prix_offre: number,
    date_debut_plan: Date,
    date_fin_plan: Date
}

interface ModelRecapitulatif {
    total_actions: number,
    actions_cessibles: number,
    valorisation: number,
    montant_cours: number,
    date_cours: Date
}

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date,
    typologie: string,
    actions: string,
    prix_acquisition: number
}

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    message: string
}

interface ModelAvoirs {
    recapitulatif?: ModelRecapitulatif,
    disponibilites: ModelDisponibilites[],
    indisponibilites: ModelIndisponibilites[]
}

interface Store {
    errorCatcher: (error: any, id: string) => void,
    ajouterDroitsActions: (droitsActions: ModelDroitsActions[]) => void,
    ajouterDroitsStock: (droitsStock: ModelDroitsStock[]) => void,
    ajouterDisponibiliteAvoirs: (disponibiliteAvoirs: ModelAvoirs) => void,
    location: Location
}

const titre: string = 'Votre compte-titres Gecina'
type CompteTitresProps = Store

const CompteTitres = (
    props: CompteTitresProps
) => {
    const {
        errorCatcher,
        ajouterDroitsActions,
        ajouterDroitsStock,
        ajouterDisponibiliteAvoirs
    } = props

    const classes = useStyles()
    const compteTitres = useSelector((state: any) => state.compteTitres)
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [prixGecina, setPrixGecina] = React.useState<ModelPrixGecina>(
        {
            update_date: new Date('now'),
            price: ''
        }
    )
    const [droitsActions, setDroitsActions] = React.useState<ModelDroitsActions[]>([])
    const [droitsStock, setDroitsStock] = React.useState<ModelDroitsStock[]>([])

    const [avoirs, setAvoirs] = React.useState<ModelAvoirs>({
        disponibilites: [],
        indisponibilites: [],
        recapitulatif: {
            total_actions: 0,
            date_cours: new Date(),
            montant_cours: 0,
            valorisation: 0,
            actions_cessibles: 0
        }
    })
    // const [restrictions, setRestrictions] = React.useState<string[]>([])

    useEffect(() => {
            if (compteTitres.length === 0) {
                fetchFactory('/api/compte/actions')
                    .then(
                        (compte_titres: any) => {
                            compte_titres = compte_titres.sort(
                                (a: ModelCompteTitres, b: ModelCompteTitres) => {
                                    if (a.code_isin > b.code_isin) {
                                        return 1
                                    }
                                    if (a.code_isin < b.code_isin) {
                                        return -1
                                    }
                                    return 0
                                }
                            )

                            const compteTitresTries: ModelCompteTitres[] = []

                            compte_titres.forEach(
                                (compte_titre: ModelCompteTitres) => {
                                    if (compte_titre.code_isin === 'FR0010040865') {
                                        compteTitresTries.unshift(compte_titre)
                                    } else {
                                        compteTitresTries.push(compte_titre)
                                    }
                                }
                            )

                            dispatch(ajouterCompteTitres(compteTitresTries))
                        },
                        (error: any) => {
                            errorCatcher(error, 'compte_titres')
                        }
                    )
                    .catch(
                        (error: any) => {
                            errorCatcher(error, 'compte_titres')
                        }
                    )
                    .finally(
                        () => {
                            setLoading(false)
                        }
                    )
            }

            fetchFactory('/api/gecina/prix_part')
                .then(
                    (prix_gecina: any) => {
                        setPrixGecina(prix_gecina)
                    },
                    (error: any) => {
                        errorCatcher(error, 'prix_gecina')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'prix_gecina')
                    }
                )

            fetchFactory('/api/droits/actionsPerformances')
                .then(
                    (droits_actions: any) => {
                        setDroitsActions(
                            droits_actions.sort(
                                (a: ModelDroitsActions, b: ModelDroitsActions) => {
                                    if (a.date_attribution < b.date_attribution) {
                                        return 1
                                    }
                                    if (a.date_attribution > b.date_attribution) {
                                        return -1
                                    }
                                    return 0
                                }
                            )
                        )

                        ajouterDroitsActions(
                            droits_actions.sort(
                                (a: ModelDroitsActions, b: ModelDroitsActions) => {
                                    if (a.date_attribution < b.date_attribution) {
                                        return 1
                                    }
                                    if (a.date_attribution > b.date_attribution) {
                                        return -1
                                    }
                                    return 0
                                }
                            )
                        )
                    },
                    (error: any) => {
                        errorCatcher(error, 'droits_actions')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'droits_actions')
                    }
                )
                .finally(
                    () => {
                        setLoading(false)
                    }
                )

            fetchFactory('/api/droits/stockOptions')
                .then(
                    (droits_stock: any) => {
                        setDroitsStock(
                            droits_stock.sort(
                                (a: ModelDroitsStock, b: ModelDroitsStock) => {
                                    if (a.date_debut_plan < b.date_debut_plan) {
                                        return 1
                                    }
                                    if (a.date_debut_plan > b.date_debut_plan) {
                                        return -1
                                    }
                                    return 0
                                }
                            )
                        )

                        ajouterDroitsStock(
                            droits_stock.sort(
                                (a: ModelDroitsStock, b: ModelDroitsStock) => {
                                    if (a.date_debut_plan < b.date_debut_plan) {
                                        return 1
                                    }
                                    if (a.date_debut_plan > b.date_debut_plan) {
                                        return -1
                                    }
                                    return 0
                                }
                            )
                        )
                    },
                    (error: any) => {
                        errorCatcher(error, 'droits_stock')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'droits_stock')
                    }
                )

            fetchFactory('/api/disponibilite/avoirs')
                .then(
                    (disponibilite_avoirs: any) => {
                        setAvoirs(
                            {
                                disponibilites: disponibilite_avoirs.disponibilites.sort(
                                    (a: ModelDisponibilites, b: ModelDisponibilites) => {
                                        if (a.date_disponibilite > b.date_disponibilite) {
                                            return 1
                                        }
                                        if (a.date_disponibilite < b.date_disponibilite) {
                                            return -1
                                        }
                                        return 0
                                    }
                                ),
                                indisponibilites: disponibilite_avoirs.indisponibilites.sort(
                                    (a: ModelIndisponibilites, b: ModelIndisponibilites) => {
                                        if (a.date_disponibilite > b.date_disponibilite) {
                                            return 1
                                        }
                                        if (a.date_disponibilite < b.date_disponibilite) {
                                            return -1
                                        }
                                        return 0
                                    }
                                )
                            }
                        )

                        ajouterDisponibiliteAvoirs(disponibilite_avoirs)
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'detail_avoirs')
                    }
                )
                .finally(
                    () => {
                        setLoading(false)
                    }
                )
        }, [compteTitres.length, dispatch, errorCatcher, ajouterDroitsActions, ajouterDroitsStock, ajouterDisponibiliteAvoirs]
    )

    // const restrictionsList: ReactChild = <p>
    // 	{
    // 		restrictions.map(
    // 			(restriction, index) => (
    // 				<span>{restriction} {index === restriction.length - 1 && ','}</span>
    // 			)
    // 		)
    // 	}
    // </p>

    const iframeContainer: ClassValue = classNames(
        'row',
        classes.iframeContainerStyle
    )

    return (
        <>
            <EnhancedEntete titre={titre}/>
            <Onglets/>

            <MediaQuery
                maxWidth={1024}
            >

                {
                    (match) => {
                        return match ? (
                            <>


                                {
                                    loading ? (
                                        <div className={classes.spinnerStyle}>
                                            <CircularProgress color="primary"/>
                                        </div>
                                    ) : (
                                        <>
                                            <EnhancedCorpsCompteTitresPetit
                                                compteTitres={compteTitres}
                                                prixGecina={prixGecina}
                                                droitsActions={droitsActions}
                                                droitsStock={droitsStock}
                                                avoirs={avoirs}
                                            />
                                            <div className={iframeContainer}>
                                                <iframe
                                                    className={classes.iframeSmallScreenStyle}
                                                    title="Historique Action Gecina"
                                                    width="100%"
                                                    src="https://prod1.solutions.webfg.ch/gecina/details2/1753313,25,814?lang=fr"/>
                                            </div>
                                            {/* TODO En attente de la validation de DGT48 */}
                                            {/*{restrictionsList}*/}
                                        </>
                                    )
                                }

                            </>
                        ) : (
                            <>
                                {
                                    loading ? (
                                        <div className={classes.spinnerStyle}>
                                            <CircularProgress color="primary"/>
                                        </div>
                                    ) : (
                                        <>
                                            <EnhancedCorpsCompteTitresGrand
                                                compteTitres={compteTitres}
                                                prixGecina={prixGecina}
                                                droitsActions={droitsActions}
                                                droitsStock={droitsStock}
                                                avoirs={avoirs}
                                            />
                                            <div className={iframeContainer}>
                                                <iframe
                                                    className={classes.iframeLargeScreenStyle}
                                                    title="Historique Action Gecina"
                                                    width="100%"
                                                    src="https://prod1.solutions.webfg.ch/gecina/details2/1753313,25,814?lang=fr"/>
                                            </div>
                                            {/*{restrictionsList}*/}
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                }
            </MediaQuery>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        },
        ajouterDroitsActions: (droitsActions: ModelDroitsActions[]) => {
            dispatch(ajouterDroitsActions(droitsActions))
        },
        ajouterDroitsStock: (droitsStock: ModelDroitsStock[]) => {
            dispatch(ajouterDroitsStock(droitsStock))
        },
        ajouterDisponibiliteAvoirs: (disponibiliteAvoirs: ModelAvoirs) => {
            dispatch(ajouterDisponibiliteAvoirs(disponibiliteAvoirs))
        }
    }
}

const EnhancedCompteTitres = compose<CompteTitresProps, {}>(
    connect(null, mapDispatchToProps)
)(CompteTitres)

export default EnhancedCompteTitres
