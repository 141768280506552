import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { DonneesPersonnellesStore, ModelOptionFiscale } from '../types/optionFiscaleModel'
import fetchFactory from '../../../utils/fetchFactory'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers'
import { ajouterFormulaireDonneesPersonnelles } from '../../reducers/formulaireDonneesPersonnelles/actions'
import errorCatcher from '../../../utils/errorCatcher'
import { CONSTANTES_FISCALITE } from '../../../utils/constantes/fiscalite'
import Theme from '../../../theme/theme'
import { utilisateurEstMultiCompte } from '../../../utils/utils'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles<typeof Theme, { isLargeScreen: boolean }>(theme => ({
	titre: {
		...styles(theme).title0,
		color: theme.palette.blue.main,
		fontSize: 20,
		marginTop: props => props.isLargeScreen ? 25 : 15,
		marginBottom: props => props.isLargeScreen ? 20 : 10
	},
	texte: {
		...styles(theme).colorPrimary,
		...styles(theme).textSize,
		...styles(theme).text,
		...styles(theme).regular
	},
	multiCompte: {
		...styles(theme).textSize,
		...styles(theme).text,
		...styles(theme).semiBold,
		color: 'white',
		backgroundColor: theme.palette.brown.main,
		padding: 10,
		marginTop: 20
	}
}))


const IN_QUALITE = ['societe', 'corresp', 'autre']
const IN_PAYS_FISCALE = ['france', 'reunion', 'martinique', 'guadeloupe', 'polynesie francaise', 'guyane']
const GET_DONNEES_PERSONNELLES = 'donnees_personnelles'

const OptionFiscale = () => {
	const { isLargeScreen } = useContext(ResponsiveContext)
	const classes = useStyles({ isLargeScreen })
	const donneesPersonnelles: DonneesPersonnellesStore = useSelector((state: RootState) => state.formulaireDonneesPersonnelles as DonneesPersonnellesStore)
	const dispatch = useDispatch()

	const [text, setText] = useState('')
	const [loading, setLoading] = useState(false)

	const getText = (optionFiscale: ModelOptionFiscale) => {
		if (!IN_QUALITE.includes(optionFiscale.qualite.toLowerCase())) {
			if (IN_PAYS_FISCALE.includes(optionFiscale.pays_fiscale.toLowerCase())) {
				if (optionFiscale.indicateur_dispense) {
					setText('Les revenus distribués sont soumis au PFU (prélèvement forfaitaire Unique) au taux de 30% ( Prel. Soc. 17,2% et Impôt sur le Revenu 12,8%)')
				} else {
					setText('Une demande de dispense du prélèvement forfaitaire d\'impôt sur le revenu a été reçue. Les revenus distribués restent soumis aux Prélèvements Sociaux au taux de 17,2%.')
				}
			} else {
				setText('Les revenus distribués sont soumis à une retenue à la source au taux de 12,8 %, sous réserve de l\'application éventuelle de stipulations plus favorables des conventions fiscales internationales.')
			}
		} else {
			setText('')
		}
	}

	useEffect(
		() => {
			if (Object.keys(donneesPersonnelles).length > 0) {
				getText(
					{
						qualite: donneesPersonnelles.qualite,
						indicateur_dispense: donneesPersonnelles.indicateur_dispense,
						pays_fiscale: donneesPersonnelles.adresse_fiscale_pays
					}
				)
			} else {
				setLoading(true)

				fetchFactory('/api/donnees/personnelles')
					.then(
						(donnees_personnelles: any) => {
							dispatch(
								ajouterFormulaireDonneesPersonnelles(donnees_personnelles)
							)
						}
					)
					.catch(
						(error: any) => {
							dispatch(
								errorCatcher(error, GET_DONNEES_PERSONNELLES)
							)
						}
					)
					.finally(
						() => {
							setLoading(false)
						}
					)
			}
		}, [dispatch, donneesPersonnelles]
	)

	if (!text && loading) {
		return <></>
	}

	return (
		<Grid container item xs={12}>
			{/* Titre du bloc */}
			<Grid container item xs={12} className={classes.titre}>
				{CONSTANTES_FISCALITE.OPTION_FISCALE.TITRE}
			</Grid>

			{/* Texte du bloc */}
			<Grid
				container
				item
				xs={12}
			>
				<Grid item xs={12} className={classes.texte}>
					{text}
				</Grid>
			</Grid>

			{
				// Message spécifique destiné aux utilisateurs multicomptes
				utilisateurEstMultiCompte() && (
					<Grid container item xs={12} justify="center" className={classes.multiCompte}>
						{CONSTANTES_FISCALITE.OPTION_FISCALE.MULTI_COMPTE}
					</Grid>
				)
			}
		</Grid>
	)
}

export default OptionFiscale
