export const authentification = {
	title: 'Connectez-vous',
	placeholderEmail: 'Identifiant de connexion',
	placeholderPassword: 'Mot de passe',
	submit: 'Se connecter',
	credentialForgotten: 'Identifiant oublié ?',
	passwordForgotten: 'Mot de passe oublié ?',
	signUp: 'Vous n\'avez pas encore activé votre compte ?',
	signUpAccount: 'Activez votre compte'
}

export const resetPassword = {
	title: 'Réinitialisez votre mot de passe',
	placeholderEmail: 'Identifiant de connexion',
	reset: 'Réinitialiser',
	backSignIn: 'Retour à la connexion',
	emailSent: {
		title: 'Lien envoyé !',
	},
	newPassword: 'Nouveau mot de passe',
	repeatNewPassword: 'Répétez le mot de passe'
}

export const getCredential = {
	title: 'Récupérer votre identifiant de connexion',
	placeholderEmail: 'Courriel',
	submit: 'Envoyer',
	emailSent: {
		title: 'Courriel envoyé !'
	}
}

export const activateAccount = {
	title: 'Activez votre compte',
	email: 'Courriel',
	confirmEmail: 'Confirmation courriel',
	identifiant: 'Identifiant',
	validationCode: 'Code de validation',
	link: 'conditions générales d\'utilisation',
	activate: 'Activez votre compte',
	emailSent: {
		title: 'Email de vérification envoyé',
		description: 'Pour finaliser la création de votre compte, veuillez consulter le message envoyé à votre adresse courriel. En activant votre compte, vous acceptez les conditions générales d\'utilisation de Digititres, accessibles depuis le courriel de confirmation.'
	}
}

export const mfa = {
	title: 'Vérifier avec l\'authentification par courriel',
	sendCode: 'Envoyer le code',
	disconnect: 'Se déconnecter',
	resendCode: 'Vous n\'avez pas encore reçu votre code?',
	sendAgain: 'Envoyer de nouveau',
	saveChoice: 'Se souvenir de mon choix sur cet appareil',
	verificationCode: 'Code de vérification',
	verify: 'Vérifier',
}

export const errors = {
	required: 'Requis*',
	formatPassword: 'Le mot de passe doit comporter au moins 8 caractères dont au moins un chiffre, une lettre minuscule et une lettre majuscule',
	samePassword: 'Les nouveaux mots de passe doivent correspondre',
	formatEmail: 'Format du courriel incorrect',
	notSameEmail: 'Le courriel saisi et sa confirmation sont différents',
	notSamePassword: 'Le mot de passe saisi et sa confirmation sont différents',
	loginFailed: 'Votre identifiant ou votre mot de passe est incorrect. Veuillez réessayer',
	activationCodeKO: 'Votre code d\'activation ne correspond pas à nos enregistrements. Veuillez réessayer',
	verificationCodeKO: 'Votre code d\'accès ne correspond pas à nos enregistrements. Veuillez réessayer',
	emailActivationKO: 'Un utilisateur comportant ce courriel existe déjà',
	newPwdKO: 'Votre nouveau mot de passe ne doit pas être le même que votre mot de passe actuel et ne doit pas être un des anciens mots de passe utilisés'
}

export const finishActivation = {
	title: 'Finalisez l\'activation de votre compte',
	password: 'Mot de passe',
	confirmPassword: 'Confirmation mot de passe'
}