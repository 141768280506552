import React, { Dispatch, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import fetchFactory from '../../../utils/fetchFactory'
import errorCatcher from '../../../utils/errorCatcher'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import formatDate from '../../../utils/formatDate'
import { ClassValue } from 'classnames/types'
import EmptyState from '../components/EmptyState'
import thousandsSeparator from '../../../utils/thousandsSeparator'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	spinnerStyle: {
		marginTop: 50,
		textAlign: 'center',

		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	},
	opacity: {
		opacity: 0.5
	},
	borderBottom: {
		borderBottom: '1px solid #0000001E',
		paddingBottom: 10,
		paddingTop: 10,
		marginBottom: 10
	},
	emptyStateContainer: {
		marginLeft: 20
	},
	marginBottom: {
		marginBottom: 20
	},
	libelleStyle: {
		fontSize: 12
	},
	valeurStyle: {
		fontSize: 15
	},
	containerStyle: {
		margin: '20px auto'
	}
}))

interface ModelOperation {
	id: number,
	date: Date,
	valeur: string,
	quantite: string,
	mouvement: string
}

interface ModelResponse {
	id: number,
	date: Date,
	code_isin: string,
	libelle_valeur: string,
	quantite: number,
	sens: string,
	libelle_operation: string
}

interface Store {
	errorCatcher: (error: any, id: string) => void
}

type HistoriqueOperationsProps = Store

const VALEUR = 'Valeur'
const DATE = 'Date'
const QUANTITE = 'Quantité'
const MOUVEMENT = 'Mouvement'

const ListeHistoriqueOperationsPetit: React.FC<HistoriqueOperationsProps> = (
	{
		errorCatcher
	}
) => {

	const classes = useStyles()
	const [operations, setOperations] = React.useState<ModelOperation[]>([])
	const [loading, setLoading] = React.useState<boolean>(true)

	useEffect(() => {
		fetchFactory('/api/operations')
			.then((operationsReponse: any) => {
					const tmpOperations: ModelOperation[] = []

					operationsReponse.sort(
						(a: ModelOperation, b: ModelOperation) => {
							if (a.date < b.date) {
								return 1
							}
							if (a.date > b.date) {
								return -1
							}
							return 0
						}
					).forEach(
						(operationResponse: ModelResponse) => {
							tmpOperations.push(
								{
									id: operationResponse.id,
									date: operationResponse.date,
									valeur: `${operationResponse.libelle_valeur} - ${operationResponse.code_isin}`,
									quantite: operationResponse.sens === 'R' ? `-${thousandsSeparator(operationResponse.quantite,true)}` : thousandsSeparator(operationResponse.quantite,true),
									mouvement: operationResponse.libelle_operation
								}
							)
						}
					)
					setOperations(tmpOperations)
				}
			)
			.catch(
				(error: any) => {
					errorCatcher(error, 'historique_operations')
				}
			)
			.finally(
				() => {
					setLoading(false)
				}
			)
	}, [errorCatcher])

	const containerStyle: ClassValue = classNames(
		'col-xs-11',
		classes.containerStyle
	)

	const rowStyle: ClassValue = classNames(
		'row middle-xs',
		classes.marginBottom
	)

	const libelleStyle: ClassValue = classNames(
		classes.text,
		classes.colorPrimary,
		classes.semiBold,
		classes.libelleStyle,
		classes.opacity
	)

	const valeurStyle: ClassValue = classNames(
		classes.text,
		classes.semiBold,
		classes.colorPrimary,
		classes.valeurStyle
	)

	return (
		<>
			{
				loading ? (
					<div className={classNames('col-xs-12', classes.spinnerStyle)}>
						<CircularProgress color="primary" />
					</div>
				) : (
					<>
						{
							operations.length > 0 ? (
								<div className={containerStyle}>
									{
										operations.map((operation, index) => (
												<div key={operation.id}>
													<>
														<div className="row">
															<div className="col-xs-12">
																<div className={rowStyle}>
																	<div className="col-xs-6">
                                                                        <span className={libelleStyle}>
                                                                            {VALEUR}
                                                                        </span>
																		<br />
																		<span className={valeurStyle}>
                                                                            {operation.valeur}
                                                                        </span>
																	</div>
																	<div className="col-xs-6 end-xs">
                                                                        <span className={libelleStyle}>
                                                                            {MOUVEMENT}
                                                                        </span>
																		<br />
																		<span className={valeurStyle}>
                                                                            {operation.mouvement}
                                                                        </span>
																	</div>
																</div>
															</div>
														</div>
														<div
															className={classNames(
																'row',
																index === operations.length - 1 ? '' : classes.borderBottom
															)}
														>
															<div className="col-xs-12">
																<div className={rowStyle}>
																	<div className="col-xs-6">
                                                                        <span className={libelleStyle}>
                                                                            {DATE}
                                                                        </span>
																		<br />
																		<span className={valeurStyle}>
                                                                            {formatDate(operation.date)}
                                                                        </span>
																	</div>
																	<div className="col-xs-6 end-xs">
                                                                        <span className={libelleStyle}>
                                                                            {QUANTITE}
                                                                        </span>
																		<br />
																		<span className={valeurStyle}>
                                                                            {operation.quantite}
                                                                        </span>
																	</div>
																</div>

															</div>
														</div>
													</>
												</div>

											)
										)
									}
								</div>
							) : (
								<div className={classes.emptyStateContainer}>
									<EmptyState />
								</div>
							)
						}
					</>
				)
			}
		</>
	)
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
	return {
		errorCatcher: (error: any, id: string) => {
			dispatch(errorCatcher(error, id))
		}
	}
}

const EnhancedListeHistoriqueOperationsPetit = compose<HistoriqueOperationsProps, {}>(
	connect(null, mapDispatchToProps)
)(ListeHistoriqueOperationsPetit)

export default EnhancedListeHistoriqueOperationsPetit
