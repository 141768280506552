import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import EnhancedCorpsDocuments from './CorpsDocuments'
import OngletsDocuments from '../../components/onglets/OngletsDocuments'
import { useLocation } from 'react-router-dom'
import CorpsDocumentsPerso from './CorpsDocumentsPerso'
import Links from '../../enum/links'
import fetchFactory from '../../utils/fetchFactory'
import errorCatcher from '../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import { afficherSnackbar } from '../reducers/snackBar/actions'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'

const useStyles = makeStyles(theme => ({
		...styles(theme),
		spinnerStyle: {
			marginTop: 50,
			textAlign: 'center',
			'& div': {
				width: '150px !important',
				height: '150px !important'
			}
		}
	})
)

interface ModelDocumentsUtiles {
	id: number,
	id_docuware: number,
	libelle: string,
	date_creation: Date,
	type_document: string
}

interface ModelResponse {
	id: number,
	id_docuware: number,
	numero_compte: number,
	type_document: string,
	libelle: string,
	date_creation: Date
}

interface ModelBouton {
	id: number,
	titre: string,
	description: string,
	texte: string,
	icon: string
}

const IDDOC = 'document'
const TITRE: string = 'Vos documents'
const DOCUMENTS_ERROR = 'documents_bdd'
export const titreOrdreBourse: string = 'Ordre de bourse'
const descriptionOrdreBourse: string = 'Vous pouvez transmettre vos ordres de bourse en complétant le formulaire en ligne (un justificatif des instructions transmises est téléchargeable).'
const texteOrdreBourse: string = 'Ordre de bourse'
const titreDemandePEE: string = 'Demande de déblocage des titres PEE'
const descriptionDemandePEE: string = 'Vous pouvez demander le déblocage anticipé de vos actions détenues dans le cadre du plan d’Epargne Groupe si vous disposez d’un des cas prévus par la législation. Il vous suffit de compléter le formulaire et de joindre les justificatifs.'
const texteDemandePEE: string = 'Demande de déblocage PEE'
const titreDemandeInscription: string = 'Demande d’inscription au nominatif pur'
const descriptionDemandeInscription: string = 'Vous souhaitez inscrire vos actions Gecina au nominatif pur. Vous pouvez compléter le formulaire joint et le transmettre à votre teneur de compte qui se chargera du transfert.'
const texteDemandeInscription: string = 'Demande d\'inscription'
const titreOptionFiscale: string = 'Option fiscale - Demande de dispense d’acompte'
const descriptionOptionFiscale: string = 'Les dividendes versés aux actionnaires individuels résidents fiscaux francais sont par défaut soumis au Prélèvement Forfaitaire Unique (30%) incluant l’impôt sur le revenu au taux de 12,8%. Vous pouvez demander à être exonéré de cet acompte sur l’impôt sur le revenu en complétant le formulaire si votre Revenu Fiscal de Référence est inférieur à un seuil précisé dans le document joint. Cette demande est à retourner avant le 30 novembre.'
const texteOptionFiscale: string = 'Option fiscale'
const titreLivretActionnaire: string = 'Livret de l\'actionnaire'
const descriptionLivretActionnaire: string = 'Découvrez le « Livret de l’actionnaire » et retrouvez de nombreuses informations relatives à votre compte-titres (mode de détention, négociations, fiscalité, participation aux assemblées générales, Le Club actionnaires, …)'
const texteLivretActionnaire: string = 'Livret de l\'actionnaire'

const Documents = () => {
	const classes = useStyles()
	const location = useLocation()
	const dispatch = useDispatch()
	const [loading, setLoading] = React.useState<boolean>(true)
	const [documentsUtiles, setDocumentsUtiles] = React.useState<ModelDocumentsUtiles[]>([])
	const [boutons, setBoutons] = React.useState<ModelBouton[]>([])

	useEffect(() => {
		fetchFactory('/api/documents')
			.then(
				(documentsResponse: any) => {
					const tmpDocuments: ModelDocumentsUtiles[] = []
					documentsResponse.sort(
						(a: ModelDocumentsUtiles, b: ModelDocumentsUtiles) => {
							if (a.date_creation < b.date_creation) {
								return 1
							}
							if (a.date_creation > b.date_creation) {
								return -1
							}
							return 0
						}
					).forEach(
						(documentsResponse: ModelResponse) => {
							tmpDocuments.push(
								{
									id: documentsResponse.id,
									date_creation: documentsResponse.date_creation,
									libelle: documentsResponse.libelle,
									id_docuware: documentsResponse.id_docuware,
									type_document: documentsResponse.type_document.split('- ').slice(1).join('- ')
								}
							)
						}
					)

					setDocumentsUtiles(tmpDocuments)
				}
			)
			.catch(
				(error: any) => dispatch(
					errorCatcher(error, DOCUMENTS_ERROR)
				)
			)
			.then(
				() => {
					fetchFactory('/api/rubrique/comptable')
						.then(
							(rubrique_comptable: any) => {
								if (rubrique_comptable === true) {
									setBoutons(
										[
											{
												id: 1,
												titre: titreLivretActionnaire,
												description: descriptionLivretActionnaire,
												texte: texteLivretActionnaire,
												icon: '/img/pictos_guide.svg'
											},
											{
												id: 2,
												titre: titreOrdreBourse,
												description: descriptionOrdreBourse,
												texte: texteOrdreBourse,
												icon: '/img/pictos_bourse.svg'
											},
											{
												id: 3,
												titre: titreDemandePEE,
												description: descriptionDemandePEE,
												texte: texteDemandePEE,
												icon: '/img/pictos_bourse.svg'
											},
											{
												id: 4,
												titre: titreDemandeInscription,
												description: descriptionDemandeInscription,
												texte: texteDemandeInscription,
												icon: '/img/pictos_bourse.svg'
											},
											{
												id: 5,
												titre: titreOptionFiscale,
												description: descriptionOptionFiscale,
												texte: texteOptionFiscale,
												icon: '/img/pictos_bourse.svg'
											}
										]
									)
								} else {
									setBoutons(
										[
											{
												id: 1,
												titre: titreLivretActionnaire,
												description: descriptionLivretActionnaire,
												texte: texteLivretActionnaire,
												icon: '/img/pictos_guide.svg'
											},
											{
												id: 2,
												titre: titreOrdreBourse,
												description: descriptionOrdreBourse,
												texte: texteOrdreBourse,
												icon: '/img/pictos_bourse.svg'
											},
											{
												id: 3,
												titre: titreDemandeInscription,
												description: descriptionDemandeInscription,
												texte: texteDemandeInscription,
												icon: '/img/pictos_bourse.svg'
											},
											{
												id: 4,
												titre: titreOptionFiscale,
												description: descriptionOptionFiscale,
												texte: texteOptionFiscale,
												icon: '/img/pictos_bourse.svg'
											}
										]
									)
								}
							}
						)
						.catch(
							(error: any) => {

								if (error.status === 404) {
									dispatch(
										afficherSnackbar(
											{
												id: IDDOC,
												message: 'Le fichier que vous souhaitez télécharger n\'existe pas',
												open: true,
												hideIcon: true
											}
										)
									)
								} else if (error.status === 403) {
									dispatch(
										afficherSnackbar(
											{
												id: IDDOC,
												message: 'Le titulaire est inconnu',
												open: true,
												hideIcon: true
											}
										)
									)
								}
							}
						).finally(
						() => {
							setLoading(false)
						}
					)
				}
			)
	}, [dispatch])

	return (
		<>
			<EnhancedEntete titre={TITRE} />
			{!loading ?
				<>
					<OngletsDocuments documentsUtiles={documentsUtiles} />
					<MediaQuery
						maxWidth={1024}
					>
						{
							(match) => {
								return Links.documentsUtiles === location.pathname ? (match ? (
										<>
											<EnhancedCorpsDocuments boutons={boutons} tailleEcran="petit" />
										</>
									) : (
										<>
											<EnhancedCorpsDocuments boutons={boutons} tailleEcran="grand" />
										</>
									))
									: (match ? (
										<>
											<CorpsDocumentsPerso documentsUtiles={documentsUtiles} tailleEcran="petit" />
										</>
									) : (
										<>
											<CorpsDocumentsPerso documentsUtiles={documentsUtiles} tailleEcran="grand" />
										</>
									))
							}
						}
					</MediaQuery>
				</>
				:
				<div className={classes.spinnerStyle}>
					<CircularProgress color="primary" />
				</div>
			}
		</>
	)
}

export default Documents
