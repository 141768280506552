import React, { useEffect, useState } from 'react'
import { slide as MenuBurger } from 'react-burger-menu'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { makeStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import Links from '../../enum/links'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import thousandsSeparator from '../../utils/thousandsSeparator'
import { useSelector } from 'react-redux'
import { RootState } from '../../modules/reducers'
import { Badge } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	title: {
		position: 'absolute',
		top: 15
	},
	icon: {
		float: 'right',
		color: theme.palette.secondary.main,
		fontSize: 40
	},
	textStyle: {
		height: 40,
		display: 'flex !important',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	menuStyle: {
		height: 90,
		display: 'flex !important',
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	expansionPanel: {
		background: theme.palette.blue.main,
		boxShadow: 'inherit',

		'& > div:first-child': {
			padding: 0,

			'& h1': {
				color: 'white',
				fontSize: 16,
				fontWeight: 600,
				fontFamily: theme.typography.fontFamilyMonserrat
			},
			'& svg': {
				color: 'white',
				width: 40,
				height: 40
			}
		},
		'& .MuiExpansionPanelDetails-root': {
			padding: 0,
			flexDirection: 'column',

			'& a': {
				color: 'white',
				textDecoration: 'none'
			}
		}
	},
	lastMenuItem: {
		display: 'flex !important',
		justifyContent: 'space-between',

		'& > div:first-child': {
			maxWidth: '70%'
		},
		'& > p span': {
			display: 'flex',
			alignItems: 'center',

			'& svg': {
				marginRight: 5
			}
		}
	},
	userName: {
		display: 'flex !important',
		alignItems: 'center',
		marginTop: 30,
		marginBottom: 0,

		'& img': {
			width: 40,
			marginRight: 20
		}
	}
}))

interface AcceptingProps {
	onClick: (lien: string) => void
}

const CustomBadge = withStyles(
	(theme) => (
		{
			badge: {
				top: -5,
				right: -15,
				backgroundColor: 'white',
				color: theme.palette.blue.main
			}
		}
	)
)(Badge)


type MenuPetitEcranProps =
	WithStyles<typeof styles>
	& AcceptingProps

const MenuPetitEcran: React.FC<MenuPetitEcranProps> = (
	{
		onClick
	}
) => {
	const classes = useStyles()
	const user = useSelector((state: RootState) => state.utilisateur)

	const [coursGecina, setCoursGecina] = useState<number>(0)

	useEffect(
		() => {
			fetch('https://www.gecina.fr/fr/gecina-finance/stock-prices')
				.then(
					(response: any) => response.json().then(
						(response: any) => setCoursGecina(Number(response[0].fr.last_quotation_rate.split(' €')[0].replace(',', '.')))
					)
				)
		}, []
	)

	const logout = () => {
		localStorage.removeItem('utilisateur')
		onClick(Links.login)
	}

	const titreClass: ClassValue = classNames(
		classes.title,
		classes.titleSize
	)

	const itemClass: ClassValue = classNames(
		'menu-item',
		classes.textStyle,
		classes.text,
		classes.semiBold,
		classes.textSize
	)

	const itemSmallClass: ClassValue = classNames(
		'menu-item--small',
		classes.textStyle,
		classes.text,
		classes.semiBold,
		classes.textSize
	)
	return (
		<div id="outer-container" className="col-xs-2">
			<span className={classes.menuStyle}>Menu</span>
			<MenuBurger
				pageWrapId="App"
				outerContainerId="outer-container"
				right
				width="100%"
			>
				<span
					className={titreClass}
				>
					Espace actionnaire
				</span>

				<a
					id="home"
					className={itemClass}
					href={Links.accueil}
				>
					Accueil

					<ArrowRightAltIcon className={classes.icon} />
				</a>

				<a
					id="home"
					className={itemClass}
					href={Links.compteTitres}
				>
					Votre Compte-Titres

					<ArrowRightAltIcon className={classes.icon} />
				</a>

				<a
					id="about"
					className={itemClass}
					href={Links.documentsUtiles}
				>
					<CustomBadge
						badgeContent={user.nbDocuments}
						color="primary"
						invisible={user.nbDocuments === 0}
					>
						Vos documents
					</CustomBadge>

					<ArrowRightAltIcon className={classes.icon} />
				</a>

				<a
					id="eServices"
					className={itemClass}
					href={Links.eServices}
				>
					E-services

					<ArrowRightAltIcon className={classes.icon} />
				</a>

				<a
					id="contact"
					className={itemClass}
					href={Links.contacts}
				>
					Vos contacts

					<ArrowRightAltIcon className={classes.icon} />
				</a>

				<a
					id="contact"
					className={itemClass}
					href={Links.clubActionnaires}
				>
					<CustomBadge
						badgeContent={1}
						color="primary"
						invisible={user.aVuNouveauteActionnaire}
					>
						Le Club des Actionnaires
					</CustomBadge>

					<ArrowRightAltIcon className={classes.icon} />
				</a>

				<div className={classes.userName}>
					<img src="/img/pictos-profil.svg" alt="Picto profil" />

					<span className={itemClass}>{user.utilisateur}</span>
				</div>

				<div className={classes.lastMenuItem}>
					<ExpansionPanel className={classes.expansionPanel}>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="profile-panel"
							id="profile-panel"
						>
							<h1>Votre profil</h1>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<a
								className={itemSmallClass}
								href={Links.donneesPerso}
							>
								Vos données personnelles

								<ArrowRightAltIcon className={classes.icon} />
							</a>

							<a
								className={itemSmallClass}
								href={Links.coordonneesBancaires}
							>
								Vos coordonnées bancaires

								<ArrowRightAltIcon className={classes.icon} />
							</a>

							<a
								className={itemSmallClass}
								href={Links.login}
								onClick={logout}
							>
								Déconnexion

								<ArrowRightAltIcon className={classes.icon} />
							</a>
						</ExpansionPanelDetails>
					</ExpansionPanel>

					<p
						className={itemSmallClass}
					>
						<span>
							<ShowChartIcon color="secondary" />
							{thousandsSeparator(coursGecina)}€
						</span>
					</p>
				</div>
			</MenuBurger>
		</div>
	)
}

const EnhancedMenuPetitEcran = compose<MenuPetitEcranProps, AcceptingProps>(
	withStyles(styles)
)(MenuPetitEcran)

export default EnhancedMenuPetitEcran
