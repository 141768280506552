import React, {useEffect} from 'react'
import styles from '../../theme/styles'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {CircularProgress} from '@material-ui/core'
import fetchFactory from '../../utils/fetchFactory'
import errorCatcher from '../../utils/errorCatcher'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import EnhancedBoutonEServices from '../eServices/BoutonEServices'
import MaterialDialog from '@material-ui/core/Dialog'
import Dialog from '../../components/dialog/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import EnhancedFormulaireEServices from './FormulaireEServices'
import {useDispatch} from 'react-redux'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    spinnerStyle: {
        marginTop: 50,
        textAlign: 'center',
        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    },
    space: {
        whiteSpace: 'break-spaces'
    },
    fontSize: {
        fontSize: 20
    },
    width: {
        width: '100%'
    },
    marginLeft: {
        marginLeft: 'auto'
    },
    marginBot: {
        marginBottom: 0
    },
    marginBotRow: {
        marginBottom: 40
    },
    alignCenter: {
        alignSelf: 'baseline'
    },
    margin: {
        margin: 0
    },
    marginInfo: {
        margin: '20px 0'
    },
    marginTop: {
        marginTop: 40
    },
    marginTitreCadre: {
        margin: '0 0 15px 0'
    },
    padding: {
        padding: 20
    },
    marginButton: {
        marginTop: 50
    },
    justify: {
        textAlign: 'justify'
    },
    blueMontserrat: {
        color: theme.palette.blue.main,
        fontFamily: theme.typography.fontFamilyMonserrat,
    },
    messageInfoStyle: {
        lineHeight: '23px'
    }
}))

interface AcceptingProps {
    tailleEcran: 'petit' | 'grand'
}

type CorpsEServicesProps = AcceptingProps

const ESERVICES: string = 'souscription'
const TITRE1: string = 'Adhésion à la e-convocation'
const TITRE2: string = 'Adresse d\'envoi de vos e-convocations'

const CorpsEServices: React.FC<CorpsEServicesProps> = (
    {
        tailleEcran
    }
) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState<boolean>(true)
    const [souscription, setSouscription] = React.useState<'O' | 'N' | ''>('')
    const [email, setEmail] = React.useState<string>('')
    const [open, setOpen] = React.useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    let titre3: string = ''
    let souscriptionStatut: string = ''
    let souscriptionParenthesis: string = ''
    let mailNonDefinie: string = ''
    let mailNullParenthesis: string = ''
    let mailParenthesis: string = ''
    let infoMessage: string = ''
    let infoMessage1: string = ''
    let infoMessage2: string = ''

    useEffect(() => {
        fetchFactory('/api/souscription')
            .then(
                (souscriptionResponse: any) => {
                    setSouscription(souscriptionResponse.souscription)
                    setEmail(souscriptionResponse.email)
                }
            )
            .catch(
                (error: any) => dispatch(
                    errorCatcher(error, ESERVICES)
                )
            )
            .finally(
                () => {
                    setLoading(false)
                }
            )
    },[dispatch])

    const containerClass: ClassValue = classNames(
        'row',
        classes.marginLeft
    )

    const titreClass: ClassValue = classNames(
        classes.width,
        classes.marginBot,
        classes.semiBold,
        classes.blueMontserrat
    )

    const cadreTitreClass: ClassValue = classNames(
        classes.width,
        classes.semiBold,
        classes.marginTitreCadre,
        classes.blueMontserrat
    )

    const souscriptionEmailClass: ClassValue = classNames(
        classes.space,
        classes.fontSize,
        classes.alignCenter,
        classes.margin,
        classes.blueMontserrat
    )

    const messageInfoClass: ClassValue = classNames(
        classes.alignCenter,
        classes.margin,
        classes.space,
        classes.blueMontserrat,
        classes.justify,
        classes.messageInfoStyle
    )

    const parenthesisClass: ClassValue = classNames(
        classes.alignCenter,
        classes.margin,
        classes.space,
        classes.blueMontserrat
    )

    const cadreInfoClass: ClassValue = classNames(
        'row col-xs-12',
        classes.backgroundWhite,
        classes.marginTop,
        classes.padding
    )

    const rowClass: ClassValue = classNames(
        'row col-xs-12',
        classes.marginBotRow
    )

    const boutonClass: ClassValue = classNames(
        'row center-xs col-xs-12',
        classes.marginButton
    )

    if (souscription === 'O') {
        souscriptionStatut = 'Oui '
        souscriptionParenthesis = '(vous recevrez votre convocation par courriel)'
        mailParenthesis = ' (adresse modifiable dans vos coordonnées personnelles)'
        titre3 = 'Rappel du fonctionnement de la e-convocation'
        mailNullParenthesis = '(accessible dans votre menu de profil)'
        infoMessage1 = 'Votre convocation vous sera adressée par e-mail quelques semaines avant la date de la tenue de l’assemblée générale. Elle contiendra les dates, heures et lieux de l’assemblée ainsi qu’un lien direct au site '
        infoMessage2 = 'Vous serez ensuite redirigé vers la plateforme sécurisée VOTACCESS où vous pourrez consulter l’ensemble de la documentation relative à l’Assemblée, demander une carte d’admission, voter les résolutions, donner pouvoir au Président de l’Assemblée ou à toute autre personne de votre choix, ….'
        mailNonDefinie = 'Adresse non définie. Merci de la communiquer via la page \'Vos coordonnées personnelles\''
    } else {
        souscriptionStatut = 'Non '
        souscriptionParenthesis = '(vous recevrez votre convocation par courrier postal)'
        titre3 = 'Optez pour la simplicité'
        infoMessage = 'En choisissant la e-convocation, vous bénéficirez d\'un service gratuit, rapide, ' +
            'simple à utiliser et vous contribuez à réduire la consommation de papier.\n' +
            'Pour passer dès maintenant à la e-convocation, transmettez nous votre demande d’adhésion.'
    }

    return (
        <>
            {!loading ? (
                <div className={containerClass}>
                    <div className={rowClass}>
                        <p className={titreClass}>{TITRE1}</p>
                        <p className={souscriptionEmailClass}>{souscriptionStatut}</p>
                        <p className={parenthesisClass}>{souscriptionParenthesis}</p>
                    </div>

                    {
                        souscription === 'O' ?
                            (
                                email !== '' ?
                                    <div className={rowClass}>
                                        <p className={titreClass}>{TITRE2}</p>
                                        <p className={souscriptionEmailClass}>{email}</p>
                                        <p className={parenthesisClass}>{mailParenthesis}</p>
                                    </div>
                                    :
                                    <div className={rowClass}>
                                        <p className={titreClass}>{TITRE2}</p>
                                        <p className={souscriptionEmailClass}>{mailNonDefinie}</p>
                                        <p className={parenthesisClass}>{mailNullParenthesis}</p>
                                    </div>
                            )
                            :
                            ''
                    }

                    <div className={cadreInfoClass}>
                        <p className={cadreTitreClass}>{titre3}</p>
                        {
                            souscription === 'O' ?
                                <>
                                    <p className={messageInfoClass}>{infoMessage1} <a rel="noopener noreferrer" target="_blank" href="http://www.nomi.olisnet.com">nomi.olisnet.com</a>.</p>

                                    <p className={messageInfoClass}>{infoMessage2}</p>
                                </>

                            :
                                <p className={messageInfoClass}>{infoMessage}</p>
                        }

                    </div>
                    <div
                        className={boutonClass}
                    >
                        <div className="col-xs-12 col-sm-5">
                            <EnhancedBoutonEServices adhesion={souscription} openDialog={() => setOpen(true)}/>
                        </div>
                    </div>

                    <MaterialDialog
                        fullScreen={fullScreen}
                        open={open}
                        aria-labelledby="responsive-dialog-title"
                        fullWidth
                        maxWidth="md"
                    >
                        <Dialog
                            hasButton={false}
                            onCloseDialog={() => setOpen(false)}
                        >
                            <EnhancedFormulaireEServices
                                closeDialog={() => setOpen(false)}
                                tailleEcran={tailleEcran}
                                souscription={souscription}
                            />
                        </Dialog>
                    </MaterialDialog>
                </div>
            ) : (
                <div className={classes.spinnerStyle}>
                    <CircularProgress color="primary"/>
                </div>
            )

            }
        </>
    )
}

export default CorpsEServices
