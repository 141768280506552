import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'

const useStyle = makeStyles(
	(theme) => (
		{
			...styles(theme),
			mentionsContainerClass: {
				'& h1': {
					margin: '40px 0 15px',
					color: theme.palette.primary.main,
					...styles(theme).title,
					fontSize: 24
				},

				'& p': {
					margin: 0,
					color: '#6A6A6A',
					...styles(theme).text,
					...styles(theme).titleSize,

					'& strong': {
						marginTop: 20
					}
				},

				'& .title': {
					'& h1': {
						marginBottom: 30,
						textAlign: 'center'
					}
				}
			}
		}
	)
)

const MentionsLegales = () => {
	const classes = useStyle()

	return (
		<div className={classes.mentionsContainerClass}>
			<div className="title">
				<h1>MENTIONS LEGALES</h1>

				<p>Gecina propose aux actionnaires dont les comptes-titres sont gérés en direct par le Service Titres & Bourse
					de la Société d’accéder à un espace de consultation sur Internet de leurs avoirs investis en valeurs mobilières
					Gecina.</p>
			</div>

			<div className="informations">
				<h1>INFORMATIONS DISPONIBLES SUR LE SITE :</h1>

				<p>Les informations disponibles sur ce site sont tout d’abord celles fournies par les actionnaires dans le cadre de la
					gestion administrative du compte-titres ouvert dans les registres d’actionnaires nominatifs de la Société et
					gérés au Nominatif Pur par le Service Titres & Bourse de la Société.</p>

				<br />

				<p>L’actionnaire aura la faculté de modifier toute information le concernant en remplissant un formulaire en ligne
					à retourner à l’adresse suivante : <a
						href="mailto:titres&bourse@gecina.fr">titres&bourse@gecina.fr</a></p>

				<br />

				<p>L’actionnaire peut aussi consulter le contenu et la valeur de son portefeuille.</p>

				<br />

				<p>Il est précisé que le cours des actions est fourni par un prestataire extérieur à Gecina. Ni Gecina ni son
					fournisseur d'informations ne sauraient être tenus pour responsables des erreurs ou délais dans la transmission
					d'informations et de leurs éventuelles conséquences.</p>
			</div>

			<div className="editor">
				<h1>EDITEUR :</h1>

				<p><strong>GECINA</strong></p>
				<p>Société Anonyme au capital de 573 949 530 €</p>
				<p>RCS PARIS: 592 014 476</p>
				<p>SIRET : 592 014 476 00150 </p>
				<p>N° TVA Intracommunautaire : FR 18 592 014 476</p>
				<p>Siège social : 14-16, rue des Capucines 75002 PARIS</p>
				<p>Adresse postale : 16, rue des Capucines 75084 PARIS CEDEX 02</p>
				<p>Tél. : +33 (1) 40 40 50 50</p>
				<p>Adresse électronique : <a href="mailto:titres&bourse@gecina.fr">titres&bourse@gecina.fr</a></p>
			</div>

			<div className="direction">
				<h1>DIRECTEUR DE LA PUBLICATION :</h1>

				<p>Méka BRUNEL, Directrice Générale de Gecina</p>
			</div>

			<div className="redaction">
				<h1>RESPONSABLE DE LA REDACTION :</h1>

				<p>Laurent LE GOFF, Responsable Communication Financière et Actionnariat individuel</p>
			</div>

			<div className="accomodation">
				<h1>HÉBERGEMENT INFORMATIQUE :</h1>

				<p><strong>PLATFORM.SH</strong></p>
				<p>131, Boulevard de Sébastopol</p>
				<p>75002 PARIS</p>
				<p>Tél. : +33 (0)1 40 09 30 00</p>
			</div>

			<div className="conception">
				<h1>CONCEPTION & RÉALISATION :</h1>

				<p><strong>IPSOSENSO</strong></p>
				<p>1 rue Saint-Georges</p>
				<p>75009 PARIS</p>
				<p>Tél. : + 33 (0)1 56 35 00 50</p>
				<p><a href="www.ipsosenso.com">www.ipsosenso.com</a></p>
			</div>

			<div className="pictures">
				<h1>CREDIT PHOTOS :</h1>

				<p>Les photos utilisées sont fournies par GettyImages, L’Autre Image, photothèque Gecina</p>
			</div>
		</div>
	)
}

export default MentionsLegales
