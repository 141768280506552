import React, {Dispatch, useEffect} from 'react'
import fetchFactory from '../../utils/fetchFactory'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import styles from '../../theme/styles'
import errorCatcher from '../../utils/errorCatcher'
import {makeStyles} from '@material-ui/core/styles'
import {ajouterFormulaireAdministration} from '../reducers/formulaireAdministration/actions'
import EnhancedFormulaireAdministration from './FormulaireAdministration'
import classNames from 'classnames'
import Links from '../../enum/links'
import {useLocation} from 'react-router-dom'
import EnhancedFormulaireAdministrationClub from './FormulaireAdministrationClub'

const useStyle = makeStyles(theme => ({
    ...styles(theme),
    titreStyle: {
        fontSize: 40,
        margin: '50px 0 30px',
        textAlign: 'center'
    }

}))

interface ModelAdministration {
    titre: string,
    accroche: string,
    presentation: string,
    leMotBrunel: string,
    titreClub: string,
    accrocheClub: string,
    presentationClub: string,
    titreActualiteClub: string,
    accrocheActualiteClub: string,
    actualiteClub: string,
    libelle_1: string,
    libelle_2: string,
    url_1: string,
    url_2: string
}

interface Store {
    errorCatcher: (error: any, id: string) => void,
    ajouterFormulaireAdministration: (formulaireAdministration: ModelAdministration) => void
}

const titreAccueil: string = 'Administration de la page d\'accueil'
const titreClub: string = 'Administration de la page du club des actionnaires'

type AdministrationProps = Store

const Administration: React.FC<AdministrationProps> = (
    {
        errorCatcher,
        ajouterFormulaireAdministration
    }
) => {

    const classes = useStyle()
    const location = useLocation()

    const lien = location.pathname

    useEffect(
        () => {
            fetchFactory('/api/blocs')
                .then(
                    (administration: any) => {
                        ajouterFormulaireAdministration(administration)
                    }
                )
                .catch(
                    (error) => {
                        errorCatcher(error, 'administration')
                    }
                )
        }, [ajouterFormulaireAdministration, errorCatcher, lien]
    )

    return (
        <>
            <div className="row">
                <div
                    className={classNames(
                        'col-xs-12',
                        classes.title,
                        classes.colorPrimary,
                        classes.titreStyle
                    )
                    }
                >
                    {Links.administrationAccueil.includes(lien) ? titreAccueil : titreClub}
                </div>
            </div>

            {
                Links.administrationAccueil.includes(lien) ? <EnhancedFormulaireAdministration/> :
                    <EnhancedFormulaireAdministrationClub/>
            }

        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        },
        ajouterFormulaireAdministration: (formulaireAdministration: ModelAdministration) => {
            dispatch(ajouterFormulaireAdministration(formulaireAdministration))
        }
    }
}

const EnhancedAdministration = compose<AdministrationProps, {}>(
    connect(null, mapDispatchToProps)
)(Administration)

export default EnhancedAdministration
