import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import EnhancedCartesContacts from './CartesContacts'
import classNames from 'classnames'
import Dialog from '../../../components/dialog/Dialog'
import MaterialDialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ContactForm from './ContactForm'

const useStyles = makeStyles(theme => (
		{
			...styles(theme),
			textStyleGrand: {
				marginBottom: 150,
				textAlign: 'center'
			},
			textStylePetit: {
				marginBottom: 110,
				fontSize: 12
			},
			textStyle: {
				background: 'white',
				opacity: 0.6,
				padding: '25px 15px'
			}
		}
	)
)

interface AcceptingProps {
	tailleEcran: 'grand' | 'petit'
}

type CorpsContactsProps = AcceptingProps

const CorpsContacts: React.FC<CorpsContactsProps> = (
	{
		tailleEcran
	}
) => {
	const classes = useStyles()

	// Dialog's props
	const [open, setOpen] = React.useState(false)
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<>
			<div className="row">
				<div className="col-xs-12">
					<p
						className={
							classNames(
								classes.colorPrimary,
								classes.titleSize,
								classes.text,
								classes.semiBold,
								tailleEcran === 'grand' ? classes.textStyleGrand : classes.textStylePetit,
								classes.textStyle
							)
						}
					>
						Nos équipes sont à votre disposition pour répondre à toutes vos demandes de renseignement.
					</p>

					<EnhancedCartesContacts
						tailleEcran={tailleEcran}
						openDialog={() => setOpen(true)}
					/>
				</div>
			</div>

			<MaterialDialog
				fullScreen={fullScreen}
				open={open}
				aria-labelledby="responsive-dialog-title"
				fullWidth
				maxWidth="md"
			>
				<Dialog
					hasButton={false}
					onCloseDialog={() => setOpen(false)}
				>
					<ContactForm
						closeDialog={() => setOpen(false)}
						tailleEcran={tailleEcran}
					/>
				</Dialog>
			</MaterialDialog>
		</>
	)
}

export default CorpsContacts
