import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../theme/styles'

const useStyle = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            glossaireContainer: {
                '& h1': {
                    margin: '40px 0 15px',
                    color: theme.palette.primary.main,
                    ...styles(theme).title,
                    fontSize: 24
                },

                '& p': {
                    textAlign: 'justify',
                    marginBottom: 0,
                    color: '#000000',
                    ...styles(theme).text,
                    ...styles(theme).titleSize,


                    '& i': {
                        color: theme.palette.primary.main,
                        fontWeight: 600
                    }
                },

                '& .title': {
                    '& h1': {
                        marginBottom: 30,
                        textAlign: 'center'
                    }
                }
            }
        }
    )
)

const Glossaire = () => {
    const classes = useStyle()

    return (
        <div className={classes.glossaireContainer}>
            <div className="title">
                <h1>Glossaire</h1>
            </div>

            <br/>

<p>
	<i>Action  :</i>
</p>
<p>Titre négociable représentant la propriété d’une fraction de capital d’une société de capitaux.</p>
<br/>
<p>
	<i>Assemblée Générale Extraordinaire (AGE)  :</i>
</p>
<p>C’est la réunion des actionnaires concernant les questions autres que les évènements ordinaires de la société. Elle est compétente pour les résolutions proposant des modifications statutaires.</p>
<br/>
<p>
	<i>Assemblée Générale Mixte (AGM)  :</i>
</p>
<p>Lors d’une même réunion, certaines résolutions sont de la compétence de l’AGO et d’autres de celles de l’AGE.</p>
<br/>
<p>
	<i>Assemblée Générale Ordinaire (AGO)  :</i>
</p>
<p>C'est la réunion des actionnaires d'une société. Elle se tient au moins une fois par an, dans les six mois suivant la clôture de l'exercice, et a pour objectif principal de présenter aux actionnaires le déroulement des activités de l'entreprise. L'AGO a plusieurs fonctions : l'approbation des comptes et du dividende, ainsi que la nomination des administrateurs.</p>
<br/>
<p>
	<i>Autorité des Marchés Financiers (AMF)  :</i>
</p>
<p>Créée par la Loi de sécurité financière du 1er août 2003, l’AMF est issue de la fusion entre la COB (Commission des Opérations de Bourse), le CMF (Conseil des Marchés Financiers) et le Conseil de discipline de la gestion financière. C’est une autorité administrative indépendante chargée de contrôler notamment l’information fournie par les sociétés cotées en bourse et tous les organismes faisant appel public à l’épargne. L’AMF contrôle également le bon fonctionnement des divers marchés financiers. Elle peut sanctionner directement les délits boursiers.</p>
<br/>
<p>
	<i>Capitalisation boursière  :</i>
</p>
<p>Valeur boursière d’une société cotée en Bourse. Elle est obtenue en multipliant le nombre d’actions de la société par le cours de l’action.</p>
<br/>
<p>
	<i>Code ISIN (International Securities Identification Number)  :</i>
</p>
<p>Il s’agit du code du titre coté en Bourse.</p>
<br/>
<p>
	<i>Compartiment Euronext  :</i>
</p>
<p>Depuis février 2005, les sociétés cotées sont classées par ordre alphabétique et sont identifiables grâce à un critère de capitalisation qui permet de distinguer : le compartiment A pour les capitalisations supérieures à 1 milliard d’euros ; le compartiment B pour les capitalisations comprises entre 150 millions et 1 milliard d’euros ; le compartiment C pour les capitalisations inférieures à 150 millions d’euros.  </p>
<br/>
<p>
	<i>Date d'arrêté (« record date »)  :</i>
</p>
<p>Date à laquelle les positions sont arrêtées en fin de journée chez le Dépositaire Central, afin d’identifier les actionnaires qui recevront le dividende.</p>
<br/>
<p>
	<i>Date de détachement du coupon (« ex date »)  :</i>
</p>
<p>Date à partir de laquelle la négociation en Bourse est exécutée sur l'action sans droit au versement à venir du dividende.</p>
<br/>
<p>
	<i>Dividende  :</i>
</p>
<p>C'est la part du bénéfice net, y compris les reports bénéficiaires des années antérieures, majoré des réserves disponibles, qui peut être distribué chaque année aux actionnaires. L'Assemblée Générale en fixe le montant par action ainsi que la date à laquelle il est payé.</p>
<br/>
<p>
	<i>Indice boursier  :</i>
</p>
<p>Un indice boursier est une valeur calculée par regroupement des titres de plusieurs sociétés, qui sert à représenter un marché boursier ou un secteur de marché particulier.</p>
<br/>
<p>
	<i>Nominatif administré  :</i>
</p>
<p>Les titres sont inscrits dans les comptes de la société, mais conservés chez l'intermédiaire financier qui gère les titres.</p>
<br/>
<p>
	<i>Nominatif pur  :</i>
</p>
<p>Les titres sont inscrits directement et uniquement dans les registres de la société.</p>
<br/>
<p>
	<i>Plus-value  :</i>
</p>
<p>Différence positive entre le prix perçu au moment de la vente d’une action et son prix d’acquisition. Lorsque cette différence est négative, on parle de moins-value.</p>
<br/>
<p>
	<i>SBF 120  :</i>
</p>
<p>Indice d’Euronext Paris (base 1000 le 31/12/90), calculé en continu toutes les 30 secondes à partir d’un panier de 40 actions sélectionnées pour leur représentativité et leur importance et qui constituent le CAC 40 ; et de 80 valeurs les plus liquides cotés à Paris parmi les 200 premières capitalisations boursières françaises. SBF signifie « Sociétés des Bourses Françaises ».</p>
<br/>
<p>
	<i>Service de règlement différé (SRD)  :</i>
</p>
<p>Marché où se négocient les actions des principales actions françaises et étrangères. Le SRD est un service qui permet de différer le règlement ou la livraison des titres. Le règlement-livraison a lieu le dernier jour de bourse du mois.</p>
<br/>
<p>
	<i>Titre  :</i>
</p>
<p>Le mot titre est utilisé comme synonyme de « valeur mobilière », catégorie à laquelle appartiennent entre autres les actions.</p>
<br/>
<p>
	<i>Titre au nominatif  :</i>
</p>
<p>Ces formes de détention nous permettent de vous identifier et d’établir avec vous un contact direct et personnalisé. Il existe deux modes d’inscription au nominatif : le nominatif administré, le nominatif pur.</p>
<br/>
<p>
	<i>Titre au porteur  :</i>
</p>
<p>Les comptes des titres au porteur sont tenus par un intermédiaire financier au choix de l’actionnaire (établissements de crédit ou entreprises d’investissement) et ne sont donc pas connus de la société. Ce système permet de regrouper tous les titres d’un portefeuille au sein d’un seul et même compte.</p>
<br/>
<p>
	<i>Valeur nominale  :</i>
</p>
<p>La valeur nominale (ou « le nominal ») d’une action résulte de la division du capital de la société par le nombre d’actions émises.</p>
<br/>
<p>
	<i>Valeurs mobilières  :</i>
</p>
<p>Les valeurs mobilières sont des titres négociables représentatifs d’un droit ou d’une créance. Elles peuvent être cotées en bourse. Les deux grandes catégories de valeurs mobilières sont les actions et les obligations. Il en existe d'autres telles que les certificats d'investissement, les bons de souscription, les bons de trésorerie émis en série, les OPCVM, etc.</p>

        </div>
    )
}

export default Glossaire