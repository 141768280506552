import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { Field, Form } from 'react-final-form'
import { Box } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import { useDispatch } from 'react-redux'
import styles from '../../../theme/styles'
import { mfaResponseModel, submitModel, submitPostModel } from '../type/logInModel'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import loginStatusEnum from './enum/loginStatusEnum'
import errorCatcher from '../../../utils/errorCatcher'
import { authentification, errors } from '../utils/commonConst'
import ErrorCard from '../../../components/errorCard/ErrorCard'
import FormTextField from '../../../components/fields/FormTextField'
import Button from '../../../components/button/Button'
import Link from '../../../components/link/Link'
import localStorageEnum from '../../../enum/localStorageEnum'
import { removeStorage } from '../../../utils/clearStorage'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center'
	},
	fieldStyle: {
		paddingBottom: 30
	},
	betweenField: {
		paddingBottom: 15
	},
	linkStyle: {
		padding: '25px 0px',

		'& a:first-child': {
			marginBottom: 10
		}
	},
	activateStyle: {
		marginBottom: 30,
		marginTop: 15,

		'& button': {
			width: '100%'
		}
	},
	formContainer: {
		'& button': {
			width: '100%'
		}
	}
}))

interface AcceptingProps {
	setGoToResetPwd: (value: boolean) => void,
	setGoToActivateAccount: (value: boolean) => void,
	setGoToMFA: (value: boolean) => void,
	setMfaInfo: (value: mfaResponseModel) => void,
	setIsMFA_ENROLL: (value: boolean) => void,
	setGoToGetCredential: (value: boolean) => void
}

type SignInProps = AcceptingProps

const SignInPage: React.FC<SignInProps> = (
	{
		setGoToResetPwd,
		setGoToActivateAccount,
		setGoToMFA,
		setMfaInfo,
		setIsMFA_ENROLL,
		setGoToGetCredential
	}
) => {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const [incorrectId, setIncorrectId] = useState<boolean>(false)
	const [seePassword, setSeePassword] = useState<boolean>(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorPrimary
	)

	const activateClass: ClassValue = classNames(
		classes.colorPrimary,
		classes.text3
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.betweenField
	)

	useEffect(
		() => {
			removeStorage()
		}, []
	)

	const onSubmit = (values: submitModel) => {

		const dataSignIn: submitPostModel = {
			username: values.email,
			password: values.password
		}

		fetch('/api/okta/login',
			{
				body: JSON.stringify(dataSignIn),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(loginResponse: any) => {
							if (!loginResponse.status) {
								localStorage.setItem(localStorageEnum.codeVerifier, loginResponse.code_verifier)
								window.location.href = loginResponse.redirect
							} else {
								switch (loginResponse.status) {
									case loginStatusEnum.MFA_CHALLENGE:
										//go to enter verification code
										setMfaInfo({
											email: loginResponse.email || '',
											verify_url: loginResponse.verify_url || '',
											state_token: loginResponse.state_token || '',
											factor_id: loginResponse.factor_id || '',
											status: loginResponse.status || ''
										})
										setGoToMFA(true)
										setIsMFA_ENROLL(false)
										break
									case loginStatusEnum.MFA_ENROLL_ACTIVATE:
										setMfaInfo({
											email: loginResponse.email || '',
											verify_url: loginResponse.verify_url || '',
											state_token: loginResponse.state_token || '',
											factor_id: loginResponse.factor_id || '',
											status: loginResponse.status || ''
										})
										setGoToMFA(true)
										setIsMFA_ENROLL(true)
										break
									case loginStatusEnum.FAILURE:
										setIncorrectId(true)
										break
								}
							}
						}
					)
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'sign in')
					)
					setIncorrectId(true)
				}
			)
	}

	const resetCredential = () => {
		setGoToGetCredential(true)
	}

	const resetPwd = () => {
		setGoToResetPwd(true)
	}

	const activateAccount = () => {
		setGoToActivateAccount(true)
	}

	const validate = (
		values: submitModel
	) => {
		const error: any = {}
		if (!values.email) {
			error.email = errors.required
		}
		if (!values.password) {
			error.password = errors.required
		}
		return error
	}

	return (
		<>
			<div className={titleClass}>
				{authentification.title}
			</div>
			{
				incorrectId &&
				<div className={containerErrorClass}>
					<ErrorCard message={errors.loginFailed} />
				</div>
			}
			<Form
				onSubmit={onSubmit}
				validate={validate}
				render={
					(
						{
							handleSubmit
						}
					) => (
						<form
							onSubmit={handleSubmit}
						>
							<div className={classes.formContainer}>
								<div className="col-xs-12">
									<div className={classes.betweenField}>
										<Field
											name="email"
											component={FormTextField}
											placeholder={authentification.placeholderEmail}
										/>
									</div>
									<div className={classes.fieldStyle}>
										<Field
											name="password"
											component={FormTextField}
											placeholder={authentification.placeholderPassword}
											type={seePassword ? 'text' : 'password'}
											showPassword
											seePassword={seePassword}
											handleClickShowPassword={() => setSeePassword(!seePassword)}
										/>
									</div>
									<Button
										background="backgroundPrimary"
										color="colorTertiary"
										type="submit"
										disabled={false}
									>
										{authentification.submit}
									</Button>
								</div>
							</div>
						</form>
					)}
			/>
			<div className="col-xs-12">
				<div className={classes.linkStyle}>
					<Link
						textStyle="text8"
						color="colorPrimary"
						onClick={resetCredential}
					>
						{authentification.credentialForgotten}
					</Link>

					<Link
						textStyle="text8"
						color="colorPrimary"
						onClick={resetPwd}
					>
						{authentification.passwordForgotten}
					</Link>
				</div>
				<div className={activateClass}>
					{authentification.signUp}
				</div>
				<Box border={1} borderColor={theme.palette.blue.main} className={classes.activateStyle}>
					<Button
						background="backgroundWhite"
						color="colorPrimary"
						onClick={activateAccount}
						disabled={false}
					>
						{authentification.signUpAccount}
					</Button>
				</Box>
			</div>
		</>
	)
}

export default SignInPage
