import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {makeStyles} from '@material-ui/core/styles'
import {compose} from 'recompose'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import MaterialTooltip from '@material-ui/core/Tooltip'

const StyledTableCell = withStyles((theme) => ({
    head: {
        height: 34,
        lineHeight: 'inherit',
        padding: '0 10px 10px 0',
        color: theme.palette.blue['50'],
        fontSize: 14,
        fontWeight: 'bold',
        verticalAlign: 'top'
    },
    body: {
        padding: '15px 10px 15px 0px',
        fontSize: 14,

        '& svg': {
            verticalAlign: 'middle'
        }
    }
}))(TableCell)

const useStyles = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            alignRight: {
                textAlign: 'right',
                paddingLeft: 25
            },
            alignCenter: {
                textAlign: 'center'
            },
            tailleTooltip: {
                maxWidth: 200
            },
            verticalAlign: {
                verticalAlign: 'middle',

                '&:first-child': {
                    marginRight: 20
                }
            },
            customWidth: {
                width: '20%'
            },
            noBorder: {
                '& tr:last-child td': {
                    borderBottom: 'inherit'
                }
            },
            tableStyle: {
                margin: '30px auto'
            }
        }
    )
)

export interface ModelColonnes {
    value: string,
    label: string,
    type: string,
    width?: string,
    render?: any
}

interface ModelLignes {
    [key: string]: any
}

interface AcceptingProps {
    colonnes: ModelColonnes[],
    lignes: ModelLignes[]
}

type TableauSimpleProps = AcceptingProps

const TableauSimple: React.FC<TableauSimpleProps> = (
    {
        colonnes,
        lignes
    }
) => {
    const classes = useStyles()

    const tableStyle: ClassValue = classNames(
        classes.tableStyle,
        'row col-md-11',
        'ariaLabel : \'simple table\''
    )
    return (
        <TableContainer>
            <Table className={tableStyle}>
                <TableHead>
                    <TableRow>
                        {
                            colonnes.map((colonne, index) => (
                                    <StyledTableCell
                                        key={index}
                                        style={{width: colonne.width}}
                                        className={
                                            classNames(
                                                classes.text,
                                                (colonne.type === 'number' || colonne.type === 'date' || colonne.label === 'Règlement du plan' || colonne.label === 'Mouvement' || colonne.label === 'Téléchargement') && colonne.label !== 'Date de disponibilité' && classes.alignRight,
                                                colonne.label === 'Date de début de conservation' && classes.customWidth
                                            )
                                        }
                                    >
										<span>
                                            {colonne.label}
                                            {
                                                colonne.label === 'Date de début de conservation' &&
                                                <MaterialTooltip
                                                    title={'Date à partir de laquelle les Actions de performance effectivement reçues ne peuvent être cédées ou converties au porteur'}
                                                    placement="right"
                                                    classes={{tooltip: classes.tailleTooltip}}
                                                >
                                                    <InfoOutlinedIcon/>
                                                </MaterialTooltip>
                                            }
										</span>
                                    </StyledTableCell>
                                )
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody className={classes.noBorder}>
                    {
                        (lignes || []).map((ligne, index) => (
                        <TableRow
                        key={ligne.id ? ligne.id : index}
                        >
                        {
                            (colonnes || []).map((colonne, index) => (
                                <StyledTableCell
                                    key={index}
                                    className={
                                        classNames(
                                            ligne.couleur ? classes[ligne.couleur as keyof typeof classes] : classes.colorPrimary,
                                            classes.text,
                                            (colonne.type === 'number' || colonne.type === 'icon' || colonne.type === 'date' || colonne.label === 'Mouvement') && colonne.label !== 'Date de disponibilité' && classes.alignRight
                                        )
                                    }
                                >
                                    {
                                        <div>
                                            {(colonne.render && colonne.render(ligne)) || ligne[colonne.value]}
                                        </div>
                                    }
                                </StyledTableCell>
                            ))
                        }
                        </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const EnhancedTableauSimple = compose<TableauSimpleProps, AcceptingProps>(
    withStyles(styles)
)(TableauSimple)

export default EnhancedTableauSimple
