import {AJOUTER_ADMINISTRATION} from './actionTypes'

const formulaireAdministration = (state = {}, action) => {

    switch (action.type) {
        case AJOUTER_ADMINISTRATION: {
            return action.payload
        }
        default:
            return state
    }
}

export default formulaireAdministration