import formatDate from '../../utils/formatDate'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import EnhancedTableauSimple from '../../components/table/TableauSimple'
import fetchFactory from '../../utils/fetchFactory'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { afficherSnackbar } from '../reducers/snackBar/actions'
import { ClassValue } from 'classnames/types'
import types, { configEnum } from '../../enum/fetchFactoryEnum'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core'
import Dialog from '../../components/dialog/Dialog'
import PopinMultiTitulaire from './components/PopinMultiTitulaire'
import MaterialDialog from '@material-ui/core/Dialog'
import errorCatcher from '../../utils/errorCatcher'
import { RootState } from '../reducers'
import { updateDocumentLastCheck } from './api/updateDocumentLastCheck'
import { enregistrerUtilisateur } from '../reducers/utilisateur/actions'
import localStorageEnum from '../../enum/localStorageEnum'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	margin: {
		marginBottom: 20,
		marginLeft: 40
	},
	spinnerStyle: {
		marginTop: 50,
		textAlign: 'center',
		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	},
	backgroundColor: {
		backgroundColor: 'white'
	},
	smallTextStyle: {
		fontSize: 10,
		fontWeight: 600
	},
	opacity: {
		opacity: 0.5
	},
	libelleStyle: {
		fontSize: 12,
		fontWeight: 300
	},
	valeurPlanStyle: {
		fontSize: 15,
		fontWeight: 600
	},
	valeurStyle: {
		fontSize: 15,
		fontWeight: 600
	},
	marginBottom: {
		marginBottom: 20
	},
	borderBottom: {
		borderBottom: '1px solid #0000001E',
		marginBottom: 10
	},
	marginLeft: {
		marginLeft: 10
	},
	marginTop: {
		marginTop: 10
	}
}))

interface ModelUtilisateur {
	numero_compte: string,
	numero_titulaire: string,
	type: string,
	derniere_date_modification: string
}

interface ModelColonnes {
	value: string,
	label: string,
	type: string,
	width: string,
	render?: (row: ModelDocumentsUtiles) => React.ReactChild
}

interface ModelDocumentsUtiles {
	id: number,
	id_docuware: number,
	libelle: string,
	date_creation: Date,
	type_document: string
}

interface AcceptingProps {
	tailleEcran: string
	documentsUtiles: ModelDocumentsUtiles[]
}

interface DocToDownload {
	id: number,
	libelle: string
}

type CorpsDocumentsPersoGrandProps = AcceptingProps

const DOWNLOAD_ERROR = 'download_error'
const UPDATE_LAST_CHECK = 'updateDocumentLastCheck'
const LIBELLE: string = 'Document'
const TYPE_DOCUMENT: string = 'Type de document'
const DATE: string = 'Date'
const TELECHARGER: string = 'Télécharger le document'

const CorpsDocumentsPerso = (
	props: CorpsDocumentsPersoGrandProps
) => {
	const {
		tailleEcran,
		documentsUtiles
	} = props
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useDispatch()
	const utilisateur = useSelector((state: RootState) => state.utilisateur)
	const [loadingDdl, setLoadingDdl] = React.useState<boolean>(false)
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [open, setOpen] = React.useState(false)
	const [docToDownload, setDocToDownload] = React.useState<DocToDownload>(
		{
			id: 0,
			libelle: ''
		}
	)

	const checkStatusAccount = (id: number, libelle: string) => {
		const utilisateur: ModelUtilisateur = JSON.parse(localStorage.getItem('utilisateur') || '')
		setDocToDownload(
			{
				id,
				libelle
			}
		)

		if (utilisateur.type.toLowerCase() === 'indivision' || utilisateur.type.toLowerCase() === 'usufruit/nue-propriete' || utilisateur.type.toLowerCase() === 'compte joint de titres') {
			setOpen(true)
		} else {
			dlDoc(id, libelle)
		}
	}

	const onDlConfirmed = (id: number, libelle: string) => {
		setOpen(false)
		dlDoc(id, libelle)
	}

	const dlDoc = (id: number, libelle: string) => {
		setLoadingDdl(true)
		let filename = libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, '') + '.pdf'
		filename = filename.replace(/[^\x20-\x7E]+/g, '')
		fetchFactory(
			'/api/ddlDocument',
			{
				body: JSON.stringify({ id, filename }),
				method: configEnum.post
			},
			types.blob
		)
			.then(
				(blob: any) => {
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = filename
					a.click()
				}
			)
			.catch(
				(error: any) => {
					if (error.status === 404) {
						dispatch(
							afficherSnackbar(
								{
									id: 'documents',
									message: 'Le fichier que vous souhaitez télécharger n\'existe pas',
									open: true,
									hideIcon: true
								}
							)
						)

					} else if (error.status === 403) {
						dispatch(
							afficherSnackbar(
								{
									id: 'documentsTitulaire',
									message: 'Le titulaire est inconnu',
									open: true,
									hideIcon: true
								}
							)
						)
					} else {
						dispatch(
							errorCatcher(error, DOWNLOAD_ERROR)
						)
					}
				}
			).finally(
			() => {
				setLoadingDdl(false)

			}
		)
	}

	const colonnes: ModelColonnes[] = [
		{
			value: 'libelle',
			label: 'Document',
			type: 'string',
			width: '10%',
			render: (row: ModelDocumentsUtiles) => (
				<div>
					{row.libelle !== undefined ? row.libelle : ''}
				</div>
			)
		},
		{
			value: 'type_document',
			label: 'Type de document',
			type: 'string',
			width: '10%',
			render: (row: ModelDocumentsUtiles) => (
				<div>
					{row.type_document !== undefined ? row.type_document : ''}
				</div>
			)
		},
		{
			value: 'date',
			label: 'Date',
			type: 'date',
			width: '3%',
			render: (row: ModelDocumentsUtiles) => (
				<div>
					{row.date_creation !== undefined ? formatDate(row.date_creation) : ''}
				</div>
			)
		},
		{
			value: 'telechargement',
			label: '',
			type: 'icon',
			width: '5%',
			render: (row: ModelDocumentsUtiles) => (
				<div>
					{loadingDdl ?
						<CircularProgress color="primary" />
						:
						<img className={
							classNames(
								classes.cursorPointer
							)}
						     width="20"
						     height="20"
						     src="/img/pictos_telechargement.svg"
						     alt="Picto download"
						     onClick={() => checkStatusAccount(row.id_docuware, row.libelle)} />
					}
				</div>
			)
		}
	]

	const containerStyle: ClassValue = classNames(
		'row',
		classes.backgroundColor
	)

	const smallTextStyle: ClassValue = classNames(
		classes.text,
		classes.colorPrimary,
		classes.smallTextStyle
	)

	const rowStyle: ClassValue = classNames(
		'row middle-xs',
		classes.marginBottom
	)

	const libelleStyle: ClassValue = classNames(
		classes.text,
		classes.colorPrimary,
		classes.opacity,
		classes.libelleStyle
	)
	const valeurPlanStyle: ClassValue = classNames(
		classes.text,
		classes.colorPrimary,
		classes.valeurPlanStyle
	)
	const valeurStyle: ClassValue = classNames(
		classes.text,
		classes.colorPrimary,
		classes.valeurStyle
	)

	const firstRowClass: ClassValue = classNames(
		'row',
		classes.marginTop
	)

	useEffect(
		() => {
			if (utilisateur.nbDocuments > 0) {
				updateDocumentLastCheck()
					.then(
						() => {
							dispatch(
								enregistrerUtilisateur(
									{
										...utilisateur,
										nbDocuments: 0
									}
								)
							)
							localStorage.setItem(localStorageEnum.user, JSON.stringify({ ...utilisateur, nbDocuments: 0 }))
						}
					)
					.catch(
						(error) => dispatch(
							errorCatcher(error, UPDATE_LAST_CHECK)
						)
					)
			}
		}, [dispatch, utilisateur]
	)

	return (
		<>
			{
				tailleEcran === 'grand' ?
					<div className={containerStyle}>
						<EnhancedTableauSimple
							colonnes={colonnes}
							lignes={documentsUtiles}
						/>
					</div>
					:
					<div className={containerStyle}>
						{
							documentsUtiles.map((documentsUtile: ModelDocumentsUtiles, index) => (
									<div className="col-xs-12" key={documentsUtile.id}>
										<>
											<div className={firstRowClass}>
												<div className="col-xs-12">
													<div className={rowStyle}>
														<div className="col-xs-6">
                                                            <span className={libelleStyle}>
                                                                {LIBELLE}
                                                            </span>

															<br />

															<span className={valeurPlanStyle}>
                                                                {documentsUtile.libelle}
                                                            </span>
														</div>

														<div className="col-xs-6 end-xs">

														</div>
													</div>
												</div>
											</div>
											<div className={firstRowClass}>
												<div className="col-xs-12">
													<div className={rowStyle}>
														<div className="col-xs-6">
                                                            <span className={libelleStyle}>
                                                                {TYPE_DOCUMENT}
                                                            </span>

															<br />

															<span className={valeurPlanStyle}>
                                                                {documentsUtile.type_document}
                                                            </span>
														</div>

														<div className="col-xs-6 end-xs">

														</div>
													</div>
												</div>
											</div>
											<div
												className={
													classNames(
														'row',
														{ [classes.borderBottom]: index === documentsUtiles.length - 1 }
													)
												}
											>
												<div className="col-xs-12">
													<div className={rowStyle}>
														<div className="col-xs-6">
                                                            <span className={libelleStyle}>
                                                                {documentsUtile.date_creation && DATE}
                                                            </span>

															<br />

															<span className={valeurStyle}>
                                                                {documentsUtile.date_creation && formatDate(documentsUtile.date_creation)}
                                                            </span>
														</div>

														<div className="col-xs-6 end-xs">
														</div>
													</div>
													<div className={rowStyle}>
														<div className="col-xs-12 end-xs">
                                                            <span
	                                                            onClick={() => checkStatusAccount(documentsUtile.id_docuware, documentsUtile.libelle)}
	                                                            className={smallTextStyle}
                                                            >
                                                                {TELECHARGER}
	                                                            <span className={classes.marginLeft}>
                                                                    <img
	                                                                    src="/img/pictos_telechargement.svg"
	                                                                    alt="telechargement icon"
	                                                                    width="20"
	                                                                    height="20"
                                                                    />
                                                                </span>
                                                            </span>
														</div>
													</div>
												</div>
											</div>
										</>
									</div>
								)
							)
						}
					</div>
			}

			<MaterialDialog
				fullScreen={fullScreen}
				open={open}
				aria-labelledby="responsive-dialog-title"
				fullWidth
				maxWidth="md"
			>
				<Dialog
					hasButton={false}
					onCloseDialog={() => setOpen(false)}
				>
					<PopinMultiTitulaire
						onDlFile={onDlConfirmed}
						onOpenRefused={() => setOpen(false)}
						id={docToDownload.id}
						libelle={docToDownload.libelle}
					/>
				</Dialog>
			</MaterialDialog>
		</>
	)
}

export default CorpsDocumentsPerso
