import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import SignInPage from '../signIn/SignInPage'
import ResetPassword from '../resetPwd/ResetPassword'
import ActivateAccountPage from '../activate/ActivateAccountPage'
import MFA from '../mfa/MFA'
import NewPassword from '../resetPwd/NewPassword'
import { mfaResponseModel } from '../type/logInModel'
import { resetType } from '../resetPwd/enum/resetType'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    formContainer: {
        padding: '25px 42px'
    },
    logInContainer: {
        backgroundColor: 'white',
        border: `1px solid ${theme.palette.blue['20']}`,
        boxShadow: '0 2px 0 rgba(175, 175, 175, 0.12)',
        width: '100%',
        maxWidth: 400
    },
    logoContainer: {
        padding: '30px 70px',
        borderBottom: `1px solid ${theme.palette.blue['20']}`
    },
    logoYF: {
        width: '100%',
        maxWidth: 300
    }
}))

interface AcceptingProps {
    toNewPwd: boolean
}

type LogInPageProps = AcceptingProps

const LogInPage: React.FC<LogInPageProps> = (
    {
        toNewPwd
    }
) => {
    const classes = useStyles()
    const [goToResetPwd, setGoToResetPwd] = React.useState(false)
    const [goToGetCredential, setGoToGetCredential] = React.useState(false)
    const [goToActivateAccount, setGoToActivateAccount] = React.useState(false)
    const [goToMFA, setGoToMFA] = React.useState(false)
    const [goToNewPwd, setGoToNewPwd] = React.useState(toNewPwd)
    const [mfaInfo, setMfaInfo] = React.useState<mfaResponseModel>({
        email: '',
        factor_id: '',
        state_token: '',
        verify_url: '',
        status: ''
    })
    const [isMFA_ENROLL, setIsMFA_ENROLL] = React.useState<boolean>(false)

    return (
        <div className={classes.logInContainer}>
            <div className={classes.logoContainer}>
                <img className={classes.logoYF} src="/img/logo_connexion.png"
                     alt="logo yf"/>
            </div>
            <div className={classes.formContainer}>
                {
                    goToResetPwd || goToGetCredential ?
                        <ResetPassword
                            setGoToResetPwd={setGoToResetPwd}
                            setGoToGetCredential={setGoToGetCredential}
                            type={goToResetPwd ? resetType.password : resetType.credential}
                        />
                        :
                        goToActivateAccount ?
                            <ActivateAccountPage setGoToActivateAccount={setGoToActivateAccount}/>
                            :
                            goToMFA ?
                                <MFA setGoToMFA={setGoToMFA} mfaInfo={mfaInfo} isMFA_ENROLL={isMFA_ENROLL}/>
                                :
                                goToNewPwd ?
                                    <NewPassword setGoToMFA={setGoToMFA} setGoToNewPwd={setGoToNewPwd}
                                                 setMfaInfo={setMfaInfo}/>
                                    :
                                    <SignInPage
                                        setGoToResetPwd={setGoToResetPwd}
                                        setGoToActivateAccount={setGoToActivateAccount}
                                        setGoToMFA={setGoToMFA}
                                        setMfaInfo={setMfaInfo}
                                        setIsMFA_ENROLL={setIsMFA_ENROLL}
                                        setGoToGetCredential={setGoToGetCredential}
                                    />
                }
            </div>
        </div>
    )
}
export default LogInPage