import React, { useCallback, useEffect } from 'react'
import { errors, finishActivation, resetPassword } from '../utils/commonConst'
import ErrorCard from '../../../components/errorCard/ErrorCard'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../../components/fields/FormTextField'
import Button from '../../../components/button/Button'
import { FinishActivationPostModel, finishValidationModel } from '../type/logInModel'
import { isPasswordOk } from '../../../utils/formUtils'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import errorCatcher from '../../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import { useHistory, useRouteMatch } from 'react-router'
import Links from '../../../enum/links'
import { afficherSnackbar } from '../../reducers/snackBar/actions'
import Loader from '../../../components/loader/Loader'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	loginBackground: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		height: 'fit-content',
		minHeight: '100vh',
		width: 'fit-content',
		minWidth: '100vw',
		backgroundImage: 'url(\'/img/visuel_connexion.jpg\')',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	fieldStyle: {
		paddingBottom: 15
	},
	logInContainer: {
		backgroundColor: 'white',
		border: `1px solid ${theme.palette.blue['20']}`,
		boxShadow: '0 2px 0 rgba(175, 175, 175, 0.12)',
		width: '100%',
		maxWidth: 400
	},
	logoContainer: {
		padding: '30px 70px',
		borderBottom: `1px solid ${theme.palette.blue['20']}`
	},
	logoYF: {
		width: '100%',
		maxWidth: 300
	},
	cardContainer: {
		padding: '25px 42px'
	},
	formContainer: {
		'& button': {
			width: '100%'
		}
	},
	textCenter: {
		textAlign: 'center'
	}
}))

interface MatchParamsModel {
	token: string
}

const GET_ACTIVATION_TOKEN = 'finish_activation'
const ACCOUNT_ACTIVATED = 'account_activated'
const FAILURE_STATUS = 'FAILURE'
const PASSWORD_NAME = 'password'
const CONFIRM_PASSWORD_NAME = 'confirmPassword'
const REGISTRATION_SUCCESS = 'SUCCESS'

const ResetPasswordPage = () => {
	const classes = useStyle()
	const dispatch = useDispatch()
	const history = useHistory()
	const match = useRouteMatch<MatchParamsModel>()
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)
	const [errorMessage, setErrorMessage] = React.useState<string>('')
	const [activationToken, setActivationToken] = React.useState<string>('')
	const [loading, setLoading] = React.useState<boolean>(true)
	const [seePassword, setSeePassword] = React.useState<boolean>(false)
	const [seeConfirmPassword, setSeeConfirmPassword] = React.useState<boolean>(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.heavy,
		classes.title4,
		classes.textCenter,
		classes.colorPrimary
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.fieldStyle
	)

	const redirectToLogin = useCallback(
		() => {
			dispatch(
				afficherSnackbar(
					{
						id: GET_ACTIVATION_TOKEN,
						message: 'Le token d\'activation n\'est pas valide ou a expiré',
						open: true,
						hideIcon: true
					}
				)
			)
			history.push(Links.login)
		}, [history, dispatch]
	)

	useEffect(
		() => {
			const tokenParam = match.params.token

			fetch(
				'/api/okta/recovery/token',
				{
					body: JSON.stringify({ token: tokenParam }),
					method: configEnum.post
				}
			)
				.then(
					(response: Response) => response.json()
						.then(
							(activationToken: any) => {
								if (activationToken.status === FAILURE_STATUS) {
									redirectToLogin()
								} else {
									if (activationToken.stateToken) {
										setActivationToken(activationToken.stateToken)
									}
									else {
										redirectToLogin()
									}
								}
							}
						)
						.catch(
							() => redirectToLogin()
						)
						.finally(
							() => setLoading(false)
						)
				)
		}, [dispatch, history, match.params.token, redirectToLogin]
	)

	const onPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault()
		return false
	}

	const onSubmit = (values: finishValidationModel) => {
		const dataRegister: FinishActivationPostModel = {
			newPassword: values.password,
			stateToken: activationToken
		}

		fetch('/api/okta/recovery/password',
			{
				body: JSON.stringify(dataRegister),
				method: configEnum.post
			})
			.then(
				(response: Response) => response.json()
					.then(
						(registerResponse: any) => {
							if (registerResponse.status === REGISTRATION_SUCCESS) {
								dispatch(
									afficherSnackbar(
										{
											id: ACCOUNT_ACTIVATED,
											message: 'Votre mot de passe a bien été changé',
											open: true,
											hideIcon: true
										}
									)
								)
								history.push(Links.login)
							} else {
								setIncorrectCode(true)
								setErrorMessage('Une erreur est survenue lors de la modification du mot de passe, vérifiez que le mot de passe ne soit pas le mot de passe actuel, ni un récent mot de passe')
							}
						}
					)
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'register')
					)
				}
			)
	}

	const validate = (
		values: finishValidationModel
	) => {
		const error: any = {}

		//Validate email and confirmEmail
		if (!values.password) {
			error.password = errors.required
		} else if (!isPasswordOk(values.password)) {
			error.password = errors.formatPassword
		}

		if (!values.confirmPassword) {
			error.confirmPassword = errors.required
		}

		if (values.password && values.confirmPassword && values.confirmPassword !== values.password) {
			error.confirmPassword = errors.notSamePassword
		}

		return error
	}

	if (loading || activationToken.length === 0) {
		return (
			<Loader />
		)
	}

	return (
		<div className={classes.loginBackground}>
			<div className={classes.logInContainer}>
				<div className={classes.logoContainer}>
					<img className={classes.logoYF} src="/img/logo_connexion.png"
					     alt="logo yf" />
				</div>
				<div className={classes.cardContainer}>
					<div className={titleClass}>
						{resetPassword.title}
					</div>

					{
						incorrectCode &&
						<div className={containerErrorClass}>
							<ErrorCard message={errorMessage} />
						</div>
					}

					<Form
						onSubmit={onSubmit}
						validate={validate}
						render={
							(
								{
									handleSubmit
								}
							) => (

								<form
									onSubmit={handleSubmit}
								>
									<div className={classes.formContainer}>
										<div className="col-xs-12">
											<div className={classes.fieldStyle}>
												<Field
													name={PASSWORD_NAME}
													component={FormTextField}
													placeholder={finishActivation.password}
													type={seePassword ? 'text' : 'password'}
													showPassword
													seePassword={seePassword}
													handleClickShowPassword={() => setSeePassword(!seePassword)}
												/>
											</div>
											<div className={classes.fieldStyle}>
												<Field
													name={CONFIRM_PASSWORD_NAME}
													component={FormTextField}
													placeholder={finishActivation.confirmPassword}
													inputOnPaste={onPaste}
													type={seeConfirmPassword ? 'text' : 'password'}
													showPassword
													seePassword={seeConfirmPassword}
													handleClickShowPassword={() => setSeeConfirmPassword(!seeConfirmPassword)}
												/>
											</div>

											<Button
												background="backgroundPrimary"
												color="colorTertiary"
												type="submit"
												disabled={false}
											>
												Valider
											</Button>
										</div>
									</div>
								</form>
							)
						}
					/>
				</div>
			</div>

		</div>
	)
}

export default ResetPasswordPage