import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import EnhancedBoutonsDocuments from './BoutonsDocuments'

const useStyles = makeStyles(theme => ({
	marginGrand: {
		marginTop: '60px'
	},
	marginPetit: {
		marginTop: '40px'
	}
}))

interface ModelBouton {
	id: number,
	titre: string,
	description: string,
	texte: string,
	icon: string
}

interface AcceptingProps {
	tailleEcran: string,
	boutons: ModelBouton[]
}

type CorpsDocumentsProps = AcceptingProps

const CorpsDocuments: React.FC<CorpsDocumentsProps> = (
	{
		tailleEcran,
		boutons
	}
) => {
	const customClasses = useStyles()

	return (
		<>
			<div
				className={
					[
						'row',
						tailleEcran === 'grand' ? customClasses.marginGrand : customClasses.marginPetit
					].join(' ')
				}
			>
				<div className="col-xs-12">
					<EnhancedBoutonsDocuments boutons={boutons} tailleEcran={tailleEcran} />
				</div>
			</div>
		</>
	)
}

const EnhancedCorpsDocuments = compose<CorpsDocumentsProps, AcceptingProps>()(CorpsDocuments)

export default EnhancedCorpsDocuments
