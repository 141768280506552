import React, { useEffect } from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import { makeStyles } from '@material-ui/core/styles'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../../theme/styles'
import { compose } from 'recompose'
import formatDate from '../../../utils/formatDate'
import EnhancedListeBoutons from '../ListeBoutons'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import MaterialTooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	borderBottom: {
		borderBottom: '1px solid #0000001E',
		paddingBottom: 10,
		paddingTop: 10,
		margin: '23px 0'
	},
	totalMargin: {
		margin: '23px 0'
	},
	titleStyle: {
		margin: '30px 0 20px',
		fontSize: 20
	},
	customFontWeight: {
		fontWeight: 600
	},
	tailleTooltip: {
		maxWidth: 200,
		fontSize: 12,
		marginTop: 0
	},
	iconContainer: {
		paddingTop: 5
	},
	button: {
		marginTop: 20,
		marginBottom: 20
	},
	opacity: {
		opacity: 0.5
	},
	customMargin: {
		margin: '14px -8px'
	},
	valueSize: {
		fontSize: 15
	}
}))

interface ModelAvoirs {
	id: number,
	date_disponibilite: Date,
	typologie?: string
	actions: string,
	prix_acquisition: number,
	message?: string
}

interface AcceptingProps {
	avoirs: ModelAvoirs[],
	type: 'disponibles' | 'indisponibles'
}

const typologie: string = 'Typologie'
const date: string = 'Date de disponibilité'
const nombre: string = 'Nombre d\'actions'
const prix: string = 'Prix d\'acquisition'
const total: string = 'Total'

type ListeAvoirsPetitProps = WithStyles<typeof styles> & AcceptingProps

const ListeAvoirsPetit = (
	props: ListeAvoirsPetitProps
) => {
	const {
		avoirs,
		type
	} = props

	const classes = useStyles()

	const [actions, setActions] = React.useState<ModelAvoirs[]>([])
	const [open, setOpen] = React.useState(
		{
			id: 0,
			open: false
		}
	)

	useEffect(() => {
			setActions([
					...avoirs
				]
			)
		}, [avoirs]
	)

	const titreStyle: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle
	)

	const rowBorderStyle: ClassValue = classNames(
		'row',
		classes.borderBottom
	)

	const rowMiddleStyle: ClassValue = classNames(
		'row middle-xs',
		classes.customMargin
	)

	const libelleStyle: ClassValue = classNames(
		classes.text,
		classes.colorPrimary,
		classes.semiBold,
		classes.opacity,
		classes.smallSize
	)

	const boldTextStyle: ClassValue = classNames(
		classes.text,
		classes.customFontWeight,
		classes.colorPrimary,
		classes.valueSize,
		classes.bold
	)

	const textStyle: ClassValue = classNames(
		classes.text,
		classes.customFontWeight,
		classes.colorPrimary,
		classes.valueSize
	)

	const buttonStyle: ClassValue = classNames(
		'row end-xs',
		classes.button
	)

	const rowMarginStyle: ClassValue = classNames(
		'row middle-xs',
		classes.customMargin
	)

	const totalStyle: ClassValue = classNames(
		'row',
		classes.totalMargin
	)

	const handleTooltipClose = (id: number) => {
		setOpen(
			{
				id: id,
				open: false
			}
		)
	}

	const handleTooltipOpen = (id: number) => {
		if (open.id !== id) {
			setOpen(
				{
					id: id,
					open: true
				}
			)
		}
	}

	return (
		<div className="col-xs-12">
			<h1
				className={titreStyle}
			>
				{
					type === 'disponibles' ? (
						<React.Fragment>Avoirs disponibles</React.Fragment>
					) : (
						<React.Fragment>Avoirs indisponibles</React.Fragment>
					)
				}
			</h1>

			{
				actions.map(
					(action) => (
						<div
							className={rowBorderStyle}
							key={action.id}
						>
							<div className="col-xs-12">
								<div className={rowMiddleStyle}>
									<div className="col-xs-6">
											<span
												className={libelleStyle}
											>
												{action.typologie ? typologie : date}
											</span>

										<br />

										<span
											className={boldTextStyle}
										>
												{action.typologie ? action.typologie : formatDate(action.date_disponibilite)}
											</span>
									</div>
									<div className="col-xs-6 end-xs">
										{action.message ?
											<MaterialTooltip
												PopperProps={{
													disablePortal: true
												}}
												onClose={() => handleTooltipClose(action.id)}
												open={open.id === action.id && open.open}
												onClick={() => handleTooltipOpen(action.id)}
												title={action.message}
												placement="right"
												classes={{ tooltip: classes.tailleTooltip }}
											>
												<InfoOutlinedIcon />
											</MaterialTooltip> : ''
										}
									</div>
								</div>

								<div className={rowMarginStyle}>
									<div className="col-xs-6">
											<span
												className={libelleStyle}
											>
												{nombre}
											</span>

										<br />

										<span
											className={textStyle}
										>
												{thousandsSeparator(Number(action.actions), true)}
											</span>
									</div>
									<div className="col-xs-6 end-xs">
										{
											action.prix_acquisition !== null &&
											<span
												className={libelleStyle}
											>
												{prix}
											</span>
										}

										<br />

										<span
											className={textStyle}
										>
												{action.prix_acquisition ? thousandsSeparator(Number(action.prix_acquisition)) + ' €' : ''}
											</span>
									</div>
								</div>
							</div>
						</div>
					)
				)
			}

			<div className={totalStyle}>
				<div className="col-xs-12">
					<div className={rowMarginStyle}>
						<div className="col-xs-6">
							<span
								className={libelleStyle}
							>
								{total}
							</span>

							<br />

							<span
								className={boldTextStyle}
							>
								{thousandsSeparator(actions.reduce((a, b) => a + (Number(b.actions) || 0), 0), true)}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div
				className={buttonStyle}
			>
				<div className="col-xs-6">
					<EnhancedListeBoutons type={type} />
				</div>
			</div>
		</div>
	)
}

const EnhancedListeAvoirsPetit = compose<ListeAvoirsPetitProps, AcceptingProps>(
	withStyles(styles)
)(ListeAvoirsPetit)

export default EnhancedListeAvoirsPetit
