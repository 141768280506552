import React, {Dispatch, useEffect} from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import CorpsDroitsActionsGrand from './grandEcran/CorpsDroitsActionsGrand'
import CorpsDroitsActionsPetit from './petitEcran/CorpsDroitsActionsPetit'
import errorCatcher from '../../utils/errorCatcher'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import fetchFactory from '../../utils/fetchFactory'
import CircularProgress from '@material-ui/core/CircularProgress'
import {makeStyles} from '@material-ui/core/styles'
import {afficherSnackbar} from '../reducers/snackBar/actions'
import Onglets from '../../components/onglets/Onglets'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import styles from '../../theme/styles'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    spinnerStyle: {
        marginTop: 50,
        textAlign: 'center',

        '& div': {
            width: '150px !important',
            height: '150px !important'
        }
    },
    backgroundColor: {
        backgroundColor: 'white'
    }
}))

interface ModelDroitsActions {
    id: number,
    libelle_plan: string,
    date_attribution?: Date,
    date_debut_conservation?: Date,
    date_cessibilite?: Date,
    quantite: number
    bold?: boolean
}

interface ModelDroitsStock {
    id: number,
    libelle_plan: string,
    quantite: number
    prix_offre?: number,
    date_debut_plan?: Date,
    date_fin_plan?: Date,
    bold?: boolean
}

interface ModelSnackBarData {
    id: string
    message: string,
    open: boolean,
    hideIcon: boolean
}

interface ModelStoreDroitsActions {
    droitsActions: ModelDroitsActions[],
    droitsStock: ModelDroitsStock[]
}

interface Store {
    afficherSnackBar: (snackBarData: ModelSnackBarData) => void,
    errorCatcher: (error: any, id: string) => void,
    storeDroitsActions: ModelStoreDroitsActions
    location: Location
}

const titre: string = 'Droits à actions de performance'

type DroitsActionsProps = Store

const DroitsActions = (
    props: DroitsActionsProps
) => {
    const {
        errorCatcher,
        afficherSnackBar,
        storeDroitsActions
    } = props

    const classes = useStyles()

    const [droitsActions, setDroitsActions] = React.useState<ModelDroitsActions[]>([])
    const [droitsStock, setDroitsStock] = React.useState<ModelDroitsStock[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)

    useEffect(() => {
        if (storeDroitsActions.droitsActions) {
            setDroitsActions(
                [
                    ...storeDroitsActions.droitsActions,
                    {
                        id: storeDroitsActions.droitsActions.reduce((a: number, b: ModelDroitsActions) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                        libelle_plan: 'Total',
                        quantite: storeDroitsActions.droitsActions.reduce((a: number, b: ModelDroitsActions) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                        bold: true
                    }
                ]
            )
            setLoading(false)
        } else {
            fetchFactory('/api/droits/actionsPerformances')
                .then(
                    (droits_actions: any) => {
                        setDroitsActions(
                            [
                                ...droits_actions,
                                {
                                    id: droits_actions.reduce((a: number, b: ModelDroitsActions) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                                    libelle_plan: 'Total',
                                    quantite: droits_actions.reduce((a: number, b: ModelDroitsActions) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                                    bold: true
                                }
                            ]
                        )
                    },
                    (error: any) => {
                        errorCatcher(error, 'droits_actions')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'droits_actions')
                    }
                )
                .finally(
                    () => {
                        setLoading(false)
                    }
                )
        }

        if (storeDroitsActions.droitsStock) {
            const total = storeDroitsActions.droitsStock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0)
            if (total > 0) {
                setDroitsStock([
                        ...storeDroitsActions.droitsStock,
                        {
                            id: storeDroitsActions.droitsStock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                            libelle_plan: 'Total',
                            quantite: storeDroitsActions.droitsStock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                            bold: true
                        }
                    ]
                )
            }
            setLoading(false)
        } else {
            fetchFactory('/api/droits/stockOptions')
                .then(
                    (droits_stock: any) => {
                        const total = droits_stock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0)
                        if (total > 0) {
                            setDroitsStock(
                                [
                                    ...droits_stock,
                                    {
                                        id: droits_stock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                                        libelle_plan: 'Total',
                                        quantite: droits_stock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0),
                                        bold: true
                                    }
                                ]
                            )
                        }
                    },
                    (error: any) => {
                        errorCatcher(error, 'droits_stock')
                    }
                )
                .catch(
                    (error: any) => {
                        errorCatcher(error, 'droits_stock')
                    }
                )
        }
    }, [errorCatcher, storeDroitsActions])

    const telechargerDocument = (id: number, type: string) => {
        let filename: string = ''

        fetchFactory(`/api/droits/document?document=${type}&droit_id=${id}`, {
            method: 'POST'
        }, 'response')
            .then(
                (response: any) => {
                    response.headers.forEach((header: string) => {
                        if (header.includes('filename=')) {
                            filename = header.split('filename=')[1].split(';')[0].replace(/"/g, '')
                        }
                    })

                    return response.blob()
                        .then(
                            (blob: any) => {
                                const url = window.URL.createObjectURL(blob)
                                const a = document.createElement('a')
                                a.href = url
                                a.download = filename
                                a.click()
                            }
                        )
                }
            )
            .catch(
                (error: any) => {
                    if (error.status === 404) {
                        afficherSnackBar(
                            {
                                id: `droits_${type}`,
                                message: `Le fichier que vous souhaitez télécharger (${type}.pdf) n'existe pas`,
                                open: true,
                                hideIcon: true
                            }
                        )
                    } else if (error.status === 403) {
                        afficherSnackBar(
                            {
                                id: `droits_${type}`,
                                message: 'Le titulaire est inconnu',
                                open: true,
                                hideIcon: true
                            }
                        )
                    }
                }
            )
    }

    const containerStyle: ClassValue = classNames(
        'row',
        classes.backgroundColor
    )

    const spinnerStyle: ClassValue = classNames(
        'col-xs-12 center-xs',
        classes.spinnerStyle
    )
    return (
        <>
            <EnhancedEntete titre={titre}/>
            <Onglets/>

            <MediaQuery
                maxWidth={1024}
            >

                {
                    (match) => {
                        return match ? (
                            <div className={containerStyle}>
                                {
                                    loading ? (
                                        <div className={spinnerStyle}>
                                            <CircularProgress color="primary"/>
                                        </div>
                                    ) : (

                                        <CorpsDroitsActionsPetit
                                            droitsActions={
                                                droitsActions.sort(
                                                    (a: ModelDroitsActions, b: ModelDroitsActions) => {
                                                        if (a.date_attribution && b.date_attribution) {
                                                            if (a.date_attribution > b.date_attribution) {
                                                                return 1
                                                            }
                                                            if (a.date_attribution < b.date_attribution) {
                                                                return -1
                                                            }
                                                        }
                                                        return 0
                                                    }
                                                )
                                            }
                                            droitsStock={
                                                droitsStock.sort(
                                                    (a: ModelDroitsStock, b: ModelDroitsStock) => {
                                                        if (a.date_debut_plan && b.date_debut_plan) {
                                                            if (a.date_debut_plan > b.date_debut_plan) {
                                                                return 1
                                                            }
                                                            if (a.date_debut_plan < b.date_debut_plan) {
                                                                return -1
                                                            }
                                                        }
                                                        return 0
                                                    }
                                                )
                                            }
                                            telechargement={telechargerDocument}
                                        />
                                    )
                                }
                            </div>
                        ) : (
                            <>
                                {
                                    loading ? (
                                        <div className={classes.spinnerStyle}>
                                            <CircularProgress color="primary"/>
                                        </div>
                                    ) : (
                                        <div className={containerStyle}>
                                            <CorpsDroitsActionsGrand
                                                droitsActions={
                                                    droitsActions.sort(
                                                        (a: ModelDroitsActions, b: ModelDroitsActions) => {
                                                            if (a.date_attribution && b.date_attribution) {
                                                                if (a.date_attribution > b.date_attribution) {
                                                                    return 1
                                                                }
                                                                if (a.date_attribution < b.date_attribution) {
                                                                    return -1
                                                                }
                                                            }
                                                            return 0
                                                        }
                                                    )
                                                }
                                                droitsStock={
                                                    droitsStock.sort(
                                                        (a: ModelDroitsStock, b: ModelDroitsStock) => {
                                                            if (a.date_debut_plan && b.date_debut_plan) {
                                                                if (a.date_debut_plan > b.date_debut_plan) {
                                                                    return 1
                                                                }
                                                                if (a.date_debut_plan < b.date_debut_plan) {
                                                                    return -1
                                                                }
                                                            }
                                                            return 0
                                                        }
                                                    )
                                                }
                                                telechargement={telechargerDocument}
                                            />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                }
            </MediaQuery>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        storeDroitsActions: state.droitsActions
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        afficherSnackBar: (snackBarData: ModelSnackBarData) => {
            dispatch(afficherSnackbar(snackBarData))
        },
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedDroitsActions = compose<DroitsActionsProps, {}>(
    connect(mapStateToProps, mapDispatchToProps)
)(DroitsActions)

export default EnhancedDroitsActions
