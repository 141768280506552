import { afficherSnackbar } from '../modules/reducers/snackBar/actions'

const messageErreur: string = 'Une erreur est survenue lors de la requête, veuillez réessayer plus tard'

const errorCatcher = (error: any, id: string) => (dispatch: any, getState: any) => {
	if (error.errors) {
		console.error(error.errors._error)
		dispatch(afficherSnackbar(
			{
				id: id,
				message: error.errors._error,
				open: true,
				hideIcon: true
			}
		))
	} else if (error.status === 500) {
		console.error(messageErreur)
		dispatch(afficherSnackbar(
			{
				id: id,
				message: messageErreur,
				open: true,
				hideIcon: true
			}
		))
	} else if (error.response && error.response.status === 400) {
		console.error(error.response.statusText + ' : ' + error.bodyError)
		dispatch(afficherSnackbar(
			{
				id: id,
				message: error.bodyError,
				open: true,
				hideIcon: true
			}
		))
	} else if (error.status === 400) {
		console.error('Erreur: Les valeurs saisies sont incorrectes')
		dispatch(afficherSnackbar(
			{
				id: id,
				message: 'Les valeurs saisies sont incorrectes.',
				open: true,
				hideIcon: true
			}
		))
	} else if (typeof error === 'string') {
		console.error('Erreur: ' + error)
		dispatch(afficherSnackbar(
			{
				id: id,
				message: error,
				open: true,
				hideIcon: true
			}
		))
	}
}

export default errorCatcher
