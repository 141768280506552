const styles = (theme) => {

	return (
		{
			backgroundPrimary: {
				backgroundColor: theme.palette.primary.main
			},
			backgroundSecondary: {
				backgroundColor: theme.palette.secondary.main
			},
			backgroundWhite: {
				backgroundColor: 'white'
			},
			backgroundGrey: {
				backgroundColor: theme.gecina.grey
			},
			backgroundInherit: {
				backgroundColor: 'inherit'
			},
			titleSize: {
				fontSize: 20
			},
			textSize: {
				fontSize: 16
			},
			smallSize: {
				fontSize: 12
			},
			title: {
				fontFamily: theme.typography.fontFamilyBlackerDisplay
			},
			text: {
				fontFamily: theme.typography.fontFamilyMonserrat
			},
			title0: theme.typography.title0,
			title1: theme.typography.title1,
			title2: theme.typography.title2,
			title3: theme.typography.title3,
			title4: theme.typography.title4,
			title5: theme.typography.title5,
			title6: theme.typography.title6,
			title7: theme.typography.title7,
			title8: theme.typography.title8,
			text0: theme.typography.text0,
			text0_opacity: theme.typography.text0_opacity,
			text1_opacity: theme.typography.text1_opacity,
			text1: theme.typography.text1,
			text2_opacity: theme.typography.text2_opacity,
			text2: theme.typography.text2,
			text3_opacity: theme.typography.text3_opacity,
			text3: theme.typography.text3,
			text4_opacity: theme.typography.text4_opacity,
			text4: theme.typography.text4,
			text5: theme.typography.text5,
			text6: theme.typography.text6,
			text6_opacity: theme.typography.text6_opacity,
			text7: theme.typography.text7,
			text8: theme.typography.text8,
			text9: theme.typography.text9,
			text9_opacity: theme.typography.text9_opacity,
			text10: theme.typography.text10,
			text11: theme.typography.text11,
			text11_opacity: theme.typography.text11_opacity,
			text12: theme.typography.text12,
			text13: theme.typography.text13,
			text13_opacity: theme.typography.text13_opacity,
			text14: theme.typography.text14,
			text15: theme.typography.text15,
			text16_opacity: theme.typography.text16_opacity,
			text17: theme.typography.text17,
			text18: theme.typography.text18,
			text19: theme.typography.text19,
			borderPrimary: {
				border: `1px solid ${theme.palette.primary.main}`
			},
			borderSecondary: {
				border: `2px solid ${theme.palette.secondary.main}`
			},
			borderSecondaryLight: {
				border: `1px solid ${theme.palette.secondary.main}`
			},
			colorPrimary: {
				color: theme.palette.primary.main
			},
			colorSecondary: {
				color: theme.palette.secondary.main
			},
			colorTertiary: {
				color: 'white'
			},
			fullWidth: {
				width: '100%',
				height: 'auto !important'
			},
			fab: {
				margin: theme.spacing(1)
			},
			marginBottom: {
				marginBottom: 40
			},
			marginTop: {
				marginTop: 50
			},
			cursorPointer: {
				cursor: 'pointer'
			},
			sizeContainerGrand: {
				maxWidth: 1024,
				margin: 'auto',
				paddingBottom: 140,
				padding: '0px 10px'
			},
			sizeContainerPetit: {
				maxWidth: '85%',
				margin: 'auto',
				paddingBottom: 170
			},
			typoName: {
				marginLeft: theme.spacing(2.4)
			},
			flex: {
				display: 'flex'
			},
			flexEnd: {
				justifyContent: 'flex-end'
			},
			flexCenter: {
				justifyContent: 'center'
			},
			alignCenter: {
				alignItems: 'center'
			},
			regular: {
				fontWeight: 400
			},
			medium: {
				fontWeight: 500
			},
			semiBold: {
				fontWeight: 600
			},
			bold: {
				fontWeight: 800
			},
			heavy: {
				fontWeight: 900
			}
		}
	)
}

export default styles

export const getStyles = (overrideStyles = {}) => ({
	...Object.keys(overrideStyles).reduce((map, key) => ({
		...map,
		[key]: {
			...map[key],
			...overrideStyles[key]
		}
	}), styles)
})