import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { CONTACT_MOCK } from '../utils/contactMock'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import SvgMobile from '../../../icons/Mobile'
import Button from '../../../components/button/Button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles(
	(theme) => (
		{
			contactContainerStyle: {
				background: 'white',
				position: 'relative',

				'& > div:nth-child(2)': {
					'& > div': {
						padding: 0
					}
				},
				'& > div:last-child': {
					position: 'absolute',
					bottom: -25,
					right: 35,

					'& button': {
						padding: '15px 20px'
					}
				},
				'& img': {
					maxWidth: '50%',
					marginTop: -70,

				},
				'& h1, & h2, & p': {
					margin: 0,
					color: theme.palette.blue.main
				},
				'& h1': {
					marginTop: 20,
					marginBottom: 5
				}
			},
			contactContainerLargeScreenStyle: {
				padding: '0 60px 75px 30px',
				marginBottom: 200,
				minHeight: 360,

				'& > div:first-child': {
					marginBottom: 40,
					position: 'absolute'
				},
				'& h1': {
					fontSize: 32,
					fontWeight: 800,
					fontFamily: theme.typography.fontFamilyBlackerDisplay
				},
				'& h2, & p': {
					fontSize: 20,
					fontWeight: 'normal',
					fontFamily: theme.typography.fontFamilyMonserrat
				},
				'& p': {
					lineHeight: '1.5',
					color: '#0a2943'
				},
				'& p:last-child': {
					color: '#c2ad8a'
				},
				'& img': {
					minWidth: 172,
					height: 172

				}
			},
			contactContainerSmallScreenStyle: {
				padding: '0 30px 70px 15px',
				marginBottom: 150,

				'& > div:first-child': {
					marginBottom: 40
				},
				'& h1': {
					fontSize: 26,
					fontWeight: 800,
					fontFamily: theme.typography.fontFamilyBlackerDisplay
				},
				'& h2, & p': {
					fontSize: 16,
					fontWeight: 'normal',
					fontFamily: theme.typography.fontFamilyMonserrat
				},
				'& p': {
					lineHeight: 1.5,
					color: '#0a2943'
				},
				'& p:last-child': {
					color: '#c2ad8a'
				}
			},
			telephoneStyle: {
				position: 'relative',
				top: 240,
				marginTop: 20
			}
		}
	)
)

interface AcceptingProps {
	tailleEcran: 'petit' | 'grand',
	openDialog: () => void
}

type CartesContactsProps = AcceptingProps

const CartesContacts: React.FC<CartesContactsProps> = (
	{
		tailleEcran,
		openDialog
	}
) => {
	const classes = useStyles()
	const theme = useTheme()

	const contactContainerClass: ClassValue = classNames(
		'col-xs-12 col-md-5',
		classes.contactContainerStyle,
		tailleEcran === 'grand' ? classes.contactContainerLargeScreenStyle : classes.contactContainerSmallScreenStyle
	)

	const telephoneInfoClass: ClassValue = classNames(
		'row',
		tailleEcran === 'grand' && classes.telephoneStyle

	)
	const sendMail = () => {
		openDialog()
	}

	return (
		<div className="row between-xs">
			{
				CONTACT_MOCK.map(
					(contact) => (
						<div
							key={contact.name}
							className={contactContainerClass}
						>
							<div>
								<img src={contact.picture} alt={contact.name} />

								<h1>{contact.name}</h1>
								<h2>{contact.position}</h2>
							</div>

							<div className={telephoneInfoClass}>
								<div className="col-xs-2">
									<SvgMobile color={theme.palette.blue.main} width={50} height={50} />
								</div>

								<div className="col-xs-10">
									<p>Téléphone</p>

									<p>{contact.phone}</p>
								</div>
							</div>

							<div>
								<Button
									disabled={false}
									//Si on est sur le domaine de la recette, on renseigne l'email en dur (tout comme le header rose)
									onClick={sendMail}
								>
									<span>Envoyer un message</span>
									<ChevronRightIcon />
								</Button>
							</div>
						</div>
					)
				)
			}
		</div>
	)
}

export default CartesContacts
