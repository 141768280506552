import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '../button/Button'
import styles from '../../theme/styles'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    iconStyle: {
        position: 'absolute',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.blue['5'],
        borderRadius: '100%',
        padding: 12,
        fontSize: 24,
        cursor: 'pointer',
        zIndex: 2
    },
    iconLargeScreenStyle: {
        top: 35,
        right: 40
    },
    iconSmallScreenStyle: {
        top: 20,
        right: 20
    },
    buttonContainer: {
        marginRight: 20
    }
}))

interface DialogProps {
    onCloseDialog?: any,
    hasButton: boolean,
    buttons?: any[],
    children?: React.ReactChild,
    hasCloseIcon?: boolean
}

const Dialog: React.FC<DialogProps> = (
    {
        onCloseDialog,
        hasButton,
        buttons,
        children,
        hasCloseIcon = true
    }
) => {
    const classes = useStyles()
    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )

    const handleClose = (button: any) => {
        if (button.onClick) {
            button.onClick()
        }
        onCloseDialog(button.flag)
    }

    return (
        <>
            {
                onCloseDialog && hasCloseIcon && (
                    <CloseIcon
                        className={
                            classNames(
                                classes.iconStyle,
                                isLargeScreen ? classes.iconLargeScreenStyle : classes.iconSmallScreenStyle
                            )
                        }
                        onClick={() => handleClose(false)}
                    />
                )
            }

            <DialogContent>
                {children}
            </DialogContent>
            {
                hasButton && (
                    <DialogActions>
                        {
                            buttons && buttons.map(button => {
                                return (
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            onClick={() => handleClose(button)}
                                            key={button.id}
                                            disabled={false}
                                        >
                                            {button.content}
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </DialogActions>
                )
            }

        </>
    )
}

export default Dialog
