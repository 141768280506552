import React from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import {makeStyles} from '@material-ui/core/styles'
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles'
import styles from '../../../theme/styles'
import {compose} from 'recompose'
import formatDate from '../../../utils/formatDate'
import Button from '../../../components/button/Button'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import fetchFactory from '../../../utils/fetchFactory'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    borderBottom: {
        margin: '0 auto',
        borderBottom: '1px solid #0000001E',
        paddingBottom: 20,
        paddingTop: 10
    },
    noBorderBottom: {
        margin: '0 auto',
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10
    },
    smallText: {
        fontSize: 12
    },
    textSize: {
        fontSize: 16
    },
    semiBold: {
        fontWeight: 700
    },
    bold: {
        fontWeight: 800
    },
    buttonContainer: {
        padding: 0,
        margin: '10px 0 0 0',

        '& button': {

            '& #icon-left': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
            }
        }
    },
    marginTop: {
        marginTop: 12
    },
    marginPdf: {
        margin: '0 20px'
    },
    marginIcon: {
        marginRight: 11
    },
    libelleStyle: {
        opacity: 0.5,
        fontFamily: theme.typography.fontFamilyMonserrat,
        fontSize: 10
    },
    textStyle: {
        fontFamily: theme.typography.fontFamilyMonserrat,
        fontSize: 14,
        fontWeight: 600
    },
    blocCoursAction: {
        lineHeight: 1.9,
        margin: '30px 0 40px 20px'
    },
    coursGecina: {
        fontFamily: theme.typography.fontFamilyMonserrat,
        fontSize: 10
    }
}))

interface ModelCompteTitres {
    id: number,
    code_isin: string,
    nature_valeur: string,
    solde_global: number,
    valorisation: number,
    libelle_valeur: string
}

interface ModelPrixGecina {
    update_date: Date,
    price: string
}

interface AcceptingProps {
    compteTitres: ModelCompteTitres[],
    prixGecina?: ModelPrixGecina
}

const VALORISATION = 'valorisation'

type ListeActionsGrandProps = WithStyles<typeof styles> & AcceptingProps

const ListeActionsGrand = (
    props: ListeActionsGrandProps
) => {
    const {
        compteTitres,
        prixGecina
    } = props

    const classes = useStyles()

    const onButtonClicked = () => {
        fetchFactory('/api/attestation', {
            method: 'POST'
        }, 'blob')
            .then(
                (blob: any) => {
                    const url = window.URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = 'attestation.pdf'
                    a.click()
                }
            )
    }

    const rowPdfStyle: ClassValue = classNames(
        'row col-xs-12 end-xs',
        classes.buttonContainer
    )
    const buttonPdfStyle: ClassValue = classNames(
        'col-xs-6 end-xs',
        classes.marginPdf
    )

    const libelleStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.libelleStyle
    )

    const textStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.textStyle
    )

    const coursActionStyle: ClassValue = classNames(
        'col-xs-6',
        classes.blocCoursAction
    )

    const coursGecinaStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.coursGecina,
        classes.bold
    )

    const valeurCoursGecinaStyle: ClassValue = classNames(
        classes.colorPrimary,
        classes.coursGecina
    )

    const blocActionBorderStyle: ClassValue = classNames(
        'row col-xs-11',
        classes.borderBottom
    )

    const blocActionNoBorderStyle: ClassValue = classNames(
        'row col-xs-11',
        classes.noBorderBottom
    )

    const marginBlocStyle: ClassValue = classNames(
        'row col-xs-12',
        classes.marginTop
    )

    const iconClass: ClassValue = classNames(
        classes.marginIcon,
        classes.colorSecondary
    )
    return (
        <>
            {
                compteTitres.map((action, index) => (
                        <div
                            key={action.id}
                            className={index === compteTitres.length - 1 ? blocActionNoBorderStyle : blocActionBorderStyle}
                        >
                            <div className={marginBlocStyle}>
                                <div className="col-xs-6">
                                    <div>
										<span
                                            className={libelleStyle}
                                        >
											Action
										</span>
                                    </div>
                                    <div>
										<span
                                            className={textStyle}
                                        >
											{action.code_isin}
										</span>
                                    </div>
                                </div>
                            </div>

                            <div className={marginBlocStyle}>
                                <div className="col-xs-6">
                                    <div>
										<span
                                            className={libelleStyle}
                                        >
											Solde global
										</span>
                                    </div>
                                    <div>
                                        <span
                                            className={textStyle}
                                        >
                                                {thousandsSeparator(action.solde_global, true)} actions
                                            </span>
                                    </div>
                                </div>

                                <div className="col-xs-6 end-xs">
                                    {
                                        VALORISATION in action &&
                                        <>
                                            <div>
                                                <span
                                                    className={libelleStyle}
                                                >
                                                    Valorisation*
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={textStyle}
                                                >
                                                    {thousandsSeparator(Number(action.valorisation))} €
                                                </span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                )
            }
            <div className={rowPdfStyle}>
                <div className={buttonPdfStyle}>
                    <Button
                        disabled={false}
                        onClick={onButtonClicked}
                    ><PictureAsPdfOutlinedIcon className={iconClass}/>
                        Télécharger
                    </Button>
                </div>
            </div>
            {
                prixGecina && compteTitres.length > 0 &&
                <div className={coursActionStyle}>
                    <div className="row">
                        <small
                            className={coursGecinaStyle}
                        >
                            *Cours de l'action
                        </small>
                    </div>

                    <div className="row">
                        <small
                            className={valeurCoursGecinaStyle}
                        >
                            {thousandsSeparator(Number(prixGecina.price))} €
                            au {formatDate(prixGecina.update_date)}
                        </small>
                    </div>
                </div>
            }
        </>
    )
}

const EnhancedListeActionsGrand = compose<ListeActionsGrandProps, AcceptingProps>(
    withStyles(styles)
)(ListeActionsGrand)

export default EnhancedListeActionsGrand
