import React from 'react'
import { compose } from 'recompose'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import { makeStyles } from '@material-ui/core/styles'
import MediaQuery from 'react-responsive'
import EnhancedMenuGrandEcran from './MenuGrandEcran'
import './styles.css'
import { useHistory } from 'react-router-dom'
import EnhancedMenuPetitEcran from './MenuPetitEcran'
import { withOktaAuth } from '@okta/okta-react'
import fetchFactory from '../../utils/fetchFactory'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { afficherSnackbar } from '../../modules/reducers/snackBar/actions'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import localStorageEnum from '../../enum/localStorageEnum'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	height: {
		zIndex: 999,
		height: 96,
		display: 'flex'
	},
	menuImg: {
		'& img': {
			width: '100%',
			maxWidth: 250
		}
	},
	pinkBackground: {
		backgroundColor: 'pink'
	}
}))

interface AcceptingProps {
	location: Location
}

interface ModelAuthService {
	logout: (path: string) => void
}

interface ModelOkta {
	authService: ModelAuthService
}

interface ModelSnackBarData {
	id: string
	message: string,
	open: boolean,
	hideIcon: boolean
}

interface Store {
	afficherSnackBar: (snackbarData: ModelSnackBarData) => void
}

const lienAccueil: string = '/accueil'

type MenuProps =
	WithStyles<typeof styles>
	& AcceptingProps
	& ModelOkta
	& Store

const Menu = (
	props: MenuProps
) => {

	const {
		location,
		authService,
		afficherSnackBar
	} = props

	const classes = useStyles()
	const history = useHistory()
	const removeCookies = useCookies()[2]

	const redirectionVers = (lien: string) => {
		if (lien === '/login') {
			localStorage.removeItem('utilisateur')

			removeCookies(localStorageEnum.idToken, {
				path: '/',
				domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
			})
			removeCookies(localStorageEnum.accessToken, {
				path: '/',
				domain: JSON.parse(localStorage.getItem('commonUri') || '').cookie_domain || ''
			})

			authService.logout(lien)
		} else if (lien === 'download') {
			fetchFactory('/api/aide', {
				method: 'POST'
			}, 'blob')
				.then(
					(blob: any) => {
						const url = window.URL.createObjectURL(blob)
						const a = document.createElement('a')
						a.href = url
						a.download = 'aide.pdf'
						a.click()
					}
				)
				.catch(
					(error: any) => {
						if (error.status === 404) {
							afficherSnackBar(
								{
									id: 'aide',
									message: 'Le fichier que vous souhaitez télécharger n\'existe pas',
									open: true,
									hideIcon: true
								}
							)
						} else if (error.status === 403) {
							afficherSnackBar(
								{
									id: 'aide',
									message: 'Le titulaire est inconnu',
									open: true,
									hideIcon: true
								}
							)
						}
					}
				)
		} else {
			history.push(lien)
		}
	}

	const menuStyle: ClassValue = classNames(
		classes.backgroundWhite,
		classes.colorPrimary,
		classes.height,
		{
			[classes.pinkBackground]: window.origin.includes('digititres.youfirst.ipsosenso.com')
		}
	)

	const itemStyle: ClassValue = classNames(
		'col-md-2',
		'col-xs-8',
		classes.flex,
		classes.alignCenter,
		classes.cursorPointer,
		classes.menuImg
	)

	return (
		<div
			className={menuStyle}
		>
			<div
				className={itemStyle}
				onClick={() => redirectionVers(lienAccueil)}
			>
				<img src="/img/logo_header.png" alt="Header Gecina" />
			</div>

			<MediaQuery
				maxWidth={1024}
			>
				{
					(match) => {
						return match ? (
							<>
								<EnhancedMenuPetitEcran onClick={redirectionVers} />
							</>
						) : (
							<>
								<EnhancedMenuGrandEcran
									location={location}
									onClick={redirectionVers}
								/>
							</>
						)
					}
				}
			</MediaQuery>
		</div>
	)
}

const mapDispatchToProps = (dispach: Dispatch) => (
	{
		afficherSnackBar: (snackBarData: ModelSnackBarData) => {
			afficherSnackbar(snackBarData)
		}
	}
)

const EnhancedMenu = compose<MenuProps, AcceptingProps>(
	connect(null, mapDispatchToProps),
	withStyles(styles)
)(Menu)

export default withOktaAuth(EnhancedMenu)
