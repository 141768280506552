export enum validityDateEnum {
	day = 'day',
	month = 'month',
	date = 'date'
}

export const VALIDITY_LABEL = {
	day: 'Jour',
	month: 'Fin de mois',
	date: 'Autre date'
}
