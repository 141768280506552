import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import { FieldRenderProps } from 'react-final-form'
import classNames from 'classnames'
import { InputBaseComponentProps } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			labelStyle: {
				fontSize: 14,
				fontWeight: 600,
				textTransform: 'uppercase'
			},
			textFieldSyle: {
				width: '100%',
				paddingTop: 8,
				boxSizing: 'border-box',

				'& input, & textarea': {
					color: theme.palette.blue['70'],
					fontSize: 18,
					fontWeight: 600
				}
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: 10
			},
			errorColor: {
				color: '#B92D00 !important'
			},
			errorBorder: {
				border: '1px solid #B92D00'
			}
		}
	)
)

interface AcceptingProps {
	label: string,
	id: string,
	multiline?: boolean,
	maxLength?: number,
	ibanFormatter?: boolean,
	placeholder?: string,
	inputOnPaste?: () => void,
	showPassword?: boolean,
	seePassword?: boolean,
	handleClickShowPassword?: () => void
}

type FormTextFieldProps =
	FieldRenderProps<string & Date, HTMLElement>
	& AcceptingProps & InputBaseComponentProps

const FormTextField: React.FC<FormTextFieldProps> = (
	{
		input,
		label,
		id,
		multiline,
		maxLength,
		meta: { touched, error },
		ibanFormatter = false,
		placeholder,
		inputOnPaste,
		showPassword,
		seePassword,
		handleClickShowPassword
	}
) => {
	const classes = useStyles()

	const ibanFormat = (e: any) => {
		let ibanSpace: string = ''
		const ibanBase: string = e.target.value.replace(/\s/g, '')
		for (let i = 0; i < ibanBase.length; i += 4) {
			i + 4 >= ibanBase.length ? ibanSpace += ibanBase.substr(i, 4) :
				ibanSpace += ibanBase.substr(i, 4) + ' '
		}

		input.onChange(ibanSpace)
	}

	const onValueChange = (e: any) => {
		input.onChange(e)
	}

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault()
	}

	return (
		<>
			<span
				className={
					classNames(
						classes.labelStyle,
						classes.text,
						classes.colorPrimary,
						touched && error && classes.errorColor
					)
				}
			>
				{label}
			</span>

			<TextField
				{...input}
				id={id}
				placeholder={placeholder ? placeholder : ''}
				color="secondary"
				className={
					classNames(
						classes.textFieldSyle,
						touched && error && classes.errorBorder
					)
				}
				multiline={multiline}
				inputProps={{
					maxLength: maxLength,
					onPaste: inputOnPaste
				}}
				onChange={
					(e) =>
						ibanFormatter ? ibanFormat(e) : onValueChange(e)
				}
				InputProps={
					{
						endAdornment: showPassword ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
								>
									{seePassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						) : undefined
					}
				}
			/>
			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</>
	)
}

export default FormTextField