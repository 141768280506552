export const CONSTANTES_FISCALITE = {
	OPTION_FISCALE: {
		TITRE: 'Option fiscale (année en cours)',
		MULTI_COMPTE: 'Les montants présentés concernent le compte-titre quel que soit le titulaire'
	},
	VOS_MONTANTS: {
		TITRE: 'Vos montants déclarés / à déclarer',
		EXERCICE_LABEL: 'Exercice',
		MONTANT_BRUT: 'Montant brut des revenus - zone de la déclaration des revenus 2TS',
		MONTANT_PS: 'Montant pour lesquels les PS ont déjà été appliqués - Produits susceptibles d\'ouvrir droit à CSG déductible en cas d\'option pour le barème - zone de la déclaration des revenus : 2BH',
		CUMUL_PRELEVEMENT: 'Cumul des prélèvements sociaux retenus à la source',
		CUMUL_CREDIT: 'Cumul crédit d\'impôt prélèvement - zone de la déclaration des revenus : 2CK',
		MONTANT_NET: 'Montant net payé',
		BASE_PRELEVEMENT: 'Base du prélèvement (actionnaire non-résident)',
		MONTANT_PRELEVEMENT: 'Montant du prélèvement (actionnaire non-résident)',
		MONTANT_CESSION: 'Montant brut de cession',
		MONTANTS_EXONERES: 'Montant exonérés (Plan d\'Epargne d\'Entreprise - réinvestissement)',
		MONTANTS_DONNEES: 'Ces montants sont donnés en cours d\'exercice à titre indicatif.',
		MONTANTS_FISCAUX: 'Les montants fiscaux définitifs vous seront communiqués en début d\'année prochaine.'
	}
}