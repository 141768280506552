import React from 'react'
import NumberFormat from 'react-number-format'
import { mapProps } from 'recompose'
import FormTextField from './FormTextField'

// Wrapped component, we had to redefine functions because NumberFormat uses the same callbacks than the FormInput so we had to adapt them to FormInput
const Wrapped = mapProps(
	(
		{
			reduxProps: {
				input: {
					onFocus,
					onBlur
				},
				...others
			},
			onChange,
			value
		}: any
	) => {
		return {
			input: {
				onChange,
				onFocus,
				onBlur: () => {
					onBlur(undefined)
				},
				value
			},
			...others
		}
	})(FormTextField)

// Component used to format entered number with thousand separator
const FormNumberFormatField = (reduxFormProps: any) => {
	return (
		<NumberFormat
			customInput={Wrapped}
			isNumericString
			onValueChange={(values) => {
				reduxFormProps.input.onChange(values.floatValue)
			}}
			thousandSeparator=" "
			reduxProps={reduxFormProps}
			value={reduxFormProps.input.value}
			allowNegative={reduxFormProps.allowNegative}
			decimalScale={reduxFormProps.decimalScale}
			suffix={reduxFormProps.suffix}
		/>
	)
}

export default FormNumberFormatField
