import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import formatDate from '../../../utils/formatDate'
import fetchFactory from '../../../utils/fetchFactory'
import errorCatcher from '../../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import EnhancedTableauSimple from '../../../components/table/TableauSimple'
import EmptyState from '../components/EmptyState'
import thousandsSeparator from '../../../utils/thousandsSeparator'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	spinnerStyle: {
		marginTop: 50,
		textAlign: 'center',

		'& div': {
			width: '150px !important',
			height: '150px !important'
		}
	}
}))

interface ModelColonnes {
	value: string,
	label: string,
	type: string,
	render?: (row: ModelOperation) => React.ReactChild
}

interface ModelOperation {
	id: number,
	date: Date,
	valeur: string,
	quantite: string | number,
	mouvement: string
}

interface ModelResponse {
	id: number,
	date: Date,
	code_isin: string,
	libelle_valeur: string,
	quantite: number,
	sens: string,
	libelle_operation: string
}

const HISTORICAL_ERROR = 'historique_operations'

const ListeHistoriqueOperationsGrand = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const colonnes: ModelColonnes[] = [
		{
			value: 'valeur',
			label: 'Valeur',
			type: 'string'
		},
		{
			value: 'date',
			label: 'Date',
			type: 'date',
			render: (row: ModelOperation) => (
				<div>
					{formatDate(row.date)}
				</div>
			)
		},
		{
			value: 'quantite',
			label: 'Quantité',
			type: 'number',
			render: (row: ModelOperation) => (
				<span>{thousandsSeparator(Number(row.quantite), true)}</span>
			)
		},
		{
			value: 'mouvement',
			label: 'Mouvement',
			type: 'string'
		}
	]

	const [operations, setOperations] = React.useState<ModelOperation[]>([])
	const [loading, setLoading] = React.useState<boolean>(true)

	useEffect(() => {
		fetchFactory('/api/operations')
			.then((operationsReponse: any) => {
					const tmpOperations: ModelOperation[] = []

					operationsReponse.sort(
						(a: ModelOperation, b: ModelOperation) => {
							if (a.date < b.date) {
								return 1
							}
							if (a.date > b.date) {
								return -1
							}
							return 0
						}
					).forEach(
						(operationResponse: ModelResponse) => {
							tmpOperations.push(
								{
									id: operationResponse.id,
									date: operationResponse.date,
									valeur: `${operationResponse.libelle_valeur} - ${operationResponse.code_isin}`,
									quantite: operationResponse.sens === 'R' ? `-${operationResponse.quantite}` : operationResponse.quantite,
									mouvement: operationResponse.libelle_operation
								}
							)
						}
					)
					setOperations(tmpOperations)
				}
			)
			.catch(
				(error: any) => dispatch(
					errorCatcher(error, HISTORICAL_ERROR)
				)
			)
			.finally(
				() => {
					setLoading(false)
				}
			)
	}, [dispatch])

	return (
		<>
			{
				loading ? (
					<div className={classes.spinnerStyle}>
						<CircularProgress color="primary" />
					</div>
				) : (
					<>
						{
							operations.length > 0 ? (
								<EnhancedTableauSimple colonnes={colonnes} lignes={operations} />

							) : (
								<EmptyState />
							)
						}
					</>
				)
			}
		</>
	)
}

export default ListeHistoriqueOperationsGrand
