import {ENREGISTRER_UTILISATEUR, SUPPRIMER_UTILISATEUR, UPDATE_CGU} from './actionTypes'

const utilisateur = (state = {}, action) => {

    switch (action.type) {
        case ENREGISTRER_UTILISATEUR: {
            return action.payload
        }
        case SUPPRIMER_UTILISATEUR: {
            return {}
        }
        case UPDATE_CGU: {
            return {
                ...state,
                hasCgu: action.payload
            }
        }
        default:
            return state
    }
}

export default utilisateur