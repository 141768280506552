import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form } from 'react-final-form'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import styles from '../../../theme/styles'
import { forgotPasswordPostModel, resetModel } from '../type/logInModel'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import statusEnum from './enum/statusEnum'
import errorCatcher from '../../../utils/errorCatcher'
import { authentification, errors, getCredential, resetPassword } from '../utils/commonConst'
import FormTextField from '../../../components/fields/FormTextField'
import Button from '../../../components/button/Button'
import Link from '../../../components/link/Link'
import { resetType } from './enum/resetType'
import Loader from '../../../components/loader/Loader'
import { afficherSnackbar } from '../../reducers/snackBar/actions'

const useStyle = makeStyles(theme => ({
    ...styles(theme),
    titleStyle: {
        padding: '15px 0.5rem',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    fieldStyle: {
        paddingBottom: 30
    },
    linkStyle: {
        padding: '25px 0px'
    },
    descriptionStyle: {
        paddingTop: 15
    },
    formContainer: {
        '& button': {
            width: '100%'
        }
    }
}))

interface AcceptingProps {
    setGoToResetPwd: (value: boolean) => void,
    setGoToGetCredential: (value: boolean) => void
    type: resetType
}

type ResetPasswordProps = AcceptingProps

const ResetPassword: React.FC<ResetPasswordProps> = (
    {
        setGoToResetPwd,
        setGoToGetCredential,
        type
    }
) => {
    const classes = useStyle()
    const dispatch = useDispatch()
    const [sentEmail, setSentEmail] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState('')

    const titleClass: ClassValue = classNames(
        'col-xs-12',
        classes.titleStyle,
        classes.heavy,
        classes.title4,
        classes.colorPrimary
    )

    const descriptionClass: ClassValue = classNames(
        'col-xs-12',
        classes.colorPrimary,
        classes.text3,
        classes.descriptionStyle
    )

    const onSubmit = (values: { email: string }) => {
        setLoading(true)
        const dataForgotPwd: forgotPasswordPostModel = {
            email: values.email
        }
        setEmail(values.email)

        if (type === resetType.password) {
            fetch('/api/okta/forgotPassword',
                {
                    body: JSON.stringify(dataForgotPwd),
                    method: configEnum.post
                })
                .then(
                    (response: Response) => response.json()
                        .then(
                            (forgotPwdResponse: any) => {
                                setLoading(false)

                                if (forgotPwdResponse === statusEnum.OK) {
                                    setSentEmail(true)
                                } else {
                                    dispatch(
                                        afficherSnackbar(
                                            {
                                                id: 'forgot_password',
                                                message: forgotPwdResponse,
                                                open: true,
                                                hideIcon: true
                                            }
                                        )
                                    )
                                }
                            }
                        )
                )
                .catch(
                    (error) => {
                        dispatch(
                            errorCatcher(error, 'forgot_password')
                        )
                    }
                )
        } else {
            fetch('/api/okta/getCredential',
                {
                    body: JSON.stringify(dataForgotPwd),
                    method: configEnum.post
                })
                .then(
                    (response: Response) => response.json()
                        .then(
                            (getCredentialResponse: any) => {
                                setLoading(false)

                                if (getCredentialResponse === statusEnum.OK) {
                                    setSentEmail(true)
                                } else {
                                    dispatch(
                                        afficherSnackbar(
                                            {
                                                id: 'get_credential',
                                                message: getCredentialResponse,
                                                open: true,
                                                hideIcon: true
                                            }
                                        )
                                    )
                                }
                            }
                        )
                )
                .catch(
                    (error) => {
                        dispatch(
                            errorCatcher(error, 'get_credential')
                        )
                    }
                )
        }
    }

    const backToLogIn = () => {
        setGoToGetCredential(false)
        setGoToResetPwd(false)
        setSentEmail(false)
    }

    const validate = (
        values: resetModel
    ) => {
        const error: any = {}

        if (!values.email) {
            error.email = errors.required
        }

        return error
    }

    return (
        <>
            <div className={titleClass}>
                {
                    sentEmail ?
                        <span>{type === resetType.password ? resetPassword.emailSent.title : getCredential.emailSent.title}</span>
                        :
                        <span>{type === resetType.password ? resetPassword.title : getCredential.title}</span>
                }
            </div>

            {
                sentEmail ?
                    <div className={descriptionClass}>
                        Un courriel a été envoyé à {email} avec {type === resetType.password ? ' des instructions pour réinitialiser votre mot de passe.' : 'votre identifiant de connexion'}
                    </div>
                    :
                    <Form
                        onSubmit={onSubmit}
                        validate={validate}
                        render={
                            (
                                {
                                    handleSubmit
                                }
                            ) => (

                                <form
                                    onSubmit={handleSubmit}
                                >
                                    <div className={classes.formContainer}>
                                        <div className="col-xs-12">
                                            <div className={classes.fieldStyle}>
                                                <Field
                                                    name="email"
                                                    component={FormTextField}
                                                    placeholder={type === resetType.password ? authentification.placeholderEmail : getCredential.placeholderEmail}
                                                />
                                            </div>

                                            {
                                                loading ? (
                                                    <Loader/>
                                                ) : (
                                                    <Button
                                                        background="backgroundPrimary"
                                                        color="colorTertiary"
                                                        type="submit"
                                                        disabled={false}
                                                    >
                                                        {type === resetType.password ? resetPassword.reset : getCredential.submit}
                                                    </Button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </form>

                            )}
                    />
            }

            <div className="col-xs-12">
                <div className={classes.linkStyle}>
                    <Link
                        textStyle="text8"
                        color="colorPrimary"
                        onClick={backToLogIn}
                    >
                        {resetPassword.backSignIn}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ResetPassword