import EnhancedEntete from '../../components/entete/Entete'
import Onglets from '../../components/onglets/Onglets'
import React from 'react'
import CorpsHistoriqueOperationsGrand from './grandEcran/CorpsHistoriqueOperationsGrand'
import CorpsHistoriqueOperationsPetit from './petitEcran/CorpsHistoriqueOperationsPetit'
import MediaQuery from 'react-responsive'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../theme/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme)
}))

const titre: string = 'Votre historique d\'opérations'

const HistoriqueOperations = () => {
    const classes = useStyles()

    return (
        <>
            <EnhancedEntete titre={titre}/>
            <Onglets/>
            <MediaQuery
                maxWidth={1024}

            >
                {
                    (match) => {
                        return match ? (
                            <div className={classNames('row', classes.backgroundWhite)}>
                                <CorpsHistoriqueOperationsPetit/>
                            </div>
                        ) : (
                            <div className={classNames('row center-md', classes.backgroundWhite)}>
                                <CorpsHistoriqueOperationsGrand/>
                            </div>
                        )
                    }
                }
            </MediaQuery>
        </>
    )
}

export default HistoriqueOperations