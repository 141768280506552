import React from 'react'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(theme => ({
		...styles(theme),
		titleSizeGrand: {
			fontSize: '32px'
		},
		titleSizePetit: {
			fontSize: '24px'
		},
		letterSpace: {
			letterSpacing: 1
		}
	}
))

interface AcceptingProps {
	title: string
}

type HeaderProps = AcceptingProps

const Header: React.FC<HeaderProps> = (
	{
		title
	}
) => {
	const classes = useStyles()
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const titleClass: ClassValue = classNames(
		classes.title,
		classes.colorPrimary,
		classes.letterSpace,
		isLargeScreen ? classes.titleSizeGrand : classes.titleSizePetit
	)

	return (
		<div className="row">
			<div className="col-xs-11">
				<span className={titleClass}>
					{title}
				</span>
			</div>
		</div>
	)
}

export default Header
