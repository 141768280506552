import { AJOUTER_DISPONIBILITE_AVOIRS } from './actionTypes'

const disponibiliteAvoirs = (state = {}, action) => {

	switch (action.type) {
		case AJOUTER_DISPONIBILITE_AVOIRS: {
			return action.payload
		}
		default:
			return state
	}
}

export default disponibiliteAvoirs
