import React from 'react'
import ListeHistoriqueOperationsGrand from './ListeHistoriqueOperationsGrand'

const CorpsHistoriqueOperationsGrand = () => {
    return (
        <>
            <ListeHistoriqueOperationsGrand/>
        </>
    )
}

export default CorpsHistoriqueOperationsGrand