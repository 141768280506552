import React, { useContext, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { CONSTANTES_FISCALITE } from '../../../utils/constantes/fiscalite'
import { recuperationAnneesPrecedentes } from '../utils/recuperationAnneesPrecedentes'
import { DetailsFiscaliteModel, INITIAL_DETAILS_FISCALITE } from '../types/vosMontantsModel'
import { formulaireVosMontantsEnum } from '../enums/formulaireVosMontantsEnum'
import MenuItem from '@material-ui/core/MenuItem'
import classNames from 'classnames'
import Select from '@material-ui/core/Select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from '../../../theme/styles'
import { recuperationMontantsFiscaux } from '../api/recuperationMontantsFiscaux'
import errorCatcher from '../../../utils/errorCatcher'
import { useDispatch } from 'react-redux'
import Theme from '../../../theme/theme'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import Loader from '../../../components/loader/Loader'
import DetailsFiscalite from './DetailsFiscalite'

const useStyles = makeStyles<typeof Theme, { isLargeScreen: boolean }>(
	(theme) => ({
		...styles(theme),
		titre: {
			...styles(theme).title0,
			color: theme.palette.blue.main,
			fontSize: 20,
			marginTop: props => props.isLargeScreen ? 40 : 20,
			marginBottom: props => props.isLargeScreen ? 40 : 20
		},
		labelStyle: {
			fontSize: 14,
			fontWeight: 600
		},
		selectStyle: {
			width: '100%',
			paddingTop: 8,
			color: theme.palette.blue['70'],

			'& .MuiSelect-root': {
				fontSize: 18,
				fontWeight: 600
			},
			'& .MuiSelect-icon': {
				height: '100%',
				width: '8%',
				marginTop: -6,
				color: theme.palette.secondary.main
			}
		}
	})
)

const GET_MONTANT_FISCAUX = 'getMontantFiscaux'

const VosMontants = () => {
	const { isLargeScreen } = useContext(ResponsiveContext)
	const classes = useStyles({ isLargeScreen })
	const dispatch = useDispatch()

	const listeAnnees = recuperationAnneesPrecedentes()
	const [annee, setAnnee] = useState(listeAnnees[0])
	const [detailsFiscalite, setDetailsFiscalite] = useState<DetailsFiscaliteModel>(INITIAL_DETAILS_FISCALITE)
	const [loading, setLoading] = useState(false)

	useEffect(
		() => {
			setLoading(true)

			recuperationMontantsFiscaux(annee)
				.then(
					(response: any) => setDetailsFiscalite(response)
				)
				.catch(
					(error) => dispatch(
						errorCatcher(error, GET_MONTANT_FISCAUX)
					)
				)
				.finally(() => setLoading(false))
		}, [annee, dispatch]
	)

	return (
		<Grid container item xs={12}>
			{/* Titre du bloc */}
			<Grid container item xs={12} className={classes.titre}>
				{CONSTANTES_FISCALITE.VOS_MONTANTS.TITRE} (en cours de développement)
			</Grid>

			<Grid container item xs={12} md={3}>
				<span
					className={
						classNames(
							classes.labelStyle,
							classes.text,
							classes.colorPrimary
						)
					}
				>
					{CONSTANTES_FISCALITE.VOS_MONTANTS.EXERCICE_LABEL}
				</span>

				<Select
					value={annee}
					name={formulaireVosMontantsEnum.exercice}
					onChange={(e) => setAnnee(e.target.value as number)}
					IconComponent={ExpandMoreIcon}
					className={
						classNames(
							classes.selectStyle,
							classes.text,
							classes.regular,
							classes.textSize
						)
					}
				>
					{
						listeAnnees.map(
							(listeAnnee) => (
								<MenuItem value={listeAnnee} key={listeAnnee}>
									{listeAnnee}
								</MenuItem>
							)
						)
					}
				</Select>
			</Grid>

			<Grid container item xs={12}>
				{
					loading ? (
						<Loader />
					) : (
						<DetailsFiscalite detailsFiscalite={detailsFiscalite} annee={annee} />
					)
				}
			</Grid>
		</Grid>
	)
}

export default VosMontants