import { conditionEnum } from '../enums/conditionEnum'
import { orderDirectionEnum } from '../enums/orderDirectionEnum'
import { ModelFichiers } from '../../coordonneesBancaires/formulaireCoordonneesBancaires/FormulaireCoordonneesBancaires'
import { validityDateEnum } from '../enums/validityDateEnum'
import { getNextDate } from '../../../utils/formatDate'

export const BOURSE_FORM_INITIAL: BourseFormModel = {
	address: '',
	email: '',
	account: '',
	firstname: '',
	lastname: '',
	comment: '',
	condition: conditionEnum.onMarket,
	nbOrders: 0,
	ongoing: 0,
	orderDirection: orderDirectionEnum.buy,
	files: [],
	validityDate: validityDateEnum.day,
	date: getNextDate()
}

export interface BourseFormModel {
	account: string,
	lastname: string,
	firstname: string,
	address: string,
	email: string,
	orderDirection: orderDirectionEnum,
	nbOrders: number,
	condition: conditionEnum,
	ongoing: number,
	comment: string,
	files: ModelFichiers[],
	validityDate: string,
	date: string
}
