import React, {Dispatch, ReactChild, useEffect} from 'react'
import Button from '../../components/button/Button'
import {compose} from 'recompose'
import styles from '../../theme/styles'
import {makeStyles} from '@material-ui/core/styles'
import ListAltIcon from '@material-ui/icons/ListAlt'
import {connect} from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import CircularProgress from '@material-ui/core/CircularProgress'
import fetchFactory from '../../utils/fetchFactory'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    buttonContainer: {
        height: 48,
        paddingRight: 20,
        marginBottom: 20,
    },
    button: {
        width: 160,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icon: {
        width: 20,
        height: 20
    },
    spinnerStyle: {
        textAlign: 'center',

        '& div': {
            width: '100px !important',
            height: '100px !important'
        }
    },
    textStyle: {
        fontSize: 15,
        marginLeft: 4
    }
}))

interface ModelBouton {
    id: number,
    texte: string,
    type: 'csv' | 'pdf',
    icon: ReactChild
}

interface Store {
    errorCatcher: (error: any, id: string) => void
}

interface AcceptingProps {
    type: 'disponibles' | 'indisponibles'
}

const texteTelecharger: string = 'Télécharger'

type ListeBoutonsProps =
    Store
    & AcceptingProps

const ListeBoutons = (
    props: ListeBoutonsProps
) => {
    const {
        errorCatcher,
        type
    } = props

    const classes = useStyles()

    const [boutons, setBoutons] = React.useState<ModelBouton[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const iconStyle: ClassValue = classNames(
        classes.colorSecondary,
        classes.icon
    )

    useEffect(() => {
        const buttonList: ModelBouton[] = [
            {
                id: 4,
                texte: texteTelecharger,
                type: 'csv',
                icon: <ListAltIcon
                    className={iconStyle}
                />
            }
        ]

        buttonList.push(
            {
                id: 5,
                texte: texteTelecharger,
                type: 'pdf',
                icon: <PictureAsPdfOutlinedIcon
                    className={iconStyle}
                />
            }
        )

        setBoutons(buttonList)
    }, [type, classes,iconStyle])

    const onButtonClicked = (fileType: string) => {
        setLoading(true)
        let filename: string = ''

        fetchFactory('/api/telecharger/disponibilite/avoirs', {
            method: 'POST',
            body: JSON.stringify(
                {
                    type: type,
                    file: fileType
                }
            )
        }, 'response')
            .then(
                (response: any) => {
                    response.headers.forEach((header: string) => {
                        if (header.includes('filename=')) {
                            filename = header.split('filename=')[1].split(';')[0].replace(/"/g, '')
                        }
                    })

                    return response.blob()
                        .then(
                            (blob: any) => {
                                const url = window.URL.createObjectURL(blob)
                                const a = document.createElement('a')
                                a.href = url
                                a.download = filename
                                a.click()
                            }
                        )
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, 'disponibilite_avoirs')
                }
            )
            .finally(
                () => {
                    setLoading(false)
                }
            )
    }
const buttonClass: ClassValue = classNames(
    classes.buttonContainer,
    'col-md-2'
)
    return (
        <>
            {
                !loading && boutons.map(bouton => (
                    <div
                        className={buttonClass}
                        key={bouton.id}
                    >
                        <Button
                            disabled={false}
                            onClick={() => onButtonClicked(bouton.type)}
                        >
							<span className={classes.button}>
								{bouton.icon}
                                <span className={classes.textStyle}>{bouton.texte}</span>
								<span></span>
							</span>
                        </Button>
                    </div>
                ))
            }

            {
                loading &&
                <div className={classes.spinnerStyle}>
                    <CircularProgress color="primary"/>
                </div>
            }
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedListeBoutons = compose<ListeBoutonsProps, AcceptingProps>(
    connect(null, mapDispatchToProps)
)(ListeBoutons)

export default EnhancedListeBoutons
