import React from 'react'
import styles from '../../theme/styles'
import Button from '../../components/button/Button'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    buttonContainer: {
        paddingBottom: '20px',

        '& button': {
            minHeight: '54px'
        }
    }
}))

interface AcceptingProps {
    openDialog: () => void,
    adhesion: string
}



type BoutonEServicesProps = AcceptingProps

const BoutonEServices = (
    props: BoutonEServicesProps
) => {
    const {
        adhesion,
        openDialog
    } = props

    const texteDemande: string = adhesion === 'N' ? 'Demande d’adhésion à la e-convocation' : 'Demande de modification'


    const classes = useStyles()
    const boutons = [
        {
            id: 1,
            texte: texteDemande
        }
    ]

    const onButtonClicked = () => {
        openDialog()
    }

    return (
        <div>
            {
                boutons.map(bouton => (
                    <div
                        className={classes.buttonContainer}
                        key={bouton.id}
                    >
                        <Button
                            disabled={false}
                            onClick={onButtonClicked}
                        >
                            <span className={classes.textSize}>{bouton.texte}</span>
                        </Button>
                    </div>
                ))
            }
        </div>
    )
}

export default BoutonEServices
