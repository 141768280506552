import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { Field, FieldRenderProps } from 'react-final-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormRadioField from './FormRadioField'
import styles from '../../theme/styles'
import { fieldModel } from './types/fieldsModel'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			labelStyle: {
				fontSize: 14,
				fontWeight: 600,
				textTransform: 'uppercase'
			},
			formControl: {
				width: '100%'
			},
			radioGroupRow: {
				flexDirection: 'row'
			},
			radioGroupColumn: {
				flexDirection: 'column'
			},
			smallGroup: {
				justifyContent: 'space-between'
			},
			largeGroup: {
				'& label:not(:first-child)': {
					marginLeft: 150
				}
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: '10px'
			},
			errorColor: {
				color: '#B92D00 !important'
			},
		}
	)
)

interface AcceptingProps {
	items: fieldModel[],
	textStyle: string,
	textColor: string,
	textWeight?: string,
	margin: boolean,
	rowDirection: boolean,
	spaceBetween?: boolean,
	label?: string
}

type RadioFieldProps = AcceptingProps & FieldRenderProps<string>

const FormRadioGroup: React.FC<RadioFieldProps> = (
	{
		meta: {
			touched,
			error
		},
		input,
		items,
		textStyle,
		textColor,
		textWeight,
		margin,
		rowDirection,
		spaceBetween = true,
		label
	}
) => {
	const classes = useStyles()
	const isMobileScreen = useMediaQuery(
		{
			query: '(max-width: 500px)'
		}
	)
	const radioGroupClass: ClassValue = classNames(
		rowDirection ? classes.radioGroupRow : classes.radioGroupColumn,
		{
			[classes.largeGroup]: !isMobileScreen && margin,
			[classes.smallGroup]: isMobileScreen && spaceBetween
		}
	)

	const optionClass: ClassValue = classNames(
		classes[textColor as keyof typeof styles],
		classes[textStyle as keyof typeof styles],
		classes[textWeight as keyof typeof styles]
	)

	return (
		<FormControl component="fieldset" className={classes.formControl}>
			{
				label && (
					<span
						className={
							classNames(
								classes.labelStyle,
								classes.text,
								classes.colorPrimary,
								touched && error && classes.errorColor
							)
						}
					>
						{label}
					</span>
				)
			}

			<RadioGroup
				className={radioGroupClass}
				{...input}
			>
				{items.map((item: fieldModel, index: number) =>
					<FormControlLabel
						key={`radio-${input.name}-${index}`}
						classes={{
							label: optionClass
						}}
						value={item.value}
						control={
							<Field
								type="radio"
								name={input.name}
								value={item.value}
								component={FormRadioField}
							/>
						}
						label={item.label}
					/>
				)}
			</RadioGroup>
			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</FormControl>
	)
}

export default FormRadioGroup
