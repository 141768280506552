import {AJOUTER_DROITS_ACTIONS, AJOUTER_DROITS_STOCK} from './actionTypes'

const droitsActions = (state = {}, action) => {

    switch (action.type) {
        case AJOUTER_DROITS_ACTIONS: {
            return {
                droitsActions: action.payload,
                droitsStock: state.droitsStock
            }
        }
        case AJOUTER_DROITS_STOCK: {
            return {
                droitsActions: state.droitsActions,
                droitsStock: action.payload
            }
        }
        default:
            return state
    }
}

export default droitsActions