export enum formulaireVosMontantsEnum {
	exercice = 'exercice'
}

export enum CLE_DETAILS_FISCALITE {
	M1 = 'M1',
	M2 = 'M2',
	M3 = 'M3',
	M4 = 'M4',
	M5 = 'M5',
	M6 = 'M6',
	M7 = 'M7',
}