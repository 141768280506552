import React from 'react'
import ListeDisponibilitesGrand from './ListeDisponibilitesGrand'
import ListeIndisponibilitesGrand from './ListeIndisponibilitesGrand'

interface ModelDisponibilites {
    id: number,
    date_disponibilite: Date,
    typologie: string,
    actions: string,
    prix_acquisition: number
}

interface ModelIndisponibilites {
    id: number,
    date_disponibilite: Date,
    actions: string,
    prix_acquisition: number,
    message: string
}

interface AcceptingProps {
    disponibilites: ModelDisponibilites[],
    indisponibilites: ModelIndisponibilites[]
}

type ListeActionsGrandProps = AcceptingProps

const ListeActionsGrand = (
    props: ListeActionsGrandProps
) => {
    const {
        disponibilites,
        indisponibilites
    } = props

    return (
        <>
            {
                disponibilites.length > 0 &&
                <div className="row">
                    <ListeDisponibilitesGrand disponibilites={disponibilites}/>
                </div>
            }

            {
                indisponibilites.length > 0 &&
                <div className="row">
                    <ListeIndisponibilitesGrand indisponibilites={indisponibilites}/>
                </div>
            }
        </>
    )
}

export default ListeActionsGrand

