import React, {Dispatch} from 'react'
import {compose} from 'recompose'
import {WithStyles, withStyles} from '@material-ui/core'
import styles from '../../theme/styles'
import {Field, InjectedFormProps, reduxForm} from 'redux-form'
import EnhancedFormTextField from '../../components/fields/FormTextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '../../components/button/Button'
import {connect} from 'react-redux'
import {afficherSnackbar} from '../reducers/snackBar/actions'
import errorCatcher from '../../utils/errorCatcher'
import {makeStyles} from '@material-ui/core/styles'
import fetchFactory from '../../utils/fetchFactory'
import FormRichText from '../../components/fields/FormRichText'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyle = makeStyles((theme) => (
    {
        ...styles(theme),
        marginBottom: {
            marginBottom: 20,

            '& h1': {
                margin: 0
            }
        },
        marginButton: {
            marginBottom: 20,

            '& button': {
                minHeight: 54
            }
        },
        button: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 40
        },
        upload: {
            marginLeft: 11
        },
        formulaireText: {
            fontWeight: 800,
            color: theme.palette.blue.main,
            fontFamily: theme.typography.fontFamilyMonserrat,
            fontSize: 12
        }
    }
))

interface FormData {
    titre: string,
    accroche: string,
    presentation: string,
    actualite: string
    libelle_1: string,
    libelle_2: string,
    url_1: string,
    url_2: string,
}

interface ModelSnackBarData {
    id: string
    message: string,
    open: boolean
}

interface Store {
    afficherSnackBar: (snackBarData: ModelSnackBarData) => void,
    errorCatcher: (error: any, id: string) => void,
}

const nomFormulaire: string = 'FormulaireAdministrationForm'
const messageConfirmation: string = 'Le contenu de la page d\'accueil a été modifié avec succès'
const titre: string = 'Titre de la page d\'accueil'
const accroche: string = 'Accroche de la page d\'accueil'
const presentation: string = 'Bloc de présentation de l\'espace actionnaire'
const leMotBrunel: string = 'Bloc "Le mot de Meka Brunel"'
const enregistrer: string = 'Enregistrer'
const annuler: string = 'Annuler'
const formulaireId: string = 'formulaire_administration'

type FormulaireAdministrationProps =
    InjectedFormProps<FormData>
    & WithStyles<typeof styles>
    & Store

const FormulaireAdministration: React.FC<FormulaireAdministrationProps> = (
    {
        afficherSnackBar,
        errorCatcher,
        handleSubmit,
        error,
        reset
    }
) => {

    const classes = useStyle()
    const [loading, setLoading] = React.useState<boolean>(false)

    const rowStyle: ClassValue = classNames(
        'row',
        classes.marginBottom
    )

    const onSubmit = (values: FormData) => {
        setLoading(true)

        const data = JSON.stringify({...values})
        fetchFactory('/api/bloc',
            {
                method: 'PUT',
                body: data
            }
        )
            .then(
                () => {
                    afficherSnackBar(
                        {
                            id: formulaireId,
                            message: messageConfirmation,
                            open: true
                        }
                    )
                }
            )
            .catch(
                (error: any) => {
                    errorCatcher(error, formulaireId)
                }
            )
            .finally(
                () => {
                    setLoading(false)

                }
            )

    }

    const cancelForm = () => {
        reset()
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
        >
            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="titre"
                        component={EnhancedFormTextField}
                        label={titre}
                        id="titre"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="accroche"
                        component={EnhancedFormTextField}
                        label={accroche}
                        id="accroche"
                        multiline
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="presentation"
                        component={FormRichText}
                        label={presentation}
                        id="presentation"
                    />
                </div>
            </div>

            <div
                className={rowStyle}
            >
                <div className="col-xs-12">
                    <Field
                        name="leMotBrunel"
                        component={FormRichText}
                        label={leMotBrunel}
                        id="leMotBrunel"
                    />
                </div>
            </div>

            <div className="row middle-xs center-xs reverse-xs">
                <div
                    className={classNames(
                        'col-xs-12 col-sm-4 last-sm',
                        classes.marginButton
                    )
                    }
                >
                    {
                        loading ? (
                            <CircularProgress color="primary"/>
                        ) : (
                            <Button
                                disabled={false}
                                type="submit"
                            >
                                {enregistrer}
                            </Button>
                        )
                    }
                </div>

                <div
                    className={classNames(
                        'col-xs-12 col-sm-4',
                        classes.marginButton
                    )
                    }
                >
                    <Button
                        type="button"
                        disabled={false}
                        onClick={cancelForm}
                    >
                        {annuler}
                    </Button>
                </div>
            </div>
            {
                error && <strong>{error}</strong>
            }
        </form>
    )
}

const validate = (values: FormData) => {
    const errors: FormData = {
        titre: '',
        accroche: '',
        presentation: '',
        actualite: '',
        libelle_1: '',
        url_1: '',
        libelle_2: '',
        url_2: ''
    }

    if (!values.titre) {
        errors.titre = 'Requis*'
    }
    if (!values.presentation) {
        errors.presentation = 'Requis*'
    }

    return errors
}

const mapStateToProps = (state: any) => {
    return {
        initialValues: state.formulaireAdministration
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        afficherSnackBar: (snackBarData: ModelSnackBarData) => {
            dispatch(afficherSnackbar(snackBarData))
        },
        errorCatcher: (error: any, id: string) => {
            dispatch(errorCatcher(error, id))
        }
    }
}

const EnhancedFormulaireAdministration = compose<FormulaireAdministrationProps, {}>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: nomFormulaire,
        validate,
        enableReinitialize: true
    }),
    withStyles(styles)
)(FormulaireAdministration)

export default EnhancedFormulaireAdministration
