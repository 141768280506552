import fetchFactory from '../../../utils/fetchFactory'
import types, { configEnum } from '../../../enum/fetchFactoryEnum'

export const postBourseForm = (bourseForm: FormData) => fetchFactory('/api/bourse_form',
	{
		method: configEnum.post,
		body: bourseForm
	},
	types.blob
)
