import {makeStyles} from '@material-ui/core/styles'
import styles from '../../theme/styles'
import {useHistory, useLocation} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import Links from '../../enum/links'
import React from 'react'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    rowStyleGrand: {
        height: 92,
        width: '100%',
        margin: 0
    },
    rowStylePetit: {
        height: 'fit-content',
        width: '100%',
        marginLeft: 0
    },
    itemStyleGrand: {
        height: 92,
        width: '50%',
        borderBottom: '1px solid #D8D8D8'
    },
    itemSelectedGrand: {
        height: 92,
        width: '50%',
        borderBottom: '2px solid #c2ad8a'
    },
    itemStylePetit: {
        height: 114,
        width: '50%',
        borderBottom: '1px solid #D8D8D8'
    },
    itemSelectedPetit: {
        width: '50%',
        borderBottom: '2px solid #c2ad8a'
    },
    containerStyleGrand: {
        marginTop: -53,
        backgroundColor: 'white',
        marginBottom: 20
    },
    containerStylePetit: {
        marginTop: -32,
        backgroundColor: 'white'

    },
    contenuStyleGrand: {
        height: '100%',
        paddingLeft: 20,
        '& span': {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamilyMonserrat,
            fontWeight: 600,
            opacity: 0.5
        },
        '& img': {
            marginRight: 26,
            marginTop: 32,
            marginBottom: -8,
            marginLeft: 26
        }
    },
    contenuSelectedGrand: {
        height: '100%',
        paddingLeft: 20,
        '& span': {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamilyMonserrat,
            fontWeight: 600
        },
        '& img': {
            marginRight: 26,
            marginTop: 32,
            marginBottom: -8,
            marginLeft: 26
        }
    },
    contenuStylePetit: {
        height: '100%',
        '& span': {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamilyMonserrat,
            fontWeight: 600,
            opacity: 0.5,
            textAlign: 'center',
            fontSize: 13,
            padding: '0px 30%',
            placeContent: 'center'
        },
        '& img': {
            marginTop: 24,
            marginBottom: 9,
            marginLeft: '46%'
        }
    },
    contenuSelectedPetit: {
        '& span': {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamilyMonserrat,
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 13,
            padding: '0px 30%',
            placeContent: 'center'
        },
        '& img': {
            marginTop: 24,
            marginBottom: 9,
            marginLeft: '46%'
        }
    }
}))

const OngletsEServices = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const isLargeScreen = useMediaQuery(
        {
            query: '(min-width: 1025px)'
        }
    )

    const lien = location.pathname

    const rowsContainer: ClassValue = classNames(
        'row',
        isLargeScreen ? classes.containerStyleGrand : classes.containerStylePetit
    )

    const rowStyle: ClassValue = classNames(
        'row',
        isLargeScreen ? classes.rowStyleGrand : classes.rowStylePetit
    )

    const itemStyle: ClassValue = classNames(
        isLargeScreen ? classes.itemStyleGrand : classes.itemStylePetit,
        classes.cursorPointer
    )

    const itemSelectedStyle: ClassValue = classNames(
        isLargeScreen ? classes.itemSelectedGrand : classes.itemSelectedPetit
    )

    const contenuStyle: ClassValue = classNames(
        isLargeScreen ? classes.contenuStyleGrand : classes.contenuStylePetit
    )

    const contenuSelectedStyle: ClassValue = classNames(
        isLargeScreen ? classes.contenuSelectedGrand : classes.contenuSelectedPetit
    )

    const rowClass: ClassValue = classNames(
        isLargeScreen ? '' : 'row'
    )
    return (
        <>
            {
                <div className={rowsContainer}>
                    <div className={rowStyle}>
                        <div className={Links.eServices === lien ? itemSelectedStyle : itemStyle}
                             onClick={() => history.push(Links.eServices)}
                        >
                            <div className={Links.eServices.includes(lien) ? contenuSelectedStyle : contenuStyle}>
                                <img
                                    src="/img/picto_convocation.png"
                                    alt="Document icon"
                                    width={isLargeScreen ? 32 : 24}
                                    height={isLargeScreen ? 32 : 24}
                                    className={rowClass}
                                />
                                <span className={rowClass}>La e-convocation (Assemblée Générale)</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default OngletsEServices