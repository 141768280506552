import React from 'react'
import NumberFormat from 'react-number-format'
import { mapProps } from 'recompose'
import EnhancedFormTextField from './FormTextField'

// Wrapped component, we had to redefine functions because NumberFormat uses the same callbacks than the FormInput so we had to adapt them to FormInput
const Wrapped = mapProps(
	(
		{
			reduxProps: {
				input: {
					onFocus,
					onBlur
				},
				...others
			},
			onChange,
			value
		}: any
	) => {
		return {
			input: {
				onChange,
				onFocus,
				onBlur: () => {
					onBlur(undefined)
				},
				value
			},
			...others
		}
	})(EnhancedFormTextField)

// Component used to format entered number with thousand separator
const FormNumberMaskField = (reduxFormProps: any) => {
	return (
		<NumberFormat
			customInput={Wrapped}
			onValueChange={
				(values: any) => {
					reduxFormProps.input.onChange(values.value)
				}
			}
			format={reduxFormProps.formatMask}
			reduxProps={reduxFormProps}
			value={reduxFormProps.input.value}
			allowEmptyFormatting
		/>
	)
}

export default FormNumberMaskField