import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Links from '../../enum/links'
import React from 'react'
import { Badge, withStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from '../../modules/reducers'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	rowStyleGrand: {
		height: 92,
		width: '100%',
		margin: 0
	},
	rowStylePetit: {
		height: 114,
		width: '100%',
		marginLeft: 0
	},
	itemStyleGrand: {
		height: 92,
		width: '50%',
		borderBottom: '1px solid #D8D8D8'
	},
	itemSelectedGrand: {
		height: 92,
		width: '50%',
		borderBottom: '2px solid #c2ad8a'
	},
	itemStylePetit: {
		height: 114,
		width: '50%',
		borderBottom: '1px solid #D8D8D8'
	},
	itemSelectedPetit: {
		height: 114,
		width: '50%',
		borderBottom: '2px solid #c2ad8a'
	},
	containerStyleGrand: {
		marginTop: -53,
		backgroundColor: 'white'
	},
	containerStylePetit: {
		marginTop: -32,
		backgroundColor: 'white'

	},
	contenuStyleGrand: {
		height: '100%',
		paddingLeft: 20,
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600,
			opacity: 0.5
		},
		'& img': {
			marginRight: 26,
			marginTop: 32,
			marginBottom: -8,
			marginLeft: 26
		}
	},
	contenuSelectedGrand: {
		height: '100%',
		paddingLeft: 20,
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600
		},
		'& img': {
			marginRight: 26,
			marginTop: 32,
			marginBottom: -8,
			marginLeft: 26
		}
	},
	contenuStylePetit: {
		height: '100%',
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600,
			opacity: 0.5,
			textAlign: 'center',
			fontSize: 13,
			padding: '0px 30%',
			placeContent: 'center'
		},
		'& img': {
			marginTop: 24,
			marginBottom: 9,
			marginLeft: '46%'
		}
	},
	contenuSelectedPetit: {
		'& span': {
			color: theme.palette.primary.main,
			fontFamily: theme.typography.fontFamilyMonserrat,
			fontWeight: 600,
			textAlign: 'center',
			fontSize: 13,
			padding: '0px 30%',
			placeContent: 'center'
		},
		'& img': {
			marginTop: 24,
			marginBottom: 9,
			marginLeft: '46%'
		}
	}
}))

interface ModelDocumentsUtiles {
	id: number,
	id_docuware: number,
	libelle: string,
	date_creation: Date
}

interface AcceptingProps {
	documentsUtiles: ModelDocumentsUtiles[]
}

const CustomBadgeLargeScreen = withStyles(
	() => (
		{
			root: {
				height: '100%'
			},
			badge: {
				top: 30,
				right: -10
			}
		}
	)
)(Badge)

const CustomBadgeSmallScreen = withStyles(
	() => (
		{
			badge: {
				top: 60,
				right: 15
			}
		}
	)
)(Badge)

const UNDEFINED = 'undefined'

const OngletsDocuments: React.FC<AcceptingProps> = (
	{
		documentsUtiles
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const utilisateur = useSelector((state: RootState) => state.utilisateur)

	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const lien = location.pathname

	const rowsContainer: ClassValue = classNames(
		'row',
		isLargeScreen ? classes.containerStyleGrand : classes.containerStylePetit
	)

	const rowStyle: ClassValue = classNames(
		'row',
		isLargeScreen ? classes.rowStyleGrand : classes.rowStylePetit
	)

	const itemStyle: ClassValue = classNames(
		isLargeScreen ? classes.itemStyleGrand : classes.itemStylePetit,
		classes.cursorPointer
	)

	const itemSelectedStyle: ClassValue = classNames(
		isLargeScreen ? classes.itemSelectedGrand : classes.itemSelectedPetit
	)

	const contenuStyle: ClassValue = classNames(
		isLargeScreen ? classes.contenuStyleGrand : classes.contenuStylePetit
	)

	const contenuSelectedStyle: ClassValue = classNames(
		isLargeScreen ? classes.contenuSelectedGrand : classes.contenuSelectedPetit
	)

	const rowClass: ClassValue = classNames(
		isLargeScreen ? '' : 'row'
	)
	return (
		<>
			{
				<div className={rowsContainer}>
					<div className={rowStyle}>
						<div className={Links.documentsUtiles === lien ? itemSelectedStyle : itemStyle}
						     onClick={() => history.push(Links.documentsUtiles)}
						>
							<div className={Links.documentsUtiles.includes(lien) ? contenuSelectedStyle : contenuStyle}>
								<img
									src="/img/pictos_guide.svg"
									alt="Document icon"
									width={isLargeScreen ? 32 : 24}
									height={isLargeScreen ? 32 : 24}
									className={rowClass}
								/>
								<span className={rowClass}>Documents utiles</span>
							</div>
						</div>
						{typeof documentsUtiles !== UNDEFINED && documentsUtiles.length > 0 &&
						<div className={Links.documentsPerso === lien ? itemSelectedStyle : itemStyle}
						     onClick={() => history.push(Links.documentsPerso)}
						>
							{
								isLargeScreen ? (
									<CustomBadgeLargeScreen
										badgeContent={utilisateur.nbDocuments}
										color="primary"
										invisible={utilisateur.nbDocuments === 0}
									>
										<div className={!Links.documentsUtiles.includes(lien) ? contenuSelectedStyle : contenuStyle}>
											<img
												src="/img/picto-document.png"
												alt="Recherche icon"
												width={isLargeScreen ? 32 : 24}
												height={isLargeScreen ? 32 : 24}
											/>
											<span className={rowClass}>Documents personnels</span>
										</div>
									</CustomBadgeLargeScreen>
								) : (
									<CustomBadgeSmallScreen
										badgeContent={utilisateur.nbDocuments}
										color="primary"
										invisible={utilisateur.nbDocuments === 0}
									>
										<div className={!Links.documentsUtiles.includes(lien) ? contenuSelectedStyle : contenuStyle}>
											<img
												src="/img/picto-document.png"
												alt="Recherche icon"
												width={isLargeScreen ? 32 : 24}
												height={isLargeScreen ? 32 : 24}
											/>
											<span className={rowClass}>Documents personnels</span>
										</div>
									</CustomBadgeSmallScreen>
								)
							}
						</div>
						}
					</div>
				</div>
			}
		</>
	)
}
export default OngletsDocuments
