import formatNumber from './formatNumber'

const formatDate = (date: Date) => {
    return date && date.toString() !== 'Invalid Date' ? new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).format(new Date(date)) : ''
}

export const formatInputDate = (date: Date) => {
    if (date && date.toString() === 'Invalid Date') {
        return ''
    }

    const day = formatNumber(date.getDate(), 2)
    const month = formatNumber(date.getMonth() + 1, 2)
    let year = formatNumber(date.getFullYear(), 4)

    year = year.toString().substr(0, 4)

    return `${year}-${month}-${day}`
}

export default formatDate

export const getNextDate = () => {
    let newDate = new Date()
    newDate.setDate(newDate.getDate() + 1)
    const date = newDate.getDate()
    const month = newDate.getMonth() + 1
    const year = newDate.getFullYear()
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : date}`
}
