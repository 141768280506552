import React from 'react'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../theme/styles'
import formatDate from '../../../utils/formatDate'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    ...styles(theme),
    borderBottom: {
        borderBottom: '1px solid #0000001E',
        paddingBottom: 10,
        paddingTop: 10,
        marginBottom: 10
    },
    marginBottom: {
        marginBottom: 20
    },
    textDecoration: {
        textDecoration: 'underline'
    },
    marginLeft: {
        marginLeft: 10
    },
    opacity: {
        opacity: 0.5
    },
    libelleStyle: {
        fontSize: 12,
        fontWeight: 300
    },
    valeurPlanStyle: {
        fontSize: 15,
        fontWeight: 600
    },
    valeurStyle: {
        fontSize: 15,
        fontWeight: 600
    },
    smallTextStyle: {
        fontSize: 10,
        fontWeight: 600
    }
}))

interface ModelDroitsStock {
    id: number,
    libelle_plan: string,
    quantite: number
    prix_offre?: number,
    date_debut_plan?: Date,
    date_fin_plan?: Date,
    bold?: boolean
}

interface AcceptingProps {
    droitsStock: ModelDroitsStock[],
    telechargement: (id: number, type: string) => void
}

const LIBELLE_PLAN: string = 'Plan'
const DATE_DEBUT: string = 'Date de début de plan'
const DATE_FIN: string = 'Date de fin de plan'
const PRIX_OFFRE: string = 'Prix de l\'offre'
const QUANTITE_ATTRIBUEE: string = 'Quantité à exercer'
const REGLEMENT_PLAN: string = 'Télécharger le règlement du plan'

type ListeDroitsStockPetitProps = AcceptingProps

const ListeDroitsStockPetit = (
    props: ListeDroitsStockPetitProps
) => {
    const {
        droitsStock,
        telechargement
    } = props

    const classes = useStyles()

    const telechargerDocument = (id: number) => {
        telechargement(id, 'stock')
    }

    const containerStyle: ClassValue = classNames(
        'col-xs-12',
        classes.marginBottom
    )

    const rowStyle: ClassValue = classNames(
        'row middle-xs',
        classes.marginBottom
    )

    const titreStyle: ClassValue = classNames(
        classes.titleSize,
        classes.colorPrimary,
        classes.text,
        classes.bold,
        classes.marginBottom
    )
    const libelleStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.opacity,
        classes.libelleStyle
    )
    const valeurPlanStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.valeurPlanStyle
    )
    const valeurStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.valeurStyle
    )
    const smallTextStyle: ClassValue = classNames(
        classes.text,
        classes.colorPrimary,
        classes.smallTextStyle
    )

    return (
        <div className={containerStyle}>
            {
                droitsStock.reduce((a: number, b: ModelDroitsStock) => a + (Number(b.quantite.toString().replace(/\s/g, '')) || 0), 0) > 0 ?
                    <>
                        <div
                            className={titreStyle}
                        >
                            <p>Droits à stock-options</p>
                        </div>


                        {
                            droitsStock.map((droitStock, index) => (
                                    <div key={droitStock.id}>
                                        {droitStock.libelle_plan !== 'Total' ?
                                            <>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">
                                                    <span
                                                        className={libelleStyle}
                                                    >
                                                        {LIBELLE_PLAN}
                                                    </span>
                                                                <br/>
                                                                <span
                                                                    className={valeurPlanStyle}
                                                                >
                                                        {droitStock.libelle_plan}
                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classNames(
                                                        [
                                                            'row',
                                                            index === droitsStock.length - 1 ? '' : classes.borderBottom
                                                        ]
                                                    )}
                                                >
                                                    <div className="col-xs-12">
                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">
                                                    <span
                                                        className={libelleStyle}
                                                    >
                                                        {DATE_DEBUT}
                                                    </span>

                                                                <br/>

                                                                <span
                                                                    className={valeurStyle}
                                                                >
                                                        {droitStock.date_debut_plan && formatDate(droitStock.date_debut_plan)}
                                                    </span>
                                                            </div>

                                                            <div className="col-xs-6 end-xs">
                                                    <span
                                                        className={libelleStyle}
                                                    >
                                                        {DATE_FIN}
                                                    </span>

                                                                <br/>

                                                                <span
                                                                    className={valeurStyle}
                                                                >
                                                        {droitStock.date_fin_plan && formatDate(droitStock.date_fin_plan)}
                                                    </span>
                                                            </div>
                                                        </div>

                                                        <div className={rowStyle}>
                                                            <div className="col-xs-6">
                                                    <span
                                                        className={libelleStyle}
                                                    >
                                                        {PRIX_OFFRE}
                                                    </span>

                                                                <br/>

                                                                <span
                                                                    className={valeurStyle}
                                                                >
                                                        {droitStock.prix_offre !== undefined ? `${thousandsSeparator(droitStock.prix_offre)} €` : ''}
                                                    </span>
                                                            </div>

                                                            <div className="col-xs-6 end-xs">
                                                    <span
                                                        className={libelleStyle}
                                                    >
                                                        {QUANTITE_ATTRIBUEE}
                                                    </span>

                                                                <br/>

                                                                <span
                                                                    className={valeurStyle}
                                                                >
                                                        {thousandsSeparator(droitStock.quantite, true)}
                                                    </span>
                                                            </div>
                                                        </div>

                                                        {droitStock.libelle_plan !== 'Total' ? (
                                                                <div className={rowStyle}>
                                                                    <div className="col-xs-2">
                                                                    </div>

                                                                    <div className="col-xs-10 end-xs">
                                                            <span
                                                                onClick={() => telechargerDocument(droitStock.id)}
                                                                className={smallTextStyle}
                                                            >
                                                                {REGLEMENT_PLAN}
                                                                <span className={classes.marginLeft}>
                                                                    <img
                                                                        src="/img/pictos_telechargement.svg"
                                                                        alt="telechargement icon"
                                                                        width="20"
                                                                        height="20"
                                                                    />
                                                                </span>
                                                            </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                            : ('')
                                                        }

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className={'row'}>
                                                    <div className="col-xs-12">
                                                        <div className={rowStyle}>
                                                            <div className="col-xs-4">
                                                    <span
                                                        className={valeurStyle}
                                                    >
                                                        {droitStock.libelle_plan}
                                                    </span>
                                                            </div>
                                                            <div className="col-xs-4"/>
                                                            <div className="col-xs-4 end-xs">
                                                    <span
                                                        className={libelleStyle}
                                                    >
                                                        {QUANTITE_ATTRIBUEE}
                                                    </span>
                                                                <br/>
                                                                <span
                                                                    className={valeurStyle}
                                                                >
                                                        {thousandsSeparator(droitStock.quantite, true)}
                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>

                                )
                            )
                        }
                    </>
                    :
                    <></>
            }
        </div>
    )
}

export default ListeDroitsStockPetit
