import React from 'react'
import MediaQuery from 'react-responsive'
import EnhancedEntete from '../../components/entete/Entete'
import CorpsEServices from './CorpsEServices'
import OngletsEServices from '../../components/onglets/OngletsEServices'

const TITRE: string = 'Vos e-services'

const EServices = () => {
    return (
        <>
            <EnhancedEntete titre={TITRE} />
            <OngletsEServices />
            <MediaQuery
                maxWidth={1024}
            >

                {
                    (match) => {
                        return match ? (
                            <>
                                <CorpsEServices tailleEcran="petit" />
                            </>
                        ) : (
                            <>
                                <CorpsEServices tailleEcran="grand" />
                            </>
                        )
                    }
                }
            </MediaQuery>
        </>
    )
}

export default EServices
