import React, { useEffect } from 'react'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import styles from '../../theme/styles'
import Button from '../../components/button/Button'
import { makeStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'

const useStyles = makeStyles(theme => ({
	buttonContainer: {
		paddingBottom: '20px',

		'& button': {
			minHeight: '54px'
		}
	}
}))

interface ModelBouton {
	id: number,
	texte: string
}

interface AcceptingProps {
	openDialog: () => void
}

const texteDemande: string = 'Demande de modification'

type BoutonDonneesPersonnellesProps = WithStyles<typeof styles> & AcceptingProps

const BoutonDonneesPersonnelles = (
	props: BoutonDonneesPersonnellesProps
) => {
	const {
		classes,
		openDialog
	} = props

	const customClasses = useStyles()

	const [boutons, setBoutons] = React.useState<ModelBouton[]>([])

	useEffect(() => {
		setBoutons([
			{
				id: 1,
				texte: texteDemande
			}
		])
	}, [])

	const onButtonClicked = () => {
		openDialog()
	}

	return (
		<div>
			{
				boutons.map(bouton => (
					<div
						className={customClasses.buttonContainer}
						key={bouton.id}
					>
						<Button
							disabled={false}
							onClick={onButtonClicked}
						>
							<span className={classes.textSize}>{bouton.texte}</span>
						</Button>
					</div>
				))
			}
		</div>
	)
}

const EnhancedBoutonDonneesPersonnelles = compose<BoutonDonneesPersonnellesProps, AcceptingProps>(
	withStyles(styles)
)(BoutonDonneesPersonnelles)

export default EnhancedBoutonDonneesPersonnelles
