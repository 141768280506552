import React, { useCallback, useEffect, useState } from 'react'
import { DetailsFiscaliteModel } from '../types/vosMontantsModel'
import { Grid, makeStyles } from '@material-ui/core'
import { CONSTANTES_FISCALITE } from '../../../utils/constantes/fiscalite'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import styles from '../../../theme/styles'
import EnhancedTableauSimple, { ModelColonnes } from '../../../components/table/TableauSimple'
import { TableauFiscalitesModel } from '../types/optionFiscaleModel'
import { CLE_DETAILS_FISCALITE } from '../enums/formulaireVosMontantsEnum'

const useStyles = makeStyles(
	(theme) => ({
		tableauContainer: {
			'& table': {
				maxWidth: '100%'
			}
		},
		messageAnneeFiscaleEnCours: {
			...styles(theme).textSize,
			...styles(theme).text,
			...styles(theme).semiBold,
			color: 'white',
			backgroundColor: theme.palette.brown.main,
			padding: 10,
			marginTop: 20,

			'& p': {
				margin: 0
			}
		}
	})
)

interface AcceptingProps {
	detailsFiscalite: DetailsFiscaliteModel,
	annee: number
}

type DetailsFiscaliteProps = AcceptingProps

const DetailsFiscalite: React.FC<DetailsFiscaliteProps> = (
	{
		detailsFiscalite,
		annee
	}
) => {
	const classes = useStyles()

	/**
	 * Mapping des rubriques des fiscalités selon la clé fournie en param
	 * @param cle
	 */
	const mapDetailSelonCle = useCallback(
		(cle: string) => {
			switch (cle) {
				case CLE_DETAILS_FISCALITE.M1:
					return [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANT_BRUT,
							montant: thousandsSeparator(detailsFiscalite.M1!, true)
						},
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANT_PS,
							montant: thousandsSeparator(detailsFiscalite.M1!, true)
						}
					]
				case CLE_DETAILS_FISCALITE.M2:
					return [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.CUMUL_PRELEVEMENT,
							montant: thousandsSeparator(detailsFiscalite.M2!, true)
						}
					]
				case CLE_DETAILS_FISCALITE.M3:
					const ligneM3 = [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.CUMUL_CREDIT,
							montant: thousandsSeparator(detailsFiscalite.M3!, true)
						}
					]

					if (detailsFiscalite.M1 && detailsFiscalite.M1 > 0) {
						ligneM3.push(
							{
								rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANT_NET,
								montant: thousandsSeparator(detailsFiscalite.M1 - (detailsFiscalite.M2 ?? 0) - (detailsFiscalite.M3 ?? 0))
							}
						)
					}

					return ligneM3
				case CLE_DETAILS_FISCALITE.M4:
					return [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.BASE_PRELEVEMENT,
							montant: thousandsSeparator(detailsFiscalite.M4!, true)
						}
					]
				case CLE_DETAILS_FISCALITE.M5:
					const ligneM5 = [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANT_PRELEVEMENT,
							montant: thousandsSeparator(detailsFiscalite.M5!, true)
						}
					]

					if (detailsFiscalite.M4 && detailsFiscalite.M4 > 0) {
						ligneM5.push(
							{
								rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANT_NET,
								montant: thousandsSeparator(detailsFiscalite.M4 - (detailsFiscalite.M5 ?? 0))
							}
						)
					}

					return ligneM5
				case CLE_DETAILS_FISCALITE.M6:
					return [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANT_CESSION,
							montant: thousandsSeparator(detailsFiscalite.M6!, true)
						}
					]
				default:
				case CLE_DETAILS_FISCALITE.M7:
					return [
						{
							rubrique: CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANTS_EXONERES,
							montant: thousandsSeparator(detailsFiscalite.M7!, true)
						}
					]
			}
		}, [detailsFiscalite]
	)

	const [lignes, setLignes] = useState<TableauFiscalitesModel[]>([])

	useEffect(
		() => {
			const cumulLignes: TableauFiscalitesModel[] = []

			Object.keys(detailsFiscalite)
				// On filtre pour ne récupérer que les clés possédant des valeurs
				.reduce(
					(acc: string[], detailFiscalite) => {
						if (detailsFiscalite[detailFiscalite as keyof typeof detailsFiscalite]) {
							acc.push(detailFiscalite)
						}

						return acc
					}, []
				)
				// On mappe pour renvoyer les lignes avec la bonne rubrique et le bon montant selon la clé
				.map((cleDetail) => mapDetailSelonCle(cleDetail))
				.forEach((detailMappe) => cumulLignes.push(...detailMappe))

			setLignes(cumulLignes)
		}, [detailsFiscalite, mapDetailSelonCle]
	)

	const colonnes: ModelColonnes[] = [
		{
			value: 'rubrique',
			label: 'Rubriques',
			type: 'string',
			render: (row: TableauFiscalitesModel) => (
				<div>
					{row.rubrique}
				</div>
			)
		},
		{
			value: 'montant',
			label: 'Montants',
			type: 'number',
			render: (row: TableauFiscalitesModel) => (
				<div style={{ width: 110 }}>
					{row.montant} €
				</div>
			)
		}
	]

	const afficherMessageAnneeFiscaleEnCours = () => {
		const anneeCorrecte = annee === new Date().getFullYear()
		const M1Correcte = detailsFiscalite.M1 && detailsFiscalite.M1 > 0
		const M2Correcte = detailsFiscalite.M2 && detailsFiscalite.M2 > 0
		const M3Correcte = detailsFiscalite.M3 && detailsFiscalite.M3 > 0
		const M4Correcte = detailsFiscalite.M4 && detailsFiscalite.M4 > 0
		const M5Correcte = detailsFiscalite.M5 && detailsFiscalite.M5 > 0

		return anneeCorrecte && (M1Correcte || M2Correcte || M3Correcte || M4Correcte || M5Correcte)
	}

	return (
		<>
			<Grid container item xs={12} className={classes.tableauContainer}>
				<EnhancedTableauSimple
					colonnes={colonnes}
					lignes={lignes}
				/>
			</Grid>

			{
				afficherMessageAnneeFiscaleEnCours() && (
					<Grid
						container
						item
						xs={12}
						direction="column"
						alignItems="center"
						className={classes.messageAnneeFiscaleEnCours}
					>
						<p>{CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANTS_DONNEES}</p>
						<p>{CONSTANTES_FISCALITE.VOS_MONTANTS.MONTANTS_FISCAUX}</p>
					</Grid>
				)
			}
		</>
	)
}

export default DetailsFiscalite