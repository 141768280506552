import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import styles from '../../../theme/styles'
import {
	mfaActivatePostModel,
	mfaCheckPostModel,
	mfaModel,
	mfaResendCodePostModel,
	mfaResponseModel
} from '../type/logInModel'
import { configEnum } from '../../../enum/fetchFactoryEnum'
import errorCatcher from '../../../utils/errorCatcher'
import ErrorCard from '../../../components/errorCard/ErrorCard'
import { errors, mfa } from '../utils/commonConst'
import FormTextField from '../../../components/fields/FormTextField'
import Link from '../../../components/link/Link'
import CheckBoxField from '../../../components/fields/CheckBoxField'
import Button from '../../../components/button/Button'

const CODE_VERIFIER: string = 'code_verifier'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	titleStyle: {
		padding: '15px 0px',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center'
	},
	linkStyle: {
		padding: '25px 0px',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	descriptionStyle: {
		paddingBottom: 25
	},
	checkBoxStyle: {
		paddingBottom: 10
	},
	betweenField: {
		paddingBottom: 15
	}
}))

interface AcceptingProps {
	mfaInfo: mfaResponseModel,
	setGoToMFA: (value: boolean) => void,
	isMFA_ENROLL: boolean
}

type MFAProps = AcceptingProps

const MFA: React.FC<MFAProps> = (
	{
		setGoToMFA,
		mfaInfo,
		isMFA_ENROLL
	}
) => {
	const classes = useStyle()
	const dispatch = useDispatch()
	const [showMessage, setShowMessage] = useState<boolean>(false)
	const [incorrectCode, setIncorrectCode] = React.useState<boolean>(false)

	const titleClass: ClassValue = classNames(
		'col-xs-12',
		classes.titleStyle,
		classes.heavy,
		classes.title4,
		classes.colorPrimary
	)

	const descriptionClass: ClassValue = classNames(
		classes.colorPrimary,
		classes.text3,
		classes.descriptionStyle
	)

	const textClass: ClassValue = classNames(
		classes.colorPrimary,
		classes.text3
	)

	const containerErrorClass: ClassValue = classNames(
		'col-xs-12',
		classes.betweenField
	)

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowMessage(true)
		}, 30000)

		return () => clearTimeout(timeout)
	}, [])

	const backToLogIn = () => {
		setGoToMFA(false)
	}

	const onSubmit = (values: mfaModel) => {
		if (!isMFA_ENROLL) {
			const dataMFAChek: mfaCheckPostModel = {
				code_verification: values.verificationCode,
				factor_id: mfaInfo.factor_id,
				state_token: mfaInfo.state_token,
				rememberChoice: values.rememberChoice
			}

			fetch('/api/okta/MFA/check',
				{
					body: JSON.stringify(dataMFAChek),
					method: configEnum.post
				})
				.then(
					(response: Response) => response.json()
						.then(
							(checkMFAResponse: any) => {
								if (!checkMFAResponse.status) {
									localStorage.setItem(CODE_VERIFIER, checkMFAResponse.code_verifier)
									window.location.href = checkMFAResponse.redirect
								} else {
									setIncorrectCode(true)
								}
							}
						)
				)
				.catch(
					(error) => {
						dispatch(
							errorCatcher(error, 'check send code')
						)
					}
				)
		} else {
			const dataMFAActivate: mfaActivatePostModel = {
				pass_code: values.verificationCode,
				factor_id: mfaInfo.factor_id,
				state_token: mfaInfo.state_token
			}
			fetch('/api/okta/MFA/activate',
				{
					body: JSON.stringify(dataMFAActivate),
					method: configEnum.post
				})
				.then(
					(response: Response) => response.json()
						.then(
							(activateMFAResponse: any) => {
								if (!activateMFAResponse.status) {
									localStorage.setItem(CODE_VERIFIER, activateMFAResponse.code_verifier)
									window.location.href = activateMFAResponse.redirect
								} else {
									setIncorrectCode(true)
								}
							}
						)
				)
				.catch(
					(error) => {
						dispatch(
							errorCatcher(error, 'activate send code')
						)
					}
				)
		}
	}

	const validate = (
		values: mfaModel
	) => {
		const error: any = {}

		if (!values.verificationCode) {
			error.verificationCode = errors.required
		}

		return error
	}

	const sendCode = () => {

		const dataResendCode: mfaResendCodePostModel = {
			factor_id: mfaInfo.factor_id,
			state_token: mfaInfo.state_token
		}

		fetch('/api/okta/MFA/resend',
			{
				body: JSON.stringify(dataResendCode),
				method: configEnum.post
			})
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, 'resend code')
					)
				}
			)
	}

	const emailToSendCode = () => {
		return (
			<b>{mfaInfo.email}</b>
		)
	}

	return (
		<>
			<div className={titleClass}>
				{mfa.title}
			</div>

			{
				incorrectCode &&
				<div className={containerErrorClass}>
					<ErrorCard message={errors.verificationCodeKO} />
				</div>
			}

			<div className="col-xs-12">
				<div className={descriptionClass}>
					{`Un code de vérification a été envoyé à ${emailToSendCode}. Vérifiez vos emails et entrez le code ci-dessous.`}
				</div>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					initialValues={{
						rememberChoice: true
					}}
					render={
						(
							{
								handleSubmit
							}
						) => (

							<form
								onSubmit={handleSubmit}
							>
								<div>

									<div>
										<Field
											name="verificationCode"
											component={FormTextField}
											placeholder={mfa.verificationCode}
										/>
									</div>

									{showMessage &&
									<div className={classes.descriptionStyle}>
										<div className={textClass}>
											{mfa.resendCode}
										</div>
										<Link
											textStyle="text4"
											color="colorPrimary"
											onClick={sendCode}
										>
											{mfa.sendAgain}
										</Link>
									</div>
									}

									{
										!isMFA_ENROLL && <div className={classes.checkBoxStyle}>
											<Field
												name="rememberChoice"
												component={CheckBoxField}
												label={mfa.saveChoice}
												color="colorPrimary"
												textStyle="text3"
												type="checkbox"
											/>
										</div>
									}

									<Button
										background="backgroundPrimary"
										color="colorTertiary"
										type="submit"
										disabled={false}
									>
										{mfa.verify}
									</Button>
								</div>
							</form>

						)}
				/>
			</div>

			<div className={classes.linkStyle}>
				<Link
					textStyle="text8"
					color="colorPrimary"
					onClick={backToLogIn}
				>
					{mfa.disconnect}
				</Link>
			</div>
		</>
	)
}

export default MFA
